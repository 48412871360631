import { checkRole } from "../Services/Utils/utils";

const secondsToHMS = (_seconds) => {
  _seconds = Number(_seconds);
  var hours = Math.floor(_seconds / 3600);
  var minutes = Math.floor((_seconds % 3600) / 60);

  var hourDisplay = hours > 0 ? hours + (hours === 1 ? " hr, " : " hr, ") : "";
  var minuteDisplay =
    minutes > 0 ? minutes + (minutes === 1 ? " min " : " min ") : "";
  return hourDisplay + minuteDisplay;
};

const formatSeconds = (_seconds, type) => {
  _seconds = Number(_seconds);
  var hours = Math.floor(_seconds / 3600);
  var minutes = Math.floor((_seconds % 3600) / 60);
  var seconds = _seconds % 60;

  var hourDisplay = hours > 0 ? hours + (hours === 1 ? " hr, " : " hr, ") : "";
  var minuteDisplay =
    minutes > 0 ? minutes + (minutes === 1 ? " min " : " min ") : "";

  var secondDisplay = seconds + (seconds <= 1 ? " sec " : " secs ");
  if (type == "hh:mm:ss") {
    return hours + ":" + minutes + ":" + seconds;
  }
  return hourDisplay + minuteDisplay + secondDisplay;
};

const getTotalSeconds = (assessments, filter) => {
  // console.log("filter :>> ", filter);
  let seconds = 0;

  assessments?.map((_assessment) => {
    let final;
    const first = +_assessment.totalTime.split(":")[0] * 60;
    const second = +_assessment.totalTime.split(":")[1];
    final = first + second;
    if (filter) {
      if (filter === "avg") final = final / assessments.length;
    }
    return (seconds = seconds + final);
  });

  return secondsToHMS(seconds);
};

const getAvgScoreALS = (assessments) => {
  let totalScore = assessments.reduce((t, c) => {
    t = t + c["overallAccuracy"];
    return t;
  }, 0);

  const avgScore = Math.round(totalScore / assessments.length);
  return avgScore;
};

const getAETrueCount = (assessments) => {
  let count = assessments.reduce((t, c) => {
    if (c["abcde"] === true) t = t + 1;
    return t;
  }, 0);

  return `${count} / ${assessments.length}`;
};

const groupUserAssessments = (assessments) => {
  const _assessmentDataResults = [...assessments];
  // console.log(
  //   "scatter cb _assessmentDataResults :>> ",
  //   _assessmentDataResults
  // );
  return _assessmentDataResults.reduce((r, a) => {
    r[a.uuid] = [...(r[a.uuid] || []), a];
    return r;
  }, {});
};

const getAvgScoreCB = (assessments) => {
  let _final = [];
  const groupedAssessments = { ...groupUserAssessments(assessments) };

  for (const key in groupedAssessments) {
    let count = {
      confused: 0,
      irratable: 0,
      boisterous: 0,
      pyshicalThreats: 0,
      verbalThreats: 0,
      attacking: 0,
    };

    _final.push({
      data: groupedAssessments[key].map(
        ({ dateCreated, guesses, answers }, index) => {
          let score = 0;
          let percent;
          let answerLength =
            guesses.length < answers.length ? guesses.length : answers.length;
          let total = answerLength * Object.keys(count).length;

          for (let i = 0; i < answerLength; i++) {
            for (let key of Object.keys(count)) {
              if (guesses[i][key] === answers[i][key]) score++;
            }
          }

          percent = Math.round((score / total) * 100);
        }
      ),
    });
  }
};

const getAvgScoreCB2 = (assessments) => {
  let score = 0;
  let answerLength;

  const groupUserAssessments = () => {
    const _assessmentDataResults = [...assessments];
    console.log("cbbbbbbbb:>> ", _assessmentDataResults);
    return _assessmentDataResults.reduce((r, a) => {
      r[a.uuid] = [...(r[a.uuid] || []), a];
      return r;
    }, {});
  };

  // const groupedAssessments = { ...groupUserAssessments() };
  // console.log(
  //   "groupedAssessments",
  //   groupedAssessments,
  // );

  // for (const key in groupedAssessments) {
  // console.log("KEYYY",key)
  let count = {
    confused: 0,
    irratable: 0,
    boisterous: 0,
    pyshicalThreats: 0,
    verbalThreats: 0,
    attacking: 0,
  };

  assessments.map(({ guesses, answers }, index) => {
    answerLength =
      guesses.length < answers.length ? guesses.length : answers.length;

    for (let i = 0; i < answerLength; i++) {
      for (let key of Object.keys(count)) {
        if (guesses[i][key] === answers[i][key]) score++;
      }
    }
  });
  // }
  console.log("scoreeeee", score, assessments.length, answerLength);

  const avgScore = Math.round(score / assessments.length);
  console.log("AVGGGGG", avgScore);
  return avgScore;
};

const genColor = () => Math.floor(Math.random() * 255);
const getMultiPieChart = (rythmCount, rythemData, setState) => {
  let shockAccuracyCount = 0;
  let drugSchedCorrectCount = 0;
  let offChestTimeCount = 0;
  let evalTimeCount = 0;
  let pulseCheckedCount = 0;
  const MAX_ALLOWED = 3;

  console.log("rythemData", rythemData);
  rythemData.map((assess, index) => {
    assess.map((coach) => {
      // console.log("helpersss caoch", coach);
      if (
        (coach.shockable === true && coach.shocked === true) ||
        (coach.shockable === false && coach.shocked === false)
      ) {
        shockAccuracyCount++;
      }

      // if (coach.drugSchedCorrect === true) {
      //   drugSchedCorrectCount++;
      // }
      // if (coach.offChestTime < MAX_ALLOWED) {
      //   offChestTimeCount++;
      // }
      // if (parseInt(coach.evalTime) < MAX_ALLOWED) {
      //   evalTimeCount++;
      // }
      // if (coach.pulseChecked === true && coach.shouldCheckPulse === true) {
      //   pulseCheckedCount++;
      // }
    });
  });
  console.log("shockAccuracyCount", shockAccuracyCount, rythmCount);
  let countArray;
  if (rythmCount > 0) {
    // console.log("helperssssss inside if");
    countArray = [
      {
        id: 0,
        Correct: Math.round((shockAccuracyCount / rythmCount) * 100),
      },
      // {
      //   id: 1,
      //   drugSchedCorrect_yay: Math.round(
      //     (drugSchedCorrectCount / rythmCount) * 100
      //   ),

      // },
      // {
      //   id: 2,
      //   offChestTime_yay: Math.round((offChestTimeCount / rythmCount) * 100),
      // },
      // { id: 3, evalTime_yay: Math.round((evalTimeCount / rythmCount) * 100) },
      // {
      //   id: 4,
      //   pulseChecked_yay: Math.round((pulseCheckedCount / rythmCount) * 100),
      // },
    ];
  } else {
    // console.log("helperssssss inside else");

    countArray = [
      {
        id: 0,
        Correct: 0,
      },
      // { id: 1, drugSchedCorrect_yay: 0 },
      // { id: 2, offChestTime_yay: 0 },
      // { id: 3, evalTime_yay: 0 },
      // { id: 4, pulseChecked_yay: 0 },
    ];
  }

  // console.log("helperssssss", rythmCount, countArray);

  let labels = [];
  var datasets = [];
  countArray.map((data) => {
    let { id, ...item } = data;

    labels.push(
      ...Object.keys(item),
      "In" + Object.keys(item).toString().toLowerCase()
    );
    // datasets = renderDatasets(data)
    datasets.push({
      backgroundColor:
        // [
        // `rgba(${genPrimaryColor()},${genPrimaryColor()},${genPrimaryColor()}, 0.7)`,
        // `rgba(${genSecondaryColor()},${genSecondaryColor()},${genSecondaryColor()}, 0.7)`,
        // ],
        genBgColor(id),
      data: [...Object.values(item), 100 - Object.values(item)],
      // data: [0, 0],
    });
  });

  // console.log("before setstae", labels, datasets);
  setState({ labels, datasets });
};

const genBgColor = (id) => {
  switch (id) {
    // case 0:
    //   return ["#AAAAAA", "#777777"];
    case 0:
      return ["#5cb55b", "#ff4f4f"];

    // case 2:
    //   return ["#77FE34", "#3BB300"];

    // case 3:
    //   return ["#34FFFF", "#00B3B3"];

    // case 4:
    //   return ["#EEFF00", "#FEE5A9"];
  }
};

// De-Escalation

const getAvgDesc = (assessments, type) => {
  let totalScore = assessments.reduce((t, c) => {
    console.log("t = t + c[type]", t, c[type]);
    if (c[type]) t = t + c[type];
    else {
    }
    return t;
  }, 0);

  console.log(
    "🚀 ~ file: index.js ~ line 295 ~ getAvgDesc ~ assessments",
    assessments
  );
  console.log(
    "🚀 ~ file: index.js ~ line 295 ~ getAvgDesc ~ totalScore",
    totalScore
  );

  const avgScore = Math.round(totalScore / assessments.length);

  return avgScore;
};

// const getAvgArrayDesc = (assessments, type) => {
//   let typeLength = 0;
//   let totalScore = assessments.reduce((t, c) => {
//     t =
//       t +
//       c[type].reduce((it, ic) => {
//         typeLength++;
//         it = it + ic;
//         return it;
//       }, 0);
//     return t;
//   }, 0);

//   console.log("totalScore", totalScore, assessments.length, typeLength);

//   const avgScore = Math.round(totalScore / typeLength);
//   return avgScore;
// };

const getTotalDesc = (assessments, type) => {
  let totalScore = assessments.reduce((t, c) => {
    if (c[type]) t = t + c[type];
    else {
    }
    return t;
  }, 0);

  // const avgScore = Math.round(totalScore / assessments.length);
  return formatSeconds(totalScore, "hh:mm:ss");
};

const getAvgArrayDesc = (assessments, type = []) => {
  let count = 0;

  let totalScore = assessments.reduce((t, c) => {
    let sum = 0;
    t =
      t +
      type.reduce((ot, oc, index) => {
        sum =
          sum +
          c[oc].reduce((it, ic) => {
            count++;
            it = it + ic;
            return it;
          }, 0);

        return sum;
      }, 0);

    return t;
  }, 0);

  console.log("totalScore", totalScore, assessments.length, count);

  const avgScore = Math.round(totalScore / count);

  return formatSeconds(avgScore);
};

const getResponseTime = (assessmentData, type) => {
  console.log(
    "🚀 ~ file: index.js ~ line 326 ~ getResponseTime ~ assessmentData",
    assessmentData
  );
  const sortedData = assessmentData && assessmentData.slice().sort();

  if (type === "best") return formatSeconds(sortedData[0]);
  else if (type === "worst")
    return formatSeconds(sortedData[sortedData.length - 1]);
};

const getMaxMin = (distanceResponse, distanceUserInput) => {
  let newArray = [...distanceResponse, ...distanceUserInput];
  newArray = newArray.sort();
  return { min: newArray[0] - 5, max: newArray[newArray.length - 1] + 5 };
};

const changeDateFormatFromYYYY_MM_DDToDD_MM_YYYY = (date) => {
  console.log("date...", date);
  let dateArray = date.split("-");
  return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
};
/*
scale the scenario duration for bubble graph in range 0 to y.
scaling formula = ((x - x_min) / (x_max - x_min)) * y.

if all the values are equal i.e x_min == x_max, choose any number 
in the range 0 to y.
*/
const scaleScenarioDuration = (dataList) => {
  let maxScenarioDuration = dataList[0].scenarioDuration;
  let minScenarioDuration = dataList[0].scenarioDuration;

  console.log("dataTest....", typeof maxScenarioDuration);
  for (let i = 1; i < dataList.length; i++) {
    if (dataList[i].scenarioDuration > maxScenarioDuration) {
      maxScenarioDuration = dataList[i].scenarioDuration;
    }

    if (dataList[i].scenarioDuration < minScenarioDuration) {
      minScenarioDuration = dataList[i].scenarioDuration;
    }
  }
  let range = maxScenarioDuration - minScenarioDuration;
  if (range != 0) {
    for (let i = 0; i < dataList.length; i++) {
      dataList[i].scenarioDuration =
        (dataList[i].scenarioDuration - minScenarioDuration) / range;
      dataList[i].scenarioDuration = dataList[i].scenarioDuration * 20;
    }
  } else {
    for (let i = 0; i < dataList.length; i++) {
      dataList[i].scenarioDuration = 5;
    }
  }
};
const scaleScenarioHandoverDuration = (dataList) => {
  let totalScore = dataList.reduce((t, object) => {
    console.log("getHandoverAvgScoreDesc t = t + c[type]", t, object);

    if (object && object.results && object.results.length > 0) {
      const selectedObj = object.results.filter((object) => {
        if (object.name === "duration") return object;
      });
      console.log("selectedObj", selectedObj[0]);
      t = t + selectedObj[0]["value"][0]["x"];
    }
    // if (c[type]) t = t + c[type];
    // else {
    // }
    return t;
  }, 0);

  let maxScenarioDuration = dataList[0].results.scenarioDuration;
  let minScenarioDuration = dataList[0].results.scenarioDuration;

  console.log("dataTest....", typeof maxScenarioDuration);
  for (let i = 1; i < dataList.length; i++) {
    if (dataList[i].scenarioDuration > maxScenarioDuration) {
      maxScenarioDuration = dataList[i].scenarioDuration;
    }

    if (dataList[i].scenarioDuration < minScenarioDuration) {
      minScenarioDuration = dataList[i].scenarioDuration;
    }
  }
  let range = maxScenarioDuration - minScenarioDuration;
  if (range != 0) {
    for (let i = 0; i < dataList.length; i++) {
      dataList[i].scenarioDuration =
        (dataList[i].scenarioDuration - minScenarioDuration) / range;
      dataList[i].scenarioDuration = dataList[i].scenarioDuration * 20;
    }
  } else {
    for (let i = 0; i < dataList.length; i++) {
      dataList[i].scenarioDuration = 5;
    }
  }
};
/*
  scale the totol time for bubble graph in range 0 to y.
  scaling formula = ((x - x_min) / (x_max - x_min)) * y.

  if all the values are equal i.e x_min == x_max, choose any number 
  in the range 0 to y.
*/

const scaleTotalTime = (dataList) => {
  let maxScenarioDuration = parseInt(dataList[0].totalTime);
  let minScenarioDuration = parseInt(dataList[0].totalTime);

  console.log("dataTest....", typeof maxScenarioDuration);
  for (let i = 1; i < dataList.length; i++) {
    if (parseInt(dataList[i].totalTime) > maxScenarioDuration) {
      maxScenarioDuration = parseInt(dataList[i].totalTime);
    }

    if (parseInt(dataList[i].totalTime) < minScenarioDuration) {
      minScenarioDuration = parseInt(dataList[i].totalTime);
    }
  }
  let range = maxScenarioDuration - minScenarioDuration;
  console.log("range", range, maxScenarioDuration, minScenarioDuration);
  if (range != 0) {
    for (let i = 0; i < dataList.length; i++) {
      dataList[i].scaledTotalTime =
        (parseInt(dataList[i].totalTime) - minScenarioDuration) / range;
      dataList[i].scaledTotalTime = dataList[i].scaledTotalTime * 20;
    }
  } else {
    for (let i = 0; i < dataList.length; i++) {
      dataList[i].scaledTotalTime = 5;
    }
  }
};
/*
  filterd invitation includes all the accepted invitation in which user is teacher or 
  admin or student with seats alotted
   */
/*
  default organization is the first organization from the list of filtered 
  invitations  in which user is admin or teacher
*/

const findDefaultOrg = (inviteList) => {
  if (!inviteList || !(inviteList.length > 0)) return null;
  for (let i = 0; i < inviteList.length; i++) {
    const res = checkRole(inviteList[i]);
    if (res.isAdmin || res.isTeacher || res.owner) {
      return inviteList[i];
    }
  }

  return null;
};

// Handover
const getHandoverAvgScoreDesc = (assessments, type) => {
  console.log("getHandoverAvgScoreDesc assessments", assessments);
  let totalScore = assessments.reduce((t, object) => {
    console.log("getHandoverAvgScoreDesc t = t + c[type]", t, object);

    if (object && object.results && object.results.length > 0) {
      const selectedObj = object.results.filter((object) => {
        if (object.name === type) return object;
      });
      console.log("selectedObj", selectedObj[0]);
      if (
        selectedObj[0] &&
        selectedObj[0]["value"] &&
        selectedObj[0]["value"].length > 0
      ) {
        t = t + selectedObj[0]["value"][0]["x"];
      }
    }
    // if (c[type]) t = t + c[type];
    // else {
    // }
    return t;
  }, 0);

  // console.log(
  //   "🚀 ~ file: index.js ~ line 295 ~ getAvgDesc ~ assessments",
  //   assessments
  // );
  console.log(
    "🚀 ~ file: index.js ~ line 295 ~ getAvgDesc ~ totalScore",
    totalScore
  );

  const avgScore = Math.round(totalScore / assessments.length);

  return avgScore;
  // return 1;
};

const getHandoverMostExpCase = (assessments, type) => {
  console.log("getHandoverAvgScoreDesc assessments", assessments);

  let totalScore = assessments.reduce((t, object) => {
    console.log("getHandoverAvgScoreDesc t = t + c[type]", t, object);

    let frequencyCount = {};
    object &&
      object.results.length > 0 &&
      object.results.forEach((obj) => {
        if (obj.name === type) {
          obj.value.forEach((item) => {
            let sentence = item.x;
            if (frequencyCount[sentence]) {
              frequencyCount[sentence]++;
            } else {
              frequencyCount[sentence] = 1;
            }
          });
        }
      });

    let mostRepeatedSentence = null;
    let highestFrequency = 0;
    for (let sentence in frequencyCount) {
      if (frequencyCount[sentence] > highestFrequency) {
        highestFrequency = frequencyCount[sentence];
        mostRepeatedSentence = sentence;
      }
    }

    t = mostRepeatedSentence;

    // if (object && object.results && object.results.length > 0) {
    //   let selectedObj = object.results.filter((object) => {
    //     if (object.name === type) return object;
    //   });
    //   selectedObj = selectedObj[0];
    //   if (selectedObj.value === type) {
    //   }
    //   finalArry = finalArry[selectedObj.value[0]["x"]];
    //   // t;
    //   // t = t + selectedObj["value"][0]["x"];
    // }

    return t;
  }, 0);

  return totalScore;
};

const getHandoverTotalDesc = (assessments, type) => {
  let totalScore = assessments.reduce((t, object) => {
    console.log("getHandoverAvgScoreDesc t = t + c[type]", t, object);

    if (object && object.results && object.results.length > 0) {
      const selectedObj = object.results.filter((object) => {
        if (object.name === type) return object;
      });
      if (
        selectedObj[0] &&
        selectedObj[0]["value"] &&
        selectedObj[0]["value"].length > 0
      ) {
        console.log("selectedObj", selectedObj[0]);
        t = t + selectedObj[0]["value"][0]["x"];
      }
    }
    // if (c[type]) t = t + c[type];
    // else {
    // }
    return t;
  }, 0);
  // const avgScore = Math.round(totalScore / assessments.length);
  return formatSeconds(totalScore, "hh:mm:ss");
};

const resolveLatestVersion = ({ appId, storeApps, launcherApp }) => {
  let version = "";
  if (appId === "launcher") {
    version = launcherApp[0].version;
  } else {
    const foundApp = storeApps.find((app) => app.appId === appId);
    if (foundApp) version = foundApp.version;
  }
  return version;
};

const getAppName = (appId, storeApps) => {
  const foundApp = storeApps.find((app) => app.appId === appId);
  if (foundApp) {
    return foundApp.appName;
  } else return appId;
};

export {
  getHandoverTotalDesc,
  getHandoverMostExpCase,
  getHandoverAvgScoreDesc,
  getTotalSeconds,
  getAvgScoreALS,
  getAETrueCount,
  getAvgScoreCB,
  getAvgScoreCB2,
  formatSeconds,
  getMultiPieChart,
  getAvgDesc,
  getMaxMin,
  getAvgArrayDesc,
  getTotalDesc,
  getResponseTime,
  changeDateFormatFromYYYY_MM_DDToDD_MM_YYYY,
  scaleScenarioDuration,
  findDefaultOrg,
  scaleTotalTime,
  scaleScenarioHandoverDuration,
  resolveLatestVersion,
  getAppName,
};
