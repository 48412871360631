import axios from "axios";
import { CommonActions, ProductActions } from ".";
import { ACTION_TYPE } from "../../Services/Constants/index";
import Axios from "../../Services/Utils/axios";

export const getAllOrgs =
  ({ uuid, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.get(`/org/all/${uuid}`);
      if (response.data && response.data.success)
        dispatch({
          type: ACTION_TYPE.GET_ALL_ORGS,
          payload: response.data.result,
        });

      callback({ success: true, result: "ok" });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

export const createOrg =
  ({
    uuid,
    email,
    ownerUuid,
    orgName,
    orgId,
    orgImage,
    subscriptionStartDate,
    subscriptionEndDate,
    callback,
  }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post(`/org/create`, {
        uuid,
        email,
        ownerUuid,
        orgName,
        orgId,
        orgImage,
        subscriptionStartDate,
        subscriptionEndDate,
      });

      callback({ success: true, result: "ok" });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

export const getOrgResults =
  ({ orgId, product, callback }) =>
  async (dispatch) => {
    try {
      dispatch(CommonActions.startLoading());
      dispatch(
        ProductActions.setProduct({
          product,
          callback: () => {},
        })
      );
      const response = await Axios.post(`/org/results`, { orgId, product });
      console.log(
        "🚀 ~ file: SuperAdminActions.js ~ line 66 ~ response",
        response
      );

      dispatch({
        type: ACTION_TYPE.ORG_RESULTS,
        payload: response.data,
      });

      dispatch(CommonActions.stopLoading());

      callback({
        success: true,
        result: response.data.result,
        product: response.data.product,
      });
    } catch (error) {
      console.log("🚀 ~ file: SuperAdminActions.js ~ line 84 ~ error", error);
      callback({ success: false, result: error.message });
    }
  };

export const getOrgResultsSuperAdmin =
  ({ orgId, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post(`/org/superAdmin/results`, { orgId });

      dispatch({
        type: ACTION_TYPE.ORG_RESULTS,
        payload: response.data,
      });

      callback({
        success: true,
        result: response.data.result,
      });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

export const sendInviteToOrg =
  ({ email, uuid, orgId, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post(`/auth/invite/org`, {
        email,
        uuid,
        orgId,
      });

      callback({ success: true, result: "ok" });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

export const sendInviteToUser =
  ({ email, uuid, orgId, role, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post(`/auth/invite/user`, {
        email,
        uuid,
        orgId,
        role,
      });
      if (response.data.result && response.data.result.pendingInvitations) {
        console.log(
          "RESPONSEEEEEEEEEEEE",
          response.data.result.pendingInvitations
        );

        dispatch({
          type: ACTION_TYPE.UPDATE_USERS,
          payload: {
            pendingInvites: response.data.result.pendingInvitations,
            users: response.data.result.users,
          },
        });
      }
      if (
        !response?.data?.finalResult?.success &&
        response?.data?.finalResult?.userAlreadyExists
      ) {
        callback({
          success: false,
          result: "User already exists",
          userAlreadyExists: true,
        });
      } else {
        callback({ success: true, result: "ok" });
      }
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

export const fetchUserDetails =
  (userId, { callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post(`/user/id/${userId}`);
      dispatch({
        type: ACTION_TYPE.FETCH_USER_DETAILS,
        payload: response.data,
      });

      callback({ success: true, result: "ok" });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

export const getOrgById =
  (data, { callback }) =>
  async (dispatch) => {
    console.log("SUPER ACTION");

    try {
      const response = await Axios.get(`/org/id/${data}`);

      dispatch({
        type: ACTION_TYPE.GET_ORG_BY_ID,
        payload: response.data,
      });

      callback({ success: true, result: "ok" });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };
