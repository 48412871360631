import React from "react";
import "./style.css";

const Labels = ({ subTitles, subValues }) => {
  console.log("labels", subTitles, subValues);
  return (
    <div className="card-info-container">
      {subTitles && subTitles.length > 0 ? (
        subTitles.map((subTitle, index) => (
          <div
            className="card-info"
            key={Math.random() + Math.random() + index}
          >
            <div
              className="info-status-container"
              style={{
                backgroundColor:
                  subValues[index] == "true" ? "#5cb55b" : "#ff4f4f",
              }}
            >
              {subValues[index] == "true" ? "Completed" : "Skipped"}
            </div>
            <div
              className="info-container"
              // style={{ fontWeight: title ? "bold" : null }}
            >
              {subTitle}
            </div>
          </div>
        ))
      ) : (
        <div className="card-info" key={Math.random() + Math.random()}>
          <h6>N/A</h6>
        </div>
      )}
    </div>
  );
};

export default Labels;
