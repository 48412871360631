import React, { useEffect, useState } from "react";

import Table from "../../Component/Table/Table";
import Button from "../../Component/Button/button";

import { useSelector, useDispatch } from "react-redux";
// import { ROUTES } from "../../Services/Constants";

import HeaderBar from "../../Component/HeaderBar/headerBar";
import SideNav from "../../Component/SideNav/sideNav";
import CustomCard from "../../Component/Card/customCard";
import DetailsModel from "../../Component/Modal/inviteModal";
import { SuperAdminActions } from "../../ReduxStore/Actions";

import "./SuperAdmin.css";
import RBAC from "../../Component/RBAC/RBAC";
import { useHistory } from "react-router-dom";
import _Firebase from "../../Services/Utils/firebase";
import { initList } from "../../Component/SideNav/initList";

const SuperAdminDashBoard = () => {
  const [auth, setAuth] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState([]);

  const [detailsModel, setDetailsModel] = useState(false);
  const [email, setEmail] = useState();
  const [inputOrgId, setInputOrgId] = useState();

  let collapsedClass = useSelector(
    (state) => state.CollapsedSidenavReducer.collapsedClass
  );

  let allOrgs = useSelector((state) => state.SuperAdminReducer.allOrgs);
  let user = useSelector((state) => state.AuthReducer.user);

  useEffect(() => {
    if (user.uuid)
      dispatch(
        SuperAdminActions.getAllOrgs({
          uuid: user.uuid,
          callback: (response) => {},
        })
      );
  }, [user]);

  useEffect(() => {
    if (user?.uuid) getUserData();
  }, []);

  const getUserData = () => {
    dispatch(
      SuperAdminActions.fetchUserDetails(user?.uuid, {
        callback: (response) => {},
      })
    );
  };

  useEffect(() => {
    getTableData(allOrgs);
  }, [allOrgs]);

  const getTableData = (result) => {
    let finalData = result.map((data) => {
      return [
        data["orgName"] || "-",
        data["ownerEmail"],
        data["ownerUuid"] ? "Accepted" : "Pending",
        data["users"]?.length,
        data["seats"],
        // activeUsers
      ];
    });

    setTableData(finalData);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleInvite = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const { uuid } = user;
    const orgId = inputOrgId;
    dispatch(
      SuperAdminActions.sendInviteToOrg({
        uuid,
        email,
        orgId,
        callback: () => {
          setDetailsModel(false);
          setEmail();
          setInputOrgId();
        },
      })
    );
  };

  return (
    <div className="d-flex over">
      <div className={collapsedClass ? " sideNavExpand" : "sideNavContract "}>
        {user && (
          <RBAC
            userRole={[user?.role]} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}{" "}
      </div>
      <div className={collapsedClass ? "screenContract bg" : "screenExpand bg"}>
        <HeaderBar
          width={collapsedClass ? "80%" : "95%"}
          title="Super Admin Dashboard"
          searchbar="no"
        />
        <div className="componentSec supAdminDash">
          <div className="d-flex  flexsupad">
            <CustomCard
              no={allOrgs?.length}
              fontSize="64px"
              title="Total Organizations"
            ></CustomCard>
            <CustomCard
              handleClick={() => setDetailsModel(true)}
              no="+"
              fontSize="60px"
              title="Invite Organizations"
            ></CustomCard>

            <div className="thirdFlex" style={{ flex: "0 0 30%" }}>
              <CustomCard
                no={allOrgs.filter((org) => org["ownerUuid"]).length}
                // flex="0 0 30%"
                height="50px"
                fontSize="28px"
                title="Accepted"
                color="#377E22"
              ></CustomCard>
              <CustomCard
                no={allOrgs?.length}
                height="50px"
                fontSize="28px"
                // flex="0 0 30%"
                title="Invited"
                color="#116a9a"
              ></CustomCard>
            </div>
          </div>
          <div style={{ marginTop: "10px" }}>
            {tableData.length > 0 && (
              <Table
                height="45vh"
                tableHead={[
                  "Name",
                  "Email",
                  "Status",
                  "No. of Users",
                  "Total Seats",
                ]}
                tableData={tableData}
              />
            )}
          </div>
          <div className="super-admin-invite-org-container">
            <DetailsModel
              open={detailsModel}
              handleClose={() => setDetailsModel(false)}
              title="Invite Organisation"
              extraClasses={"super-admin-invite-org-container"}
              orgId={true}
              orgInput={
                <input
                  placeholder="Enter Org Id"
                  style={{
                    maxWidth: "659px",
                    width: "100%",
                    padding: "4px 15px",
                    height: "47px",
                    marginBottom: "19px",
                    background: "#FFFFFF",
                    boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.25)",
                    borderRadius: "10px",
                    border: "none",
                  }}
                  value={inputOrgId}
                  onChange={(e) => setInputOrgId(e.target.value)}
                />
              }
              input={
                <input
                  placeholder="Enter e-mail address"
                  style={{
                    maxWidth: "659px",
                    width: "100%",
                    padding: "4px 15px",
                    height: "47px",
                    marginBottom: "19px",
                    background: "#FFFFFF",
                    boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.25)",
                    borderRadius: "10px",
                    border: "none",
                  }}
                  value={email}
                  onChange={handleEmailChange}
                />
              }
              button={
                <Button
                  background="secondary"
                  color="primary"
                  name="Invite"
                  handleClick={handleInvite}
                />
              }
              cancelButton={
                <Button
                  background="secondary"
                  color="primary"
                  name="Cancel"
                  handleClick={() => {
                    setDetailsModel(false);
                    setEmail();
                    setInputOrgId();
                  }}
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SuperAdminDashBoard;
