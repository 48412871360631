import React from "react";
import CustomCard from "../Card/customCard";
import "./style.css";
import { useSelector } from "react-redux";

import { withRouter } from "react-router";
import { CircularProgress } from "@material-ui/core";
import BubbleGraph from "./bubble";
import BarChart from "./BarChart";
import { getAvgDesc, getTotalDesc } from "../../Helper";
import { propTypes } from "react-bootstrap/esm/Image";

const DetPatDash = React.memo((props) => {
  let { assessmentData, peopleCount } = props;

  let assessmentDataScenario =
    assessmentData &&
    assessmentData?.length > 0 &&
    assessmentData?.filter((data) => data.type === "scenario");
  const { loading } = useSelector((state) => state.CommonReducer);
  let assessmentTrainer =
    assessmentData &&
    assessmentData?.length > 0 &&
    assessmentData?.filter((data) => data.type === "trainer");

  let user = useSelector((state) => state.AuthReducer.user);
  let currentOrg = useSelector((state) => state.OrgReducer.currentOrg);

  if (loading)
    return (
      <div className="spinnerDiv" style={{ width: "100%" }}>
        {" "}
        <CircularProgress color="inherit"></CircularProgress>
      </div>
    );
  else
    return (
      <>
        {assessmentData && assessmentData?.length > 0 && (
          <div className="d-flex  flexsupad">
            {currentOrg.length === 0 && user?.role != "superAdmin" ? (
              <CustomCard
                handleClick={() =>
                  props.history.push(`/dp-assessment/${user.uuid}`)
                }
                // fontSize="64px"
                title="View Assessments"
              />
            ) : (
              <CustomCard
                no={peopleCount}
                // fontSize="64px"
                title="Number of People"
              ></CustomCard>
            )}

            <CustomCard
              no={getAvgDesc(assessmentDataScenario, "overallScore")}
              // fontSize="64px"
              title="Average Overall Score"
            ></CustomCard>

            <CustomCard
              no={getAvgDesc(assessmentDataScenario, "scenarioDuration")}
              fontSize="64px"
              title="Average Scenario Duration"
            ></CustomCard>

            <CustomCard
              no={assessmentDataScenario.length}
              fontSize="64px"
              title="Number of Assessments"
            ></CustomCard>

            <CustomCard
              no={getTotalDesc(assessmentDataScenario, "scenarioDuration")}
              fontSize="64px"
              title="Time in app"
            ></CustomCard>
          </div>
        )}
        {assessmentData && assessmentData?.length > 0 && (
          <>
            <div className="flexGraph als">
              <div className="graphbgMainL">
                <div className="GraphBG">
                  <BubbleGraph
                    assessmentData={assessmentDataScenario}
                    // orgUsers={orgUsers}
                  ></BubbleGraph>
                </div>
              </div>
            </div>

            <div className="GraphBG bar-chart">
              <h1>Bar chart for Trainer schema</h1>
              <BarChart
                assessmentData={assessmentTrainer}
                type={"Bar chart for Trainer schema"}
              />
            </div>
          </>
        )}
      </>
    );
});
export default withRouter(DetPatDash);
