import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import "./customCard.css";

const useStyles = makeStyles({
  root: {
    borderRadius: "3px",
  },
  content: {
    display: "flex",
    flexDirection: "row",
  },

  card: {
    borderRadius: "3px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

function SimpleCard(props) {
  const classes = useStyles();

  return (
    <Card
      className="mainCard"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: props.width,
        flex: props.flex,
        height: props.height,
        fontSize: props.fontSize,

        padding: "10px 20px",
        color: props.color,
        onClick: props?.handleClick,
        cursor: props?.handleClick ? "pointer" : "auto",
      }}
      onClick={props?.handleClick}
    >
      <card className={classes.card}>
        {props.title ? (
          <>
            {props.no ? <div className="noCardcus">{props.no}</div> : null}
            <div className="titleCardcus">{props.title}</div>
          </>
        ) : (
          <div className="noCardcus">{props.no}</div>
        )}
      </card>
    </Card>
  );
}
export default SimpleCard;
