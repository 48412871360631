import { ACTION_TYPE } from "../../Services/Constants/index";
import Axios from "../../Services/Utils/axios";
import jwt from "jsonwebtoken";

export const setProduct =
  ({ product, callback }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACTION_TYPE.SET_PRODUCT,
        payload: product,
      });

      callback({ success: true });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };
