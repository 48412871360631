import React from "react";
import { Doughnut } from "react-chartjs-2";
import ChartLabels from "./ChartLabels";
import "./styles.css";

export const Donut = ({
  height = 100,
  width = 100,
  percentage = 0,
  labels,
}) => {
  const calculatePercent = () => {
    let count = 0;
    let newLabels = [];
    let _labels = labels.map(({ success }) => {
      return success && (count = count + 1);
    });
    newLabels = _labels.filter((label) => !label.title);
    return parseInt((count / newLabels.length) * 100);
  };

  const data = {
    datasets: [
      {
        data: [calculatePercent(), 100 - calculatePercent()],
        backgroundColor: ["#5cb55b", "#ff4f4f"],
      },
    ],
  };

  return (
    <div className="card-container">
      <div className="card-donut-container">
        <div className="donut-container">
          <Doughnut
            data={data}
            height={height}
            width={width}
            options={{
              elements: { arc: { borderWidth: 0 } },
              tooltips: { enabled: false },
            }}
          />
        </div>
        <div className="donut-info-container">{calculatePercent()}%</div>
      </div>
      <ChartLabels labels={labels} />
    </div>
  );
};
export default Donut;
