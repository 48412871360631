import { PersonAdd } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import Button from "../../Component/Button/button";
import "./userManagement.css";
import Table from "../../Component/Table/Table";
import InviteModel from "../../Component/Modal/inviteModal";
import DetailsModel from "../../Component/Modal/modal";
import { useSelector, useDispatch } from "react-redux";
import HeaderBar from "../../Component/HeaderBar/headerBar";
import RBAC from "../../Component/RBAC/RBAC";
import { initList } from "../../Component/SideNav/initList";
import { SuperAdminActions, OrgActions } from "../../ReduxStore/Actions";
import _Firebase from "../../Services/Utils/firebase";
import SideNav from "../../Component/SideNav/sideNav";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import CancelIcon from "@material-ui/icons/Cancel";
import { ACTION_TYPE } from "../../Services/Constants";
import AlertDialog from "../../Component/AlertDialog/alertDialog";
import Tabs from "./Component/tab";
import Toast from "../../Component/Toast/toast";

const UserManagement = () => {
  const [detailsModel, setDetailsModel] = useState(false);
  const [isDropdown, setIsDropdown] = useState({});
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [orgToDelete, setOrgToDelete] = useState();
  const [seat, setSeat] = useState();
  const [seatModal, setSeatModal] = useState();
  const dispatch = useDispatch();
  const [inviteModel, setInviteModel] = useState(false);
  const [seatLeft, setSeatLeft] = useState();
  const [showRole, setshowRole] = useState({
    id: null,
    showRole: false,
  });
  const dropdownList = ["admin", "teacher", "student"];
  const [email, setEmail] = useState("");
  const [inviteAs, setInviteAs] = useState("student");
  const [toast, setToast] = useState();

  // const [runUserManagementTour, setRunUserManagementTour] = useState(false);

  // const [runInviteUserTour, setRunInviteUserTour] = useState(false);
  // const inviteUserTourSteps = [
  //   {
  //     target: ".inviteUserTourStep1",
  //     content: "Enter the email address of the user you want to invite. If you want to send invite to multiple users, you can enter multiple email addresses separated by comma.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".inviteUserTourStep2",
  //     content: "Select the role you want to invite the user as. While Sendinig invite to multiple user role will be fixed to student",
  //   }

  // ];
  // const userManagementTourSteps = [
  //   {
  //     target: ".userMngTourStep1",
  //     content: "Click this button to send invitation to users to join you organization. Click the button to continue tour",
  //     disableBeacon: true,
  //   }

  // ];

  let multipleEMails = ["student"];
  let adminRoles = ["student", "admin", "teacher"];
  // let teacherRoles=["teacher","student"]
  const [dropDownArray, setDropDownArray] = useState([]);

  let { user: selectedUser } = useSelector((state) => state.OrgReducer.user);
  let collapsedClass = useSelector(
    (state) => state.CollapsedSidenavReducer.collapsedClass
  );
  let user = useSelector((state) => state.AuthReducer.user);
  let currentOrg = useSelector((state) => state.OrgReducer.currentOrg);
  let users = useSelector((state) => state.OrgReducer.users);

  console.log("currOrg", currentOrg);
  useEffect(() => {
    let seat = 0;

    if (currentOrg[0]?.users || currentOrg[0]?.pendingInvitations) {
      console.log("currentOrg[0].users changed :>> ", currentOrg[0].users);
      getTableData(currentOrg[0]);
      currentOrg[0]?.users.map((user) => {
        console.log(
          "🚀 ~ file: userManagement.js ~ line 91 ~ currentOrg[0]?.users.map ~ user",
          user
        );

        if (user && user.seatAlloted === true) seat += 1;
      });
      currentOrg[0]?.removedUsers &&
        currentOrg[0]?.removedUsers.length > 0 &&
        currentOrg[0]?.removedUsers.map((user) => {
          if (user && user.seatAlloted === true) seat += 1;
        });
      seat = seat;
      setSeatLeft(seat);
    }
  }, [
    currentOrg,
    currentOrg.length > 0 && currentOrg[0].users,
    currentOrg.length > 0 && currentOrg[0].pendingInvitations,
  ]);

  useEffect(() => {
    setDropDownArray(adminRoles);
    // else if(user && user.role && user.role==="teacher"){
    //     setDropDownArray(teacherRoles)
    //   }
    console.log("nnnnnnnnnnnnnuser.role", dropDownArray);
  }, [user]);

  // useEffect(() => {
  //   setRunUserManagementTour(true);
  // },[]);

  const getTableData = (result) => {
    console.log("results getTableData :>> ", result);
    let aggregatedUsers = [...result?.users, ...result?.pendingInvitations];
    console.log("aggregatedUsers :>> ", aggregatedUsers);
    let finalData = aggregatedUsers?.map((data) => {
      return [
        data["email"],
        data["uuid"] ? "Accepted" : "Pending",
        data["roles"],
        data["uuid"] ? data["uuid"] : "-",
        data["uuid"]
          ? data["seatAlloted"]
            ? "Allotted"
            : "Not Allotted"
          : "-",
      ];
    });

    setTableData(finalData);
  };
  const handleDropdownToggle = (e, val, index) => {
    setshowRole({
      id: index,
      showRole: !showRole.showRole,
    });
  };

  // let { user: authUser } = useSelector((state) => state.AuthReducer);
  let organisation = useSelector((state) => state.OrgReducer.currentOrg);

  // useEffect(() => {
  //   // dispatch(
  //   //   SuperAdminActions.getAllOrgs({
  //   //     callback: (response) => {},
  //   //   })
  //   // );
  //   // if (authUser?.orgId) {
  //   //   dispatch(
  //   //     SuperAdminActions.getOrgById(authUser.orgId, {
  //   //       callback: (response) => {},
  //   //     })
  //   //   );
  //   // }
  // }, [authUser]);
  const handleBtnClickSecond = () => {
    setSeatModal(false);
    let seatAllotment = seat[4] !== "Allotted" ? true : false;

    console.log("seatAllotmentttttttttttttttt", seatAllotment, seat[0]);
    if (seatAllotment) {
      console.log("insideeeeeeeeeeeeee", seatAllotment, seat[0]);

      dispatch(
        OrgActions.UpdateSeat({
          uuid: seat[3],
          seatAlloted: seatAllotment,
          email: seat[0],
          orgId: organisation[0]?.orgId,
          callback: (response) => {
            //   getTableData(currentOrg[0]?.users);
          },
        })
      );

      // dispatch(
      //   OrgActions.getAllGroupsInOrg({
      //     orgId: currentOrg[0]?.orgId,
      //     callback: (response) => {},
      //   })
      // );
    }
  };
  //to invite a new user
  const handleInvite = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const uuid = user.uuid;

    const orgId = currentOrg[0]?.orgId;
    let emails = email.filter((data) => data !== "");
    setEmail(emails);

    dispatch(
      SuperAdminActions.sendInviteToUser({
        uuid,
        email,
        orgId,
        role: inviteAs,
        callback: ({ success, result, userAlreadyExists }) => {
          if (!success && userAlreadyExists) {
            setToast({
              open: true,
              message: "Unable to send invite. User Already Exists.",
              type: "error",
              duration: 5000,
            });
            setInviteModel(false);
            setEmail([]);
            return;
          }
          setInviteModel(false);
          setEmail([]);
          dispatch(
            OrgActions.getOrgById({
              orgId: currentOrg[0]?.orgId,
              callback: (response) => {
                console.log("response is here", response.result.org[0]);
                dispatch({
                  type: ACTION_TYPE.UPDATE_ORG,
                  payload: response.result.org[0],
                });
              },
            })
          );
        },
      })
    );
  };

  const handleEmailChange = (e) => {
    let { value } = e.target;

    let emails = value.split(",");
    emails = emails.filter((data) => data !== undefined);

    if (emails.length >= 2) {
      setDropDownArray(multipleEMails);
      setInviteAs("student");
    } else if (user.role === "admin") {
      setDropDownArray(adminRoles);
    }
    console.log("222222222 emailsss", emails);
    // else if(user.role==="teacher"){
    //   setDropDownArray(teacherRoles)
    // }
    setEmail(emails);
  };
  // function to update the role of the user
  // rowData:[email,status,roles,uuid, seat]

  const handleChangeCheckBox = (e, rowData) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      OrgActions.UpdateRole({
        uuid: rowData[3] === "-" ? null : rowData[3],
        orgId: currentOrg[0]?.orgId,
        role: e.target.value,
        email: rowData[0] === "-" ? null : rowData[0],

        callback: (response) => {
          //   getTableData(currentOrg[0]?.users);
          console.log("response", response);
        },
      })
    );
  };

  // function to show the details of the user
  const handleBtnClick = (tableRow) => {
    console.log("tableRow :>> ", tableRow);
    const email = tableRow[0];
    setDetailsModel(true);
    dispatch(
      OrgActions.fetchUserDetails({
        email: email,
        callback: (response) => {},
      })
    );
  };

  // to detele a user
  // orgToDelete:[email,status,roles,uuid, seat]

  const handleDeleteUser = () => {
    setOpen(false);
    dispatch(
      OrgActions.deleteUserFromOrg({
        uuid: orgToDelete[3] === "-" ? null : orgToDelete[3],
        email: orgToDelete[0],
        orgId: currentOrg[0].orgId,
        callback: (response) => {
          // dispatch(
          //   OrgActions.getAllGroupsInOrg({
          //     orgId: currentOrg[0]?.orgId,
          //     callback: (response) => {},
          //   })
          // );
          //   getTableData(currentOrg[0]?.users);
        },
      })
    );
  };
  const handelSelect = (e) => {
    console.log("invite as", inviteAs, e.target.value);

    setInviteAs(e.target.value);
  };

  // const UserMngTourCallback = (data) => {
  //   console.log("data....:::",data);

  //   if(data.action === ACTIONS.NEXT){
  //       if(data.index === 1){
  //         console.log(data);
  //         setInviteModel(true);
  //       }
  //       return;
  //   }
  //   if (data.status === STATUS.FINISHED || data.status === STATUS.SKIPPED) {
  //     setRunUserManagementTour(false);
  //   }
  // }
  console.log("currentorg in um", seatLeft);

  return (
    <div className="d-flex over">
      {/* <AppTour 
        run={runUserManagementTour}
        steps={userManagementTourSteps}
        handleClose={() => {setRunUserManagementTour(false)}}
        // callback={UserMngTourCallback}
      /> */}
      <div className={collapsedClass ? " sideNavExpand" : "sideNavContract"}>
        {user && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}{" "}
      </div>
      <div
        className={
          collapsedClass
            ? "screenContract bg"
            : "screenExpand bg fixScreenExpand"
        }
      >
        <HeaderBar
          width={collapsedClass ? "80%" : "95%"}
          title="User Management"
          searchbar="no"
          // startTour={() => {setRunUserManagementTour(true)}}
        />
        <div className="componentSec">
          <div className="d-flex justify-content-end flexsupad">
            <select className="SortBY" name="Sort By" id="SortBy" hidden>
              <option disabled selected>
                Sort By
              </option>
            </select>

            <div className="inviteButton userMngTourStep1">
              <Button
                background="secondary"
                borderRadius="15px"
                fontSize="15px"
                color="primary"
                padding="5px 40px"
                transition="all 0.5s ease"
                display="inline-flex"
                alignItem="center"
                icon={<PersonAdd />}
                name="Invite"
                handleClick={() => setInviteModel(true)}
              />
              <InviteModel
                open={inviteModel}
                handleClose={() => setInviteModel(false)}
                title="Invite User"
                // tourSteps={inviteUserTourSteps}
                // runTour={runInviteUserTour}
                // closeTour={() => {setRunInviteUserTour(false)}}
                // startTour={() => {setRunInviteUserTour(true)}}
                input={
                  <input
                    className="inviteUserTourStep1"
                    placeholder="Enter e-mail address (Add commas for multiple email addresses)"
                    style={{
                      maxWidth: "659px",
                      width: "100%",
                      padding: "4px 15px",
                      height: "47px",
                      marginBottom: "19px",
                      background: "#FFFFFF",
                      boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.25)",
                      borderRadius: "3px",
                      border: "none",
                    }}
                    value={email}
                    onChange={handleEmailChange}
                  />
                }
                dropDown={
                  <select
                    name="Role"
                    onChange={(e) => handelSelect(e)}
                    className="selectUserM inviteUserTourStep2"
                    id="role"
                  >
                    {dropDownArray &&
                      dropDownArray.length > 0 &&
                      dropDownArray.map((data) => {
                        console.log("drop data", data);
                        return (
                          <option value={data} className="optionUserM">
                            {data}
                          </option>
                        );
                      })}
                  </select>
                }
                button={
                  <Button
                    background="secondary"
                    color="primary"
                    name="Invite"
                    handleClick={handleInvite}
                  />
                }
                cancelButton={
                  <Button
                    background="secondary"
                    color="primary"
                    name="Cancel"
                    handleClick={() => {
                      setInviteModel(false);
                      setEmail();
                    }}
                  />
                }
              />
              <DetailsModel
                open={detailsModel}
                handleClose={() => {
                  dispatch({
                    type: ACTION_TYPE.CLEAR_SELECTED_USER,
                  });
                  setDetailsModel(false);
                }}
                user={selectedUser}
                mainIcon={
                  <AccountCircleOutlinedIcon
                    color="primary"
                    className="ModelIcon text-center"
                  />
                }
                secondIcon={
                  <CancelIcon
                    onClick={() => {
                      dispatch({
                        type: ACTION_TYPE.CLEAR_SELECTED_USER,
                      });
                      setDetailsModel(false);
                    }}
                    color="primary"
                    className="modelIcon2"
                  />
                }
                width="868px"
                height="400px"
                list1={[
                  "Username",
                  "Gender",
                  "Critical Speciality",
                  "Years of ALS Experience",
                  "Do you wear glasses?",
                ]}
                list2={[
                  "Email",
                  "Occupation",
                  "Position or Grade",
                  "Experience with virtual reality",
                  "Completed ALS Program before",
                ]}
                list3={[
                  "Year of Birth",
                  "Gaming Experience",
                  "Years of Post Grad experience",
                  "Is English your second language?",
                ]}
              />
            </div>
          </div>
          <AlertDialog
            open={seatModal}
            handleClose={() => setSeatModal(false)}
            message={
              seat && seat[4] == "Allotted"
                ? "Not Allowed!"
                : // "Are you sure you want to revoke the seat allotment?"

                  "Are you sure you want to allot seat?"
            }
            button1={seat && seat[4] == "Allotted" ? "" : "Yes"}
            button2={seat && seat[4] == "Allotted" ? "Close" : "No"}
            handleDelete={handleBtnClickSecond}
          />

          <div>
            <Tabs
              tableData={tableData}
              dropDownList={dropdownList}
              handleBtnClick={handleBtnClick}
              handleDeleteUser={(data) => {
                setOpen(true);
                setOrgToDelete(data);
              }}
              isDropdown={isDropdown}
              handleBtnClickSecond={(data) => {
                setSeatModal(true);
                setSeat(data);
              }}
              handleChangeCheckBox={handleChangeCheckBox}
              setIsDropdown={setIsDropdown}
              handleDropdownToggle={handleDropdownToggle}
              setDetailsModel={setDetailsModel}
              setEmail={setEmail}
              CheckUUID={user.uuid}
              ownerUuid={currentOrg[0]?.ownerUuid}
              tableTopText={`${
                //   currentOrg[0]?.seats - currentOrg[0]?.activeUsers?.length
                currentOrg[0]?.seats - seatLeft
              } out of ${currentOrg[0]?.seats} seats left`}
            />
            {console.log(
              "asdfghjkl",
              currentOrg[0]?.seats,
              currentOrg[0]?.activeUsers?.length
            )}
          </div>

          <div className="pb-31">
            <Table
              dropDownList={dropdownList}
              handleBtnClick={handleBtnClick}
              handleDeleteUser={(data) => {
                setOpen(true);
                setOrgToDelete(data);
              }}
              isDropdown={isDropdown}
              handleBtnClickSecond={(data) => {
                if (data[4] !== "Allotted") {
                  setSeatModal(true);
                  setSeat(data);
                }
              }}
              handleChangeCheckBox={handleChangeCheckBox}
              setIsDropdown={setIsDropdown}
              handleDropdownToggle={handleDropdownToggle}
              setDetailsModel={setDetailsModel}
              setEmail={setEmail}
              CheckUUID={user.uuid}
            />
          </div>
        </div>
        <AlertDialog
          open={open}
          handleClose={() => setOpen(false)}
          message="Are you sure you want to Delete?"
          button1="Yes"
          button2="No"
          handleDelete={handleDeleteUser}
        />
      </div>
      {toast?.open ? (
        <Toast
          open={toast.open}
          message={toast.message}
          duration={toast.duration}
          type={toast.type}
          handleClose={() =>
            setToast({
              ...toast,
              open: false,
            })
          }
        ></Toast>
      ) : null}
    </div>
  );
};
export default UserManagement;
