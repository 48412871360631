import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { CollapsedSidenavAction } from "../../ReduxStore/Actions/CollapseSidenav";
// import logo from "../../Images/CommonImages/logo.png";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import "../../DashboardModule/SideNav/sideNav.css";
// import BusinessOutlinedIcon from "@material-ui/icons/BusinessOutlined";
// import Download from "@material-ui/icons/Download";
import MenuIcon from "@material-ui/icons/Menu";
import {
  LauncherActions,
  OrgActions,
  ProductActions,
} from "../../ReduxStore/Actions";
import Button from "../../Component/Button/button";

// import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import {
  ACTION_TYPE,
  APPLICATION_CONSTANTS,
  ROUTES,
} from "../../Services/Constants";
import { AuthActions } from "../../ReduxStore/Actions";
import { checkRole } from "../../Services/Utils/utils";
import { useHistory, useLocation } from "react-router-dom";
import {GetApp } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  hide: {
    display: "none",
  },
  activeClass: {
    "& .MuiTypography-body1": {
      color: "#ffffff  !important",
    },
    "& .noImgOrg": {
      color: "#ffffff  !important",
      borderColor: "#ffffff  !important",
      fontSize: "14px !important",
    },

    "&": {
      background: " #116a9a ",
      borderRadius: "3px",
      color: "#ffffff !important",
      textDecoration: "none !important",
      padding: "0px 4px !important",
      " & svg": {
        color: "white !important",
      },
      " & li": {
        color: "white !important",
      },

      "& .MuiListItem-button": {
        background: " #116a9a",
        borderRadius: "3px",
        color: "#fff !important",
        // margin: "4px 16px 4px 4px"
      },
      "& .MuiButtonBase-root.MuiListItem-button": {
        padding: "6px 8px",
        margin: "2px 0",
      },
    },
  },
  listitem: {
    cursor: "pointer",

    "& svg": {
      color: "#130C2D",
    },
    // "&:hover": {
    //   background: " #116a9a ",
    //   borderRadius: "3px",
    //   color: "#ffffff !important",
    //   textDecoration: "none !important",
    //   "& svg": {
    //     color: "white",
    //   },
    //   "& li": {
    //     color: "white",
    //   },
    //   "& .MuiListItem-button:hover": {
    //     background: " #116a9a",
    //     borderRadius: "3px",
    //     color: "#fff !important",
    //   },
    // },
  },
  sublistitem: {
    cursor: "pointer",
    padding: "0px 4px !important",
    "& svg": {
      color: "#130C2D",
    },

    "&:hover": {
      background: " #116a9a ",
      borderRadius: "3px",
      color: "#ffffff !important",
      textDecoration: "none !important",
      "& svg": {
        color: "white",
      },
      "& li": {
        color: "white",
      },
      "& .MuiListItem-button:hover": {
        background: " #116a9a",
        borderRadius: "3px",
        color: "#fff !important",
      },
    },
  },
  drawer: {
    flexShrink: 0,
    height: "100%",
    whiteSpace: "nowrap",
    "& .MuiListItemIcon-root": {
      minWidth: "auto",
      margin: "0 auto",
    },
    "& .MuiListItem-gutters": {
      paddingLeft: "0px",
      paddingRight: "0px",
      // padding:"0px 8px"
    },
  },
  drawerOpen: {
    transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "& .MuiListItem-gutters": {
      paddingLeft: " 8px",
      paddingRight: "8px",
    },
  },
  drawerClose: {
    transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    overflowX: "revert",
    overflowY: "revert",
    position: "relative",
    minWidth: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      minWidth: theme.spacing(9) + 1,
    },
    width: "100%",
    "& .MuiListItemText-root": {
      display: "none",
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  icon: {
    width: "200px",
    margin: "0 auto",
    maxWidth: "100%",
  },
  closeIcon: {
    width: "50px",
    marginBottom: "20px",
  },
  title: {
    //color: "#130C2D",
    overflow: "hidden",
    fontWeight: "600",
    marginLeft: "10px",
    whiteSpace: "initial",
    "& .MuiTypography-body1": {
      fontSize: "18px",
      fontWeight: "800",
      color: "#116a9a",

      // "&:hover": {
      //   color: "#ffffff",
      // },
    },
  },
  subTitle: {
    //color: "#130C2D",
    overflow: "hidden",
    fontWeight: "600",
    marginLeft: "10px",
    whiteSpace: "initial",
    "& .MuiTypography-body1": {
      fontSize: "12px",
      fontWeight: "200",
      color: "#116a9a",

      "&:hover": {
        color: "#ffffff",
      },
    },
  },
  list: {
    height: "100%",
    "& ul": {
      height: "100%",
      position: "relative",
    },
  },

  button: {
    outLine: "unset",
  },
  "& .MuiButtonBase-root:focus": {
    outLine: "unset !important",
  },
}));

function MiniDrawer(props) {
  let initList = props[0];
  let dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [show, setShow] = React.useState(true);
  const [dropdownShow, setDropdownShow] = React.useState(true);
  const { user } = useSelector((state) => state.AuthReducer);
  let currentOrg = useSelector((state) => state.OrgReducer.currentOrg);
  const [role, setRole] = useState(null);
  const [orgRole, setOrgRole] = useState(null);
  const [launcherApp, setLauncherApp] = useState(null);

  const { loading } = useSelector((state) => state.CommonReducer);
  const { product } = useSelector((state) => state.ProductReducer);
  const location = useLocation();
  const history = useHistory();

  console.log("initial state", initList);
  const handleChangeProduct = (product) => {
    if (loading) return;
    if (currentOrg.length > 0)
      dispatch(
        OrgActions.getOrgResults(currentOrg[0]?.orgId, product, {
          callback: (response) => {},
        })
      );
    else {
      dispatch(ProductActions.setProduct({ product, callback: () => {} }));
    }
  };
  const fetchLauncherApp = () => {
    dispatch(
      LauncherActions.getLauncherApp({
        callback: (res) => {
          if (res.result.length > 0) {
            setLauncherApp(res.result[0]);
          }
        },
      })
    );
  };

  useEffect(() => {
    dispatch(CollapsedSidenavAction(true));
    fetchLauncherApp();
  }, []);

  useEffect(() => {
    const currPath = location.pathname.split("/")[1];
    console.log("currPath", currPath);
    if (
      currPath == "recent-assessment" ||
      currPath == "recent-assessment-data"
    ) {
      dispatch(
        ProductActions.setProduct({ product: "als", callback: () => {} })
      );
    }

    if (
      currPath == "situation-assessment" ||
      currPath == "situation-assessment-data"
    ) {
      dispatch(
        ProductActions.setProduct({ product: "codeBlack", callback: () => {} })
      );
    }

    if (
      currPath == "deescalation-assessment" ||
      currPath == "deescalation-assessment-data"
    ) {
      dispatch(
        ProductActions.setProduct({
          product: "deEscalation",
          callback: () => {},
        })
      );
    }

    if (currPath == "dp-assessment" || currPath == "dp-assessment-data") {
      dispatch(
        ProductActions.setProduct({
          product: "deterioratingPatient",
          callback: () => {},
        })
      );
    }
    if (
      currPath == "handover-assessment" ||
      currPath == "handover-assessment-data"
    ) {
      dispatch(
        ProductActions.setProduct({
          product: "handoverSim",
          callback: () => {},
        })
      );
    }
  }, [location.pathname]);
  const handleDrawer = async () => {
    await dispatch(CollapsedSidenavAction(!open));
    setOpen(!open);
  };

  useEffect(() => {
    (async () => {
      if (window.innerWidth < 760) {
        setShow(false);
      }
    })();
  }, []);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 760) {
        setShow(false);
      } else setShow(true);

      // console.log("window.innerWidth :>> ", window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    if (user?.role === "superAdmin") setRole("superAdmin");
    else if (currentOrg.length > 0) {
      const roles = checkRole(currentOrg[0]);

      if (roles.isAdmin || roles.isTeacher || roles.owner) setRole("org");
      if (roles.isTeacher) setOrgRole("Teacher");
      if (roles.isAdmin || roles.owner) setOrgRole("Org Admin");
    } else {
      setRole("user");
    }
  }, [currentOrg]);

  return (
    <div className="showNavParentRoot">
      <div className="menuItems">
        <MenuIcon
          className="mobile__hamburger__icon"
          onClick={() => setShow(!show)}
        />
        <img
          src={APPLICATION_CONSTANTS.SITE_ICON}
          alt="logo"
          className="logoMobile"
          onClick={() => history.push("/")}
        />
      </div>
      {show ? (
        <div className="sideNav">
          {" "}
          <CssBaseline />
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx(
                {
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                },
                "drawerfixed"
              ),
            }}
          >
            <div className={open ? "absdownArrOpen" : "absdownArr"}>
              {theme.direction === "rtl" || open ? (
                <ArrowLeftIcon onClick={handleDrawer} className="dropdwnicon" />
              ) : (
                <ArrowRightIcon
                  onClick={handleDrawer}
                  className="dropdwniconOpen"
                />
              )}
            </div>
            <div className={classes.list}>
              <List>
                <ListItem>
                  <ListItemIcon
                    className="d-flex flex-column sideNavAlignment "
                    style={{ maxWidth: "100%", marginTop: "10px" }}
                  >
                    <img
                      src={
                        open
                          ? APPLICATION_CONSTANTS.SITE_LOGO_MENU
                          : !open
                          ? APPLICATION_CONSTANTS.SITE_ICON
                          : null
                      }
                      alt="sidenavImg"
                      className={clsx({
                        [classes.icon]: open,
                        [classes.closeIcon]: !open,
                      })}
                    />
                    {/* <p
                      style={{
                        color: "#116A9A",
                        fontWeight: 600,
                        marginTop: 5,
                        whiteSpace: "initial",
                        textAlign: "center",
                        padding: "0 8px",
                      }}
                      className={clsx({
                        [classes.hide]: !open,
                      })}
                    >
                      {user?.role == "superAdmin"
                        ? " Super Admin"
                        : role == "org"
                        ? ` ${orgRole} in ${currentOrg[0]?.orgName}`
                        : role == "user"
                        ? ` ${user["userName"]}`
                        : null}
                    </p> */}
                  </ListItemIcon>
                </ListItem>

                {initList.map((item, index1) => {
                  return (
                    <>
                      {console.log("item", item)}

                      <ListItem
                        activeClassName={classes.activeClass}
                        className={classes.listitem}
                      >
                        <ListItemText
                          className={clsx(classes.title)}
                          primary={item.title}
                        />
                      </ListItem>
                      {item?.list?.map((listItem, index2) => {
                        return (
                          <ListItem
                            onClick={() => {
                              history.push(ROUTES.PRODUCT_ANALYTICS_ADMIN);
                              console.log(
                                "pathname check...",
                                location.pathname
                              );
                              const currPath = location.pathname.split("/")[1];
                              console.log("location", location);
                              console.log("currPath", currPath);
                              if (
                                currPath == "situation-assessment" ||
                                currPath == "recent-assessment" ||
                                currPath == "deescalation-assessment" ||
                                currPath == "dp-assessment"
                              ) {
                                history.push(
                                  listItem.assessmentPath +
                                    location.pathname.split("/")[2]
                                );
                              }
                              if (currPath == "account-settings") {
                                history.push(ROUTES.USER_DASHBOARD);
                              }
                              if (
                                currPath == "org-settings" ||
                                currPath == "user-management"
                              ) {
                                history.push(ROUTES.GROUPMANAGEMENT);
                              }

                              if (
                                currPath == "recent-assessment-data" ||
                                currPath == "situation-assessment-data" ||
                                currPath == "deescalation-assessment-data" ||
                                currPath == "dp-assessment-data"
                              ) {
                                const query = new URLSearchParams(
                                  props.location.search
                                );
                                const id = query.get("id");
                                history.push(listItem.assessmentPath + id);
                              }
                              if (currPath == "group-management") {
                                props.handleTabChange();
                                history.push(ROUTES.GROUPMANAGEMENT);
                              }
                              handleChangeProduct(listItem.product);
                            }}
                            className={
                              location.pathname.split("/")[1] !=
                                "account-settings" &&
                              location.pathname.split("/")[1] !=
                                "org-settings" &&
                              listItem.product == product
                                ? classes.activeClass
                                : classes.sublistitem
                            }
                            button
                            key={index2}
                          >
                            {console.log("item", listItem)}
                            <ListItemIcon>
                              {open ? item.icon : listItem.icon}
                            
                            </ListItemIcon>

                            <ListItemText
                              className={clsx(classes.subTitle)}
                              primary={listItem.title}
                            />
                          </ListItem>
                        );
                      })}
                    </>
                  );
                })}
                {/* {initList.map((text, index) => (
                  <>
                    <NavLink
                      to={
                        text["btnName"] === "signout"
                          ? { pathname: props.location.pathName }
                          : { pathname: text.route }
                      }
                      activeClassName={classes.activeClass}
                      className={classes.listitem}
                      onClick={() =>
                      (text.dropDown = "yes"
                        ? setDropdownShow(!dropdownShow)
                        : setDropdownShow(dropdownShow))
                      }
                    >
                      <ListItem button key={text.index}>
                        <ListItemIcon>{text.icon}</ListItemIcon>
                        <ListItemText
                          className={clsx(classes.title)}
                          primary={text.title}
                        />
                        <ListItemIcon>
                          {
                            (text.dropDown =
                              "yes" && dropdownShow
                                ? text.iconDropUp
                                : (text.dropDown =
                                  "yes" && !dropdownShow
                                    ? text.iconDropDown
                                    : null))
                          }
                        </ListItemIcon>
                      </ListItem>
                    </NavLink>
                    {text?.list?.map((item) => {
                      if (dropdownShow) {
                        return (
                          <NavLink
                            to={{
                              pathname: `${item.subRouteName}${user.uuid}`,
                            }}
                          >
                          {console.log("dgg", item.subRouteName)}

                          <ListItem
                            className={classes.listitem}
                            button
                            key={item.index}
                          >
                            {console.log("item", item)}
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText
                              className={clsx(classes.title)}
                              primary={item.title}
                            />
                          </ListItem>
                        </NavLink>
                        );
                      }
                    })}
                  </>
                ))} */}
                {user?.role != "superAdmin" && launcherApp && (
                  <>
              {open &&  <div className="downloadSimdashButtonContainer">
                  <p className="downloadSimdashTitle">
                    Simdash Launcher
                  </p>
                      <div className="downloadButton">
                    <Button
                      background="primary"
                      color="secondary"
                      padding="5px"
                      name="Download Now"
                      handleClick={() => {
                        window.open(launcherApp.downloadLink);
                      }}
                    />
                  </div>
                </div>} 
                {!open && <div className="downloadSimdashButtonContainer">
                <p className="imgOfOrg noImgOrg">SL</p>
                <div className="imgOfOrg noImgOrg downloadButtonIcon">
                  <GetApp fill='#116a9a'
                      handleClick={() => {
                        window.open(launcherApp.downloadLink);
                      }}/>
                </div>
                     
                </div>}  
                </>
                )}
                     
              </List>
              
            </div>
          </Drawer>
          <main className={classes.content}>
            <div className={classes.toolbar} />
          </main>
        </div>
      ) : null}
    </div>
  );
}

export default withRouter(MiniDrawer);
