import React, { useState } from "react";
import "./Footer.css";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CancelIcon from "@material-ui/icons/Cancel";
const Footer = () => {
  const [modalState, setModal] = useState(false);
  const [tncState, setTncModal] = useState(false);
  const [content, setContent] = useState({
    tncContent: "",
    privacyPolicy: "",
  });

  return (
    <div className="Footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 colOne">
            <h2>LearnSuite</h2>
            <p
              onClick={() => {
                setModal(!modalState);
              }}
            >
              Privacy Policy
            </p>
            <p
              onClick={() => {
                setTncModal(!tncState);
              }}
            >
              Terms and Conditions
            </p>
          </div>
          <div className="col-lg-3 ">
            <h2>Product Pages</h2>
            <p>
              <a
                href="https://learnsuite.kb.help/advanced-life-support"
                target="_blank"
              >
                {" "}
                Advance Life Support{" "}
              </a>
            </p>
            <p>
              <a href="https://learnsuite.kb.help/codeblack/" target="_blank">
                {" "}
                Codeblack
              </a>
            </p>
            <p>
              {" "}
              <a
                rel="noreferrer"
                href="https://learnsuite.kb.help/de-escalation/"
                target="_blank"
              >
                De-Escalation
              </a>
            </p>
            <p>
              {" "}
              <a
                rel="noreferrer"
                href="https://learnsuite.kb.help/rapid-response/"
                target="_blank"
              >
                Rapid Response
              </a>
            </p>
            <p>
              <a
                rel="noreferrer"
                href="https://learnsuite.kb.help/handover/"
                target="_blank"
              >
                Handover Sim
              </a>
            </p>
          </div>
          <div className="col-lg-3 ">
            <h2>Support Centre</h2>
            <p>
              <a href="https://learnsuite.kb.help" target="_blank">
                {" "}
                Knowledge Base
              </a>
            </p>
            <p>
              <a href="https://learnsuite.kb.help/learnsuite/" target="_blank">
                {" "}
                LearnSuite
              </a>
            </p>
            <p>
              <a href="https://learnsuite.kb.help/simdash/" target="_blank">
                {" "}
                SimDash{" "}
              </a>
            </p>
            <p>
              <a href="https://learnsuite.kb.help/faq/" target="_blank">
                {" "}
                FAQs{" "}
              </a>
            </p>
          </div>
          <div className="col-lg-3 ">
            <h2>Frameless Interactive</h2>
            <p>About Us</p>
            <p>
              {" "}
              <a href="https://www.framelessinteractive.com.au" target="_blank">
                {" "}
                Frameless{" "}
              </a>
            </p>
          </div>
        </div>

        <div className="row footerEnd">
          <p className="col1">
            Copyright © {new Date().getFullYear()} Frameless Interactive
          </p>
        </div>
      </div>
      <Dialog
        open={modalState}
        className="details detailsINfo"
        // onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title " className="text-center model-head">
          <div className="modelIcon2">
            <CancelIcon
              onClick={() => {
                setModal(false);
              }}
            />
          </div>
        </DialogTitle>
        <DialogContent className="privacyContent">
          <h1>www.simdash.xyz Privacy Policy </h1>
          <div className="subtitle">
            <p>Type of website: Educational</p>
            <p>Effective date: 1st day of January, 2020</p>
          </div>
          <div>
            <p>
              <b>www.simdash.xyz</b> is owned and operated by Frameless
              Interactive. Frameless Interactive can be contacted at:
            </p>
            <p>support@simdash.xyz</p>
          </div>
          <h3>Purpose</h3>
          <p>
            The purpose of this privacy policy (this "Privacy Policy") is to
            inform users of our Site of the following:
          </p>
          <ol>
            <li>The personal data we will collect;</li>
            <li>Use of collected data;</li>
            <li>Who has access to the data collected;</li>
            <li>The rights of Site users; and</li>
            <li>The Site's cookie policy.</li>
          </ol>
          <p>
            This Privacy Policy applies in addition to the terms and conditions
            of our Site.
          </p>
          <h3>Consent</h3>
          <p>By using our Site users agree that they consent to:</p>
          <ol>
            <li>1. The conditions set out in this Privacy Policy; and</li>
            <li>
              The collection, use, and retention of the data listed in this
              Privacy Policy
            </li>
          </ol>
          <h3>Personal Data We Collect</h3>
          <p>
            We only collect data that helps us achieve the purpose set out in
            this Privacy Policy. We will not collect any additional data beyond
            the data listed below without notifying you first.
          </p>
          <h3>Data Collected in a Non-Automatic Way</h3>
          <p>
            We may also collect the following data when you perform certain
            functions on our Site
          </p>
          <ol>
            <li>First and last name;</li>
            <li>Age;</li>
            <li>Date of birth;</li>
            <li>Sex; and</li>
            <li>Email address.</li>
          </ol>
          <p>This data may be collected using the following methods:</p>
          <ol>
            <li>Simulation events.</li>
          </ol>
          <h3>How We Use Personal Data</h3>
          <p>
            Data collected on our Site will only be used for the purposes
            specified in this Privacy Policy or indicated on the relevant pages
            of our Site. We will not use your data beyond what we disclose in
            this Privacy Policy. <br />
            The data we collect when the user performs certain functions may be
            used for the following purposes:
          </p>
          <ol>
            <li>Managing account and users.</li>
          </ol>
          <h2>Who We Share Personal Data With</h2>
          <h3>Employees</h3>
          <p>
            We may disclose user data to any member of our organisation who
            reasonably needs access to user data to achieve the purposes set out
            in this Privacy Policy.
          </p>
          <h3>Third Parties</h3>
          <p>We may share user data with the following third parties: </p>
          <ol>
            <li>Your authorised simdash organisations.</li>
          </ol>
          <p>We may share the following user data with third parties:</p>
          <ol>
            <li>SImulation metrics.</li>
          </ol>
          <p>
            We may share user data with third parties for the following
            purposes:
          </p>
          <ol>
            <li>Assessing learning and training outcomes.</li>
          </ol>

          <p>
            Third parties will not be able to access user data beyond what is
            reasonably necessary to achieve the given purpose.
          </p>
          <h3>Other Disclosures</h3>
          <p>
            We will not sell or share your data with other third parties, except
            in the following cases:
          </p>
          <ol>
            <li>If the law requires it;</li>
            <li>If it is required for any legal proceeding;</li>
            <li>To prove or protect our legal rights; and</li>
            <li>
              To buyers or potential buyers of this company in the event that we
              seek to sell the company.
            </li>
          </ol>
          <p>
            If you follow hyperlinks from our Site to another Site, please note
            that we are not responsible for and have no control over their
            privacy policies and practices.
          </p>
          <h3>How Long We Store Personal Data</h3>
          <p>
            User data will be stored until the purpose the data was collected
            for has been achieved. <br />
            You will be notified if your data is kept for longer than this
            period.
          </p>
          <h3>How We Protect Your Personal Data</h3>
          <p>
            In order to protect your security, we use the strongest available
            encryption and store all of our data on servers in secure
            facilities. All data is only accessible to our authorized employees.{" "}
            <br />
            While we take all reasonable precautions to ensure that user data is
            secure and that users are protected, there always remains the risk
            of harm. The Internet as a whole can be insecure at times and
            therefore we are unable to guarantee the security of user data
            beyond what is reasonably practical.
          </p>
          <h3>Children</h3>
          <p>
            We do not knowingly collect or use personal data from children under
            16 years of age. If we learn that we have collected personal data
            from a child under 16 years of age, the personal data will be
            deleted as soon as possible. If a child under 16 years of age has
            provided us with personal data their parent or guardian may contact
            our privacy officer.
          </p>
          <h3>
            How to Access, Modify, Delete, or Challenge the Data Collected
          </h3>
          <p>
            If you would like to know if we have collected your personal data,
            how we have used your personal data, if we have disclosed your
            personal data and to who we disclosed your personal data, or if you
            would like your data to be deleted or modified in any way, please
            contact our privacy officer here:
          </p>
          <p>
            Michael Coleman
            <br /> michaelcoleman@framelessinteractive.com.au
          </p>
          <h3>Cookie Policy</h3>
          <p>We use the following types of cookies on our Site:</p>
          <ol>
            <li>
              Functional cookies Functional cookies are used to remember the
              selections you make on our Site so that your selections are saved
              for your next visits.
            </li>
          </ol>
          <h3>Modifications</h3>
          <p>
            This Privacy Policy may be amended from time to time in order to
            maintain compliance with the law and to reflect any changes to our
            data collection process. When we amend this Privacy Policy we will
            update the "Effective Date" at the top of this Privacy Policy. We
            recommend that our users periodically review our Privacy Policy to
            ensure that they are notified of any updates. If necessary, we may
            notify users by email of changes to this Privacy Policy.
          </p>
          <h3>Contact Information</h3>
          <p>
            If you have any questions, concerns or complaints, you can contact
            our privacy officer, Michael Coleman, at:
          </p>
          <p>michaelcoleman@framelessinteractive.com.au</p>
        </DialogContent>
      </Dialog>

      <Dialog
        open={tncState}
        className="details detailsINfo"
        // onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title " className="text-center model-head">
          <div className="modelIcon2">
            <CancelIcon
              onClick={() => {
                setTncModal(false);
              }}
            />
          </div>
        </DialogTitle>
        <DialogContent className="privacyContent">
          <h1>TERMS AND CONDITIONS</h1>
          <p>
            These "Terms and Conditions" govern the use of{" "}
            <b>www.simdash.xyz</b>. This Site is owned and operated by Michael
            Coleman. This Site is a educational. By using this Site, you
            indicate that you have read and understand these Terms and
            Conditions and agree to abide by them at all times.
          </p>
          <h3>Intellectual Property</h3>
          <p>
            All content published and made available on our Site is the property
            of Michael Coleman and the Site's creators. This includes, but is
            not limited to images, text, logos, documents, downloadable files
            and anything that contributes to the composition of our Site.
          </p>
          <h3>Accounts</h3>
          <p>
            When you create an account on our Site, you agree to the following:
          </p>
          <ol>
            <li>
              You are solely responsible for your account and the security and
              privacy of your account, including passwords or sensitive
              information attached to that account; and
            </li>
            <li>
              All personal information you provide to us through your account is
              up to date, accurate, and truthful and that you will update your
              personal information if it changes.
            </li>
          </ol>
          <p>
            We reserve the right to suspend or terminate your account if you are
            using our Site illegally or if you violate these Terms and
            Conditions.
          </p>
          <h3>Limitation of Liability</h3>
          <p>
            Michael Coleman and our directors, officers, agents, employees,
            subsidiaries, and affiliates will not be liable for any actions,
            claims, losses, damages, liabilities and expenses including legal
            fees from your use of the Site.
          </p>
          <h3>Indemnity</h3>
          <p>
            Except where prohibited by law, by using this Site you indemnify and
            hold harmless Michael Coleman and our directors, officers, agents,
            employees, subsidiaries, and affiliates from any actions, claims,
            losses, damages, liabilities and expenses including legal fees
            arising out of your use of our Site or your violation of these Terms
            and Conditions.
          </p>
          <h3>Applicable Law</h3>
          <p>
            These Terms and Conditions are governed by the laws of the State of
            New South Wales.
          </p>
          <h3>Severability</h3>
          <p>
            If at any time any of the provisions set forth in these Terms and
            Conditions are found to be inconsistent or invalid under applicable
            laws, those provisions will be deemed void and will be removed from
            these Terms and Conditions. All other provisions will not be
            affected by the removal and the rest of these Terms and Conditions
            will still be considered valid.
          </p>
          <h3>Changes</h3>
          <p>
            These Terms and Conditions may be amended from time to time in order
            to maintain compliance with the law and to reflect any changes to
            the way we operate our Site and the way we expect users to behave on
            our Site. We will notify users by email of changes to these Terms
            and Conditions or post a notice on our Site.
          </p>
          <h3>Contact Details</h3>
          <div className="flexDiv">
            <div>
              <p>
                Please contact us if you have any questions or concerns. Our
                contact details are as follows:
              </p>
              <p>support@simdash.xyz</p>
            </div>
            <div>
              <p>Effective Date: 1st day of January, 2020</p>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Footer;
