import { resolveLatestVersion } from "../../Helper";

const isBlank = (str) => str.length === 0;

export const isValidImage = (file) =>
  typeof file === "object" &&
  file instanceof File &&
  (file.type === "image/jpeg" || file.type === "image/png");

export const validateAppDetailsForm = (
  appDetails,
  appDetailsErrors,
  launcherApp,
  storeApps
) => {
  console.log("app Details in validation", appDetails);

  const prevVersion = resolveLatestVersion({
    appId: appDetails?.appId,
    launcherApp,
    storeApps,
  });

  for (let app in appDetails) {
    if (
      appDetails.type !== "EDIT" &&
      app === "version" &&
      (isNaN(Number(appDetails.version)) ||
        Number(prevVersion) >= Number(appDetails.version))
    ) {
      appDetailsErrors[
        app
      ] = `Please Upload higher version than ${prevVersion}`;
    }

    if (typeof appDetails[app] === "string" && isBlank(appDetails[app])) {
      appDetailsErrors[app] = "This is a required field.";
    }

    if (appDetails[app] === null) {
      appDetailsErrors[app] = "This is a required field.";
    }

    if (
      app === "bannerImage" &&
      !(appDetails[app] instanceof File || typeof appDetails[app] === "string")
    ) {
      appDetailsErrors[app] = "Please upload Banner Image";
    }

    if (Array.isArray(appDetails[app]) && appDetails[app].length === 0) {
      appDetailsErrors[app] = "Please upload atleast one App Image.";
    }
  }
  console.log({ appDetailsErrors });
  return appDetailsErrors;
};
