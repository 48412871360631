import React from "react";
import { Bubble } from "react-chartjs-2";
import { useSelector } from "react-redux";
// import { assessments, FINAL_ASSESSMENTS } from "../../../FIXTURE";
import moment from "moment";
import { scaleTotalTime } from "../../Helper";

const BubbleGraph = ({
  orgId,
  filters = [],
  allTeamsAssessmentResults,
  orgUsers,
}) => {
  // group users assessments according to the uuid

  //Grouping the userAssesment by uuid
  const groupUserAssessments = () => {
    const _allTeamsAssessmentResults =
      // filters && filters.length
      //   ? checkFilters()        :
      [...allTeamsAssessmentResults];
    return _allTeamsAssessmentResults.reduce((r, a) => {
      r[a.uuid] = [...(r[a.uuid] || []), a];
      return r;
    }, {});
  };

  // function to  return data for the bubble graph filtered according to orgId
  const filterGraphDataByOrg = () => {
    let _final = [];
    const groupedAssessments = { ...groupUserAssessments() };
    for (const key in groupedAssessments) {
      console.log("groupedAssessments[key] :>> ", groupedAssessments[key]);
      scaleTotalTime(groupedAssessments[key]);
      console.log("scaled groupedAssessments[key] :>> ", groupedAssessments[key]);

      const backgroundColor = `rgba(${genColor()},${genColor()},${genColor()}, 1)`;
      _final.push({
        ...options,
        backgroundColor,
        pointBackgroundColor: backgroundColor,
        uuid: key,
        // label: '',
        label:
          groupedAssessments[key][0] &&
          groupedAssessments[key][0]["user"] &&
          groupedAssessments[key][0]["user"][0] &&
          groupedAssessments[key][0]["user"][0]["userName"]
            ? groupedAssessments[key][0]["user"][0]["userName"]
            : key.slice(0, 5).toUpperCase(), // this displays the ids on top of the chart
        // label: "a", // this displays the ids on top of the chart
        data: groupedAssessments[key]
          // .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
          .sort((a, b) => moment(a.createdAt) - moment(b.createdAt))
          .map(({ overallAccuracy, createdAt, scaledTotalTime}, index) => ({
            x: index,
            y: overallAccuracy,
            r: parseInt(scaledTotalTime),
            createdAt,
          })),
      });
    }

    return _final;
  };

  // const checkFilters = () => {
  //   const filterList = filters.map((filter) => filter["field"]);

  //   let allUsers = [];
  //   // get all users ids into array
  //   for (const org in orgUsers) {
  //     for (const user of orgUsers[org]) {
  //       allUsers.push(user);
  //     }
  //   }
  //   // filters users using selected filters from dropdown
  //   let filtered = [];
  //   for (const field of filterList) {
  //     allUsers.filter((user) => user[field] && filtered.push(user.uuid));
  //   }
  //   // filter the filtered list further against assessments to leave only those ids that exist in the users
  //   // filtered list above
  //   const final = [
  //     ...allTeamsAssessmentResults.filter((assess) =>
  //       filtered.includes(assess.uuid)
  //     ),
  //   ];
  //   return final;
  // };

  // generate random color
  const genColor = () => Math.floor(Math.random() * 255);

  // Graph options.
  const options = {
    fill: true,
    // backgroundColor: 'purple',
    // pointBorderColor: 'rgba(75,192,192,1)',
    pointBackgroundColor: "rgba(75,192,192,1)",
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    // pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    // pointHoverBorderColor: 'rgba(220,220,220,1)',
    // pointHoverBorderWidth: 2,
    pointRadius: 4,
    pointHitRadius: 5,
  };

  const data = {
    datasets: filterGraphDataByOrg(),
  };

  // console.log("data in the bubble ", groupUserAssessments());

  return (
    <div
      id="line-graph-container"
      style={{ margin: "30px 0px", boxShadow: "none", padding: "10px" }}
    >
      <p style={{ fontWeight: "600", paddingTop: "10px", textAlign: "center" }}>
        Scores Ordered by Assessment
      </p>
      <Bubble
        data={data}
        label="First Dataset"
        options={{
          responsive: true,

          legend: {
            display: true,
            labels: { fontColor: "black", boxWidth: 20, fontSize: 8 },
            align: "start",
          },
          scales: {
            y: {
              beginAtZero: true,
            },
            // yAxes: [
            //   {
            //     ticks: {
            //       beginAtZero: true,
            //       min: 0,
            //     },
            //     scaleLabel: {
            //       display: true,
            //       labelString: "Score",
            //       fontSize: 14,
            //       fontStyle: "bold",
            //     },
            //   },
            // ],
            // xAxes: [
            //   {
            //     ticks: {
            //       beginAtZero: true,
            //       min: 0,
            //       stepSize: 1,
            //       // fontSize: 8
            //     },
            //     scaleLabel: {
            //       display: true,
            //       labelString: "Assessment",
            //       fontSize: 14,
            //       fontStyle: "bold",
            //     },
            //   },
            // ],
          },
        }}
      />
      {/* <Test options={_options} /> */}
    </div>
  );
};

export default BubbleGraph;
