import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";

import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import DatePicker from "../DatePicker/datePicker";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import Divider from "@material-ui/core/Divider";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";

import Button from "../Button/button";
const useStyles = makeStyles({
  root: {
    display: "flex",
    flex: "0 0 33%",
    flexDirection: "column",
    justifyContent: "space-evenly",

    margin: "15px auto ",
    padding: "14px",
  },
  media: {
    height: 140,
  },
  action: {
    borderTop: "2px solid #116a9a",
    justifyContent: "space-evenly",
    background: "#fff",
    borderRadius: "3px",
    marginTop: "1px",
    "& .MuiSvgIcon-root": {
      width: "25px !important",
    },
    "& svg:hover": {
      color: "#116a9a",
    },
  },
  vtl: {
    background: "#116a9a",
    color: "#116a9a",
  },
  card: {
    borderRadius: "3px",
    padding: "0px 20px",
    "& .MuiCardContent-root": {
      padding: "16px 0px",
    },
  },
  hor: {
    height: "3px",
    width: "180px",

    margin: "10px auto",
    "& .MuiDivider-root": {
      backgroundColor: "#116a9a",
    },
    backgroundColor: "#116a9a",
  },
  icon: {
    textAlign: "center",
    "& .MuiSvgIcon-root": {
      width: "110px !important",
      color: "#116a9a",
    },
  },
  ul1: {
    paddingInlineStart: "10px",
    textAlign: "left",
  },
  ul2: {
    paddingInlineStart: "13px",
    textAlign: "left",
  },
  list1: {
    listStyle: "none",
    "&:nth-child(1)": {
      fontWeight: "600",
    },
    display: "flex",
    fontSize: "12px",
    justifyContent: "space-between",
    "& span": {
      flex: "0 0 50%",
      wordBreak: "break-all",
    },
  },
});

function MediaCard({
  org,
  handleCalendar,
  calendar,
  setCalendar,
  handleDelete,
  editable,
  editList,
  setEditList,
  handleEditList,
  handleSave,
  setInitialOrg,
  handleInvitePress,
}) {
  const classes = useStyles();
  const [seatLeft, setSeatLeft] = useState();
  const [subDate, setSubDate] = useState({});
  useEffect(() => {
    let seat = 0;

    if (org?.users || org?.pendingInvitations) {
      org.users.map((user) => {
        if (user.seatAlloted === true) seat += 1;
      });
      setSeatLeft(seat);
    }
  }, [
    org,
    org.length > 0 && org[0].users,
    org.length > 0 && org[0].pendingInvitations,
  ]);

  return (
    <Grid id="gridsubs" className="gridsubs">
      <div className={classes.root}>
        <Card className={classes.card}>
          <div className={classes.icon}>
            <AccountCircleOutlinedIcon />
            <Divider className={classes.hor} variant="middle" />

            <CardContent>
              <div>
                <ul className={classes.ul1}>
                  <li className={classes.list1}>
                    <span>Org Name</span>
                    {editList[`${org.orgId}`] ? (
                      <input
                        style={{ overflow: "auto" }}
                        value={org.orgName}
                        type="text"
                        name="orgName"
                        id={org.orgId}
                        onChange={handleEditList}
                      />
                    ) : (
                      <span> {org.orgName}</span>
                    )}
                  </li>
                  <li className={classes.list1}>
                    <span>Owner Email</span>

                    <span> {org.ownerEmail}</span>
                    {/* )} */}
                  </li>
                  <li className={classes.list1}>
                    <span>Total Seats</span>

                    {editList[`${org.orgId}`] ? (
                      <input
                        style={{ overflow: "auto" }}
                        value={org?.seats}
                        type="text"
                        name="seats"
                        id={org.orgId}
                        onChange={handleEditList}
                      />
                    ) : (
                      <span> {org?.seats}</span>
                    )}
                  </li>
                  <li className={classes.list1}>
                    <span>Seats Allotted</span>

                    <span> {seatLeft}</span>
                  </li>
                  <li className={classes.list1}>
                    <span> Subscription Start date</span>
                    {calendar[`${org.orgId}`] ? (
                      <DatePicker
                        id={org.orgId}
                        dateDefault={
                          org.subscriptionStartDate &&
                          org.subscriptionStartDate.split("T")[0]
                        }
                        name="subscriptionStartDate"
                        handleChange={handleEditList}
                      />
                    ) : (
                      <span>
                        {" "}
                        {org.subscriptionStartDate &&
                          org.subscriptionStartDate.split("T")[0]}
                      </span>
                    )}
                  </li>

                  <li className={classes.list1}>
                    <span> Subscription End date</span>
                    {calendar[`${org.orgId}`] ? (
                      <DatePicker
                        id={org.orgId}
                        dateDefault={
                          org.subscriptionEndDate &&
                          org.subscriptionEndDate.split("T")[0]
                        }
                        name="subscriptionEndDate"
                        handleChange={handleEditList}
                      />
                    ) : (
                      <span>
                        {" "}
                        {org.subscriptionEndDate &&
                          org.subscriptionEndDate.split("T")[0]}
                      </span>
                    )}
                  </li>

                  <li className={classes.list1}>
                    <span>Number of Users</span>
                    <span> {org?.users?.length}</span>
                  </li>
                  <li className={classes.list1}>
                    <span>OrgId</span>
                    <span> {org.orgId}</span>
                  </li>
                </ul>
              </div>
            </CardContent>
          </div>
        </Card>

        <div>
          <CardActions className={classes.action}>
            <div>
              <Button
                background="secondary"
                color="primary"
                name="Invite"
                handleClick={() => handleInvitePress(org)}
              />
            </div>

            <DeleteOutlineIcon onClick={() => handleDelete(org.orgId)} />
            <Divider orientation="vertical" className={classes.vtl} flexItem />
            <EditOutlinedIcon
              onClick={() =>
                setEditList((prevState) => ({
                  ...prevState,
                  [`${org.orgId}`]: true,
                }))
              }
            />
            <Divider orientation="vertical" className={classes.vtl} flexItem />
            <CalendarTodayOutlinedIcon
              onClick={() =>
                setCalendar((prevState) => ({
                  ...prevState,
                  [`${org.orgId}`]: true,
                }))
              }
            />
          </CardActions>
        </div>
        {(calendar[`${org.orgId}`] || editList[`${org.orgId}`]) && (
          <div>
            <CardActions className={classes.action}>
              <Button
                name="Save"
                handleClick={() => handleSave(org)}
                color="primary"
                textAlign="center"
              />
              <Divider
                orientation="vertical"
                className={classes.vtl}
                flexItem
              />
              <Button
                name="Cancel"
                handleClick={() => {
                  setEditList((prevState) => ({
                    ...prevState,
                    [`${org.orgId}`]: false,
                  }));
                  setCalendar((prevState) => ({
                    ...prevState,
                    [`${org.orgId}`]: false,
                  }));
                  setInitialOrg();
                }}
                color="primary"
                textAlign="center"
              />
            </CardActions>
          </div>
        )}
      </div>
    </Grid>
  );
}
export default MediaCard;
