import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import "./modal.css";
import { OrgActions, SuperAdminActions } from "../../ReduxStore/Actions";
import { useDispatch, useSelector } from "react-redux";
const FormDialog = (props) => {
  if (!props.user) return null;
  else
    return (
      <div>
        <Dialog
                disableBackdropClick
          open={props.open}
          className="details detailsINfo"
          onClose={props.handleClose}
          aria-labelledby="form-dialog-title"
        >
          {props?.user?.userImage ? (
            <img
              style={{ maxWidth: "65px", height: "auto", margin: "auto" }}
              src={props?.user?.userImage}
            />
          ) : (
            <IconButton
              type="submit"
              className="adminSchoolsearchIcon"
              aria-label="search"
              style={{
                maxWidth: "fit-content",
                height: "auto",
                margin: "auto",
              }}
            >
              {props.mainIcon}
            </IconButton>
          )}
          <DialogTitle
            id="form-dialog-title "
            className="text-center model-head"
          >
            <div className="modelIcon2">{props.secondIcon}</div>
          </DialogTitle>
          <DialogContent className="">
            <Grid
              container
              xs={12}
              spacing={3}
              // direction="row"
              justify="space-around"
              // alignItems="center"
            >
              <TextField
                className=" mt-3"
                variant="outlined"
                type="text"
                label="First Name"
                InputProps={{
                  readOnly: true,
                }}
                defaultValue={props?.user?.userName}
                id="custom-css-outlined-input"
              />
              <TextField
                className=" mt-3"
                variant="outlined"
                type="text"
                label="Last Name"
                InputProps={{
                  readOnly: true,
                }}
                defaultValue={props?.user?.lastName}
                id="custom-css-outlined-input"
              />
              <TextField
                className=" mt-3"
                variant="outlined"
                type="text"
                label="Email"
                InputProps={{
                  readOnly: true,
                }}
                defaultValue={props?.user?.email}
                id="custom-css-outlined-input"
              />{" "}
              <TextField
                className=" mt-3"
                variant="outlined"
                type="text"
                label="Year of Birth"
                InputProps={{
                  readOnly: true,
                }}
                defaultValue={props?.user?.yob}
                id="custom-css-outlined-input"
              />{" "}
              <TextField
                className=" mt-3"
                variant="outlined"
                type="text"
                label="Gender"
                defaultValue={props?.user?.gender}
                id="custom-css-outlined-input"
                InputProps={{
                  readOnly: true,
                }}
              />{" "}
              <TextField
                className=" mt-3"
                variant="outlined"
                type="text"
                label="Occupation"
                defaultValue={props?.user?.occupation}
                id="custom-css-outlined-input"
                InputProps={{
                  readOnly: true,
                }}
              />{" "}
              <TextField
                className=" mt-3"
                variant="outlined"
                type="text"
                label="Gaming Experience"
                defaultValue={props?.user?.gamingExp}
                id="custom-css-outlined-input"
                InputProps={{
                  readOnly: true,
                }}
              />{" "}
              <TextField
                className=" mt-3"
                variant="outlined"
                type="text"
                label="Clinical Speciality"
                defaultValue={props?.user?.clinicalSpecialty}
                id="custom-css-outlined-input"
                InputProps={{
                  readOnly: true,
                }}
              />{" "}
              <TextField
                className=" mt-3"
                variant="outlined"
                type="text"
                label="Years of Post Grad experience"
                defaultValue={props?.user?.yearsPostGradExp}
                id="custom-css-outlined-input"
                InputProps={{
                  readOnly: true,
                }}
              />{" "}
              <TextField
                className=" mt-3"
                variant="outlined"
                type="text"
                label="Years of experience with ALS"
                defaultValue={props?.user?.yearsAlsExp}
                id="custom-css-outlined-input"
                InputProps={{
                  readOnly: true,
                }}
              />{" "}
              <TextField
                className=" mt-3"
                variant="outlined"
                type="text"
                label="Position or Grade"
                defaultValue={props?.user?.positionGrade}
                id="custom-css-outlined-input"
                InputProps={{
                  readOnly: true,
                }}
              />{" "}
              <TextField
                className=" mt-3"
                variant="outlined"
                type="text"
                label="Experience with virtual Reality"
                defaultValue={props?.user?.usedVirtualReality}
                InputProps={{
                  readOnly: true,
                }}
                id="custom-css-outlined-input"
              />{" "}
              <TextField
                InputProps={{
                  readOnly: true,
                }}
                className=" mt-3"
                variant="outlined"
                type="text"
                label="Is english your second language?"
                defaultValue={props?.user?.englishSecond}
                id="custom-css-outlined-input"
              />{" "}
              <TextField
                InputProps={{
                  readOnly: true,
                }}
                className=" mt-3"
                variant="outlined"
                type="text"
                label="Do you wear glasses?"
                defaultValue={props?.user?.glasses}
                id="custom-css-outlined-input"
              />{" "}
              <TextField
                InputProps={{
                  readOnly: true,
                }}
                className=" mt-3"
                variant="outlined"
                type="text"
                label="Completed ALS program before"
                defaultValue={props?.user?.completedAlsPrev}
                id="custom-css-outlined-input"
              />
              <TextField
                InputProps={{
                  readOnly: true,
                }}
                className=" mt-3"
                variant="outlined"
                type="text"
                label="Experience with Computer"
                defaultValue={props?.user?.usedComp}
                id="custom-css-outlined-input"
              />
              <TextField
                InputProps={{
                  readOnly: true,
                }}
                className=" mt-3"
                variant="outlined"
                type="text"
                label="Experience with Tablet"
                defaultValue={props?.user?.usedTablet}
                id="custom-css-outlined-input"
              />
              <TextField
                InputProps={{
                  readOnly: true,
                }}
                className=" mt-3"
                variant="outlined"
                type="text"
                label="Experience with SmartPhone"
                defaultValue={props?.user?.usedSmartPhone}
                id="custom-css-outlined-input"
              />
              <TextField
                InputProps={{
                  readOnly: true,
                }}
                className=" mt-3"
                variant="outlined"
                type="text"
                label="Experience with Tablet"
                defaultValue={props?.user?.usedTablet}
                id="custom-css-outlined-input"
              />
              <TextField
                InputProps={{
                  readOnly: true,
                }}
                className=" mt-3"
                variant="outlined"
                type="text"
                label="Experience with AR"
                defaultValue={props?.user?.usedAugmentedReality}
                id="custom-css-outlined-input"
              />
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
};
export default FormDialog;
