import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import OrganizationDashboard from "../DashboardModule/OrganizationDashboard/organizationDashboard";
import userManagement from "../DashboardModule/userManagement/userManagement";
import { ROUTES } from "../Services/Constants/index";
import Login from "./Login/Login";
import Register from "./Register/register";
import ForgotPassword from "./ForgotPassword";
import RouteRBAC from "./../Component/RBAC/RouteRBAC";

import GroupManagement from "../DashboardModule/GroupManagement/groupManagement";
import SuperAdminDashBoard from "../DashboardModule/SuperAdminDashboard/superAdminDashboard";
import ProductAnalyticsAdmin from "../DashboardModule/ProductAnalyticsAdmin/productAnalyticsAdmin";
import OrganisationManagement from "../DashboardModule/OrganisationManagement/organisationManagement";
import Subscription from "../DashboardModule/Subscriptions/subscription";
import Invite from "./Invite/Invite";
import AccountSettings from "../DashboardModule/AccountSettings/accountSettings";
import OrgSettings from "../DashboardModule/OrganisationSettings/organisationSettings";
import UserData from "../DashboardModule/UserData/userData";

import RecentAssessment from "../DashboardModule/AssessmentData/RecentAssessment/recentAssessment";
import RecentAssessmentData from "../DashboardModule/AssessmentData/RecentAssessmentData/recentAssessmentData";
import SituationAssessment from "../DashboardModule/AssessmentData/SituationAssessment/situationAssessment";
import SituationAssesmentData from "../DashboardModule/AssessmentData/SituationAssessmentData/situationAssesmentData";
import DeEscalationAssessment from "../DashboardModule/AssessmentData/DeEscalationAssessment";
import DeEscalationAssessmentData from "../DashboardModule/AssessmentData/DeEscalationAssessmentData";
import ProductAnalytics from "../DashboardModule/ProductAnalytic/productAnalytics";
import UserDashBoard from "../DashboardModule/UserDashboard/userDashBoard";
import UserSetting from "../DashboardModule/UserSetting/index";
// import OrgSideNav from "../DashboardModule/organizationSideNav/orgSideNav";
import OrgSelection from "../DashboardModule/OrgSelection/index";
import LoginSuperAdmin from "./Login/loginSuperAdmin";
import InviteAccept from "../DashboardModule/InviteAccept/index";
import WelcomeMessage from "../DashboardModule/WelcomeMessage/index";
import DPAssessment from "../DashboardModule/AssessmentData/DetPatAssessment";
import DetPatResultView from "../DashboardModule/AssessmentData/DetPatResultView";
import HandoverAssessment from "../DashboardModule/AssessmentData/HandoverAssessment";

import HandoverAssessmentData from "../DashboardModule/AssessmentData/HandoverAssessmentData";
import SimdashLauncher from "../DashboardModule/SimdashLauncher";
import PreviousVersions from "../DashboardModule/PreviousVersions";
const WebsiteModule = () => {
  return (
    <Router>
      <Switch>
        <RouteRBAC renderNoAccess={() => <Redirect to={{ pathname: "/" }} />}>
          {/* <Route
            authorisedUsers={["admin", "teacher", "student"]}
            path={ROUTES.ORG_SELECTION}
            component={OrgSelection}
            exact
          /> */}
          <Route
            authorisedUsers={["admin", "teacher", "student"]}
            path={ROUTES.WELCOME_MESSAGE}
            component={WelcomeMessage}
            exact
          />

          <Route
            authorisedUsers={["admin", "teacher", "student"]}
            path={ROUTES.INVITE_ACCEPT}
            component={InviteAccept}
            exact
          />
          <Route
            authorisedUsers={["guest"]}
            path={ROUTES.LOGIN}
            component={Login}
            exact
          ></Route>
          <Route
            authorisedUsers={["guest"]}
            path={ROUTES.FORGOT_PASSWORD}
            component={ForgotPassword}
            exact
          ></Route>
          <Route
            authorisedUsers={["guest"]}
            path={ROUTES.LOGIN_SUPERADMIN}
            component={LoginSuperAdmin}
            exact
          ></Route>
          <Route
            authorisedUsers={["guest"]}
            path={ROUTES.INVITE}
            component={Invite}
            exact
          />
          <Route
            path={ROUTES.USER_DATA}
            authorisedUsers={["admin", "teacher"]}
            component={UserData}
            exact
          />
          <Route
            authorisedUsers={["superAdmin", "admin", "teacher", "student"]}
            path={ROUTES.RECENTASSESSMENTDATA}
            component={RecentAssessmentData}
            exact
          />
          <Route
            authorisedUsers={["superAdmin", "admin", "teacher", "student"]}
            path={ROUTES.SITUATIONASSESSMENTDATA}
            component={SituationAssesmentData}
            exact
          />
          <Route
            authorisedUsers={["superAdmin", "admin", "teacher", "student"]}
            path={ROUTES.DEESCALATIONASSESSMENTDATA}
            component={DeEscalationAssessmentData}
            exact
          />
          <Route
            authorisedUsers={["superAdmin", "admin", "teacher", "student"]}
            path={ROUTES.HANDOVER_ASSESSMENT_DATA}
            component={HandoverAssessmentData}
            exact
          />
          <Route
            authorisedUsers={["superAdmin", "admin", "teacher", "student"]}
            path={ROUTES.RECENTASSESSMENT}
            component={RecentAssessment}
            exact
          />
          <Route
            authorisedUsers={["superAdmin", "admin", "teacher", "student"]}
            path={ROUTES.SITUATIONASSESSMENT}
            component={SituationAssessment}
            exact
          />
          <Route
            authorisedUsers={["superAdmin", "admin", "teacher", "student"]}
            path={ROUTES.DEESCALATIONASSESSMENT}
            component={DeEscalationAssessment}
            exact
          />
          <Route
            authorisedUsers={["superAdmin", "admin", "teacher", "student"]}
            path={ROUTES.DPASSESSMENT}
            component={DPAssessment}
            exact
          />
          <Route
            authorisedUsers={["superAdmin", "admin", "teacher", "student"]}
            path={ROUTES.DPASSESSMENTDATA}
            component={DetPatResultView}
            exact
          />
          <Route
            authorisedUsers={["superAdmin", "admin", "teacher", "student"]}
            path={ROUTES.HANDOVER_ASSESSMENT}
            component={HandoverAssessment}
            exact
          />
          <Route
            authorisedUsers={["superAdmin", "admin"]}
            path={ROUTES.PRODUCT_ANALYTICS}
            component={ProductAnalytics}
            exact
          />
          <Route
            authorisedUsers={["guest"]}
            path={ROUTES.REGISTER}
            component={Register}
            exact
          />
          {/* <Route
            path={ROUTES.USER_DATA}
            authorisedUsers={["admin"]}
            component={UserData}
            exact
          /> */}
          <Route
            path={ROUTES.USER_PRODUCT_ANALYTIC}
            authorisedUsers={["student"]}
            component={UserData}
            exact
          />
          <Route
            path={ROUTES.USER_DASHBOARD}
            authorisedUsers={["student"]}
            component={UserDashBoard}
            exact
          />
          <Route
            path={ROUTES.USER_SETTING}
            authorisedUsers={["student"]}
            component={UserSetting}
            exact
          />
          <Route
            authorisedUsers={["admin"]}
            path={ROUTES.USERMANAGEMENT}
            component={userManagement}
            exact
          />
          <Route
            authorisedUsers={["superAdmin", "admin"]}
            path={ROUTES.OGRANIZATION_MANAGEMENT}
            component={OrganisationManagement}
            exact
          />
          <Route
            path={ROUTES.GROUPMANAGEMENT}
            authorisedUsers={["admin", "superAdmin", "teacher"]}
            component={GroupManagement}
            exact
          />
          <Route
            path={ROUTES.OGRANIZATIONDASHBOARD}
            authorisedUsers={["admin", "teacher"]}
            component={OrganizationDashboard}
            exact
          />
          <Route
            path={ROUTES.ORGANISATION_SETTINGS}
            authorisedUsers={["admin"]}
            component={OrgSettings}
            exact
          />
          <Route
            path={ROUTES.SUPER_ADMIN_DASHBOARD}
            authorisedUsers={["superAdmin"]}
            component={SuperAdminDashBoard}
            exact
          />
          <Route
            path={ROUTES.PRODUCT_ANALYTICS_ADMIN}
            authorisedUsers={["superAdmin"]}
            component={ProductAnalyticsAdmin}
            exact
          />
          <Route
            path={ROUTES.SIMDASH_LAUNCHER}
            authorisedUsers={["superAdmin"]}
            component={SimdashLauncher}
            exact
          />
          <Route
            path={ROUTES.PREVIOUS_VERSIONS}
            authorisedUsers={["superAdmin"]}
            component={PreviousVersions}
            exact
          />
          <Route
            path={ROUTES.ACCOUNT_SETTINGS}
            authorisedUsers={["student", "superAdmin"]}
            component={UserSetting}
            exact
          />
          <Route
            authorisedUsers={["superAdmin"]}
            path={ROUTES.SUBSCRIPTION}
            component={Subscription}
            exact
          />
        </RouteRBAC>
      </Switch>
    </Router>
  );
};
export default WebsiteModule;
