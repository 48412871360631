import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import HeaderBar from "../../Component/HeaderBar/headerBar";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import SideNav from "../../Component/SideNav/sideNav";
import SideUser from "../../Component/SideUser/sideUser";
import RBAC from "../../Component/RBAC/RBAC";
import { initList } from "../../Component/SideNav/initList";
import "./index.css";
import { AuthActions, OrgActions } from "../../ReduxStore/Actions";
import DetailsModel from "../../Component/Modal/inviteModal";
import Button from "../../Component/Button/button";
import AlertDialog from "../../Component/AlertDialog/alertDialog";
import { useHistory } from "react-router-dom";
import { storage } from "../../Services/Utils/firebase";
import { ACTION_TYPE } from "../../Services/Constants";
import UserInfo from "./components/userInfo";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  textBox: {
    "& TextField": {
      color: "#fff",
    },
    "& MuiFormLabel-root": {
      color: "#fff",
    },
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const UserSettings = () => {
  const classes = useStyles();
  const history = useHistory();
  let collapsedClass = useSelector(
    (state) => state.CollapsedSidenavReducer.collapsedClass
  );
  let user = useSelector((state) => state.AuthReducer.user);
  let currentOrg = useSelector((state) => state.OrgReducer.currentOrg);
  console.log("useruser", user);
  // const [open, setOpen] = React.useState(false);
  const [alertDetails, setAlertDetails] = useState({
    open: false,
    message: "",
    action: null,
  });

  const [passwordModel, setPasswordModel] = useState(false);
  const [newPassword, setNewPassword] = useState();
  const [currentPassword, setCurrentPassword] = useState();
  const allInputs = { imgUrl: "" };
  const [imageAsUrl, setImageAsUrl] = useState(allInputs);

  const [imageAsFile, setImageAsFile] = useState("");
  const [picEdit, setPicEdit] = useState(false);
  const [detailsState, setDetailsState] = useState();
  let dispatch = useDispatch();
  let { user: selectedUser } = useSelector((state) => state.OrgReducer.user);
  let userDetails = useSelector((state) => state.AuthReducer.user);
  let userDetailsById = useSelector((state) => state.AuthReducer.userDetails);
  useEffect(() => {
    if (userDetails?.uuid) {
      dispatch(
        OrgActions.fetchUserDetails({
          email: userDetails.email,
          callback: (response) => {},
        })
      );
      setImageDetails();
    }
  }, [userDetails]);

  const setImageDetails = () => {
    setPicEdit(false);
    if (userDetails.userImage == "" || userDetails.userImage == undefined) {
      setImageAsUrl({
        ...imageAsUrl,
        imgUrl: null,
      });
    } else {
      setImageAsUrl({
        ...imageAsUrl,
        imgUrl: userDetails.userImage,
      });
    }
  };

  useEffect(() => {
    if (selectedUser) {
      setDetailsState({
        ...selectedUser,

        glasses: selectedUser.glasses === true ? "yes" : "no",
        completedAlsPrev: selectedUser.completedAlsPrev == true ? "yes" : "no",
        englishSecond: selectedUser.englishSecond == true ? "yes" : "no",
      });
    }
  }, [selectedUser]);

  useEffect(() => {}, detailsState);

  const handlePicChange = (e) => {
    const image = e.target.files[0];
    console.log("image :>> ", image, image.size >= 5e6);
    if (image.size >= 5e6) {
      setAlertDetails({
        ...alertDetails,
        open: true,
        message: "Please upload an image less than 5MB!",
      });
      return;
    }
    setImageAsFile((imageAsFile) => image);
    setImageAsUrl({
      ...imageAsUrl,
      imgUrl: URL.createObjectURL(image),
    });
    setPicEdit(true);
  };

  const handleChangePassword = (e) => {
    e.preventDefault();

    dispatch(
      AuthActions.updatePassword({
        currentPassword,
        newPassword,
        user,
        callback: () => {
          setPasswordModel(false);
          setNewPassword("");
          setCurrentPassword("");
        },
      })
    );
  };
  const handleChange = (e) => {
    setDetailsState({
      ...detailsState,
      [e.target.name]: e.target.value,
    });
  };
  const handelSave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(
      AuthActions.updateUser({
        updateBody: detailsState,
        uuid: selectedUser.uuid,

        callback: (resp) => {
          setAlertDetails({
            ...alertDetails,
            open: false,
            message: "",
            action: null,
          });
        },
      })
    );
  };

  const handleFireBaseUpload = (e) => {
    e.preventDefault();
    // async magic goes here...
    if (imageAsFile === "") {
    }
    const uploadTask = storage
      .ref(`/images/${imageAsFile.name}`)
      .put(imageAsFile);
    //initiates the firebase side uploading
    uploadTask.on(
      "state_changed",
      (snapShot) => {
        //takes a snap shot of the process as it is happening
      },
      (err) => {
        //catches the errors
      },
      () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        storage
          .ref("images")
          .child(imageAsFile.name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            // const { orgId } = currentOrg[0];
            const { uuid } = userDetails;
            dispatch(
              AuthActions.updateUser({
                updateBody: { userImage: fireBaseUrl, uuid },
                uuid,

                callback: () => {
                  console.log("user", user);
                  setPicEdit(false);
                },
              })
            );
          });
      }
    );
  };

  return (
    <div className="d-flex over">
      <div className={collapsedClass ? " sideNavExpand" : "sideNavContract "}>
        {user && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}{" "}
      </div>
      <div className={collapsedClass ? "screenContract bg" : "screenExpand bg"}>
        <HeaderBar
          width={collapsedClass ? "80%" : "95%"}
          title="User Account Settings"
          searchbar="no"
        />
        <div className="componentSec accSettingMain settingUser">
          <Grid container className="orgMainParent" spacing={2}>
            <Grid className="sideUserOrg" xs item>
              <UserInfo
                name="true"
                userName={selectedUser?.userName}
                updateOrg="true"
                uploadImage="true"
                handlePicChange={handlePicChange}
                imageAsUrl={imageAsUrl}
                setImageAsFile={setImageAsFile}
                picEdit={picEdit}
                setPicEdit={setPicEdit}
                handleFireBaseUpload={handleFireBaseUpload}
                created={currentOrg[0]?.dateCreated}
                // sideUserType="user"
                setImageDetails={setImageDetails}
                changePass="true"
                handleChangePasswordClick={() => setPasswordModel(true)}
                vrCode={userDetailsById.vrCode ? userDetailsById.vrCode : null}
              ></UserInfo>
            </Grid>
            {detailsState && (
              <Grid
                className="userData justify-content-center"
                item
                xs
                container
              >
                <h4 className="textongrid"> Update your profile information</h4>
                <Grid
                  container
                  xs={12}
                  spacing={3}
                  className={classes.textBox}
                  justify="flex-start"
                >
                  <TextField
                    className=" mt-3"
                    variant="outlined"
                    type="text"
                    onChange={(e) =>
                      setDetailsState({
                        ...detailsState,
                        [e.target.name]: e.target.value,
                      })
                    }
                    name="userName"
                    label="First Name"
                    defaultValue={detailsState?.userName}
                    id="custom-css-outlined-?Sinput"
                  />
                  <TextField
                    className=" mt-3"
                    variant="outlined"
                    type="text"
                    onChange={(e) =>
                      setDetailsState({
                        ...detailsState,
                        [e.target.name]: e.target.value,
                      })
                    }
                    name="lastName"
                    label="Last Name"
                    defaultValue={detailsState?.lastName}
                    id="custom-css-outlined-?Sinput"
                  />
                  <TextField
                    className=" mt-3"
                    variant="outlined"
                    type="text"
                    label="Email"
                    onChange={(e) =>
                      setDetailsState({
                        ...detailsState,
                        [e.target.name]: e.target.value,
                      })
                    }
                    name="email"
                    defaultValue={detailsState?.email}
                    InputProps={{
                      readOnly: true,
                    }}
                    id="custom-css-outlined-input"
                  />{" "}
                  <TextField
                    className=" mt-3"
                    variant="outlined"
                    type="text"
                    onChange={(e) =>
                      setDetailsState({
                        ...detailsState,
                        [e.target.name]: e.target.value,
                      })
                    }
                    label="Year of Birth"
                    defaultValue={detailsState?.yob}
                    name="yob"
                    id="custom-css-outlined-input"
                  />{" "}
                  <FormControl variant="outlined" className="GenderDD mt-3">
                    <InputLabel
                      className=""
                      id="demo-simple-select-outlined-label"
                    >
                      Gender
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={detailsState?.gender}
                      onChange={handleChange}
                      name="gender"
                      label="Gender"
                    >
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                      <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" className="GenderDD mt-3">
                    <InputLabel
                      className=""
                      id="demo-simple-select-outlined-label"
                    >
                      Occupation
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={detailsState?.occupation}
                      onChange={handleChange}
                      name="occupation"
                      label="Occupation"
                    >
                      <MenuItem value={"Paramedic"}>
                        {" "}
                        Paramedic/Ambulance Officer
                      </MenuItem>
                      <MenuItem value={"Doctor"}>Doctor</MenuItem>

                      <MenuItem value={"Nurse"}>Nurse</MenuItem>

                      <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" className="GenderDD mt-3">
                    <InputLabel
                      className=""
                      id="demo-simple-select-outlined-label"
                    >
                      Gaming Experience
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={detailsState?.gamingExp}
                      name="gamingExp"
                      onChange={handleChange}
                      label="Gaming Experience"
                    >
                      <MenuItem value={"Never"}>Never</MenuItem>
                      <MenuItem value={"Rarely"}>Rarely</MenuItem>
                      <MenuItem value={"Several times a year"}>
                        Several times a year
                      </MenuItem>
                      <MenuItem value={"Once a month"}>Once a month</MenuItem>
                      <MenuItem value={"Once a fortnight"}>
                        Once a fortnight
                      </MenuItem>
                      <MenuItem value={"Once a week"}>Once a week</MenuItem>
                      <MenuItem value={"Everyday"}>Everyday</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" className="GenderDD mt-3">
                    <InputLabel
                      className=""
                      id="demo-simple-select-outlined-label"
                    >
                      Select clinical speciality
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={detailsState?.clinicalSpecialty}
                      name="clinicalSpecialty"
                      onChange={handleChange}
                      label="clinical speciality"
                    >
                      <MenuItem value={"Education"}>Education</MenuItem>
                      <MenuItem value={"ICU"}>ICU</MenuItem>
                      <MenuItem value={"ED"}>Ed</MenuItem>
                      <MenuItem value={"Cardiology"}>Cardiology</MenuItem>
                      <MenuItem value={"Anaesthetics"}>Anaesthetics</MenuItem>
                      <MenuItem value={"HDU"}>HDU</MenuItem>

                      <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                  </FormControl>
                  {detailsState && detailsState.occupation == "Nurse" && (
                    <FormControl variant="outlined" className="GenderDD mt-3">
                      <InputLabel
                        className=""
                        id="demo-simple-select-outlined-label"
                      >
                        Select position or grade
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={detailsState?.positionGrade}
                        name="positionGrade"
                        onChange={handleChange}
                        label="Select position or grade"
                      >
                        <MenuItem value={"RN1-8"}>RN1-8</MenuItem>
                        <MenuItem value={"CNS"}>CNS</MenuItem>
                        <MenuItem value={"CNE"}>CNE</MenuItem>
                        <MenuItem value={"NE"}>NE</MenuItem>
                        <MenuItem value={"NUM"}>NUM</MenuItem>
                        <MenuItem value={"NM"}>NM</MenuItem>
                        <MenuItem value={"Other"}>Other</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  {detailsState && detailsState.occupation == "Doctor" && (
                    <FormControl variant="outlined" className="GenderDD mt-3">
                      <InputLabel
                        className=""
                        id="demo-simple-select-outlined-label"
                      >
                        Select position or grade
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={detailsState?.positionGrade}
                        name="positionGrade"
                        onChange={handleChange}
                        label="Select position or grade"
                      >
                        <MenuItem value={"Other"}>Other</MenuItem>

                        <MenuItem value={"Intern-8"}>Intern</MenuItem>
                        <MenuItem value={"Resident"}>Resident</MenuItem>
                        <MenuItem value={"Registrar"}>Registrar</MenuItem>
                        <MenuItem value={"Senior Registrar"}>
                          Senior Registrar
                        </MenuItem>
                        <MenuItem value={"Fellow"}>Fellow</MenuItem>
                        <MenuItem value={"Staff"}>Staff</MenuItem>
                        <MenuItem value={"VMO"}>VMO</MenuItem>
                        <MenuItem value={"CMO"}>CMO</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  {detailsState && detailsState.occupation == "Paramedic" && (
                    <FormControl variant="outlined" className="GenderDD mt-3">
                      <InputLabel
                        className=""
                        id="demo-simple-select-outlined-label"
                      >
                        Select position or grade
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={detailsState?.positionGrade}
                        name="positionGrade"
                        onChange={handleChange}
                        label="Select position or grade"
                      >
                        <MenuItem value={"Other"}>Other</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  {detailsState && detailsState.occupation == "Other" && (
                    <FormControl variant="outlined" className="GenderDD mt-3">
                      <InputLabel
                        className=""
                        id="demo-simple-select-outlined-label"
                      >
                        Select position or grade
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={detailsState?.positionGrade}
                        name="positionGrade"
                        onChange={handleChange}
                        label="Select position or grade"
                      >
                        <MenuItem value={"Other"}>Other</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  <TextField
                    className=" mt-3"
                    variant="outlined"
                    type="number"
                    onChange={(e) =>
                      setDetailsState({
                        ...detailsState,
                        [e.target.name]: e.target.value,
                      })
                    }
                    label="Years of post grad experience"
                    defaultValue={detailsState.yearsPostGradExp}
                    name="yearsPostGradExp"
                    id="custom-css-outlined-input"
                  />{" "}
                  <TextField
                    className=" mt-3"
                    variant="outlined"
                    type="number"
                    onChange={(e) =>
                      setDetailsState({
                        ...detailsState,
                        [e.target.name]: e.target.value,
                      })
                    }
                    label="Select Years of ALS experience"
                    defaultValue={detailsState.yearsAlsExp}
                    name="yearsAlsExp"
                    id="custom-css-outlined-input"
                  />{" "}
                  <FormControl variant="outlined" className="GenderDD mt-3">
                    <InputLabel
                      className=""
                      id="demo-simple-select-outlined-label"
                    >
                      Experience with Vr
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={detailsState?.vrExp}
                      name="vrExp"
                      onChange={handleChange}
                      label="Experience with Vr"
                    >
                      <MenuItem value={"yes"}>yes</MenuItem>
                      <MenuItem value={"no"}>No</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" className="GenderDD mt-3">
                    <InputLabel
                      className=""
                      id="demo-simple-select-outlined-label"
                    >
                      Is English your second language
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={detailsState?.englishSecond}
                      name="englishSecond"
                      onChange={handleChange}
                      label="Is English your second language"
                    >
                      <MenuItem value={"yes"}>Yes</MenuItem>
                      <MenuItem value={"no"}>No</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" className="GenderDD mt-3">
                    <InputLabel
                      className=""
                      id="demo-simple-select-outlined-label"
                    >
                      Do you were glasses
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={detailsState.glasses}
                      name="glasses"
                      onChange={handleChange}
                      label="Do you were glasses"
                    >
                      <MenuItem value={"yes"}>yes</MenuItem>
                      <MenuItem value={"no"}>No</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" className="GenderDD mt-3">
                    <InputLabel
                      className=""
                      id="demo-simple-select-outlined-label"
                    >
                      Completes ALS program before
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={detailsState.completedAlsPrev}
                      name="completedAlsPrev"
                      onChange={handleChange}
                      label="Completes ALS program before"
                    >
                      <MenuItem value={"yes"}>yes</MenuItem>
                      <MenuItem value={"no"}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <h4 className="textBelow">
                  What technology are you familiar with?
                </h4>
                <div className="belowLine">
                  <div>
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="usedComp"
                      onChange={(e) => {
                        setDetailsState({
                          ...detailsState,
                          [e.target.name]:
                            detailsState.usedComp === true ? false : true,
                        });
                      }}
                      checked={detailsState.usedComp == true ? true : false}
                    />
                    <label for="vehicle1">Computer</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="usedTablet"
                      onChange={(e) => {
                        setDetailsState({
                          ...detailsState,
                          [e.target.name]:
                            detailsState.usedTablet === true ? false : true,
                        });
                      }}
                      value={detailsState.usedTablet}
                      checked={detailsState.usedTablet == true ? true : false}
                    />
                    <label for="vehicle1">Tablet</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="usedSmartPhone"
                      onChange={(e) => {
                        setDetailsState({
                          ...detailsState,
                          [e.target.name]:
                            detailsState.usedSmartPhone == true ? false : true,
                        });
                      }}
                      value={detailsState.usedSmartPhone}
                      checked={
                        detailsState.usedSmartPhone == true ? true : false
                      }
                    />
                    <label for="vehicle1">Smart phone</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="usedAugmentedReality"
                      onChange={(e) => {
                        setDetailsState({
                          ...detailsState,
                          [e.target.name]:
                            detailsState.usedAugmentedReality == true
                              ? false
                              : true,
                        });
                      }}
                      value={detailsState.usedAugmentedReality}
                      checked={
                        detailsState.usedAugmentedReality == true ? true : false
                      }
                    />
                    <label for="vehicle1"> AR</label>
                  </div>

                  <div>
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="usedVirtualReality"
                      onChange={(e) => {
                        setDetailsState({
                          ...detailsState,
                          [e.target.name]:
                            detailsState.usedVirtualReality == true
                              ? false
                              : true,
                        });
                      }}
                      value={detailsState.usedVirtualReality}
                      checked={
                        detailsState.usedVirtualReality == true ? true : false
                      }
                    />
                    <label for="vehicle1">VR</label>
                  </div>
                </div>
                <div className="buttonofsetting mt-3 mb-5 d-flex">
                  <Button
                    background="secondary"
                    color="primary"
                    border="1px solid white"
                    name="Cancel"
                    handleClick={() => history.goBack()}
                    className="ml-2"
                    marginRight="20px"
                  />{" "}
                  <Button
                    background="secondary"
                    color="#116a9a"
                    border="1px solid white"
                    handleClick={() =>
                      setAlertDetails({
                        ...alertDetails,
                        open: true,
                        message: "Do you want to save the details?",
                        action: handelSave,
                      })
                    }
                    name="Save"
                    className="ml-2"
                  />
                </div>
              </Grid>
            )}
            <AlertDialog
              open={alertDetails.open}
              handleClose={() =>
                setAlertDetails({
                  ...alertDetails,
                  open: false,
                  message: "",
                  action: null,
                })
              }
              message={alertDetails.message}
              button1={alertDetails.action == null ? null : "Yes"}
              button2={alertDetails.action == null ? "Ok" : "No"}
              handleDelete={(e) => alertDetails.action(e)}
            />
          </Grid>
        </div>

        <DetailsModel
          open={passwordModel}
          handleClose={() => {
            setPasswordModel(false);
            setNewPassword("");
            setCurrentPassword("");
          }}
          title="Update Password"
          input={
            <>
              <input
                type="password"
                placeholder="Enter Current Password"
                style={{
                  maxWidth: "659px",
                  width: "100%",
                  padding: "4px 15px",
                  height: "47px",
                  marginBottom: "19px",
                  background: "#FFFFFF",
                  boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.25)",
                  borderRadius: "10px",
                  border: "none",
                }}
                name="currentPassword"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              <input
                type="password"
                placeholder="Enter New Password"
                style={{
                  maxWidth: "659px",
                  width: "100%",
                  padding: "4px 15px",
                  height: "47px",
                  marginBottom: "19px",
                  background: "#FFFFFF",
                  boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.25)",
                  borderRadius: "10px",
                  border: "none",
                }}
                name="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </>
          }
          button={
            <Button
              background="secondary"
              color="primary"
              name="Update"
              handleClick={handleChangePassword}
            />
          }
          cancelButton={
            <Button
              background="secondary"
              color="primary"
              name="Cancel"
              handleClick={() => {
                setPasswordModel(false);
                setNewPassword("");
                setCurrentPassword("");
              }}
            />
          }
        />
      </div>
    </div>
  );
};
export default UserSettings;
