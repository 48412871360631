import { ACTION_TYPE } from "../../Services/Constants/index";
import Axios from "../../Services/Utils/axios";
import jwt from "jsonwebtoken";
import { CommonActions, ProductActions } from ".";
import { checkRole } from "../../Services/Utils/utils";
import { findDefaultOrg } from "../../Helper";

export const setActiveOrg =
  ({ user, callback }) =>
  async (dispatch) => {
    try {
      const orgToken = jwt.sign(
        {
          orgId: user.filteredInvites[0]?.orgId,
          userId: user.uuid,
        },
        process.env.REACT_APP_PRIVATE_KEY
      );
      const defaultOrg = findDefaultOrg(user?.filteredInvites || []);
      if (defaultOrg) {
        dispatch({
          type: ACTION_TYPE.SET_ACTIVE_ORG,
          payload: {
            activeOrg: defaultOrg,
            token: orgToken,
          },
        });
      }
      callback({ success: true, result: true });

      return;
    } catch (error) {
      callback({ success: false, result: error });
    }
  };

export const getOrgById =
  ({ orgId, callback }) =>
  async (dispatch) => {
    console.log("ORG ACTION");
    try {
      const response = await Axios.get(`/org/id/${orgId}`);
      callback({ success: true, result: response.data });
      console.log("currentRes", response);
    } catch (error) {
      return dispatch({
        type: ACTION_TYPE.LOGOUT,
      });
    }
  };

export const updateDate =
  (orgId, subscriptionStartDate, { callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post(`/org/update/${orgId}`, {
        subscriptionStartDate,
      });

      dispatch({
        type: ACTION_TYPE.UPDATE_DATE,
        payload: response.data.result,
      });
      callback({ success: true, result: "ok" });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

export const updateOrg =
  ({ updateBody, orgId, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post(`/org/update`, {
        updateBody,
        orgId,
      });
      console.log("response", response);
      dispatch({
        type: "UPDATE_ORG",
        payload: response.data.result,
      });
      callback({ success: true, result: "ok" });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

export const deleteOrg =
  (orgId, { callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post(`/org/delete/${orgId}`, { orgId });

      dispatch({
        type: ACTION_TYPE.UPDATE_DATE,
        payload: response.data.result,
      });
      callback({ success: true, result: "ok" });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

//For Group overView page we will fetch the result from store and filter out the result by userid
export const getOrgResults =
  (orgId, product, { callback }) =>
  async (dispatch) => {
    try {
      dispatch(CommonActions.startLoading());
      dispatch(
        ProductActions.setProduct({
          product,
          callback: () => {},
        })
      );

      const response = await Axios.post(`/org/results`, { orgId, product });

      dispatch({
        type: ACTION_TYPE.ORG_RESULTS,
        payload: response.data,
      });
      console.log("updateorg", response);
      dispatch(CommonActions.stopLoading());
      callback({ success: true, result: response.data.result });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

export const getOrgUsers =
  ({ orgId, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post(`/org/allUsers`, {
        orgId,
      });

      callback({ success: true, result: "ok" });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

//Organizarion/UserManagement - Fetch user details by email
export const fetchUserDetails =
  ({ email, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post(`/user/email`, { email });
      dispatch({
        type: ACTION_TYPE.FETCH_USER_DETAILS,
        payload: response.data,
      });

      callback({ success: true, data: response.data });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

// get use detalis with uuid
export const fetchUserDetailsUuid =
  ({ uuid, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post(`user/id/${uuid}`);

      dispatch({
        type: ACTION_TYPE.FETCH_USER_DETAILS_UUID,
        payload: response,
      });

      callback({ success: true, result: response });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

//Organizarion/UserManagement - Delete user from the org users - tested
export const deleteUserFromOrg =
  ({ uuid, email, orgId, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post(`/org/remove/user/${orgId}`, {
        uuid,
        email,
      });
      if (response.data.result && response.data.result.users)
        dispatch({
          type: ACTION_TYPE.UPDATE_USERS,
          payload: {
            users: response.data.result.users,
            pendingInvites: response.data.result.pendingInvitations,
            removedUsers: response.data.result.removedUsers,
          },
        });

      callback({ success: true, result: "ok" });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

// Organizarion/UserManagement - Make user admin or teacher - Activate seat pending -tested
export const UpdateRole =
  ({ uuid, orgId, role, email, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post(`/org/add-remove-role/${orgId}`, {
        uuid,
        role,
        email,
      });

      dispatch({
        type: ACTION_TYPE.SET_ORGS,
        payload: response.data.result,
      });

      dispatch({
        type: ACTION_TYPE.SET_ALL_USERS,
        payload: response.data.result.users,
      });

      callback({ success: true, result: response.data.result });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

//org add seat
export const UpdateSeat =
  ({ uuid, orgId, email, seatAlloted, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post(`/org/update/seat/${orgId}`, {
        uuid,
        seatAlloted,
        email,
        orgId,
      });

      if (response.data.result && response.data.result.users)
        dispatch({
          type: ACTION_TYPE.UPDATE_USERS,
          payload: { users: response.data.result.users },
        });
      // dispatch({
      //   type: "UPGRADE_SEAT",
      //   payload: response.data.result[0],
      // });
      dispatch({
        type: ACTION_TYPE.SET_ALL_USERS,
        payload: response.data.result.users,
      });

      callback({ success: true, result: response.data.result });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

// Organizarion/UserManagement - Remove admin or teacher role from user - Activate seat pending- tested
export const removeUserRole =
  ({ uuid, orgId, role, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post(`/org/remove-role/${orgId}`, {
        uuid,
        role,
      });

      dispatch({
        type: ACTION_TYPE.SET_ORGS,
        payload: response.data.result,
      });

      dispatch({
        type: ACTION_TYPE.SET_ALL_USERS,
        payload: response.data.result.users,
      });

      callback({ success: true, result: response.data.result });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

// Organizarion/GroupManagement - get All Groups In Org - tested
export const getAllGroupsInOrg = //run this after create group

    ({ orgId, callback }) =>
    async (dispatch) => {
      try {
        const response = await Axios.get(`/groups/all/${orgId}`);
        console.log(response, "responseTesttt");
        dispatch({
          type: ACTION_TYPE.GET_ALL_GROUPS_IN_ORG,
          payload: response.data.result,
        });

        callback({ success: true, result: response.data.result });
      } catch (error) {
        callback({ success: false, result: error.message });
      }
    };

// Organizarion/GroupManagement - create Group - tested
export const createGroup =
  ({ name, ownerUid, orgId, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post(`/groups/create`, {
        name,
        ownerUid,
        orgId,
      });

      dispatch({
        type: ACTION_TYPE.GET_ALL_GROUPS_IN_ORG,
        payload: response.data.result,
      });

      callback({ success: true, result: response.data.result });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

// Organizarion/GroupManagement - create Group - tested
export const deleteGroup =
  ({ groupId, orgId, uuid, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post(`/groups/delete/${groupId}`, {
        orgId,
        uuid,
      });

      dispatch({
        type: "",
        payload: response.data.result,
      });

      callback({ success: true, result: response.data.result });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

// Organizarion/GroupManagement - add User To Group - tested
export const addUserToGroup =
  ({ users, orgId, groupId, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post(`/groups/add/${groupId}`, {
        users,
        orgId,
      });
      console.log("resp", response);
      // dispatch({
      //   type: "UPGRADE_SEAT",
      //   payload: response.data.result,
      // });

      callback({ success: true, result: response.data.result });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

// Organizarion/GroupManagement - remove User from Group - tested
export const removeUserFromGroup =
  ({ uuid, groupId, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post(`/groups/remove/${groupId}`, { uuid });

      dispatch({
        type: "",
        payload: response.data.result,
      });

      callback({ success: true, result: response.data.result });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

//Organizarion/GroupManagement/UserData - remove User from Group - tested
export const getSingleUserAssessmentResults = // this one is choosen

    ({ uuid, callback }) =>
    async (dispatch) => {
      try {
        const response = await Axios.get(`/result/als/user-all/${uuid}`);

        dispatch({
          type: ACTION_TYPE.SET_SINGLE_USER_ASSESSMNTS,
          payload: response.data.alsAssessmentResult,
        });

        callback({ success: true, result: response.data.result });
      } catch (error) {
        callback({ success: false, result: error.message });
      }
    };

// Organizarion/GroupManagement/UserData - remove User from Group - tested
export const getSingleUserSituationAssessment =
  ({ uuid, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.get(`/situation/user/${uuid}`);

      dispatch({
        type: ACTION_TYPE.SET_SITUATION_ASSESSMENTS,
        payload: response.data.result,
      });

      callback({ success: true, result: response.data.result[0] });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

export const getSingleUserDeEscalationResults =
  ({ uuid, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.get(`/deEscalation/user/${uuid}`);

      dispatch({
        type: ACTION_TYPE.SET_SINGLE_USER_DEESCALATION_ASSESSMNTS,
        payload: response.data.result,
      });

      callback({ success: true, result: response.data.result[0] });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

export const getSingleUserHandoverResults =
  ({ uuid, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.get(`/task-results/user/${uuid}`);

      dispatch({
        type: ACTION_TYPE.SET_SINGLE_USER_HANDOVER_ASSESSMNTS,
        payload: response.data.result,
      });

      callback({ success: true, result: response.data.result[0] });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

export const getSingleUserDPResults =
  ({ uuid, callback }) =>
  async (dispatch) => {
    try {
      console.log("scenarioooo", uuid);

      const response = await Axios.get(
        `/deterioratingPatient/scenario/user/${uuid}`
      );
      console.log("scenarioooo", response.data);
      dispatch({
        type: ACTION_TYPE.SET_SINGLE_USER_DP_ASSESSMNTS,
        payload: response.data.result,
      });

      callback({ success: true, result: response.data.result[0] });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };
export const getHandoverDataByAppId =
  ({ uuid, appid, callback }) =>
  async (dispatch) => {
    try {
      console.log("scen###########3arioooo", appid);

      const response = await Axios.post(`/task-results/getUserResults`, {
        appid,
        uuid,
      });
      console.log("scenarioooo###########", response.data);
      dispatch({
        type: ACTION_TYPE.GET_HANGOVER_DATA,
        payload: response.data.result,
      });

      callback({ success: true, result: response.data.result[0] });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };
export const getSingleAssessmentResults =
  ({ aid, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.get(`/result/als/${aid}`);

      console.log("getSingleAssessmentResults", response);
      dispatch({
        type: ACTION_TYPE.SET_SINGLE_ASSESSMNTS_RESULT,
        payload: response.data.result,
      });

      callback({ success: true, result: response.data.result });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

export const getSingleAssessmentResults_Handover =
  ({ aid, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.get(
        `/task-results/single-assessment/${aid}`
      );

      console.log("getSingleAssessmentResults", response);
      dispatch({
        type: ACTION_TYPE.SET_SINGLE_ASSESSMNTS_RESULT,
        payload: response.data.result,
      });

      callback({ success: true, result: response.data.result });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

export const getSingleDeEscalationResults =
  ({ aid, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.get(`/deEscalation/result/${aid}`);

      dispatch({
        type: ACTION_TYPE.SET_SINGLE_DEESCALATION_RESULT,
        payload: response.data.result,
      });

      callback({ success: true, result: response.data.result });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

export const getSingleDPResults =
  ({ aid, callback }) =>
  async (dispatch) => {
    try {
      console.log("AIDDDDDDD", aid);

      const response = await Axios.get(
        `/deterioratingPatient/scenario/result/${aid}`
      );
      console.log("AIDDDDDDD", response, aid);
      dispatch({
        type: ACTION_TYPE.SET_SINGLE_DP_RESULT,
        payload: response.data.result,
      });

      callback({ success: true, result: response.data.result });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

export const getSingleSituationResults =
  ({ aid, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.get(`/situation/result/${aid}`);

      dispatch({
        type: ACTION_TYPE.SET_SINGLE_SITUATION_RESULT,
        payload: response.data.result,
      });

      callback({ success: true, result: response.data.result });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

// Get all situation results
export const getCBResults =
  ({ callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.get(`/situation/all`);

      dispatch({
        type: ACTION_TYPE.SET_SITUATION_ASSESSMENTS,
        payload: response.data.result,
      });
      console.log("get situation results", response);

      callback({ success: true, result: response.data.result });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };
