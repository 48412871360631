import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Routing from "../src/AuthRouter/PublicRoutes";
import Footer from "./Component/Footer/Footer";
import { AuthActions, OrgActions } from "./ReduxStore/Actions";
import { ACTION_TYPE } from "./Services/Constants";
import {
  checkJWTExpiry,
  getCustomJWT,
  getUserDetailsFromAPI,
  getUserDetailsJWT,
} from "./Services/Utils/utils";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!(localStorage.getItem("token") && localStorage.getItem("user"))) {
      dispatch(AuthActions.signOut({ callback: () => {} }));
      return;
    } else if (localStorage.getItem("orgToken")) {
      // no need to call getuserdetails
      const orgToken = getCustomJWT("orgToken");
      dispatch(
        OrgActions.getOrgById({
          // add
          orgId: orgToken.orgId,
          callback: (response) => {
            dispatch({
              type: ACTION_TYPE.SET_ACTIVE_ORG,
              payload: {
                activeOrg: response.result.org[0],
              },
            });
          },
        })
      );
    }
    dispatch(
      AuthActions.matchToken({
        callback: (response) => {
          const { user } = response;

          dispatch(
            AuthActions.getUserDetails({
              uuid: user?.uuid,
              email: user?.email,
              callback: (response) => {},
            })
          );
        },
      })
    );
  }, []);

  return (
    <div>
      <div className="App">
        <Routing />
      </div>
      <Footer />
    </div>
  );
}

export default App;
