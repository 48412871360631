import { ACTION_TYPE } from "../../Services/Constants/index";
export const initialState = {
  collapsedClass: true,
  token: "",
  users: {},
  isAuthenticated: false,
  allOrgs: [],
  Orgs: [],
  orgResults: [],
  currentOrg: [],
  fieldList: [],
  user: {},
  situationAssessment: [],
  singleUserAssessment: [],
  deEscalationAssessment: [],
  userDetailsUuid: {},
  singleAssessment: {},
  allGroups: [],
  singleSituationResult: {},
  singleDeEscalationResult: {},
  singleAssessmentResult: [],
  singleDPResult: {},
  singleDPAssessment: {},
  handoverData: [],
};

export const OrgReducer = (state = initialState, action) => {
  console.log("reducer RESULLLLLLLLLTTTTTTTT", action.payload);
  switch (action.type) {
    case ACTION_TYPE.SET_ACTIVE_ORG:
      const { token, activeOrg } = action.payload;
      if (token) localStorage.setItem("orgToken", token);
      return {
        ...state,
        currentOrg: [activeOrg],
      };

    case ACTION_TYPE.CLEAR_CURRENT_ORG:
      localStorage.removeItem("orgToken");
      return {
        ...state,
        currentOrg: [],
        orgResults: [],
        allGroups: [],
        situationAssessment: [],
        singleUserAssessment: [],
      };

    case ACTION_TYPE.GET_ALL_ORGS:
      return {
        ...state,
        allOrgs: action.payload,
      };

    case ACTION_TYPE.ORG_RESULTS:
      return {
        ...state,
        orgResults: action.payload.result,
      };
    case ACTION_TYPE.GET_ALL_GROUPS_IN_ORG:
      return {
        ...state,
        allGroups: action.payload,
      };
    case ACTION_TYPE.SET_SINGLE_USER_DP_ASSESSMNTS:
      return {
        ...state,
        singleDPResult: action.payload,
      };
    case ACTION_TYPE.GET_HANGOVER_DATA:
      return {
        ...state,
        handoverData: action.payload,
      };
    case ACTION_TYPE.SET_SINGLE_DP_RESULT:
      return {
        ...state,
        singleDPAssessment: action.payload,
      };

    case ACTION_TYPE.FETCH_USER_DETAILS:
      return {
        ...state,
        user: action.payload,
      };

    case ACTION_TYPE.SET_ORGS:
      return {
        ...state,
        orgs: action.payload,
        currentOrg: [action.payload],
      };

    case ACTION_TYPE.SET_ALL_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case "UPDATE_ORG":
      return {
        ...state,
        currentOrg: [action.payload],
      };
    case ACTION_TYPE.UPDATE_ORG:
      return {
        ...state,
        currentOrg: [action.payload],
      };

    case ACTION_TYPE.CLEAR_SELECTED_USER:
      return {
        ...state,
        user: {},
      };
    case ACTION_TYPE.SET_SITUATION_ASSESSMENTS:
      return {
        ...state,
        situationAssessment: action.payload,
      };
    case ACTION_TYPE.SET_SINGLE_ASSESSMNTS_RESULT:
      return {
        ...state,
        singleAssessment: action.payload,
      };
    case ACTION_TYPE.SET_SINGLE_USER_ASSESSMNTS:
      return {
        ...state,
        singleUserAssessment: action.payload,
      };

    case ACTION_TYPE.SET_SINGLE_USER_DEESCALATION_ASSESSMNTS:
      return {
        ...state,
        deEscalationAssessment: action.payload,
      };

    case ACTION_TYPE.FETCH_USER_DETAILS_UUID:
      return {
        ...state,
        userDetailsUuid: action.payload.data,
      };
    case ACTION_TYPE.SET_SINGLE_SITUATION_RESULT:
      return {
        ...state,
        singleSituationResult: action.payload,
      };

    case ACTION_TYPE.SET_SINGLE_DEESCALATION_RESULT:
      return {
        ...state,
        singleDeEscalationResult: action.payload,
      };
    case ACTION_TYPE.SET_SINGLE_USER_HANDOVER_ASSESSMNTS:
      return {
        ...state,
        singleAssessmentResult: action.payload,
      };
    case ACTION_TYPE.UPDATE_USERS:
      const { users, pendingInvites, removedUsers } = action.payload;
      console.log(
        "users, pendingInvites",
        users && pendingInvites,
        users,
        pendingInvites
      );
      if (users && pendingInvites)
        return {
          ...state,
          currentOrg: [
            {
              ...state.currentOrg[0],
              users: users,
              pendingInvitations: pendingInvites,
              removedUsers: removedUsers || [],
            },
          ],
        };
      if (users)
        return {
          ...state,
          currentOrg: [
            {
              ...state.currentOrg[0],
              users: users,
            },
          ],
        };
      if (pendingInvites)
        return {
          ...state,
          currentOrg: [
            {
              ...state.currentOrg[0],
              pendingInvitations: pendingInvites,
            },
          ],
        };

    // case ACTION_TYPE.DEL_USER_FROM_ORG:
    //   return {
    //     ...state,
    //     currentOrg: [
    //       {
    //         ...state.currentOrg[0],
    //         users: action.payload,
    //       },
    //     ],
    //   };
    case "REMOVE_USER":
      return {
        ...state,
        currentOrg: [action.payload],
      };

    default:
      return state;
  }
};
