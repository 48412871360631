import React, { useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DataGrid } from "@material-ui/data-grid";
import Button from "../../Component/Button/button";

import "./inviteModal.css";
import { DialogContentText } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { OrgActions } from "../../ReduxStore/Actions";

const AddUserDialog = (props) => {
  const [selectionModel, setSelectionModel] = useState([]);
  let dispatch = useDispatch();
const handleChange = ()=>{
  let listOfUser =selectionModel
  setSelectionModel([])

  props.handleAddUser(listOfUser);
}
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        disableBackdropClick
        className="AddUserDialog"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add User</DialogTitle>
        <DialogContent className="mb-3" style={{ width: "100%" }}>
          <DialogContentText>
            Select user from the list to add in your group
          </DialogContentText>
          <DataGrid
          className="dataGrid"
            rows={props.rows}
            columns={props.columns}
            pageSize={10}
            checkboxSelection
            onSelectionModelChange={(e) => {
              let selectedIDs = new Set(e);
              let selectedRowData = props.rows.filter((row) =>
                selectedIDs.has(row.id)
              );
              selectedRowData = selectedRowData.map((data) => ({
                uuid: data.id,
                email: data.email,
              }));

              setSelectionModel(selectedRowData);
            }}
          />
        </DialogContent>
        <Button
          background="primary"
          color="secondary"
          handleClick={handleChange
          }
          name="Add"
        ></Button>
      </Dialog>
    </div>
  );
};
export default AddUserDialog;
