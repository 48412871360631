import React, { useState, useEffect } from "react";
import "./recentAssessment.css";
import Table from "../../../Component/Table/Table";
import { useSelector, useDispatch } from "react-redux";
import HeaderBar from "../../../Component/HeaderBar/headerBar";
import RBAC from "../../../Component/RBAC/RBAC";
import { initList } from "../../../Component/SideNav/initList";
import { OrgActions } from "../../../ReduxStore/Actions";
import _Firebase from "../../../Services/Utils/firebase";
import SideNav from "../../../Component/SideNav/sideNav";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../Services/Constants";
import { changeDateFormatFromYYYY_MM_DDToDD_MM_YYYY } from "../../../Helper";
const RecentAssessment = (props) => {
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const dispatch = useDispatch();
  let collapsedClass = useSelector(
    (state) => state.CollapsedSidenavReducer.collapsedClass
  );
  let user = useSelector((state) => state.AuthReducer.user);
  let assessmentData = useSelector(
    (state) => state.OrgReducer.singleUserAssessment
  );

  console.log("checkk.. assData",assessmentData);

  let userName = useSelector((state) => state.OrgReducer.userDetailsUuid);
  useEffect(() => {
    getTableData(assessmentData);
  }, [assessmentData, userName]);


  const getTableData = (result) => {
    console.log("checkk.. result",result);
    if (result.length > 0) {
      let label, score;

      let finalData = result?.map((data) => {
        label = setResults(data);
        score = calculatePercent(label);
        const date = changeDateFormatFromYYYY_MM_DDToDD_MM_YYYY(
          data["createdAt"].split("T")[0]
        );
        return [
          "ALS",
          date,
          "Assessment Mode",
          data["overallAccuracy"] + "%",

          data["_id"],
        ];
      });
      console.log("checkk.. finaldata", finalData);
      setTableData(finalData);
    }
  };
  const setResults = (assessment) => {
    let labels = [];

    if (assessment.overallAccuracy > 80)
      labels.push({
        success: true,
        label: `Overall Accuracy ${assessment.overallAccuracy}%`,
      });
    else
      labels.push({
        success: false,
        label: `Overall Accuracy ${assessment.overallAccuracy}%`,
      });

    if (formatTime(assessment.totalTime) < 800)
      labels.push({
        success: true,
        label: `Total Time Taken: ${assessment.totalTime}`,
      });
    else
      labels.push({
        success: false,
        label: `Total Time Taken: ${assessment.totalTime}`,
      });

    if (assessment.stoppedCprCountOutCoached !== 0)
      labels.push({
        success: false,
        label: `Incorrectly stopped CPR out of COACHED: x ${assessment.stoppedCprCountOutCoached}`,
      });

    if (assessment.abcde)
      labels.push({
        success: true,
        label: `Completed A-E assessment before patient recovery`,
      });
    else
      labels.push({
        success: false,
        label: `Completed A-E assessment before patient recovery`,
      });

    if (formatTime(assessment.timeToFirstCoached) <= 100)
      labels.push({
        success: true,
        label: `Time to first COACHED: ${assessment.timeToFirstCoached}`,
      });
    else
      labels.push({
        success: false,
        label: `Time to first COACHED: ${assessment.timeToFirstCoached}`,
      });

    return labels;
  };
  const formatTime = (_time) => {
    return +_time?.replace(":", "");
  };
  const calculatePercent = (labels) => {
    let count = 0;
    let newLabels = [];
    let _labels = labels.map(({ success }) => {
      return success && (count = count + 1);
    });
    newLabels = _labels.filter((label) => !label.title);
    return parseInt((count / newLabels.length) * 100);
  };
  useEffect(() => {
    console.log("checkk.. params id", props.match.params.id);
    if (props.match.params.id)
      dispatch(
        OrgActions.getSingleUserAssessmentResults({
          uuid: props.match.params.id,
          callback: (response, result) => {},
        })
      );
  }, []);
  const handleBtnClick = (item) => {
    history.push(
      `/recent-assessment-data/${item[4]}?id=${props.match.params.id}`
    );
  };

  return (
    <div className="d-flex over">
      <div className={collapsedClass ? " sideNavExpand" : "sideNavContract"}>
        {user && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}{" "}
      </div>
      <div className={collapsedClass ? "screenContract bg" : "screenExpand bg"}>
        {/* <HeaderBar title="Seat Management" /> */}
        <HeaderBar
          width={collapsedClass ? "80%" : "95%"}
          title="Recent Assessments"
          searchbar="no"
          noProduct={true}
        />
        <div className="componentSec">
          <div className="d-flex justify-content-between flexsupad">
            <div className="fullDiv">
              <div
                className="backIcon mr-2"
                onClick={() =>
                  history.push(ROUTES.GROUPMANAGEMENT + "?tabValue=1")
                }
              >
                <ArrowBackIcon className="icon" />
              </div>
              User Data
            </div>
          </div>
          <div className="pb-31 mt-4">
            {console.log("checkkkk....", tableData)}
            {
              <Table
                height="45vh"
                tableHead={["App", "Date", "Mode", "Score"]}
                tableData={tableData}
                button="yes"
                handleBtnClick={handleBtnClick}
                // button="true"
                index="4"
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};
export default RecentAssessment;
