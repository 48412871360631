import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../Component/Table/Table";
import { OrgActions } from "../../ReduxStore/Actions";

import HeaderBar from "../../Component/HeaderBar/headerBar";
import RBAC from "../../Component/RBAC/RBAC";
import { initList } from "../../Component/SideNav/initList";
import _Firebase from "../../Services/Utils/firebase";
import SideNav from "../../Component/SideNav/sideNav";
import { useHistory } from "react-router-dom";
const ProductAnalytics = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let collapsedClass = useSelector(
    (state) => state.CollapsedSidenavReducer.collapsedClass
  );
  const [tableData, setTableData] = useState([]);

  let currentOrg = useSelector((state) => state.OrgReducer.currentOrg);

  let user = useSelector((state) => state.AuthReducer.user);
  // useEffect(() => {
  //   if (currentOrg.length > 0)
  //     dispatch(
  //       OrgActions.getOrgResults(currentOrg[0]?.orgId, {
  //         callback: (response) => {},
  //       })
  //     );
  // }, [currentOrg]);
  useEffect(() => {
    getTableData(currentOrg[0]?.users);
  }, [currentOrg]);

  // function to set the data in desired order for table
  const getTableData = (result) => {
    let finalData = result?.map((data) => {
      return [
        data["email"],
        data["orgName"] ? "Pending" : "Accepted",
        data["roles"].toString(),
        data["uuid"],
      ];
    });
    setTableData(finalData);
  };
  // to show the assesment result of the user
  //tableRow:[email,orgName,roles,uuid]
  const handleBtnClick = (tableRow) => {
    history.push({
      pathname: `/user-data/${tableRow[3]}`,
      state: {
        address: "Product Analytics",
      },
    });
  };
  return (
    <div className="d-flex over">
      <div className={collapsedClass ? " sideNavExpand" : "sideNavContract"}>
        {user && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}{" "}
      </div>
      <div className={collapsedClass ? "screenContract bg" : "screenExpand bg"}>
        {/* <HeaderBar title="Seat Management" /> */}
        <HeaderBar
          width={collapsedClass ? "80%" : "95%"}
          title="Product Analytics"
          searchbar="no"
        />
        <div className="componentSec">
          <div className="pb-31 mt-4">
            {tableData?.length > 0 && (
              <Table
                height="50vh"
                tableHead={["Email", "Status", "Role"]}
                tableData={tableData}
                handleBtnClick={handleBtnClick}
                button="true"
                buttonName="Assessment"
                index="3"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductAnalytics;
