import React, { useState, useEffect } from "react";
import "../Notification/Notification.css";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "../../Services/Constants";
import { useHistory } from "react-router-dom";
import { AuthActions, OrgActions } from "../../ReduxStore/Actions";
import { ACTION_TYPE } from "../../Services/Constants";
import { checkRole } from "../../Services/Utils/utils";
import jwt from "jsonwebtoken";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { findDefaultOrg } from "../../Helper";

const Account = (props) => {
  // console.log("props", props);
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState(false);
  const [role, setRole] = useState(null);
  const { user, userDetails } = useSelector((state) => state.AuthReducer);
  /*
  filterd invitation includes all the accepted invitation in which user is teacher or 
  admin or student with seats alotted
   */
  /*
  default organization is the first organization from the list of filtered 
  invitations  in which user is admin or teacher
*/
  const [defaultOrg, setDefaultOrg] = useState(null);

  // Handle change organization

  const setActiveOrgToReducer = (org) => {
    const orgToken = jwt.sign(
      { orgId: org.orgId, userId: user.uuid },
      process.env.REACT_APP_PRIVATE_KEY
    );

    dispatch({
      type: ACTION_TYPE.CLEAR_CURRENT_ORG,
    });
    dispatch({
      type: ACTION_TYPE.SET_ACTIVE_ORG,
      payload: {
        activeOrg: org,
        token: orgToken,
      },
    });
  };

  let currentOrg = useSelector((state) => state.OrgReducer.currentOrg);

  // const findDefaultOrg = () => {
  //   if (
  //     !userDetails ||
  //     !userDetails?.filteredInvites ||
  //     !(userDetails?.filteredInvites?.length > 0)
  //   )
  //     return;
  //   for (let i = 0; i < userDetails?.filteredInvites.length; i++) {
  //     const res = checkRole(userDetails?.filteredInvites[i]);
  //     if (res.isAdmin || res.isTeacher || res.owner) {
  //       return userDetails?.filteredInvites[i];
  //     }
  //   }

  //   return null;
  // };
  /*
  Change to organization only if user is admin or teacher in that organization  
 */
  const handleOrgChange = (org) => {
    const res = checkRole(org);
    if (res.isAdmin || res.isTeacher || res.owner) {
      setActiveOrgToReducer(org);
    }
  };

  // useEffect(() => {

  // },[currentOrg])

  /*
     if no organization is selected, set active organization to default organization
  */
  const handleDefaultOrgChange = () => {
    // const defaultOrg = findDefaultOrg();
    console.log("curr org use effect...", currentOrg, defaultOrg);
    if (currentOrg && currentOrg.length == 0) {
      console.log("hello...");
      // if (defaultOrg)
      setActiveOrgToReducer(defaultOrg);
    }
  };

  useEffect(() => {
    const org = findDefaultOrg(userDetails?.filteredInvites || []);
    setDefaultOrg(org);
    console.log("🚀 ~ file: account.js ~ line 99 ~ useEffect ~ org", org);
  }, [userDetails?.filteredInvites]);

  useEffect(() => {
    const org = findDefaultOrg(userDetails?.filteredInvites || []);
    setDefaultOrg(org);
  }, []);

  useEffect(() => {
    if (user.uuid) {
      if (user?.role === "superAdmin") setRole("superAdmin");
      else if (currentOrg.length > 0) {
        const roles = checkRole(currentOrg[0]);
        if (roles.isAdmin || roles.isTeacher || roles.owner) setRole("org");
      } else {
        setRole("user");
      }
      // console.log("currentrole", currentOrg[0]?.orgImage);
    }
  }, [user, currentOrg]);

  /*
   add event listener to listen for the click event outside the avatar section, so that account section
     can be closed when click outside the avatar section 
    
    event listener is only added when account section is open
  */
  useEffect(() => {
    if (state) {
      document.addEventListener("click", (e) => {
        console.log("target", e.target?.parentNode, e.target?.id);
        if (
          e.target?.parentNode?.id != "relatdiv" &&
          e.target?.id != "relatdiv"
        ) {
          setState(false);
        }
      });
    }
  }, [state]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props}
    </Tooltip>
  );
  console.log("user Details", userDetails);
  return (
    <div id="account" className="dropdownMain" onClick={() => setState(!state)}>
      <div id="relatdiv" className="relatdiv">
        {userDetails?.userImage ? (
          <img src={userDetails?.userImage} className="imgOfOrg tourTest1" />
        ) : user.userImage ? (
          <img src={user?.userImage} className="imgOfOrg tourTest1" />
        ) : (
          <p className="imgOfOrg noImgOrg">
            {userDetails?.userName && userDetails?.userName[0]}
          </p>
        )}
        {currentOrg && currentOrg.length > 0
          ? role !== "superAdmin" &&
            (currentOrg[0]?.orgImage ? (
              <img src={currentOrg[0]?.orgImage} className="absImg imgOfOrg" />
            ) : (
              <p className="imgOfOrg absImg">
                {currentOrg[0]?.orgName && currentOrg[0]?.orgName[0]}
              </p>
            ))
          : null}
      </div>
      {state ? (
        <div
          className={
            "dropdown accountIcon text-center d-flex" +
            (userDetails &&
            (!userDetails.filteredInvites ||
              userDetails.filteredInvites.length == 0)
              ? " smallDropDown"
              : "")
          }
        >
          {console.log("bugggg..", userDetails)}
          {userDetails &&
            userDetails.filteredInvites &&
            userDetails.filteredInvites.length > 0 && (
              <div
                className={
                  defaultOrg != null
                    ? "col-lg-2 text-center column1"
                    : "col-lg-4 text-center column1"
                }
              >
                {/*    {role === "superAdmin" ? null : (
              <li
                onClick={() => {
                  dispatch({
                    type: ACTION_TYPE.CLEAR_CURRENT_ORG,
                  });
                  history.push(ROUTES.USER_DASHBOARD);
                }}
              >
                Personal
              </li>
              )} */}
                <ul
                  className="ChangeOrganization"
                  style={{
                    backgroundColor: "#116a9a",
                  }}
                >
                  {/* Change organization */}
                  {userDetails &&
                    userDetails.filteredInvites &&
                    userDetails.filteredInvites.length > 0 &&
                    userDetails.filteredInvites.map((org) => (
                      <li
                        key={org.orgId}
                        onClick={() => handleOrgChange(org)}
                        className="organizationListBlock"
                      >
                        {/* <span class="orgToolTiptext">{org.orgName}</span> */}
                        {console.log("org in avatar", org)}
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip(org.orgName)}
                        >
                          {org.orgImage ? (
                            <img
                              src={org.orgImage}
                              alt="111"
                              style={{
                                width: "46px",
                                height: "46px",
                                borderRadius: "50%",
                              }}
                            />
                          ) : (
                            <p
                              className="noImgOrg"
                              style={{
                                borderRadius: "50%",
                                width: "46px",
                                height: "46px",
                              }}
                            >
                              {org.orgName[0]}
                            </p>
                          )}
                        </OverlayTrigger>
                      </li>
                    ))}
                </ul>
                {/* <li
              onClick={() => {
                dispatch(
                  AuthActions.signOut({
                    callback: () => {
                      history.push(ROUTES.LOGIN);
                    },
                  })
                );
              }}
            >
              Sign Out
            </li>
            */}
              </div>
            )}
          {defaultOrg != null && (
            <div
              className="col-lg-5 text-center column"
              style={{ borderRight: "2px solid #116a9a" }}
            >
              <div
                className="d-flex align-items-center userInfo"
                onClick={() => {
                  handleDefaultOrgChange();
                  history.push(ROUTES.GROUPMANAGEMENT);
                }}
              >
                {(currentOrg &&
                  currentOrg.length > 0 &&
                  currentOrg[0]?.orgImage) ||
                defaultOrg?.orgImage ? (
                  <img
                    src={
                      currentOrg && currentOrg.length > 0
                        ? currentOrg[0]?.orgImage
                        : defaultOrg?.orgImage
                    }
                    className="imgOfOrg tourTest1"
                  />
                ) : (
                  <p className="imgOfOrg noImgOrg">
                    {currentOrg && currentOrg.length > 0
                      ? currentOrg[0]?.orgName[0]
                      : defaultOrg?.orgName[0]}
                  </p>
                )}
                <p>
                  {currentOrg && currentOrg.length > 0
                    ? currentOrg[0]?.orgName
                    : defaultOrg?.orgName}
                </p>
              </div>
              <ul>
                {/* <li
                      onClick={() => {
                        history.push(ROUTES.OGRANIZATIONDASHBOARD);
                      }}
                    >
                      Org Dashboard
                    </li> */}
                <li
                  onClick={() => {
                    handleDefaultOrgChange();
                    history.push(ROUTES.GROUPMANAGEMENT);
                  }}
                >
                  {currentOrg && currentOrg.length > 0
                    ? currentOrg[0]?.orgName
                    : defaultOrg?.orgName}{" "}
                  - Dashboard
                </li>
                <li
                  onClick={() => {
                    handleDefaultOrgChange();
                    history.push(ROUTES.ORGANISATION_SETTINGS);
                  }}
                >
                  Org Settings
                </li>
                <li
                  onClick={() => {
                    handleDefaultOrgChange();
                    history.push(ROUTES.USERMANAGEMENT);
                  }}
                >
                  User Management
                </li>
              </ul>
            </div>
          )}

          <div
            className={
              defaultOrg != null
                ? "col-lg-5 text-center column"
                : userDetails &&
                  (!userDetails.filteredInvites ||
                    userDetails.filteredInvites.length == 0)
                ? "col-lg-12 text-center column centerTxt"
                : "col-lg-8 text-center column centerTxt"
            }
          >
            {/* <h3>Account Settings</h3> */}
            <div className="d-flex align-items-center userInfo">
              {userDetails?.userImage ? (
                <img
                  src={
                    userDetails?.userImage
                      ? userDetails?.userImage
                      : user.userImage
                  }
                  className="imgOfOrg tourTest1"
                />
              ) : user.userImage ? (
                <img src={user.userImage} className="imgOfOrg tourTest1" />
              ) : (
                <p className="imgOfOrg noImgOrg">
                  {" "}
                  {userDetails?.userName && userDetails?.userName[0]}{" "}
                </p>
              )}
              <p>
                {userDetails?.userName ? userDetails.userName : user.userName}
              </p>
            </div>
            <ul>
              {role === "superAdmin" ? null : (
                <li
                  onClick={() => {
                    dispatch({
                      type: ACTION_TYPE.CLEAR_CURRENT_ORG,
                    });
                    history.push(ROUTES.USER_DASHBOARD);
                  }}
                >
                  My Dashboard
                </li>
              )}
              {role === "superAdmin" && (
                <li
                  onClick={() => {
                    // dispatch({
                    //   type: ACTION_TYPE.CLEAR_CURRENT_ORG,
                    // });
                    history.push(ROUTES.SUPER_ADMIN_DASHBOARD);
                  }}
                >
                  Super Admin Dashboard
                </li>
              )}
              {role === "superAdmin" && (
                <li
                  onClick={() => {
                    // dispatch({
                    //   type: ACTION_TYPE.CLEAR_CURRENT_ORG,
                    // });
                    history.push(ROUTES.SUBSCRIPTION);
                  }}
                >
                  Subscription
                </li>
              )}
              {role === "superAdmin" && (
                <li
                  onClick={() => {
                    history.push(ROUTES.PRODUCT_ANALYTICS_ADMIN);
                  }}
                >
                  Product Analytics
                </li>
              )}
              {role === "superAdmin" && (
                <li
                  onClick={() => {
                    history.push(ROUTES.SIMDASH_LAUNCHER);
                  }}
                >
                  Launcher App Management
                </li>
              )}
              <li
                onClick={() => {
                  dispatch({
                    type: ACTION_TYPE.CLEAR_CURRENT_ORG,
                  });
                  history.push(ROUTES.ACCOUNT_SETTINGS);
                }}
              >
                Account Settings
              </li>

              <li
                onClick={() => {
                  dispatch(
                    AuthActions.signOut({
                      callback: () => {
                        history.push(ROUTES.LOGIN);
                      },
                    })
                  );
                }}
              >
                Sign Out
              </li>
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default Account;
