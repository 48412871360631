import { ACTION_TYPE } from "../../Services/Constants/index";
import Axios from "../../Services/Utils/axios";

export const updateDate =
  (orgId, subscriptionStartDate, { callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post(`/org/update/${orgId}`, {
        subscriptionStartDate,
      });

      dispatch({
        type: ACTION_TYPE.UPDATE_DATE,
        payload: response.data.result,
      });
      callback({ success: true, result: "ok" });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };
export const deleteOrg =
  (orgId, { callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post(`/org/delete/${orgId}`, { orgId });

      dispatch({
        type: ACTION_TYPE.UPDATE_DATE,
        payload: response.data.result,
      });
      callback({ success: true, result: "ok" });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };
