import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import HeaderBar from "../../Component/HeaderBar/headerBar";
// import Table from "../../Component/Table/Table";
import Table from "../../Component/Table2";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { MoreHoriz } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  CommonActions,
  LauncherActions,
  OrgActions,
} from "../../ReduxStore/Actions";
import RBAC from "../../Component/RBAC/RBAC";
import SideNav from "../../Component/SideNav/sideNav";
import { initList } from "../../Component/SideNav/initList";
import { ROUTES } from "../../Services/Constants";
import DetailsModel from "../../Component/Modal/inviteModal";
import Button from "../../Component/Button/button";

import "./styles.css";
import Toast from "../../Component/Toast/toast";
import { getAppName, resolveLatestVersion } from "../../Helper";
import AddEditAppDetailModal from "../../Component/Modal/AddEditAppDetailModal";
import CropModal from "../../Component/Modal/Crop/CropModal";
import getCroppedImg from "../../Component/Modal/Crop/cropImage";
import { validateAppDetailsForm } from "./validateAddEditModal";
import DeleteModal from "../../Component/Modal/DeleteModal";

const SimdashLauncher = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [file, setFile] = useState(null);
  const [deleteModal, setDeleteModal] = useState({
    type: null,
    show: false,
    appId: null,
    appName: "",
  });
  const [modalDetails, setModalDetails] = useState({});
  // all app details will be here
  const [appDetails, setAppDetails] = useState({
    appName: "",
    bannerImage: null,
    whatsNew: "",
    description: "",
    version: "",
    imageArray: [],
    type: null,
  });
  const [appDetailsErrors, setAppDetailsErrors] = useState({});
  const [storeApps, setStoreApps] = useState([]);
  const [launcherApp, setLauncherApp] = useState([]);
  const [toast, setToast] = useState();
  console.log("toast show", toast);

  const [showCropModal, setShowCropModal] = useState({
    show: false,
    type: null,
    aspect: null,
  });
  const [image, setImage] = useState("");

  const [error, setError] = useState(null);
  console.log("error", error);
  let collapsedClass = useSelector(
    (state) => state.CollapsedSidenavReducer.collapsedClass
  );
  let user = useSelector((state) => state.AuthReducer.user);
  const { loading } = useSelector((state) => state.CommonReducer);

  useEffect(() => {
    fetchStoreApps();
    fetchLauncherApp();
  }, []);

  const fetchStoreApps = () => {
    dispatch(
      LauncherActions.getStoreApps({
        callback: (res) => {
          if (res.result.length > 0) {
            console.log("res.result", res.result);
            setStoreApps(res.result);
          }
        },
      })
    );
  };

  const handleCroppedImage = async (croppedAreaPixels) => {
    try {
      const croppedImage = await getCroppedImg(
        URL.createObjectURL(image),
        croppedAreaPixels,
        image.name
      );
      console.log("donee", croppedImage);

      const imageToAdd = {
        url: croppedImage,
        id: crypto.randomUUID(),
      };

      const newAppDetails =
        showCropModal.type === "BANNER"
          ? { ...appDetails, bannerImage: croppedImage }
          : {
              ...appDetails,
              imageArray:
                appDetails?.imageArray?.length > 0
                  ? [...appDetails.imageArray, imageToAdd]
                  : [imageToAdd],
            };

      console.log({ newAppDetails });
      setAppDetails(newAppDetails);
      setShowCropModal({ show: false, type: null, aspect: null });
    } catch (e) {
      console.error(e);
    }
  };

  const fetchLauncherApp = () => {
    dispatch(
      LauncherActions.getLauncherApp({
        callback: (res) => {
          if (res.result.length > 0) {
            setLauncherApp(res.result);
          }
        },
      })
    );
  };

  const openUploadModel = (type, data) => {
    console.log("data in open upload modal", data);
    setModalDetails({
      ...modalDetails,
      open: true,
      type: "upgrade",
      appId: data.appId,
    });
    setAppDetails({
      ...appDetails,
      appId: data.appId,
      version: data.version,
      appName: data.appName,
      type: "UPGRADE",
    });
  };

  const openAddStoreModal = () => {
    setModalDetails({
      ...modalDetails,
      open: true,
      type: "add",
    });
    setAppDetails({
      type: "ADD",
      appName: "",
      bannerImage: null,
      whatsNew: "",
      description: "",
      version: "",
      imageArray: [],
    });
  };

  const handleOptionClick = (item, option) => {
    console.log("handleOptionClick", item, option);
    if (option.value === "edit") {
      setModalDetails({
        ...modalDetails,
        open: true,
        type: "edit",
        appId: item.appId,
        appname: item.appName,
        ...item,
      });
      setAppDetails({ ...appDetails, ...item, type: "EDIT" });
    }
    if (option.value === "view") {
      setModalDetails({
        ...modalDetails,
        type: "view",
        ...item,
      });
      setAppDetails({ ...appDetails, ...item, type: "VIEW" });
    }

    if (option.value === "prevVer")
      history.push(`/previous-versions/${item.appId}`);

    if (option.value === "delete") {
      setDeleteModal({
        type: "delete",
        appId: item.appId,
        appName: item.appName,
      });
    }
  };

  const handleUpload = () => {
    for (let app in error) {
      if (error[app].message) return;
    }
    if (!file) return;
    dispatch(CommonActions.startLoading());

    dispatch(
      LauncherActions.uploadFile({
        file,
        appId: modalDetails.appId,
        callback: (res) => {
          console.log("🚀 ~ file: index.js:115 ~ handleUpload ~ res:", res);
          dispatch(CommonActions.stopLoading());

          if (res.success) {
            dispatch(
              LauncherActions.saveDetailsToDB({
                params: {
                  appId: modalDetails.appId,
                  path: res.result.googleUri,
                  version: modalDetails.version,
                  metadata: modalDetails.metadata,
                  appName: modalDetails.appname,
                },
                callback: (data) => {
                  console.log("callback data", data);
                  // const version = resolveLatestVersion({
                  //   appId: modalDetails?.appId,
                  //   launcherApp,
                  //   storeApps,
                  // });
                  if (!data.success) {
                    setToast({
                      open: true,
                      message: `Error in uploading app please try again.`,
                      duration: 2500,
                      type: "error",
                    });
                  } else {
                    setToast({
                      open: true,
                      message: "Upload Successfull",
                      duration: 2500,
                      type: "success",
                    });
                  }
                  resetModal();
                  fetchStoreApps();
                  fetchLauncherApp();
                },
              })
            );
          }
        },
      })
    );
  };

  const handleUpdate = () => {
    for (let app in error) {
      if (error[app].message) return;
    }
    dispatch(CommonActions.startLoading());

    dispatch(
      LauncherActions.updateLauncherAppDetails({
        params: {
          id: modalDetails.id,
          appId: modalDetails.appId,
          version: modalDetails.version,
          metadata: modalDetails.metadata,
          appName: modalDetails.appname,
        },
        callback: (data) => {
          console.log("callback data", data);
          const version = resolveLatestVersion({
            appId: modalDetails?.appId,
            launcherApp,
            storeApps,
          });
          if (!data.success) {
            setToast({
              open: true,
              message: `Please upload version higher than ${version}`,
              duration: 2500,
              type: "error",
            });
          } else {
            setToast({
              open: true,
              message: "Updated App Details",
              duration: 2500,
              type: "success",
            });
          }
          resetModal();
          fetchStoreApps();
          fetchLauncherApp();

          dispatch(CommonActions.stopLoading());
        },
      })
    );
  };

  const updateModalDetails = (e) => {
    const { value, name } = e.target;
    console.log("modal details ", modalDetails);
    let latestVersion = modalDetails.version || 0;

    if (modalDetails.appId === "launcher") {
      latestVersion = launcherApp.find(
        (app) => app.appId === modalDetails.appId
      ).version;
    } else {
      latestVersion = storeApps.find(
        (app) => app.appId === modalDetails.appId
      ).version;
    }

    if (e.target.name === "version" && e.target.value <= latestVersion) {
      console.log("prev Version");
      setError({
        ...error,
        version: {
          message: `Please select version greater than ${latestVersion}`,
        },
      });
    } else if (e.target.name === "appname" && e.target.value === "") {
      setError({ ...error, appname: { message: `App Name cannot be empty` } });
    } else if (e.target.name === "version") {
      setError({ ...error, version: { message: null } });
    } else if (e.target.name === "appname") {
      setError({ ...error, appname: { message: null } });
    } else {
      setError({ ...error });
    }

    setModalDetails({
      ...modalDetails,
      [name]: value,
    });
  };

  const resetModal = () => {
    setError(null);
    setModalDetails({
      ...modalDetails,
      type: null,
      version: null,
      appId: null,
      appname: null,
      open: false,
      description: "",
      whatsNew: "",
      imageArray: "",
      bannerImage: "",
    });
    setFile(null);
    setDeleteModal({
      type: null,
      appId: null,
    });
    setAppDetailsErrors({});
  };

  const handleAppDetailsSubmit = (e) => {
    e.preventDefault();

    const newAppDetailsErrors = validateAppDetailsForm(
      appDetails,
      appDetailsErrors,
      launcherApp,
      storeApps
    );

    if (Object.keys(newAppDetailsErrors).length > 0) {
      setAppDetailsErrors({ ...newAppDetailsErrors });
      console.log({ appDetailsErrors });
      return;
    }
    setAppDetailsErrors({});

    // DO api call here
    const newAppDetails = {
      ...appDetails,
      prevImages: JSON.stringify(
        appDetails.imageArray.filter((i) => typeof i.url === "string")
      ),
      images: appDetails.imageArray
        .filter((i) => i.url instanceof File)
        .map((i) => i.url),
    };

    console.log("app details in submit", newAppDetails);
    if (appDetails.type === "EDIT") {
      dispatch(CommonActions.startLoading());
      dispatch(
        LauncherActions.updateStoreApp({
          params: newAppDetails,
          callback: (data) => {
            console.log("callback data", data);
            const version = resolveLatestVersion({
              appId: modalDetails?.appId,
              launcherApp,
              storeApps,
            });
            if (!data.success) {
              setToast({
                open: true,
                message: `Please upload version higher than ${version}`,
                duration: 2500,
                type: "error",
              });
            } else {
              setToast({
                open: true,
                message: "Updated App Details",
                duration: 2500,
                type: "success",
              });
            }
            dispatch(CommonActions.stopLoading());
            resetModal();
            fetchStoreApps();
            fetchLauncherApp();
          },
        })
      );
    } else if (appDetails.type === "UPGRADE") {
      console.log("file type", file);
      if (appDetails.appId !== "launcher" && !file?.name.endsWith(".zip")) {
        setAppDetailsErrors({
          ...appDetailsErrors,
          zipInput: "Please Upload a valid zip",
        });
        return;
      }

      if (appDetails.appId === "launcher" && !file?.name.endsWith(".exe")) {
        setAppDetailsErrors({
          ...appDetailsErrors,
          zipInput: "Please Upload a valid exe",
        });
        return;
      }
      setAppDetailsErrors({});
      dispatch(CommonActions.startLoading());

      dispatch(
        LauncherActions.uploadFile({
          file,
          appId: modalDetails.appId,
          callback: (res) => {
            console.log("🚀 ~ file: index.js:115 ~ handleUpload ~ res:", res);

            if (res.success) {
              dispatch(
                LauncherActions.upgradeStoreApp({
                  params: {
                    ...newAppDetails,
                    path: res.result.googleUri,
                  },
                  callback: (data) => {
                    console.log("callback data", data);
                    if (!data.success) {
                      setToast({
                        open: true,
                        message: `Error in uploading app please try again.`,
                        duration: 2500,
                        type: "error",
                      });
                    } else {
                      setToast({
                        open: true,
                        message: "App Upgraded Successfully",
                        duration: 2500,
                        type: "success",
                      });
                    }
                    dispatch(CommonActions.stopLoading());
                    resetModal();
                    fetchStoreApps();
                    fetchLauncherApp();
                  },
                })
              );
            }
          },
        })
      );
    } else if (appDetails.type === "ADD") {
      console.log("file type", file);
      if (!file?.name.endsWith(".zip")) {
        setAppDetailsErrors({
          ...appDetailsErrors,
          zipInput: "Please Upload a valid zip",
        });
        return;
      }
      setAppDetailsErrors({});
      dispatch(CommonActions.startLoading());

      dispatch(
        LauncherActions.uploadFile({
          file,
          appId: modalDetails.appId,
          callback: (res) => {
            console.log("🚀 ~ file: index.js:115 ~ handleUpload ~ res:", res);

            if (res.success) {
              dispatch(
                LauncherActions.addStoreApp({
                  params: {
                    ...newAppDetails,
                    path: res.result.googleUri,
                  },
                  callback: (data) => {
                    console.log("callback data", data);
                    if (!data.success) {
                      setToast({
                        open: true,
                        message: `Error in uploading app please try again.`,
                        duration: 2500,
                        type: "error",
                      });
                    } else {
                      setToast({
                        open: true,
                        message: "App Added Successfully",
                        duration: 2500,
                        type: "success",
                      });
                    }
                    dispatch(CommonActions.stopLoading());
                    resetModal();
                    fetchStoreApps();
                    fetchLauncherApp();
                  },
                })
              );
            }
          },
        })
      );
    }
  };

  const handleAppDetailsChange = (e) => {
    setAppDetailsErrors({});
    setAppDetails({ ...appDetails, [e.target.name]: e.target.value });
  };

  const handleDeleteApp = (appId) => {
    // TODO: request to delete an app by appId, (soft delete)
    console.log(`Delete an App ${appId}`);
    dispatch(CommonActions.startLoading());

    dispatch(
      LauncherActions.deleteStoreApp({
        params: {
          appId,
        },
        callback: (data) => {
          console.log("callback data", data);
          if (!data.success) {
            setToast({
              open: true,
              message: `Error in deleting app please try again.`,
              duration: 2500,
              type: "error",
            });
          } else {
            setToast({
              open: true,
              message: "App Deleted Successfully",
              duration: 2500,
              type: "success",
            });
          }
          dispatch(CommonActions.stopLoading());

          resetModal();
          fetchStoreApps();
          fetchLauncherApp();
        },
      })
    );
  };

  return (
    <>
      <div className="d-flex over">
        <div className={collapsedClass ? " sideNavExpand" : "sideNavContract"}>
          {user && (
            <RBAC
              userRole={user?.role} // User roles that are given to user in the backend
              initList={initList}
              renderNoAccess={() => <SideNav />}
            >
              <SideNav />
            </RBAC>
          )}{" "}
        </div>

        <div
          className={collapsedClass ? "screenContract bg" : "screenExpand bg"}
        >
          {/* <HeaderBar title="Seat Management" /> */}
          <HeaderBar
            width={collapsedClass ? "80%" : "95%"}
            title="Launcher App Management"
            searchbar="no"
            noProduct={true}
          />
          <div className="componentSec">
            <div className="d-flex justify-content-between flexsupad">
              <div className="fullDiv">
                <div
                  className="backIcon mr-2"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <ArrowBackIcon className="icon" />
                </div>
                Back
              </div>
            </div>

            <div
              className="pb-31 mt-4 d-flex flex-column"
              style={{ gap: "10px", padding: "10px 20px" }}
            >
              <div className="tableHeading">Launcher App</div>
              <Table
                className="launcherAppTable"
                tableHeaders={["Version", "Date Uploaded", "Action", "More"]}
                tableData={launcherApp}
                hideColumns={[
                  "id",
                  "metadata",
                  "downloadLink",
                  "appId",
                  "appName",
                  "whatsNew",
                  "description",
                  "bannerImage",
                  "imageArray",
                ]}
                action={{
                  // name: " ",
                  buttonOne: (data) => {
                    return (
                      <Button
                        name="upload"
                        id="updateBtn"
                        background="#fff"
                        color="#116a9a"
                        border="1px solid #116a9a"
                        maxWidth="180px"
                        padding="7px 15px"
                        handleClick={() => {
                          openUploadModel("upload", data);
                        }}
                      />
                    );
                  },
                }}
                handleOptionClick={handleOptionClick}
                optionList={[
                  {
                    name: "Edit",
                    value: "edit",
                  },
                  {
                    name: "View Details",
                    value: "view",
                  },
                  {
                    name: "View Previous Versions",
                    value: "prevVer",
                  },
                ]}
              />
            </div>

            <div
              className="pb-31 mt-4 d-flex flex-column"
              style={{
                gap: "10px",
                padding: "10px 20px",
                height: "350px",
                position: "relative",
              }}
            >
              <div className="tableHeading">Store Apps</div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button onClick={openAddStoreModal} className="addStoreAppBtn">
                  Add Store App
                </button>
              </div>
              <Table
                tableHeaders={[
                  "Name",
                  "Version",
                  "Date Uploaded",
                  "Action",
                  "More",
                ]}
                tableData={storeApps.map((app) => ({
                  appName: app.appName,
                  version: app.version,
                  createdAt: app.createdAt,
                  ...app,
                }))}
                hideColumns={[
                  "id",
                  "appId",
                  "metadata",
                  "downloadLink",
                  "description",
                  "whatsNew",
                  "bannerImage",
                  "imageArray",
                ]}
                action={{
                  // name: " ",
                  buttonOne: (data) => {
                    return (
                      <Button
                        name="upload"
                        id="updateBtn"
                        background="#fff"
                        color="#116a9a"
                        border="1px solid #116a9a"
                        maxWidth="180px"
                        padding="7px 15px"
                        loa
                        handleClick={() => {
                          openUploadModel("upload", data);
                        }}
                      />
                    );
                  },
                }}
                handleOptionClick={handleOptionClick}
                optionList={[
                  {
                    name: "Edit",
                    value: "edit",
                  },
                  {
                    name: "View Details",
                    value: "view",
                  },
                  {
                    name: "View Previous Versions",
                    value: "prevVer",
                  },
                  {
                    name: "Delete",
                    value: "delete",
                  },
                ]}
              />
            </div>
          </div>
        </div>
        {toast?.open ? (
          <div className="toastContainer">
            <Toast
              open={toast.open}
              message={toast.message}
              duration={toast.duration}
              type={toast.type}
              handleClose={() =>
                setToast({
                  ...toast,
                  open: false,
                })
              }
            />
          </div>
        ) : null}
        {/* <DetailsModel
        open={modalDetails.type === "upload" && modalDetails.open === true}
        handleClose={resetModal}
        title="Upload New Version"
        orgId={true}
        orgInput={
          <>
            {modalDetails.appId !== "launcher" && (
              <>
                <input
                  placeholder={`Enter App Name eg: ${getAppName(
                    modalDetails.appId,
                    storeApps
                  )}`}
                  style={{
                    maxWidth: "659px",
                    width: "100%",
                    padding: "4px 15px",
                    height: "47px",
                    marginBottom: "19px",
                    background: "#FFFFFF",
                    boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.25)",
                    borderRadius: "10px",
                    border: "none",
                    outlineColor: "#116a9a",
                    // outline: "none"
                  }}
                  name="appname"
                  onChange={updateModalDetails}
                />
                {error?.appname?.message ? (
                  <p style={{ color: "red", fontSize: 12 }}>
                    {error.appname.message}
                  </p>
                ) : null}
              </>
            )}
            <input
              placeholder="Enter Version Number"
              style={{
                maxWidth: "659px",
                width: "100%",
                padding: "4px 15px",
                height: "47px",
                marginBottom: "19px",
                background: "#FFFFFF",
                boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.25)",
                borderRadius: "10px",
                border: "none",
                outlineColor: "#116a9a",
                // outline: "none"
              }}
              name="version"
              onChange={updateModalDetails}
            />
            {error?.version?.message ? (
              <p style={{ color: "red", fontSize: 12 }}>
                {error.version.message}
              </p>
            ) : null}
          </>
        }
        input2={
          <input
            placeholder="Enter Additional Data"
            style={{
              maxWidth: "659px",
              width: "100%",
              padding: "4px 15px",
              height: "47px",
              marginBottom: "19px",
              background: "#FFFFFF",
              boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.25)",
              borderRadius: "10px",
              border: "none",
              outlineColor: "#116a9a",
              // outline: "none"
            }}
            name="metadata"
            onChange={updateModalDetails}
          />
        }
        input={
          <div className="modalFileSelectorInput">
            <input type="file" onChange={(e) => setFile(e.target.files[0])} />
          </div>
        }
        button={
          <Button
            background="secondary"
            color="primary"
            name="Upload"
            maxWidth="103px"
            handleClick={handleUpload}
            loading={loading}
          />
        }
        cancelButton={
          <Button
            background="secondary"
            color="primary"
            name="Cancel"
            maxWidth="103px"
            handleClick={resetModal}
          />
        }
      /> */}
        {/* <DetailsModel
        open={modalDetails.type === "details"}
        handleClose={resetModal}
        title={modalDetails?.appId?.toUpperCase()}
        detailContainer={
          <div className="simdashLauncherDetailModalContainer">
            <div className="detailModalContainerRow">
              <h6>Name</h6>
              <p>{modalDetails.appId}</p>
            </div>
            <div className="detailModalContainerRow">
              <h6>Version Number</h6>
              <p>{modalDetails.version}</p>
            </div>
            <div className="detailModalContainerRow">
              <h6>Date Uploaded</h6>
              <p>{modalDetails.createdAt}</p>
            </div>
            <div className="detailModalContainerRow">
              <h6>Additional Data</h6>
              <p>{modalDetails.metadata}</p>
            </div>
            <div className="detailModalContainerRow">
              <h6>Download</h6>
              <p>
                <a href={modalDetails.downloadLink}>
                  {modalDetails?.appId?.toUpperCase()}
                </a>
              </p>
            </div>
          </div>
        }
        cancelButton={
          <div className="cancelButton">
            <Button
              id="cancelBtn"
              color="primary"
              border="1px solid #116a9a"
              name="Close"
              maxWidth="103px"
              handleClick={resetModal}
            />
          </div>
        }
      /> */}

        <AddEditAppDetailModal
          open={modalDetails.type === "view"}
          type={modalDetails?.type?.toUpperCase()}
          handleClose={resetModal}
          appDetails={appDetails}
          setAppDetails={setAppDetails}
          setImage={setImage}
          setShowCropModal={setShowCropModal}
          handleAppDetailsChange={handleAppDetailsChange}
          handleAppDetailsSubmit={handleAppDetailsSubmit}
          appDetailsErrors={appDetailsErrors}
          setAppDetailsErrors={setAppDetailsErrors}
          setToast={setToast}
        />

        <AddEditAppDetailModal
          open={modalDetails.type === "edit"}
          type={modalDetails?.type?.toUpperCase()}
          handleClose={resetModal}
          appDetails={appDetails}
          setAppDetails={setAppDetails}
          setImage={setImage}
          setShowCropModal={setShowCropModal}
          handleAppDetailsChange={handleAppDetailsChange}
          handleAppDetailsSubmit={handleAppDetailsSubmit}
          appDetailsErrors={appDetailsErrors}
          setAppDetailsErrors={setAppDetailsErrors}
          setToast={setToast}
          loading={loading}
        />

        <AddEditAppDetailModal
          open={modalDetails.type === "upgrade"}
          type={modalDetails?.type?.toUpperCase()}
          handleClose={resetModal}
          appDetails={appDetails}
          setAppDetails={setAppDetails}
          setImage={setImage}
          setShowCropModal={setShowCropModal}
          handleAppDetailsChange={handleAppDetailsChange}
          handleAppDetailsSubmit={handleAppDetailsSubmit}
          appDetailsErrors={appDetailsErrors}
          setAppDetailsErrors={setAppDetailsErrors}
          setToast={setToast}
          loading={loading}
          setFile={setFile}
        />

        <AddEditAppDetailModal
          open={modalDetails.type === "add"}
          type={modalDetails?.type?.toUpperCase()}
          handleClose={resetModal}
          appDetails={appDetails}
          setAppDetails={setAppDetails}
          setImage={setImage}
          setShowCropModal={setShowCropModal}
          handleAppDetailsChange={handleAppDetailsChange}
          handleAppDetailsSubmit={handleAppDetailsSubmit}
          appDetailsErrors={appDetailsErrors}
          setAppDetailsErrors={setAppDetailsErrors}
          setToast={setToast}
          loading={loading}
          setFile={setFile}
        />

        <DeleteModal
          open={deleteModal.type === "delete"}
          handleClose={resetModal}
          onDelete={handleDeleteApp}
          setToast={setToast}
          loading={loading}
          appId={deleteModal.appId}
          appName={deleteModal.appName}
        />

        {showCropModal.show && (
          <div className="cropModalClass">
            <CropModal
              image={image && URL.createObjectURL(image)}
              aspect={showCropModal?.aspect || 4 / 3}
              handleCroppedImage={handleCroppedImage}
            />
          </div>
        )}

        {/* Modal to show edit current version of apps */}
        {/*<DetailsModel
          open={modalDetails.type === "edit" && modalDetails.open === true}
          handleClose={resetModal}
          title={`Edit ${modalDetails?.appName}`}
          orgId={true}
          orgInput={
            <>
              {modalDetails.appId !== "launcher" && (
                <>
                  <input
                    placeholder={`Enter App Name eg: ${getAppName(
                      modalDetails.appId,
                      storeApps
                    )}`}
                    style={{
                      maxWidth: "659px",
                      width: "100%",
                      padding: "4px 15px",
                      height: "47px",
                      marginBottom: "19px",
                      background: "#FFFFFF",
                      boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px",
                      border: "none",
                      outlineColor: "#116a9a",
                      // outline: "none"
                    }}
                    name="appname"
                    onChange={updateModalDetails}
                    value={modalDetails?.appname}
                  />

                  {error && error?.appname?.message ? (
                    <p style={{ color: "red", fontSize: 12 }}>
                      {error.appname.message}
                    </p>
                  ) : null}
                </>
              )}
              <input
                placeholder="Enter Version Number"
                style={{
                  maxWidth: "659px",
                  width: "100%",
                  padding: "4px 15px",
                  height: "47px",
                  marginBottom: "19px",
                  background: "#FFFFFF",
                  boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.25)",
                  borderRadius: "10px",
                  border: "none",
                  outlineColor: "#116a9a",
                  // outline: "none"
                }}
                name="version"
                onChange={updateModalDetails}
                value={modalDetails?.version}
              />
              {error && error?.version?.message ? (
                <p style={{ color: "red", fontSize: 12 }}>
                  {error.version.message}
                </p>
              ) : null}
            </>
          }
          input2={
            <input
              placeholder="Enter Additional Data"
              style={{
                maxWidth: "659px",
                width: "100%",
                padding: "4px 15px",
                height: "47px",
                marginBottom: "19px",
                background: "#FFFFFF",
                boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.25)",
                borderRadius: "10px",
                border: "none",
                outlineColor: "#116a9a",
                // outline: "none"
              }}
              name="metadata"
              onChange={updateModalDetails}
              value={modalDetails?.metadata}
            />
          }
          button={
            <Button
              background="secondary"
              color="primary"
              name="Update"
              maxWidth="103px"
              handleClick={handleUpdate}
              loading={loading}
            />
          }
          cancelButton={
            <Button
              background="secondary"
              color="primary"
              name="Cancel"
              maxWidth="103px"
              handleClick={resetModal}
            />
          }
        /> */}
      </div>
    </>
  );
};

export default SimdashLauncher;
