import React from "react";
import Header from "../../PublicModule/Header/Header";
import { Paper } from "@material-ui/core";
import { ROUTES } from "../../Services/Constants";
import Button from "../../Component/Button/button";
import { useHistory } from "react-router-dom";

import ProgressBarStep from "../../Component/ProgressBar/ProgressBar";




const WelcomeMessage = (props) => {
    const history = useHistory()

    return (
        <div className="AuthBg orgSelectionDiv">
            <Header />
            <div className="signIn">

                <Paper className="paperGrid">
                    <div className="d-flex flex-column p-4">
                        <h3>Welcome. </h3>
                        
                        <div className="basicMsg"> 
                        <span className="textSpan">
                        Your account is all set up

                        </span>
                            </div>
                        <Button
                            background="secondary"
                            name="Start"
                            color="primary"
                            textAlign="center"
                            handleClick={() => { history.push(ROUTES.USER_DASHBOARD) }}
                        ></Button>
                    </div>

                </Paper>


            </div>
            <ProgressBarStep value={100} />

        </div>
    );
};

export default WelcomeMessage;
