export const initialState = {
  loading: false,
  activeGroup: {}
};

export const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_STARTED":
      return {
        ...state,
        loading: true,
      };
    case "LOADING_COMPLETED":
      return {
        ...state,
        loading: false,
      };
    case "LOADING_FAILURE":
      return {
        ...state,
        loading: false,
      };
      case "SET_ACTIVE_GROUP":
        console.log("action gropu payload",action.payload);
        return {
          ...state,
          activeGroup: action.payload,
        };

    default:
      return state;
  }
};
