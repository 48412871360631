import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "../Button/button";
import { AccountCircleOutlined } from "@material-ui/icons";
import BackupIcon from "@material-ui/icons/Backup";
import moment from "moment";
import { green } from "@material-ui/core/colors";
import "./sideUser.css";
const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  paper: {
    display: "flex",
    flexDirection: "column",

    border: "2px solid white",
    backgroundColor: "#116a9a",
    // width: "20%",
    padding: "20px 0px",
    borderRadius: "3px",
    "& button": {
      margin: "0 2px",
    },
  },
  media: {
    maxWidth: "380px",
    color: "#ffffff",
    padding: "0px 0px 10px",
    height: " auto",
    objectFit: "cover",
  },
  accountIcon: {
    width: "60px !important",
    color: "#ffffff",
    padding: "0px 0px 10px",
  },
  name: {
    textAlign: "center",
    display: "flex",
    fontSize: "20px",
    justifyContent: "center",
    color: "#ffffff",
    padding: "5px",
    marginBottom: "4px",
    "& p": {
      marginBottom: "0px !important",
    },
  },

  orgName: {
    color: "#ffffff",
    textAlign: "center",
    fontSize: "27px",
  },
  dates: {
    color: "#ffffff",
    textAlign: "center",
    fontSize: "27px",
    marginBottom: "4px",
  },
  heading: {
    textAlign: "center",
    color: "white",
    fontWeight: "800",
  },
  button: {
    display: "flex",
    margin: "0 auto",
    justifyContent: "center",
  },
  OrgDP: {
    width: "50%",
    margin: "10px auto",
    "&  img": {
      width: "100%",
    },
  },
}));
const SideUser = ({
  handleUpdateClick,
  handlePicChange,
  imageAsUrl,
  setImageAsFile,
  picEdit,
  setPicEdit,
  handleFireBaseUpload,
  setImageDetails,
  handleChangePasswordClick,
  updateOrg,
  org,
  ...props
}) => {
  const picUpload = useRef();
  const classes = useStyles();
  const [image, setImage] = useState(
    "https://st4.depositphotos.com/14953852/22772/v/600/depositphotos_227725020-stock-illustration-image-available-icon-flat-vector.jpg"
  );
  const [seatLeft, setSeatLeft] = useState();
  useEffect(() => {
    let seat = 0;

    if (org[0]?.users || org?.pendingInvitations) {
      org[0].users.map((user) => {
        if (user.seatAlloted === true) seat += 1;
      });
      setSeatLeft(seat);
    }
  }, [org]);
  return (
    <div>
      <Paper className={classes.paper}>
        {props.orgName ? (
          <div className={classes.orgName}>
            <p>
              <b>{props.orgName}</b>
            </p>
          </div>
        ) : null}
        <div className="parentBox fixingParentBox">
          <div className="box1">
            {props?.sideUserType ? null : imageAsUrl?.imgUrl == null ? (
              <BackupIcon className={classes.media} />
            ) : (
              <div className="d-flex justify-content-center">
                <img className={classes.media} src={imageAsUrl.imgUrl} />
              </div>
            )}

            <input
              type="file"
              accept="image/*"
              ref={picUpload}
              onChange={handlePicChange}
              hidden
              onClick={(event) => {
                event.target.value = null;
              }}
            />
            {picEdit === false && (
              <div className="mt-3 ">
                <Button
                  color="secondary"
                  background="primary"
                  name="Upload logo"
                  padding="10px 20px"
                  height="30px"
                  textAlign="center"
                  handleClick={() => picUpload.current.click()}
                ></Button>
              </div>
            )}

            {picEdit === true && (
              <div className="d-flex flex-row justify-content-center">
                <div className="">
                  <Button
                    color="primary"
                    background="secondary"
                    name="Save"
                    padding="10px 20px"
                    height="30px"
                    textAlign="center"
                    handleClick={handleFireBaseUpload}
                  ></Button>
                </div>
                <Button
                  color="secondary"
                  background="primary"
                  name="Cancel"
                  padding="10px 20px"
                  height="30px"
                  textAlign="center"
                  handleClick={setImageDetails}
                ></Button>
              </div>
            )}
            {updateOrg ? null : (
              <div className="mt-2">
                <Button
                  color="primary"
                  background="secondary"
                  name="Change Org Name"
                  padding="10px 20px"
                  height="30px"
                  textAlign="center"
                  handleClick={handleUpdateClick}
                ></Button>
              </div>
            )}

            {props.changePass ? (
              <div className="mt-2">
                <Button
                  color="secondary"
                  background="primary"
                  name="Change password"
                  padding="10px 20px"
                  height="30px"
                  textAlign="center"
                  handleClick={handleChangePasswordClick}
                ></Button>
              </div>
            ) : null}
          </div>
          <div className="box2">
            {/* <div className={classes.dates}>
              {props?.sideUserType ? (
                <AccountCircleOutlined className={classes.accountIcon} />
              ) : null}
            </div> */}

            {props.SuperAdmin ? (
              <h6 className={classes.heading}>Super Admin</h6>
            ) : null}
            {props.name ? (
              <div className={classes.name}>
                <p>{props.userName}</p>
              </div>
            ) : null}
            {props.status ? (
              <div className={classes.name}>
                <p>
                  <strong>Status :</strong>
                </p>{" "}
                &nbsp;&nbsp;{" "}
                <p>
                  {moment(props.subEndDate).diff(
                    new Date().toISOString(),
                    "days"
                  ) < 0 ? (
                    <span style={{ color: "red", fontWeight: 700 }}>
                      Inactive
                    </span>
                  ) : (
                    <span style={{ color: "#11ff02", fontWeight: 700 }}>
                      Active
                    </span>
                  )}
                </p>
              </div>
            ) : null}
            {props.subStartDate ? (
              <div className={classes.dates}>
                <strong>
                  <h6>Subscription Start Date</h6>
                </strong>
                <p>{props.subStartDate}</p>
              </div>
            ) : null}
            {props.subEndDate ? (
              <div className={classes.dates}>
                <strong>
                  <h6>Subscription End Date</h6>
                </strong>
                <p>{moment(props.subEndDate).format("YYYY-MM-DD")}</p>
              </div>
            ) : null}
            {org ? (
              <>
                <div className={classes.dates}>
                  <strong>
                    <h6>Seat Allotted</h6>
                  </strong>
                  <p>{seatLeft}</p>
                </div>
                <div className={classes.dates}>
                  <h6>Total Seat</h6>
                  <p>{org[0]?.seats}</p>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default SideUser;
