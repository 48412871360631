import React from "react";
import "./styles.css";
import ChartCard from "./ChartCard";

const Defibulation = ({ assessment }) => {
  console.log(
    "🚀 ~ file: Defibulation.js:6 ~ Defibulation ~ assessment:",
    assessment
  );
  //COACHED 1
  const setDefib1 = () => {
    let labels = [];
    let assess = assessment.coached[0];

    labels.push({
      success: true,
      warning: true,
      label: assess.rhythm,
      title: true,
    });

    if (formatTime(assess.evalTime) <= 6)
      labels.push({
        success: true,
        label: `Rhythm evaluation time: ${assess.evalTime}`,
      });
    else
      labels.push({
        success: false,
        label: `Rhythm evaluation time: ${assess.evalTime}`,
      });

    if (assess.shocked === assess.shockable)
      labels.push({
        success: true,
        label: assess.shocked ? "Shocked" : "Defibrillator  disarmed",
      });
    else
      labels.push({
        success: false,
        label: assess.shocked ? "Shocked" : "Defibrillator  disarmed",
      });

    if (formatTime(assess.offChestTime) <= 15)
      labels.push({
        success: true,
        label: `Offchest time: ${assess.offChestTime}`,
      });
    else
      labels.push({
        success: false,
        label: `Offchest time: ${assess.offChestTime}`,
      });

    if (assess.pulseChecked === assess.shouldCheckPulse)
      labels.push({
        success: true,
        label: "Pulse checked",
      });
    else
      labels.push({
        success: false,
        label: "Pulse not checked",
      });

    if (assess.cycle < 1 && !assess.roleSwapped)
      labels.push({ success: true, label: "CPR roles not swapped" });

    if (assess.stoppedCprCountInCoached !== 0)
      labels.push({
        success: false,
        label: `Incorrectly stopped CPR during COACHED: [ ${assess.stoppedCprCountInCoached} ] times`,
      });

    if (assess.drugSchedCorrect) {
      if (assess.adrenaline) {
        labels.push({
          success: true,
          label: `Adrenaline correctly administered`,
        });
      }
      if (assess.atropine) {
        labels.push({
          success: true,
          label: `Atropine correctly administered`,
        });
      }
      if (assess.amiodarone) {
        labels.push({
          success: true,
          label: `Amiodarone correctly administered`,
        });
      }

      if (!assess.adrenaline && !assess.adrenaline && !assess.amiodarone) {
        labels.push({
          success: true,
          label: `No drugs administered`,
        });
      }
    } else {
      if (assess.adrenaline) {
        labels.push({
          success: false,
          label: `Adrenaline incorrectly administered`,
        });
      }
      if (assess.atropine) {
        labels.push({
          success: false,
          label: `Atropine incorrectly administered`,
        });
      }
      if (assess.amiodarone) {
        labels.push({
          success: false,
          label: `Amiodarone incorrectly administered`,
        });
      }

      if (!assess.adrenaline && !assess.adrenaline && !assess.amiodarone) {
        labels.push({
          success: false,
          label: `No drugs administered`,
        });
      }
    }

    if (assess?.potassium) {
      if (assess?.potassiumCorrect) {
        labels.push({
          success: true,
          label: `Potassium correctly administered`,
        });
      } else {
        labels.push({
          success: false,
          label: `Potassium incorrectly administered`,
        });
      }
    }

    return labels;
  };

  const setDefib2 = () => {
    // Coached 2
    let labels = [];
    let assess = assessment.coached[1];
    labels.push({
      success: true,
      warning: true,
      label: assess.rhythm,
      title: true,
    });
    if (formatTime(assess.evalTime) <= 6)
      labels.push({
        success: true,
        label: `Rhythm evaluation time: ${assess.evalTime}`,
      });
    else
      labels.push({
        success: false,
        label: `Rhythm evaluation time: ${assess.evalTime}`,
      });

    if (formatTime(assess.offChestTime) <= 15)
      labels.push({
        success: true,
        label: `Offchest time: ${assess.offChestTime}`,
      });
    else
      labels.push({
        success: false,
        label: `Offchest time: ${assess.offChestTime}`,
      });

    if (assess.shocked === assess.shockable)
      labels.push({
        success: true,
        label: assess.shocked ? "Shocked" : "Defibrillator  disarmed",
      });
    else
      labels.push({
        success: false,
        label: assess.shocked ? "Shocked" : "Defibrillator  disarmed",
      });

    if (assess.pulseChecked === assess.shouldCheckPulse)
      labels.push({
        success: true,
        label: "Pulse checked",
      });
    else
      labels.push({
        success: false,
        label: "Pulse not checked",
      });

    if (assess.cycle < 1 && !assess.roleSwapped)
      labels.push({ success: true, label: "CPR roles not swapped" });

    if (assess.stoppedCprCountInCoached !== 0)
      labels.push({
        success: false,
        label: `Incorrectly stopped CPR during COACHED: [ ${assess.stoppedCprCountInCoached} ] times`,
      });

    if (assess.drugSchedCorrect) {
      if (assess.adrenaline) {
        labels.push({
          success: true,
          label: `Adrenaline correctly administered`,
        });
      }
      if (assess.atropine) {
        labels.push({
          success: true,
          label: `Atropine correctly administered`,
        });
      }
      if (assess.amiodarone) {
        labels.push({
          success: true,
          label: `Amiodarone correctly administered`,
        });
      }

      if (!assess.adrenaline && !assess.adrenaline && !assess.amiodarone) {
        labels.push({
          success: true,
          label: `No drugs administered`,
        });
      }
    } else {
      if (assess.adrenaline) {
        labels.push({
          success: false,
          label: `Adrenaline incorrectly administered`,
        });
      }
      if (assess.atropine) {
        labels.push({
          success: false,
          label: `Atropine incorrectly administered`,
        });
      }
      if (assess.amiodarone) {
        labels.push({
          success: false,
          label: `Amiodarone incorrectly administered`,
        });
      }

      if (!assess.adrenaline && !assess.adrenaline && !assess.amiodarone) {
        labels.push({
          success: false,
          label: `No drugs administered`,
        });
      }
    }

    if (assess?.potassium) {
      if (assess?.potassiumCorrect) {
        labels.push({
          success: true,
          label: `Potassium correctly administered`,
        });
      } else {
        labels.push({
          success: false,
          label: `Potassium incorrectly administered`,
        });
      }
    }
    return labels;
  };

  const setDefib3 = () => {
    // Coached 3
    let labels = [];
    let assess = assessment.coached[2];
    labels.push({
      success: true,
      warning: true,
      label: assess.rhythm,
      title: true,
    });
    if (formatTime(assess.evalTime) <= 6)
      labels.push({
        success: true,
        label: `Rhythm evaluation time: ${assess.evalTime}`,
      });
    else
      labels.push({
        success: false,
        label: `Rhythm evaluation time: ${assess.evalTime}`,
      });

    if (formatTime(assess.offChestTime) <= 15)
      labels.push({
        success: true,
        label: `Offchest time: ${assess.offChestTime}`,
      });
    else
      labels.push({
        success: false,
        label: `Offchest time: ${assess.offChestTime}`,
      });

    if (assess.shocked === assess.shockable)
      labels.push({
        success: true,
        label: assess.shocked ? "Shocked" : "Defibrillator  disarmed",
      });
    else
      labels.push({
        success: false,
        label: assess.shocked ? "Shocked" : "Defibrillator  disarmed",
      });

    if (assess.pulseChecked === assess.shouldCheckPulse)
      labels.push({
        success: true,
        label: "Pulse checked",
      });
    else
      labels.push({
        success: false,
        label: "Pulse not checked",
      });

    if (assess.cycle < 1 && !assess.roleSwapped)
      labels.push({ success: true, label: "CPR roles not swapped" });

    if (assess.stoppedCprCountInCoached !== 0)
      labels.push({
        success: false,
        label: `Incorrectly stopped CPR during COACHED: [ ${assess.stoppedCprCountInCoached} ] times`,
      });

    if (assess.drugSchedCorrect) {
      if (assess.adrenaline) {
        labels.push({
          success: true,
          label: `Adrenaline correctly administered`,
        });
      }
      if (assess.atropine) {
        labels.push({
          success: true,
          label: `Atropine correctly administered`,
        });
      }
      if (assess.amiodarone) {
        labels.push({
          success: true,
          label: `Amiodarone correctly administered`,
        });
      }

      if (!assess.adrenaline && !assess.adrenaline && !assess.amiodarone) {
        labels.push({
          success: true,
          label: `No drugs administered`,
        });
      }
    } else {
      if (assess.adrenaline) {
        labels.push({
          success: false,
          label: `Adrenaline incorrectly administered`,
        });
      }
      if (assess.atropine) {
        labels.push({
          success: false,
          label: `Atropine incorrectly administered`,
        });
      }
      if (assess.amiodarone) {
        labels.push({
          success: false,
          label: `Amiodarone incorrectly administered`,
        });
      }

      if (!assess.adrenaline && !assess.adrenaline && !assess.amiodarone) {
        labels.push({
          success: false,
          label: `No drugs administered`,
        });
      }
    }

    if (assess?.potassium) {
      if (assess?.potassiumCorrect) {
        labels.push({
          success: true,
          label: `Potassium correctly administered`,
        });
      } else {
        labels.push({
          success: false,
          label: `Potassium incorrectly administered`,
        });
      }
    }
    return labels;
  };

  const formatTime = (_time) => {
    return +_time.replace(":", "");
  };

  return (
    <div className="defb-container">
      {assessment && <ChartCard labels={setDefib1()} />}
      {assessment && <ChartCard labels={setDefib2()} />}
      {assessment && <ChartCard labels={setDefib3()} />}
    </div>
  );
};

export default Defibulation;
