import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import InputBase from "@material-ui/core/InputBase";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import { Divider } from "@material-ui/core";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import BusinessIcon from "@material-ui/icons/Business";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import "./sideSearch.css";

import jwt from "jsonwebtoken";
import { useDispatch, useSelector } from "react-redux";
import { OrgActions, SuperAdminActions } from "../../ReduxStore/Actions";
import { ACTION_TYPE } from "../../Services/Constants";

// const { users: orgUsers } = useSelector(
//   (state) => state.SuperAdminReducer.currentOrg
// );
// const superAdminAllOrgs = useSelector(
//   (state) => state.SuperAdminReducer.allOrgs
// );

const useStyles = makeStyles((theme) => ({
  display: {
    display: "flex",
    flexDirection: "column",
    fontSize: "12px",
  },

  paper: {
    color: "white",
    width: "100%",
    backgroundColor: "#116a9a",
    "& .MuiTypography-colorTextSecondary": {
      color: "white",
    },
    "& ul li": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    "& ul li form": {
      width: "100%",
    },
  },
  divider: {
    color: "white",

    backgroundColor: "white",
  },
  listitem: {
    cursor: "pointer",
    margin: "4px auto",
    margin: "10px 0px",
    "& .MuiAvatar-colorDefault": {
      backgroundColor: "white",
      color: "#116a9a",
    },
    "& .MuiTypography-body1": {
      fontWeight: "900",
    },

    "&:hover": {
      backgroundColor: "white !important",
      color: "black",
      borderRadius: "3px",
      "& .MuiTypography-colorTextSecondary": {
        color: "black",
      },

      "& .MuiAvatar-colorDefault": {
        backgroundColor: "#116a9a",
        color: "white",
      },
    },
  },
  active: {
    backgroundColor: "white !important",
    color: "black",
    borderRadius: "3px",
    "& .MuiTypography-colorTextSecondary": {
      color: "black",
    },

    "& .MuiAvatar-colorDefault": {
      backgroundColor: "#116a9a",
      color: "white",
    },
  },

  list: {
    padding: "0px 5px 0px 0",
    position: "relative",
  },
  search: {
    width: "100%",
    "& .MuiPaper-root": {
      margin: "4px",
    },
  },
}));

const SideSearch = ({
  user,
  allOrgs,
  handleSelect,
  activeClass,
  setActiveClass,
  newButton,
  type,
  disable,
  handleClickButton,
  isProductAnalytics,
}) => {
  const classes = useStyles();
  const [allOrgsList, setAllOrgList] = useState();
  const dispatch = useDispatch();

  const { product } = useSelector((state) => state.ProductReducer);
  let currentOrg = useSelector((state) => state.OrgReducer.currentOrg);
  useEffect(() => {
    setAllOrgList(allOrgs);
  }, [allOrgs]);

  const filterFunction = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let searchKeyword = e.target.value;

    if (searchKeyword.trim().length > 0) {
      let list = [];

      allOrgsList &&
        allOrgsList.length > 0 &&
        allOrgsList.map((userr) => {
          var regex = new RegExp(`${searchKeyword}`, "i");
          let result = userr["orgName"].match(regex);

          if (result && result.length > 0) {
            list.push(userr);
          }
        });
      if (allOrgsList.length == 0 && allOrgs.length > 0) {
        allOrgs.map((userr) => {
          var regex = new RegExp(`${searchKeyword}`, "i");
          let result = userr["orgName"].match(regex);

          if (result && result.length > 0) {
            list.push(userr);
          }
        });
      }

      setAllOrgList(list);
    } else {
      setAllOrgList(allOrgs);
    }
  };

  return (
    <div className="d-flex sidenavsecond">
      <CssBaseline />

      <Paper className={classes.paper}>
        <List
          className={classes.list}
          style={{ maxHeight: "1580px", overflowY: "auto" }}
        >
          {newButton ? (
            <ListItem
              onClick={handleClickButton}
              style={{ cursor: "pointer", fontSize: "18px" }}
              className="newIcon fixNewIcon groupManagementTourStep1"
            >
              <ControlPointIcon />
              <div style={{ whiteSpace: "pre-wrap" }}>New Group</div>
            </ListItem>
          ) : (
            <ListItem className={classes.listitemsearch}>
              <Paper component="form" className="inputHeaderBar">
                <InputBase
                  placeholder="Search Org"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (e.keyCode === 13 || e.keyCode === "Enter") {
                      return;
                    }
                  }}
                  onChange={(e) => {
                    e.preventDefault();
                    if (e.keyCode === 13 || e.keyCode === "Enter") {
                      return;
                    }
                    filterFunction(e);
                  }}
                  className={classes.search}
                />
              </Paper>
            </ListItem>
          )}

          {allOrgsList &&
            allOrgsList.length > 0 &&
            allOrgsList.map((data, index) => {
              return (
                <div className="sidesearchlist" key={index}>
                  <ListItem
                    className={
                      isProductAnalytics
                        ? activeClass == `active${data.orgId}`
                          ? `${classes.listitem}  ${classes.active}`
                          : `${classes.listitem}`
                        : activeClass === `active${index}`
                        ? `${classes.listitem}  ${classes.active}`
                        : `${classes.listitem}`
                    }
                    onClick={
                      disable
                        ? null
                        : async () => {
                            console.log("datarahul", data);
                            setActiveClass(`active${index}`);
                            data.activeClass = `active${index}`;
                            if (isProductAnalytics) {
                              setActiveClass(`active${data.orgId}`);
                            }
                            handleSelect(data);
                          }
                    }
                  >
                    <ListItemAvatar>
                      <Avatar>
                        {index == 0 ? (
                          <BusinessIcon />
                        ) : user?.role === "superAdmin" ? (
                          <BusinessIcon />
                        ) : (
                          <SupervisorAccountIcon />
                        )}
                      </Avatar>
                    </ListItemAvatar>
                    {/* {console.log("data::",data, data?.owner[0]?.userName)} */}
                    <ListItemText
                      primary={
                        type === "group"
                          ? data.name == "Default Group"
                            ? currentOrg[0]?.orgName
                            : data?.name
                          : data.orgName
                      }
                      secondary={
                        data.owner &&
                        data?.owner.length > 0 &&
                        data?.owner[0]?.userName
                          ? "Created By: " + data?.owner[0]?.userName
                          : isProductAnalytics
                          ? data?.users?.filter((usr) => usr["seatAlloted"])
                              .length + " Members"
                          : data?.users?.length + " Members"
                      }
                    />
                  </ListItem>
                  <Divider className={classes.divider} />
                </div>
              );
            })}
        </List>
      </Paper>
    </div>
  );
};

export default SideSearch;
