import BubbleGraph from "./bubble";
import React from "react";
import CustomCard from "../Card/customCard";
import "./style.css";
import { useSelector } from "react-redux";
import { getAvgTotalTime, getNumOfPeople } from "../../Services/Utils/utils";
import { APPLICATION_CONSTANTS } from "../../Services/Constants";
import {
  getAETrueCount,
  getAvgArrayDesc,
  getAvgDesc,
  getAvgScoreALS,
  getTotalDesc,
  getTotalSeconds,
} from "../../Helper";
import { withRouter } from "react-router";
import { CircularProgress } from "@material-ui/core";
import AssessmentIcon from "@material-ui/icons/Assessment";
import Legend from "../Legend";

const DeEscalationDash = React.memo((props) => {
  const { assessmentData, peopleCount } = props;

  const { loading } = useSelector((state) => state.CommonReducer);
  let currentOrg = useSelector((state) => state.OrgReducer.currentOrg);

  let user = useSelector((state) => state.AuthReducer.user);

  if (loading)
    return (
      <div className="spinnerDiv" style={{ width: "100%" }}>
        {" "}
        <CircularProgress color="inherit"></CircularProgress>
      </div>
    );
  else
    return (
      <>
        {assessmentData && assessmentData?.length > 0 && (
          <div className="d-flex  flexsupad">
            {currentOrg.length === 0 && user?.role != "superAdmin" ? (
              <CustomCard
                handleClick={() =>
                  props.history.push(`/deescalation-assessment/${user.uuid}`)
                }
                // fontSize="64px"
                title="View Assessments"
              />
            ) : (
              <CustomCard
                no={peopleCount}
                // fontSize="64px"
                title="Number of People"
              ></CustomCard>
            )}

            <CustomCard
              no={getAvgDesc(assessmentData, "overallScore")}
              fontSize="64px"
              title="Average Overall Score"
            ></CustomCard>

            <CustomCard
              no={getAvgDesc(assessmentData, "scenarioDuration")}
              fontSize="64px"
              title="Average Scenario Duration"
            ></CustomCard>

            <CustomCard
              no={assessmentData.length}
              fontSize="64px"
              title="Number of Assessments"
            ></CustomCard>
            <CustomCard
              no={getTotalDesc(assessmentData, "scenarioDuration")}
              fontSize="64px"
              title="Time in app"
            ></CustomCard>
            <CustomCard
              no={getAvgArrayDesc(assessmentData, ["responseToUserInputDelta"])}
              fontSize="64px"
              title="Average Response Time"
            ></CustomCard>
            {/* <CustomCard
              no={getAvgArrayDesc(assessmentData, [
                "distanceUserInput",
                "distanceResponse",
              ])}
              fontSize="64px"
              title="Average Distance"
            ></CustomCard> */}
          </div>
        )}
        {assessmentData && assessmentData?.length > 0 && (
          <div className="flexGraph als">
            <div className="graphbgMainL">
              <div className="GraphBG">
                <BubbleGraph
                  assessmentData={assessmentData}
                  // orgUsers={orgUsers}
                ></BubbleGraph>
              </div>
            </div>
            {/* {assessmentData.length >= 0 ? (
          <>
            <Legend />
            <div className="fixedPieCharts">
              <div className="col-lg-2 col-md-4 col-xs-6">
                <AtrialFibrillationPie
                  assessmentData={assessmentData}
                ></AtrialFibrillationPie>
              </div>
              <div className="col-lg-2 col-md-4 col-xs-6">
                <PieChart assessmentData={assessmentData}></PieChart>
              </div>

              <div className="col-lg-2 col-md-4 col-xs-6">
                <AsystolePie assessmentData={assessmentData}></AsystolePie>
              </div>

              <div className="col-lg-2 col-md-4 col-xs-6">
                <VentriCularFibrillationPie
                  assessmentData={assessmentData}
                ></VentriCularFibrillationPie>
              </div>

              <div className="col-lg-2 col-md-4 col-xs-6">
                <VentriCularTachycardiaPie
                  assessmentData={assessmentData}
                ></VentriCularTachycardiaPie>
              </div>

              <div className="col-lg-2 col-md-4 col-xs-6">
                <SinusRythmPie assessmentData={assessmentData}></SinusRythmPie>
              </div>
            </div>
          </>
        ) : (
          <div
            style={{
              color: "black",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "3px",
              display: "flex",
              justifyContent: "center",
              margin: "20px auto",
              fontWeight: "700",
              fontSize: "19px",
            }}
          >
            Data Not Yet Available
          </div>
          )} */}

            {/* <div className="graphbgMainR barChartSpecial">
            <div className="GraphBG col-sm-6">
              <h1>ventilation</h1>

              <HorizontalBarGraph
                assessmentData={assessmentData}
                type="ventilation"
              ></HorizontalBarGraph>
            </div>
            <div className="GraphBG col-sm-6">
              <h1>fluids</h1>

              <HorizontalBarGraph
                assessmentData={assessmentData}
                type="fluids"
              ></HorizontalBarGraph>
            </div>
            <div className="GraphBG col-sm-6">
              <h1>cpr</h1>

              <HorizontalBarGraph
                assessmentData={assessmentData}
                type="cpr"
              ></HorizontalBarGraph>
            </div>
            <div className="GraphBG col-sm-6">
              <h1>HT</h1>

              <HorizontalBarGraph
                assessmentData={assessmentData}
                type="HT"
              ></HorizontalBarGraph>
            </div>
          </div> */}
          </div>
        )}
      </>
    );
});
export default withRouter(DeEscalationDash);
