import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  textField: {
    "& .MuiInputBase-root": {
      fontSize: "0.7rem",
      width: "90%",
    },
  },
}));

export default function DatePickers({
  id,
  setSubDate,
  dateDefault,
  handleChange,
  name,
}) {
  const classes = useStyles();

  return (
    <form className={classes.container} noValidate>
      <TextField
        id={id}
        name={name}
        type="date"
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        views={["year", "month", "day"]}
        defaultValue={dateDefault}
        // onChange={(e) => setSubDate({ id, value: e.target.value })}
        onChange={handleChange}
        format="YYYY-MM-DD"
      />
    </form>
  );
}
