import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import "./AddEditAppDetailModal.css";
import { Fragment } from "react";
import { isValidImage } from "../../DashboardModule/SimdashLauncher/validateAddEditModal";

const AddEditAppDetailModal = ({
  open,
  handleClose,
  type,
  appDetails,
  setAppDetails,
  setImage,
  setShowCropModal,
  handleAppDetailsChange,
  handleAppDetailsSubmit,
  appDetailsErrors,
  setAppDetailsErrors,
  setToast,
  loading,
  setFile,
}) => {
  /*
    props:
    type: "VIEW" | "EDIT" | "ADD" | "UPGRADE"
  */

  console.log("in add edit app details ", appDetails);

  const editOrAdd = type === "EDIT" || type === "ADD" || type === "UPGRADE";
  const view = type === "VIEW";

  const onUploadClick = (e) => {
    e.target.value = "";
  };
  console.log({ appDetails });

  const handleWhatsNewChange = (e) => {
    setAppDetails({
      ...appDetails,
      whatsNew: e,
    });
  };

  const handleBannerUpload = (e) => {
    setAppDetailsErrors({});
    console.log("banner image", e.target.files);
    if (!isValidImage(e.target.files[0])) {
      setToast({
        open: true,
        message: `Please reupload image with jpeg or png format`,
        duration: 2500,
        type: "error",
      });
    } else {
      setImage(e.target.files[0]);
      setShowCropModal({ show: true, type: "BANNER", aspect: 3.33 / 1 });
    }
  };

  const handleAppImageUpload = (e) => {
    console.log("app image", e.target.files);
    setAppDetailsErrors({});
    if (!isValidImage(e.target.files[0])) {
      setToast({
        open: true,
        message: `Please reupload image with jpeg or png format`,
        duration: 2500,
        type: "error",
      });
    } else {
      setImage(e.target.files[0]);
      setShowCropModal({ show: true, type: "APP", aspect: 1 / 1 });
    }
  };

  const handleRemoveImage = (id) => {
    setAppDetails({
      ...appDetails,
      imageArray: appDetails.imageArray.filter((image) => image.id !== id),
    });
  };

  const handleFileChange = (e) => {
    setAppDetailsErrors({});
    setFile(e.target.files[0]);
  };

  console.log({ appDetails });

  return (
    <div className="detailsModalContainer">
      <Dialog
        open={open}
        className="details"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <DialogContent className="mb-3" style={{ width: "100%" }}>
          {view && (
            <button
              className="addEditModalcloseBtn"
              onClick={handleClose}
              type="button"
            >
              &#x2716;
            </button>
          )}

          <form
            className="detailsModalForm"
            onSubmit={handleAppDetailsSubmit}
            style={view ? { border: "1px solid black" } : null}
          >
            <div className="appDetailBannerImgClass">
              {appDetails?.bannerImage ? (
                <div className="bannerImageBgContainer">
                  <img
                    width="100%"
                    src={
                      typeof appDetails.bannerImage === "string"
                        ? appDetails.bannerImage
                        : URL.createObjectURL(appDetails.bannerImage)
                    }
                    alt=""
                  />
                </div>
              ) : (
                <p
                  style={{
                    paddingBottom: "55px",
                    textAlign: "center",
                  }}
                >
                  Banner Image Not Found.
                </p>
              )}

              {editOrAdd && (
                <div className="bannerImageInputParent">
                  <input
                    type="file"
                    id="banner-image"
                    name="banner-image"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleBannerUpload}
                    onClick={onUploadClick}
                  />

                  <label htmlFor="banner-image">Upload Banner Image</label>
                  <p style={{ color: "red", fontSize: "12px", margin: 0 }}>
                    {appDetailsErrors?.bannerImage &&
                      appDetailsErrors.bannerImage}
                  </p>
                </div>
              )}
            </div>

            {(type === "UPGRADE" || type === "ADD") && (
              <div
                className="modalFileSelectorInput"
                style={{ marginBottom: "10px" }}
              >
                <label
                  htmlFor="zipInput"
                  style={{ maxWidth: "fit-content", marginTop: "5px" }}
                >
                  Upload App zip<span style={{ color: "red" }}>*</span>:
                </label>
                <input
                  id="zipInput"
                  type="file"
                  accept={appDetails.appId === "launcher" ? ".exe" : ".zip"}
                  onChange={handleFileChange}
                />
                <p style={{ color: "red", fontSize: "12px", margin: 0 }}>
                  {appDetailsErrors?.zipInput && appDetailsErrors.zipInput}
                </p>
              </div>
            )}

            <div
              className="addEditFlexNoEditContainer"
              style={
                view
                  ? {
                      borderWidth: "1px 0px",
                      borderStyle: "solid",
                      borderColor: "black",
                      marginBottom: "0px",
                      padding: "10px",
                    }
                  : null
              }
            >
              {view && (
                <div className="addEditFlexNoEditContainerInner">
                  <div className="addEditFlexNoEdit">
                    <label>Application Name: </label>
                    <span>{appDetails.appName}</span>
                  </div>
                  <div className="addEditFlexNoEditVersion">
                    <label>Version: </label>
                    <span>{appDetails.version}</span>
                  </div>
                </div>
              )}
              {editOrAdd && (
                <>
                  <label htmlFor="app-name">
                    Application Name
                    <span style={{ color: "red" }}>*</span>
                    :
                    <input
                      type="text"
                      id="app-name"
                      name="appName"
                      value={appDetails?.appName}
                      required
                      onChange={handleAppDetailsChange}
                      disabled={type === "UPGRADE" || type === "EDIT"}
                    />
                  </label>

                  <p style={{ color: "red", fontSize: "12px", margin: 0 }}>
                    {appDetailsErrors?.appName && appDetailsErrors.appName}
                  </p>
                </>
              )}

              {editOrAdd && (
                <>
                  <label htmlFor="version" style={{ marginTop: "20px" }}>
                    Version<span style={{ color: "red" }}>*</span>:
                    <input
                      type="text"
                      id="version"
                      name="version"
                      value={appDetails?.version}
                      required
                      onChange={handleAppDetailsChange}
                      disabled={type === "EDIT"}
                    />
                  </label>
                  <p style={{ color: "red", fontSize: "12px", margin: 0 }}>
                    {appDetailsErrors?.version && appDetailsErrors.version}
                  </p>
                </>
              )}
            </div>
            <div
              className="addEditFlexNoEditContainer"
              style={view ? { borderBottom: "1px solid black" } : null}
            >
              {view && (
                <div className="addEditFlexNoEditContainerInner">
                  {appDetails.whatsNew ? (
                    <div
                      className="addEditFlexNoEdit"
                      style={{
                        flexDirection: "column",
                        flex: "1 1 30%",
                        borderRight: "1px solid black",
                        padding: "10px",
                      }}
                    >
                      <label>Whats new</label>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: appDetails.whatsNew,
                        }}
                      />
                      {/* <div>{appDetails.whatsNew}</div> */}
                    </div>
                  ) : null}

                  {appDetails.description ? (
                    <div className="addEditFlexNoEditDescription">
                      <label style={{ textAlign: "center" }}>Description</label>
                      <span>{appDetails.description}</span>
                    </div>
                  ) : null}
                </div>
              )}
              {editOrAdd && (
                <>
                  <label htmlFor="whats-new" style={{ marginTop: "20px" }}>
                    What's New<span style={{ color: "red" }}>*</span>:{" "}
                  </label>
                  {/* <textarea
                      type="text"
                      id="whats-new"
                      name="whatsNew"
                      value={appDetails?.whatsNew}
                      required
                      rows="3"
                      onChange={handleAppDetailsChange}
                    ></textarea> */}
                  <ReactQuill
                    theme="snow"
                    id="whatsNew"
                    name="whatsNew"
                    value={appDetails?.whatsNew}
                    className="whatsNewBullets"
                    onChange={handleWhatsNewChange}
                  />
                  {appDetailsErrors?.whatsNew && (
                    <p style={{ color: "red", fontSize: "12px", margin: 0 }}>
                      {appDetailsErrors.whatsNew}
                    </p>
                  )}

                  <label htmlFor="description" style={{ marginTop: "20px" }}>
                    Description<span style={{ color: "red" }}>*</span>:
                  </label>
                  <textarea
                    type="text"
                    id="description"
                    name="description"
                    value={appDetails?.description}
                    className="textAreaDescription"
                    required
                    rows="3"
                    onChange={handleAppDetailsChange}
                  ></textarea>

                  <p style={{ color: "red", fontSize: "12px", margin: 0 }}>
                    {appDetailsErrors?.description &&
                      appDetailsErrors.description}
                  </p>
                </>
              )}
            </div>
            <div>
              <div className="uploadedImageContainer">
                {appDetails?.imageArray && appDetails.imageArray.length > 0 ? (
                  appDetails.imageArray.map(({ url, id }) => (
                    <Fragment key={id}>
                      <img
                        width="200"
                        src={
                          typeof url === "string"
                            ? url
                            : URL.createObjectURL(url)
                        }
                        alt=""
                      />
                      {editOrAdd && (
                        <button
                          type="button"
                          onClick={() => handleRemoveImage(id)}
                          className="addImageModalcloseBtn"
                        >
                          &#x2716;
                        </button>
                      )}
                    </Fragment>
                  ))
                ) : (
                  <p style={{ textAlign: "center", width: "100%" }}>
                    Images Not Found.
                  </p>
                )}
              </div>
              {editOrAdd && (
                <div
                  className="appImageUploadClass"
                  style={{ flexDirection: "column", alignItems: "center" }}
                >
                  <input
                    type="file"
                    id="app-image"
                    name="app-image"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleAppImageUpload}
                    onClick={onUploadClick}
                  />

                  <label htmlFor="app-image">Upload App Image</label>
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {appDetailsErrors?.imageArray &&
                      appDetailsErrors.imageArray}
                  </p>
                </div>
              )}
            </div>
          </form>
          <div
            className="appImageUploadButtonsClass"
            style={{ padding: "10px" }}
          >
            <button
              className="secondaryClass"
              onClick={handleClose}
              type="button"
              disabled={loading}
            >
              Close
            </button>
            {type === "VIEW" && (
              <button
                className="primaryClass"
                onClick={() => (window.location.href = appDetails.downloadLink)}
              >
                Download
              </button>
            )}
            {editOrAdd && (
              <>
                <button
                  className="primaryClass"
                  onClick={handleAppDetailsSubmit}
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "loading..." : "Save"}
                  {/* {type} */}
                </button>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddEditAppDetailModal;
