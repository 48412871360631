import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./style.css";
import HeaderBar from "../../../Component/HeaderBar/headerBar";
import RBAC from "../../../Component/RBAC/RBAC";
import { initList } from "../../../Component/SideNav/initList";
import _Firebase from "../../../Services/Utils/firebase";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SideNav from "../../../Component/SideNav/sideNav";

import { OrgActions } from "../../../ReduxStore/Actions";
import { useHistory } from "react-router-dom";
import CustomButton from "../../../Component/Button/button";

const DeEscalationAssessmentData = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let collapsedClass = useSelector(
    (state) => state.CollapsedSidenavReducer.collapsedClass
  );
  const [state, setState] = useState({ transcription: [] });
  const [tableData, setTableData] = useState([]);

  let { singleAssessment } = useSelector((state) => state.OrgReducer);
  let user = useSelector((state) => state.AuthReducer.user);
  useEffect(() => {
    if (props?.match.params.id) {
      dispatch(
        OrgActions.getSingleAssessmentResults_Handover({
          aid: props.match.params.id,
          callback: () => {},
        })
      );
    }
  }, []);
  console.log("singleAssessment", singleAssessment);
  // useEffect(() => {
  //   if (assessment?.guesses?.length > 0) {
  //     setShow(true);
  //   }
  // }, [assessment, assessment?.guesses]);
  useEffect(() => {
    if (singleAssessment && singleAssessment.length > 0) {
      singleAssessment.map((assessment) => {
        if (assessment && assessment.results.length > 0) {
          const dialogueIndex = assessment.results.findIndex(
            (result) => result.name === "dialogue"
          );
          const durationIndex = assessment.results.findIndex(
            (result) => result.name === "duration"
          );
          const scoreFractionIndex = assessment.results.findIndex(
            (result) => result.name === "score fraction"
          );
          const situationIndex = assessment.results.findIndex(
            (result) => result.name === "situation"
          );
          const backgroundIndex = assessment.results.findIndex(
            (result) => result.name === "background"
          );
          const reccomendationIndex = assessment.results.findIndex(
            (result) => result.name === "reccomendation"
          );
          const identityIndex = assessment.results.findIndex(
            (result) => result.name === "identity"
          );
          const assessmentIndex = assessment.results.findIndex(
            (result) => result.name === "assessment"
          );
          // assessment
          // console.log("Background", assessment);
          if (assessmentIndex >= 0) {
            setState((prev) => ({
              ...prev,
              assessment: assessment.results[assessmentIndex]["value"],
            }));
          }
          if (reccomendationIndex >= 0) {
            setState((prev) => ({
              ...prev,
              reccomendation: assessment.results[reccomendationIndex]["value"],
            }));
          }
          if (identityIndex >= 0) {
            setState((prev) => ({
              ...prev,
              identity: assessment.results[identityIndex]["value"],
            }));
          }
          if (dialogueIndex >= 0) {
            setState((prev) => ({
              ...prev,
              transcription: assessment.results[dialogueIndex]["value"],
            }));
          }

          if (durationIndex >= 0) {
            setState((prev) => ({
              ...prev,
              duration: assessment.results[durationIndex]["value"][0]["x"],
            }));
          }
          if (scoreFractionIndex >= 0) {
            setState((prev) => ({
              ...prev,
              scoreFraction:
                assessment.results[scoreFractionIndex]["value"][0]["x"],
            }));
          }
          if (situationIndex >= 0) {
            setState((prev) => ({
              ...prev,
              situation: assessment.results[situationIndex]["value"],
            }));
          }
          if (backgroundIndex >= 0) {
            setState((prev) => ({
              ...prev,
              background: assessment.results[backgroundIndex]["value"],
            }));
          }
        }
      });
    }
  }, [singleAssessment]);
  return (
    <div className="d-flex over">
      <div className={collapsedClass ? " sideNavExpand" : "sideNavContract"}>
        {user && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}{" "}
      </div>
      <div className={collapsedClass ? "screenContract bg" : "screenExpand bg"}>
        <HeaderBar
          width={collapsedClass ? "80%" : "95%"}
          title="Recent Assessments"
          searchbar="no"
          noProduct={true}
        />
        <div className="componentSec">
          <div className="d-flex justify-content-between flexsupad">
            <div className="fullDiv">
              <div className="backIcon mr-2" onClick={() => history.goBack()}>
                <ArrowBackIcon className="icon" />
              </div>
              Recent Assessments
            </div>
          </div>
          {console.log("transcription", state)}
          <div className="recentAssesmentcontainer">
            <div className="recentAssesmentRow scrollTranscript">
              <div className="transcriptBlock1">
                <h4>Transcript</h4>
                {/* {state?.transcription?.length > 0 &&
                  state?.transcription?.map((text) => <p>{text.x}</p>)} */}
                {(() => {
                  let transcriptHtml = "";
                  if (state?.transcription && state?.transcription.length > 0) {
                    for (const object of state?.transcription) {
                      for (const key in object) {
                        const value = object[key];
                        // console.log("TRANS", key + ": " + value);
                        transcriptHtml =
                          transcriptHtml +
                          `<p>
                              ${key} : ${value}
                            </p>`;
                      }
                    }
                    // console.log("TRANS", transcriptHtml);
                    // return <>{transcriptHtml}</>;
                    return (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: transcriptHtml,
                          }}
                        />{" "}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: transcriptHtml,
                          }}
                        />{" "}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: transcriptHtml,
                          }}
                        />{" "}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: transcriptHtml,
                          }}
                        />{" "}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: transcriptHtml,
                          }}
                        />{" "}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: transcriptHtml,
                          }}
                        />{" "}
                      </>
                    );
                  } else {
                    return (
                      <p className="msgP">
                        No feedback provided for this section
                      </p>
                    );
                  }
                })()}
              </div>
              <div className="transcriptBlock2 recentAssSpanDiv">
                <div className="row1 recommendation">
                  <h4>General</h4>
                  <div>
                    <p>
                      <span>Duration:</span>
                      {state?.duration} Seconds
                    </p>
                    <p>
                      <span>Score:</span>
                      {state?.scoreFraction}
                    </p>
                  </div>
                </div>
                <div className="row2 recommendation">
                  <h4>Identity</h4>
                  <div>
                    {(() => {
                      let transcriptHtml = "";
                      if (state?.identity && state?.identity.length > 0) {
                        for (const object of state?.identity) {
                          for (const key in object) {
                            const value = object[key];
                            // console.log("TRANS", key + ": " + value);
                            transcriptHtml =
                              transcriptHtml +
                              `<p>
                               ${value}
                            </p>`;
                          }
                        }
                        return (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: transcriptHtml,
                            }}
                          />
                        );
                      } else {
                        return (
                          <p className="msgP">
                            No feedback provided for this section
                          </p>
                        );
                      }
                    })()}
                  </div>
                </div>
                <div className="row3 recommendation">
                  <h4>Situation</h4>
                  <div>
                    {(() => {
                      let transcriptHtml = "";
                      if (state?.situation && state?.situation.length > 0) {
                        for (const object of state?.situation) {
                          for (const key in object) {
                            const value = object[key];
                            // console.log("TRANS", key + ": " + value);
                            transcriptHtml =
                              transcriptHtml +
                              `<p>
                               ${value}
                            </p>`;
                          }
                        }
                        return (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: transcriptHtml,
                            }}
                          />
                        );
                      } else {
                        return (
                          <p className="msgP">
                            No feedback provided for this section
                          </p>
                        );
                      }
                    })()}
                  </div>
                </div>
                <div className="row4 recommendation">
                  <h4>Background</h4>
                  {/* {state?.background?.length > 0 &&
                    state?.background?.map((text) => <p>{text.x}</p>)} */}
                  <div>
                    {(() => {
                      let transcriptHtml = "";
                      if (state?.background && state?.background.length > 0) {
                        for (const object of state?.background) {
                          for (const key in object) {
                            const value = object[key];
                            // console.log("TRANS", key + ": " + value);
                            transcriptHtml =
                              transcriptHtml +
                              `<p>
                               ${value}
                            </p>`;
                          }
                        }
                        return (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: transcriptHtml,
                            }}
                          />
                        );
                      } else {
                        return (
                          <p className="msgP">
                            No feedback provided for this section
                          </p>
                        );
                      }
                    })()}
                  </div>
                </div>
                <div className="row5 recommendation">
                  <h4>Assessment</h4>
                  <div>
                    {(() => {
                      let transcriptHtml = "";
                      if (state?.assessment && state?.assessment.length > 0) {
                        for (const object of state?.assessment) {
                          for (const key in object) {
                            const value = object[key];
                            // console.log("TRANS", key + ": " + value);
                            transcriptHtml =
                              transcriptHtml +
                              `<p>
                               ${value}
                            </p>`;
                          }
                        }
                        return (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: transcriptHtml,
                            }}
                          />
                        );
                      } else {
                        return (
                          <p className="msgP">
                            No feedback provided for this section
                          </p>
                        );
                      }
                    })()}
                  </div>
                </div>
                <div className="row6 recommendation">
                  <h4>Recommendation</h4>
                  <div>
                    {(() => {
                      let transcriptHtml = "";
                      if (
                        state?.reccomendation &&
                        state?.reccomendation.length > 0
                      ) {
                        for (const object of state?.reccomendation) {
                          for (const key in object) {
                            const value = object[key];
                            // console.log("TRANS", key + ": " + value);
                            transcriptHtml =
                              transcriptHtml +
                              `<p>
                               ${value}
                            </p>`;
                          }
                        }
                        return (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: transcriptHtml,
                            }}
                          />
                        );
                      } else {
                        return (
                          <p className="msgP">
                            No feedback provided for this section
                          </p>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="recentAssesmentButton">
                      <button>Finish</button>
          </div> */}
          </div>

          {/* <div className="mt-4">
            {show && (
              <Assessment singleDeEscalationResult={singleDeEscalationResult} />
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default DeEscalationAssessmentData;
