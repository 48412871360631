import "./groupManagement.css";
import React, { useEffect, useState } from "react";
import "../ProductAnalyticsAdmin/productAnalyticsAdmin.css";
import Graph from "../../Component/Graph/graph";
import { useSelector, useDispatch } from "react-redux";
import HeaderBar from "../../Component/HeaderBar/headerBar";
import SideNav from "../../Component/SideNav/sideNav";
import CustomCard from "../../Component/Card/customCard";
import SideSearch from "../../Component/SideSearch/sideSearch";
import OrganizationMetaData from "../../Component/OrganizationMetaData/organizationMetaData";
import ScatterChart from "../../Component/Graph/scatter";
import { OrgActions, SuperAdminActions } from "../../ReduxStore/Actions";
import LineGraph from "../../Component/Graph/LineGraph";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExitToAppSharpIcon from "@material-ui/icons/ExitToAppSharp";
import Button from "../../Component/Button/button";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import RBAC from "../../Component/RBAC/RBAC";
import { initList } from "../../Component/SideNav/initList";
import { TableCell, Typography } from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import Tabs from "../../Component/Tabs/Tabs";

import AddUserDialog from "../../Component/Modal/addUserModal";
import DetailsModel from "../../Component/Modal/inviteModal";
import { useHistory } from "react-router-dom";
import { PRODUCTS, ROUTES } from "../../Services/Constants";
import AlertDialog from "../../Component/AlertDialog/alertDialog";
import DetailsPop from "../../Component/Modal/modal";
import { ACTION_TYPE } from "../../Services/Constants";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import CancelIcon from "@material-ui/icons/Cancel";
import AppTour from "../../Component/AppTour/tour";
import BusinessIcon from "@material-ui/icons/Business";
import AccountDropdown from "../../Component/AccountDropdown/AccountDropdown";
import VrPasscode from "../../Component/VrPasscodeModel";

const GroupManagement = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeOrg, setActiveOrg] = useState();
  const [orgUsersState, setOrgUsersState] = useState();
  const [allOrgsState, setAllOrgsState] = useState();
  const [resultState, setResultState] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [addUser, setAddUser] = useState(false);
  const [openn, setOpenn] = useState(false);
  const [detailsPopUP, setDetailsPopUp] = useState(false);

  const [otherUsers, setOtherUsers] = useState();
  const [loading, setLoading] = useState(false);
  const [activeClass, setActiveClass] = useState();
  const [activeGroupp, setActiveGroup] = useState();
  const [open, setOpen] = React.useState(false);
  const [orgToDelete, setOrgToDelete] = useState();
  const [tabValue, setTabValue] = useState(0);
  const query = new URLSearchParams(props.location.search);
  const queryTabValue = query.get("tabValue");
  const storedActiveGroup = useSelector(
    (state) => state.CommonReducer.activeGroup
  );
  console.log("storedActiveGroup", storedActiveGroup);

  useEffect(() => {
    if (storedActiveGroup) {
      setActiveGroup(storedActiveGroup);
    }
  }, []);

  useEffect(() => {
    if (queryTabValue) {
      setTabValue(queryTabValue);
    }
  }, [queryTabValue]);
  const columns = [
    { field: "id", headerName: "ID" },

    { field: "email", headerName: "Email" },
  ];
  let collapsedClass = useSelector(
    (state) => state.CollapsedSidenavReducer.collapsedClass
  );
  const [detailsModel, setDetailsModel] = useState(false);
  const [groupName, setGroupName] = useState();
  const [modalState, setModal] = useState(
    localStorage.getItem("vrCodeState") === "false" ? false : true
  );
  useEffect(() => {
    setTimeout(() => {
      localStorage.setItem("vrCodeState", false);
    }, 4000);
  }, []);
  // const [runGroupManagementTour, setRunGroupManagementTour] = useState(false);
  // const [runCreateGroupTour,setRunCreateGroupTour] = useState(false);

  // const groupManagementTourSteps = [
  //   {
  //     target: ".groupManagementTourStep1",
  //     content: "Click This button to create a new group. Click the button to continue tour.",
  //     disableBeacon: true,
  //   }
  // ];

  // const createGroupTourSteps = [
  //   {
  //     target: ".createGroupTourStep1",
  //     content: "Click This button to create a new group. Click the button to continue tour.",
  //     disableBeacon: true,
  //   }
  // ];
  let { user: selectedUser } = useSelector((state) => state.OrgReducer.user);

  let results = useSelector((state) => state.OrgReducer.orgResults);
  let allOrgs = useSelector((state) => state.OrgReducer.allGroups);
  let user = useSelector((state) => state.AuthReducer.user);
  let userDetails = useSelector((state) => state.AuthReducer.userDetails);

  let currentOrg = useSelector((state) => state.OrgReducer.currentOrg);
  const { product } = useSelector((state) => state.ProductReducer);

  const { users: orgUsers } = useSelector(
    (state) => state.SuperAdminReducer.currentOrg
  );
  console.log("user", user);
  // function to delete a group from org
  const handleDelete = async (groupId) => {
    dispatch(
      OrgActions.deleteGroup({
        groupId: groupId,
        orgId: currentOrg[0].orgId,
        uuid: user.uuid,
        callback: (response) => {
          setOpenn(false);
          dispatch(
            OrgActions.getAllGroupsInOrg({
              orgId: currentOrg[0]?.orgId,
              callback: (response) => {},
            })
          );

          dispatch(
            OrgActions.getOrgResults(currentOrg[0]?.orgId, product, {
              callback: (response) => {},
            })
          );
        },
      })
    );
  };
  const getTableData = (result) => {
    let finalData = result?.map((data) => {
      return [
        data["email"],
        data["orgName"] ? "Pending" : "Accepted",
        data["roles"].toString(),
        data["uuid"],
      ];
    });
    setTableData(finalData);
  };

  useEffect(() => {
    if (user?.uuid) {
      dispatch(
        OrgActions.getAllGroupsInOrg({
          orgId: currentOrg[0]?.orgId,
          callback: (response) => {},
        })
      );
      dispatch(
        OrgActions.getOrgResults(currentOrg[0]?.orgId, product, {
          callback: (response) => {},
        })
      );
    }
  }, [user, currentOrg]);

  useEffect(() => {
    setOrgUsersState(orgUsers);
  }, [orgUsers]);

  useEffect(() => {
    if (allOrgs.length > 0) {
      if (storedActiveGroup && Object.keys(storedActiveGroup).length == 0) {
        allOrgs[0].activeClass = `active0`;
        handleOrgSelect(allOrgs[0]);
        setActiveClass(`active0`);
      } else {
        let res = allOrgs.find((val) => val._id == storedActiveGroup._id);
        if (allOrgs && allOrgs.length > 0 && !res) {
          console.log("testing 4");
          res = allOrgs[0];
          res.activeClass = `active0`;
          console.log("testing 5", res);
          dispatch({ type: "SET_ACTIVE_GROUP", payload: res });
          handleOrgSelect(res);
          setActiveClass(res.activeClass);
          return;
        }

        console.log("testing0", res);
        if (res) {
          console.log("testing1");
          res.activeClass = storedActiveGroup.activeClass;
          dispatch({ type: "SET_ACTIVE_GROUP", payload: res });
          handleOrgSelect(res);
          setActiveClass(res.activeClass);
        }
      }

      let orgState = allOrgs.filter((org) => org["orgId"]);
      setAllOrgsState(orgState);
    } else if (allOrgs.length == 0) {
      setAllOrgsState();
    }
  }, [allOrgs, results]);

  // useEffect(() => {
  //   setRunGroupManagementTour(true);
  // }, []);
  const handleOrgSelect = (activeGroup) => {
    setLoading(true);

    setActiveGroup(activeGroup);
    dispatch({ type: "SET_ACTIVE_GROUP", payload: activeGroup });
    const groupIds = activeGroup?.users?.map((usr) => usr.uuid);
    const res = results?.filter((res) => groupIds.includes(res.uuid) && res);
    const grpUser = currentOrg[0].users.filter(
      (res) => groupIds?.includes(res.uuid) && res
    );
    getTableData(grpUser);

    let notInGroup = currentOrg[0].users.filter(
      (res) => !groupIds.includes(res.uuid) && res
    );
    let rows = notInGroup.map((res) => ({
      id: res["uuid"],
      email: res["email"],
    }));

    setOtherUsers(rows);
    setResultState(res);

    setActiveOrg(activeGroup);
    setLoading(false);
  };
  const handleNameChange = (e) => {
    setGroupName(e.target.value);
  };

  // to add user to group
  const handleAddUser = (selectionModel) => {
    if (selectionModel.length > 0) {
      dispatch(
        OrgActions.addUserToGroup({
          users: selectionModel,
          orgId: currentOrg[0]?.orgId,
          groupId: activeOrg._id,
          callback: (response) => {
            dispatch(
              OrgActions.getAllGroupsInOrg({
                orgId: currentOrg[0]?.orgId,
                callback: (response) => {},
              })
            );
            dispatch(
              OrgActions.getOrgResults(currentOrg[0]?.orgId, product, {
                callback: (handleAddUserresponse) => {},
              })
            );
          },
        })
      );
    }
    setAddUser(false);
  };
  // function to create a  new group
  const handleCreate = async (e) => {
    await dispatch(
      OrgActions.createGroup({
        name: groupName,
        ownerUid: user.uuid,
        orgId: currentOrg[0].orgId,
        callback: (response) => {},
      })
    );

    await dispatch(
      OrgActions.getAllGroupsInOrg({
        orgId: currentOrg[0]?.orgId,
        callback: (response) => {},
      })
    );
    setDetailsModel(false);
  };
  const handleCLickUser = (tableRow) => {
    console.log("tableRow", tableRow);
    if (product === "als")
      props.history.push(`/recent-assessment/${tableRow[3]}`);
    else if (product === "codeBlack")
      props.history.push(`/situation-assessment/${tableRow[3]}`);
    else if (product === PRODUCTS.DESCALATION)
      props.history.push(`/deescalation-assessment/${tableRow[3]}`);
    else if (product === PRODUCTS.DP)
      props.history.push(`/dp-assessment/${tableRow[3]}`);
    else if (product === PRODUCTS.HANDOVER)
      props.history.push(`/handover-assessment/${tableRow[3]}`);

    // history.push(`/user-data/${tableRow[3]}`);
  };
  const handleDeletePromt = (data) => {
    setOpen(true);
    setOrgToDelete(data);
  };

  //to delete user from group
  //orgToDelete:[email,orgName.roles,uuid]

  const handleDeleteUser = async () => {
    dispatch(
      OrgActions.removeUserFromGroup({
        uuid: orgToDelete[3],
        groupId: activeGroupp?._id,
        callback: (response) => {
          dispatch(
            OrgActions.getAllGroupsInOrg({
              orgId: currentOrg[0]?.orgId,
              callback: (response) => {},
            })
          );
          dispatch(
            OrgActions.getOrgResults(currentOrg[0]?.orgId, product, {
              callback: (response) => {},
            })
          );
          setOpen(false);
        },
      })
    );
  };
  const onChangeVrPassCodeModal = () => {
    localStorage.setItem("vrCodeState", false);
    setModal(false);
  };
  const handleEmailClick = (tableRow) => {
    const email = tableRow[0];
    setDetailsPopUp(true);
    dispatch(
      OrgActions.fetchUserDetails({
        email: email,
        callback: (response) => {},
      })
    );
  };
  console.log("allorg", activeOrg);
  return (
    <div className="d-flex over group">
      {/* <AppTour
        run={runGroupManagementTour}
        steps={groupManagementTourSteps}
        handleClose={() => setRunGroupManagementTour(false)}
      /> */}

      <div className={collapsedClass ? " sideNavExpand" : "sideNavContract "}>
        {user && (
          <RBAC
            userRole={user?.role}
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav handleTabChange={() => setTabValue(0)} />
          </RBAC>
        )}
      </div>
      <AddUserDialog
        open={addUser}
        handleClose={() => setAddUser(false)}
        rows={otherUsers}
        columns={columns}
        groupId={activeOrg?._id}
        handleAddUser={handleAddUser}
      ></AddUserDialog>

      <div
        className={collapsedClass ? "screenContract bg" : "screenExpand bg "}
      >
        <HeaderBar
          width={collapsedClass ? "80%" : "95%"}
          title={` ${currentOrg[0]?.orgName} - Dashboard`}
          searchbar="no"
        />
        <div className="lifeSupport ">
          <DetailsModel
            open={detailsModel}
            handleClose={() => setDetailsModel(false)}
            title="Create Group"
            // runTour={runCreateGroupTour}
            // steps={createGroupTourSteps}
            // closeTour={() => setRunCreateGroupTour(false)}
            // startTour={() => setRunCreateGroupTour(true)}
            input={
              <input
                className="createGroupTourStep1"
                placeholder="Enter Name"
                style={{
                  maxWidth: "659px",
                  width: "100%",
                  padding: "4px 15px",
                  height: "47px",
                  marginBottom: "19px",
                  background: "#FFFFFF",
                  boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.25)",
                  borderRadius: "3px",
                  border: "none",
                }}
                onChange={handleNameChange}
              />
            }
            button={
              <Button
                background="secondary"
                color="primary"
                name="Create Group"
                handleClick={handleCreate}
              />
            }
          />
          <div className="d-flex justify-content-between combineFlexColmn">
            <SideSearch
              newButton="true"
              allOrgs={allOrgsState}
              handleSelect={handleOrgSelect}
              type="group"
              handleClickButton={() => setDetailsModel(true)}
              activeClass={activeClass}
              setActiveClass={(cls) => setActiveClass(cls)}
            />
            {allOrgsState?.length === 0 ? (
              <div className="spinnerDiv">
                <Typography variant="h2" component="h2">
                  No Groups
                </Typography>
              </div>
            ) : loading ? (
              <div className="spinnerDiv">
                {" "}
                <CircularProgress color="inherit"></CircularProgress>
              </div>
            ) : activeOrg && allOrgsState?.length > 0 ? (
              <div className="lifeSupportContent">
                <div className="grpCardDiv">
                  {console.log("allOrgsState out u", allOrgsState, activeOrg)}
                  <div className="leftGrp">
                    <div>
                      {activeOrg?.name == "Default Group" ? (
                        <BusinessIcon />
                      ) : (
                        <GroupIcon />
                      )}
                    </div>
                    <div>
                      <h2>
                        {activeOrg?.name == "Default Group"
                          ? currentOrg[0]?.orgName
                          : activeOrg?.name}
                      </h2>
                      <div
                        className="btnDiff"
                        style={{
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <Button
                          background={tabValue == 0 ? "primary" : "secondary"}
                          color={tabValue == 0 ? "secondary" : "primary"}
                          name="Overview"
                          className="personAddBtn overViewBtn"
                          handleClick={() => setTabValue(0)}
                        />
                        <Button
                          background={tabValue == 1 ? "primary" : "secondary"}
                          color={tabValue == 1 ? "secondary" : "primary"}
                          name="Users"
                          className="personAddBtn"
                          handleClick={() => setTabValue(1)}
                        />
                      </div>
                    </div>
                  </div>
                  {activeGroupp.name != "Default Group" && (
                    <div className="rightGrp delIcon">
                      {/* <Button
                      background="primary"
                      color="secondary"
                      name="Add User"
                      className="personAddBtn"
                      handleClick={() => setAddUser(true)}
                      icon={<PersonAddOutlinedIcon />}
                    /> */}
                      {activeOrg?.ownerUid === user.uuid ? (
                        <DeleteOutlineOutlinedIcon
                          onClick={() => {
                            setOpenn(true);
                          }}
                          className="dltBtn"
                        />
                      ) : null}
                      <AlertDialog
                        open={openn}
                        handleClose={() => setOpenn(false)}
                        message="Are you sure you want to delete the group?"
                        button1="Yes"
                        button2="No"
                        handleDelete={() => handleDelete(activeOrg?._id)}
                      />
                    </div>
                  )}
                </div>

                <div className="fixPadding grp-chart-parent">
                  <Tabs
                    allTeamsAssessmentResults={resultState}
                    tableData={tableData}
                    handleEmailClick={handleEmailClick}
                    handleCLickUser={handleCLickUser}
                    handleDeleteUser={handleDeletePromt}
                    product={product}
                    tabValue={tabValue}
                    setTabValue={setTabValue}
                    setAddUser={setAddUser}
                    isDefaultGroupActive={activeGroupp.name === "Default Group"}
                  />
                </div>
              </div>
            ) : (
              <div className="spinnerDiv">
                <Typography variant="h2" component="h2">
                  No Groups
                </Typography>
              </div>
            )}
          </div>
        </div>
        <AlertDialog
          open={open}
          handleClose={() => setOpen(false)}
          message="Are you sure you want to remove the user from the group?"
          button1="Yes"
          button2="No"
          handleDelete={handleDeleteUser}
        />
        <DetailsPop
          open={detailsPopUP}
          handleClose={() => {
            dispatch({
              type: ACTION_TYPE.CLEAR_SELECTED_USER,
            });
            setDetailsModel(false);
          }}
          user={selectedUser}
          mainIcon={
            <AccountCircleOutlinedIcon
              color="primary"
              className="ModelIcon text-center"
            />
          }
          secondIcon={
            <CancelIcon
              onClick={() => {
                dispatch({
                  type: ACTION_TYPE.CLEAR_SELECTED_USER,
                });
                setDetailsModel(false);
              }}
              color="primary"
              className="modelIcon2"
            />
          }
          width="868px"
          height="400px"
          list1={[
            "Username",
            "Gender",
            "Critical Speciality",
            "Years of ALS Experience",
            "Do you wear glasses?",
          ]}
          list2={[
            "Email",
            "Occupation",
            "Position or Grade",
            "Experience with virtual reality",
            "Completed ALS Program before",
          ]}
          list3={[
            "Year of Birth",
            "Gaming Experience",
            "Years of Post Grad experience",
            "Is English your second language?",
          ]}
        />
      </div>

      {userDetails && userDetails.vrCode && modalState && (
        <VrPasscode
          setModal={onChangeVrPassCodeModal}
          modalState={modalState}
          vrCode={userDetails.vrCode}
        />
      )}
    </div>
  );
};
export default GroupManagement;
