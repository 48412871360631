import React from "react";
import { Line } from "react-chartjs-2";

import moment from "moment";

const LineGraph = ({ allTeamsAssessmentResults, label = "Score" }) => {
  const renderLabels = () => {
    let _labels = [];
    allTeamsAssessmentResults &&
      allTeamsAssessmentResults.length > 0 &&
      allTeamsAssessmentResults
        .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
        .map(({ createdAt }) => {
          return _labels.push(moment(createdAt).format("DD/MM/YY"));
        });
    return _labels;
  };

  const renderData = () => {
    let _data = [];

    allTeamsAssessmentResults &&
      allTeamsAssessmentResults
        .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
        .map(({ overallAccuracy }) => _data.push(overallAccuracy));
    return _data;
  };

  // Generate random color
  const generateColor = () => Math.floor(Math.random() * 255);

  const datasets = [
    {
      // label,
      fill: false,
      lineTension: 0.1,
      backgroundColor: "rgba(75,192,192,0.4)",
      fontSize: 8,
      // borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      // pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: `rgba(${generateColor()},${generateColor()},${generateColor()}, 1)`,
      pointBorderWidth: 1,
      pointHoverRadius: 4,
      pointHoverBackgroundColor: "rgba(75,192,192,1)",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 2,
      pointHitRadius: 5,
      data: renderData(),
    },
  ];

  return (
    <div
      id="line-graph-containe"
      style={{
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      <p style={{ fontWeight: "600", paddingTop: "10px", textAlign: "center" }}>
        Scores ordered by date
      </p>

      {allTeamsAssessmentResults && (
        <Line
          data={{ datasets, labels: renderLabels() }}
          options={{
            legend: { labels: false },
            scales: {
              xAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: "Date",
                    fontSize: 14,
                    fontStyle: "bold",
                  },
                  ticks: { fontSize: 10 },
                },
              ],
              yAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: "Score",
                    fontSize: 14,
                    fontStyle: "bold",
                  },
                },
              ],
            },
          }}
        />
      )}
    </div>
  );
};
export default LineGraph;
