import React from "react";
import { Scatter } from "react-chartjs-2";
import moment from "moment";

const rand = () => Math.round(Math.random() * 20 - 10);

const options = {
  legend: {
    display: true,
    labels: { fontColor: "black", boxWidth: 20, fontSize: 8 },
    align: "start",
    // position: 'right'
  },
  scales: {
    y: {
      beginAtZero: true,
    },
    // y: [
    //   {
    //     ticks: {
    //       beginAtZero: true,
    //       min: 40,
    //     },
    //     scaleLabel: {
    //       display: true,
    //       labelString: "Score",
    //       fontSize: 14,
    //       fontStyle: "bold",
    //     },
    //   },
    // ],
    // x: [
    //   {
    //     ticks: {
    //       beginAtZero: true,
    //       min: 0,
    //       stepSize: 1,
    //       // fontSize: 8
    //     },
    //     scaleLabel: {
    //       display: true,
    //       labelString: "Assessment",
    //       fontSize: 14,
    //       fontStyle: "bold",
    //     },
    //   },
    // ],
  },
};

const ScatterChart = ({ assessmentData }) => {
  // generate random color
  const genColor = () => Math.floor(Math.random() * 255);

  // group users assessments according to the uuid
  const groupUserAssessments = () => {
    const _assessmentDataResults = [...assessmentData];
    // console.log(
    //   "scatter cb _assessmentDataResults :>> ",
    //   _assessmentDataResults
    // );
    return _assessmentDataResults.reduce((r, a) => {
      r[a.uuid] = [...(r[a.uuid] || []), a];
      return r;
    }, {});
  };

  const filterGraphDataByOrg = () => {
    let _final = [];
    const groupedAssessments = { ...groupUserAssessments() };
    console.log("groupedAssessments :>> ", groupedAssessments);
    for (const key in groupedAssessments) {
      // console.log(" groupedAssessments[key] :>> ", groupedAssessments[key]);
      let count = {
        confused: 0,
        irratable: 0,
        boisterous: 0,
        pyshicalThreats: 0,
        verbalThreats: 0,
        attacking: 0,
      };

      // console.log(" guesses key :>> ", key);
      const backgroundColor = `rgba(${genColor()},${genColor()},${genColor()}, 1)`;
      _final.push({
        ...options,
        backgroundColor,
        pointBackgroundColor: backgroundColor,
        uuid: key,
        // label: '',
        label:
          groupedAssessments[key][0] &&
          groupedAssessments[key][0]["user"] &&
          groupedAssessments[key][0]["user"][0] &&
          groupedAssessments[key][0]["user"][0]["userName"]
            ? groupedAssessments[key][0]["user"][0]["userName"]
            : key.slice(0, 5).toUpperCase(), // this displays the ids on top of the chart
        data: groupedAssessments[key]
          .sort((a, b) => moment(a.dateCreated) - moment(b.dateCreated))
          .map(({ dateCreated, guesses, answers }, index) => {
            let score = 0;
            let percent;
            let answerLength =
              guesses.length < answers.length ? guesses.length : answers.length;
            let total = answerLength * Object.keys(count).length;

            for (let i = 0; i < answerLength; i++) {
              for (let key of Object.keys(count)) {
                if (guesses[i][key] === answers[i][key]) score++;
              }
            }
            // console.log("scoreeeee1111", score, answerLength);
            percent = Math.round((score / total) * 100);

            return {
              x: index + 1,
              y: score,
              dateCreated,
            };
          }),
      });
    }

    return _final;
  };

  const data = {
    datasets: filterGraphDataByOrg(),
  };

  // console.log("data", data);
  return (
    <div>
      <p style={{ fontWeight: "600", textAlign: "center" }}>
        Scores Ordered by Assessment 
      </p>
      <Scatter data={data} options={options} />
    </div>
  );
};

export default ScatterChart;
