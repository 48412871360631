import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import HeaderBar from "../../../Component/HeaderBar/headerBar";
import RBAC from "../../../Component/RBAC/RBAC";
import { initList } from "../../../Component/SideNav/initList";
import _Firebase from "../../../Services/Utils/firebase";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SideNav from "../../../Component/SideNav/sideNav";
import Assessment from "./Assessement";
import { OrgActions } from "../../../ReduxStore/Actions";
import { useHistory } from "react-router-dom";

const SituationAssessmentData = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let collapsedClass = useSelector(
    (state) => state.CollapsedSidenavReducer.collapsedClass
  );
  const [show, setShow] = useState(false);

  let assessment = useSelector(
    (state) => state.OrgReducer.singleSituationResult
  );
  let user = useSelector((state) => state.AuthReducer.user);
  useEffect(() => {
    if (props?.match.params.id) {
      dispatch(
        OrgActions.getSingleSituationResults({
          aid: props.match.params.id,
          callback: () => {},
        })
      );
    }
  }, []);
  useEffect(() => {
    if (assessment?.guesses?.length > 0) {
      setShow(true);
    }
  }, [assessment, assessment?.guesses]);
  return (
    <div className="d-flex over">
      <div className={collapsedClass ? " sideNavExpand" : "sideNavContract"}>
        {user && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}{" "}
      </div>
      <div className={collapsedClass ? "screenContract bg" : "screenExpand bg"}>
        <HeaderBar
          width={collapsedClass ? "80%" : "95%"}
          title="Recent Assessments"
          searchbar="no"
          noProduct={true}
        />
        <div className="componentSec">
          <div className="d-flex justify-content-between flexsupad ">
            <div className="fullDiv">
              <div className="backIcon mr-2" onClick={() => history.goBack()}>
                <ArrowBackIcon className="icon" />
              </div>
              Recent Assessments
            </div>
          </div>
          <div className="mt-4">
            {show && (
              <Assessment
                guess={assessment.guesses}
                answer={assessment.answers}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SituationAssessmentData;
