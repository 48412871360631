import { ACTION_TYPE } from "../../Services/Constants/index";
import _Firebase from "../../Services/Utils/firebase";
import Axios from "../../Services/Utils/axios";
// import axios from "axios";

export const getUserDetails =
  ({ uuid, email, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post(`user/id/${uuid}`, { email });
      console.log("user details", response.data);
      dispatch({
        type: ACTION_TYPE.SET_USER_DETAILS,
        payload: response.data.user,
      });
      // dispatch({
      //   type: ACTION_TYPE.SET_ORGS_AUTH,
      //   payload: response.data.user.filteredInvites,
      // });

      // const orgToken = jwt.sign(
      //   {
      //     orgId: response.data.user.filteredInvites[0]?.orgId,
      //     userId: response.data.user.uuid,
      //   },
      //   process.env.REACT_APP_PRIVATE_KEY
      // );

      // dispatch({
      //   type: ACTION_TYPE.SET_ACTIVE_ORG,
      //   payload: {
      //     activeOrg: response.data.user.filteredInvites[0],
      //     token: orgToken,
      //   },
      // });

      callback({ success: true, result: response });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

export const confirmEmail =
  ({ uuid, email, orgId, from, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post("/org/accept/org", {
        uuid,
        email,
        orgId,
        from,
      });
      callback(response);
    } catch (error) {
      callback(error);
    }
  };
//Action will called when user first signs up and accepts invitation of list of orgs
export const confirmOrgIdsEmails =
  ({ uuid, email, Orgs, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post("/org/accept/orgs", {
        uuid,
        email,
        Orgs,
      });
      callback(response);
    } catch (error) {
      callback(error);
    }
  };

export const getInviteInfoFromToken =
  ({ token, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post("/auth/decodeToken", {
        token,
      });
      callback(response);
    } catch (error) {
      callback(error);
    }
  };

export const createUser =
  ({ registerBody, callback }) =>
  async (dispatch) => {
    dispatch({
      type: ACTION_TYPE.RESET_TOKEN,
    });
    try {
      const { email, password } = registerBody;
      const response = await _Firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);
      if (response.user)
        registerBody = { ...registerBody, uuid: response.user.uid };
      return dispatch(
        signUp({
          registerBody,
          callback,
        })
      );
    } catch (error) {
      callback(error);
    }
  };

export const signUp =
  ({ uuid, registerBody, callback }) =>
  async (dispatch) => {
    dispatch({
      type: ACTION_TYPE.RESET_TOKEN,
    });
    try {
      const response = await Axios.post("/auth/signUp", {
        uuid,
        registerBody,
      });
      callback(response);
    } catch (error) {
      callback(error);
    }
  };

export const signIn =
  ({ email, password, loginAs, callback }) =>
  async (dispatch) => {
    dispatch({
      type: ACTION_TYPE.RESET_TOKEN,
    });
    try {
      const response = await _Firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      console.log("response>Login", response);
      if (response.user)
        response.user.getIdToken().then((token) => {
          // Token retrieved successfully
          console.log("Firebase token:", token);
          return dispatch(
            connect({
              uuid: response.user.uid,
              loginAs,
              callback,
              firebaseToken: token,
            })
          );
        });

      // callback(response);
    } catch (error) {
      callback(error);
    }
  };

export const connect =
  ({ uuid, loginAs, callback, firebaseToken }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post("/auth/connect", {
        uuid,
        loginAs,
        firebaseToken,
      });

      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("vrCodeState", true);
        // localStorage.setItem("loginAs", JSON.stringify(loginAs));

        dispatch({
          type: ACTION_TYPE.SET_TOKEN,
          payload: response.data.token,
        });

        dispatch({
          type: ACTION_TYPE.SET_USER,
          payload: {
            user: response.data.user,
            token: response.data.token,
          },
        });

        dispatch({
          type: ACTION_TYPE.SET_LOGIN_AS,
          payload: loginAs,
        });
      }

      callback(response.data);
    } catch (error) {
      callback(error);
    }
  };

// signout the user ===========================================================
export const signOut =
  ({ callback }) =>
  async (dispatch) => {
    try {
      _Firebase
        .auth()
        .signOut()
        .then(() => {
          dispatch({
            type: ACTION_TYPE.LOGOUT,
          });
          dispatch({
            type: ACTION_TYPE.CLEAR_CURRENT_ORG,
          });

          dispatch({
            type: ACTION_TYPE.CLEAR_SELECTED_USER,
          });
          dispatch({
            type: ACTION_TYPE.CLEAR_USER_DETAILS,
          });

          callback();
        });
    } catch (error) {
      callback(error);
    }
  };

// Password update ============================================================
export const updatePassword =
  ({ newPassword, currentPassword, user, callback }) =>
  async (dispatch) => {
    try {
      await _Firebase
        .auth()
        .signInWithEmailAndPassword(user["email"], currentPassword);

      const response = await _Firebase
        .auth()
        .currentUser.updatePassword(newPassword);

      callback(response);
    } catch (error) {
      callback(error);
    }
  };

export const matchToken =
  ({ callback }) =>
  async (dispatch) => {
    try {
      // const token = localStorage.getItem("token");

      const response = await Axios.get("/auth/matchToken");
      console.log("respose", response.data);
      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));

        dispatch({
          type: ACTION_TYPE.SET_TOKEN,
          payload: response.data.token,
        });

        dispatch({
          type: ACTION_TYPE.SET_USER,
          payload: {
            user: response.data.user,
            token: response.data.token,
          },
        });
      }
      callback(response.data);
    } catch (error) {
      console.log("error", error);
      callback(error);
    }
  };

export const confirmEmailUser =
  ({
    token,

    callback,
  }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post("/org/accept/user", {
        token,
      });
      callback(response);
    } catch (error) {
      callback(error);
    }
  };

export const updateUser =
  ({ updateBody, uuid, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.patch(`/user/update/${uuid}`, {
        updateBody,
      });

      callback({ success: true, result: response });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };
