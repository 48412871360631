import BubbleGraph from "./bubble";
import React from "react";
import CustomCard from "../Card/customCard";
import "./style.css";
import { useSelector } from "react-redux";
import { getAvgTotalTime, getNumOfPeople } from "../../Services/Utils/utils";
import { APPLICATION_CONSTANTS } from "../../Services/Constants";
import {
  getAETrueCount,
  getAvgArrayDesc,
  getAvgDesc,
  getAvgScoreALS,
  getHandoverAvgScoreDesc,
  getHandoverMostExpCase,
  getHandoverTotalDesc,
  getTotalDesc,
  getTotalSeconds,
} from "../../Helper";
import { withRouter } from "react-router";
import { CircularProgress } from "@material-ui/core";
import AssessmentIcon from "@material-ui/icons/Assessment";
import Legend from "../Legend";

const Handover = React.memo((props) => {
  const { assessmentData, peopleCount } = props;
  console.log("assessmentData", assessmentData);
  const { loading } = useSelector((state) => state.CommonReducer);
  let currentOrg = useSelector((state) => state.OrgReducer.currentOrg);

  let user = useSelector((state) => state.AuthReducer.user);

  if (loading)
    return (
      <div className="spinnerDiv" style={{ width: "100%" }}>
        {" "}
        <CircularProgress color="inherit"></CircularProgress>
      </div>
    );
  else
    return (
      <>
        {assessmentData && assessmentData?.length > 0 && (
          <div className="d-flex  flexsupad">
            {currentOrg.length === 0 && user?.role != "superAdmin" ? (
              <CustomCard
                handleClick={() =>
                  props.history.push(`/handover-assessment/${user.uuid}`)
                }
                // fontSize="64px"
                title="View Assessments"
              />
            ) : (
              <CustomCard
                no={peopleCount}
                // fontSize="64px"
                title="Number of People"
              ></CustomCard>
            )}

            <CustomCard
              no={getHandoverAvgScoreDesc(assessmentData, "score percent")}
              fontSize="64px"
              title="Average Overall Score"
            ></CustomCard>

            <CustomCard
              no={getHandoverAvgScoreDesc(assessmentData, "duration")}
              fontSize="64px"
              title="Average Scenario Duration"
            ></CustomCard>

            <CustomCard
              no={assessmentData.length}
              fontSize="64px"
              title="Number of Assessments"
            ></CustomCard>
            <CustomCard
              no={getHandoverTotalDesc(assessmentData, "duration")}
              fontSize="64px"
              title="Time in app"
            ></CustomCard>
            <CustomCard
              no={getHandoverMostExpCase(assessmentData, "caseName")}
              fontSize="64px"
              title="Most Experienced Case"
            ></CustomCard>
          </div>
        )}
        {assessmentData && assessmentData?.length > 0 && (
          <div className="flexGraph als">
            <div className="graphbgMainL">
              <div className="GraphBG">
                <BubbleGraph
                  assessmentData={assessmentData}
                  // orgUsers={orgUsers}
                ></BubbleGraph>
              </div>
            </div>
          </div>
        )}
      </>
    );
});
export default withRouter(Handover);
