import { ACTION_TYPE } from "../../Services/Constants/index";
export const initialState = {
  collapsedClass: true,
  token: "",
  user: {},
  isAuthenticated: false,
  userDetails: {},
  orgs: [],
  loginAs: "",
};

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.RESET_TOKEN:
      localStorage.removeItem("token");
      return { ...state, token: "" };

    case ACTION_TYPE.SET_USER:
      const { token, user } = action.payload;
      localStorage.setItem("token", token);
      return { ...state, user, isAuthenticated: true, token };

    case ACTION_TYPE.LOGOUT:
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("orgToken");
      localStorage.removeItem("loginAs");
      localStorage.removeItem("loginAs");
      localStorage.removeItem("vrCodeState");
      return {
        collapsedClass: true,
        token: "",
        user: {},
        userDetails: {},
        isAuthenticated: false,
        loginAs: "",
      };

    case ACTION_TYPE.SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };

    case ACTION_TYPE.SET_ORGS_AUTH:
      return {
        ...state,
        orgs: action.payload,
      };

    case ACTION_TYPE.SET_LOGIN_AS:
      return {
        ...state,
        loginAs: action.payload,
      };

    default:
      return state;
  }
};
