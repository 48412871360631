import React from "react";
import ChartCard from "./ChartCard";

const HTs = ({ assessment }) => {
  const setHandTs = () => {
    let labels = [];
    const { ht, correctHt } = assessment;

    if (ht.hyperHypokalemia === correctHt.hyperHypokalemia)
      labels.push({ success: true, label: `Hypokalaemia / Hyperkalaemia` });
    else labels.push({ success: false, label: `Hypokalaemia / Hyperkalaemia` });

    if (ht.hypothermia === correctHt.hypothermia)
      labels.push({ success: true, label: `Hypothermia / Hyperthermia` });
    else labels.push({ success: false, label: `Hypothermia / Hyperthermia` });

    if (ht.hypovolemia === correctHt.hypovolemia)
      labels.push({ success: true, label: `Hypovolemia` });
    else labels.push({ success: false, label: `Hypovolemia` });

    if (ht.hypoxia === correctHt.hypoxia)
      labels.push({ success: true, label: `Hypoxia` });
    else labels.push({ success: false, label: `Hypoxia` });

    if (ht.tamponade === correctHt.tamponade)
      labels.push({ success: true, label: `Tamponade` });
    else labels.push({ success: false, label: `Tamponade` });

    if (ht.tensionPneumothorax === correctHt.tensionPneumothorax)
      labels.push({ success: true, label: `Tension Pneumothorax` });
    else labels.push({ success: false, label: `Tension Pneumothorax` });

    if (ht.thrombosis === correctHt.thrombosis)
      labels.push({ success: true, label: `Thrombosis` });
    else labels.push({ success: false, label: `Thrombosis` });

    if (ht.toxins === correctHt.toxins)
      labels.push({ success: true, label: `Toxins` });
    else labels.push({ success: false, label: `Toxins` });

    return labels;
  };
  return (
    <div className="HandT">
      {assessment && <ChartCard labels={setHandTs()} />}
    </div>
  );
};

export default HTs;
