import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import { Fragment } from "react";

const DeleteModal = ({
  open,
  handleClose,
  loading,
  onDelete,
  appId,
  appName,
}) => {
  return (
    <div className="detailsModalContainer">
      <Dialog
        open={open}
        disableBackdropClick
        className="details"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <DialogContent className="mb-3 deleteModal" style={{ width: "100%" }}>
          <div className="deleteModalHeading">
            {/* <button
              onClick={handleClose}
              type="button"
              className="deleteModalcloseBtn"
            >
              &#x2716;
            </button> */}
            <h1 style={{ fontSize: "25px" }}>
              Are you sure you want to delete {appName}?
            </h1>
          </div>
          <div className="deleteModalBtnContainer">
            <button
              onClick={handleClose}
              type="button"
              className="secondaryClass"
              disabled={loading}
            >
              Cancel
            </button>
            <button
              onClick={() => onDelete(appId)}
              type="button"
              className="primaryClass"
              disabled={loading}
            >
              {loading ? "loading..." : "Delete"}
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default DeleteModal;
