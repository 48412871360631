import Button from "../Button/button";
import { DeleteOutlined } from "@material-ui/icons";
import "./Table.css";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { NEXT_LINE } from "../../Services/Constants";
import { IconButton } from "@material-ui/core";
const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  formControl: {
    width: "100%",
  },
  tableStart: {
    boxShadow: "none",
  },
  table: {
    minWidth: 650,
    textAlign: "center",

    "& thead.MuiTableHead-root": {
      position: "sticky",
      top: 0,
      background: "#ffffff",
      zIndex: 1,
      borderBottom: "2px solid grey",
    },
  },
});

const Tabletab = ({
  handleDeleteUser,
  handleBtnClick,
  handleBtnClickSecond,
  handleBtnClickThird,
  tableData,
  handleEmailClick,
  split,

  ...props
}) => {
  console.log("tabtableDataleDatatableData", tableData);
  const classes = useStyles();
  const [currentData, setCurrentData] = useState();
  const [rowNo, setRowNo] = useState("0");
  useEffect(() => {
    if (tableData?.length > 0) getCurrentData(tableData);
  }, []);
  useEffect(() => {
    if (tableData?.length > 0) getCurrentData(tableData, "updated");
  }, [tableData]);

  // function for pagination have 3 case for next data, prev data, and updated data
  const getCurrentData = (data, name, e) => {
    let finalData = [];
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (name === "less") {
      data.map((item, index) => {
        if (index < rowNo - 8 && index >= rowNo - 16) {
          finalData.push(item);
        }
      });

      if (finalData.length > 0) {
        setCurrentData(finalData);
        setRowNo(parseInt(rowNo) - 8);
      }
    } else if (!e && name == "updated") {
      data.map((item, index) => {
        let pos = index + 1;
        if (pos > rowNo - 8 && pos <= rowNo) finalData.push(item);
      });
      if (finalData.length > 0) {
        setCurrentData(finalData);
      }
    } else {
      data.map((item, index) => {
        let pos = index + 1;
        if (pos > rowNo && pos <= rowNo + 8) {
          finalData.push(item);
        }
      });
      if (finalData.length > 0) {
        setCurrentData(finalData);
        setRowNo(parseInt(rowNo) + 8);
      }
    }
  };
  const { tableHead } = props; //tableHead = array of columns, tableData = array of array of row data
  if (tableHead == null || tableData == null) {
    return null;
  }

  return (
    <div className="tableCover">
      <TableContainer
        style={{ height: props.height }}
        className={classes.tableStart}
        component={Paper}
      >
        <Table
          className={`${classes.table} tableWidth`}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {tableHead.map((key) => (
                <TableCell>{key}</TableCell>
              ))}
              {props.button ? <TableCell></TableCell> : null}{" "}
              {props.icon ? <TableCell></TableCell> : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData &&
              tableData.length > 0 &&
              tableData?.map((item, i) => (
                <TableRow key={i}>
                  {item.map((val, index) =>
                    props.checkboxRole &&
                    index == 2 &&
                    props?.ownerUuid != item[3] ? (
                      <TableCell>
                        <FormControl className={classes.formControl}>
                          {/* <InputLabel id="demo-mutiple-checkbox-label">
                          Role
                        </InputLabel> */}

                          <select
                            onChange={(e) =>
                              props.handleChangeCheckBox(e, item)
                            }
                            value={val}
                          >
                            {props.dropDownList.map((roles, key) => (
                              <option key={key} value={roles}>
                                {roles}
                              </option>
                            ))}
                          </select>

                          {/* <Select
                          labelId="demo-mutiple-checkbox-label"
                          id="demo-mutiple-checkbox"
                          multiple
                          onChange={(e) => props.handleChangeCheckBox(e, item)}
                          name="role"
                          value={val}
                          input={<Input />}
                          renderValue={(selected) => selected.join(", ")}
                        >
                          {props.dropDownList.map((roles, key) => (
                            <MenuItem key={key} value={roles}>
                              <Checkbox checked={val.indexOf(roles) > -1} />
                              <ListItemText primary={"Assign " + roles} />
                            </MenuItem>
                          ))}
                        </Select> */}
                        </FormControl>
                      </TableCell>
                    ) : props.index == index ? null : props.secondButtonId ==
                        index && item[1] === "Accepted" ? (
                      <TableCell component="th">
                        <Button
                          background="secondary" // two types
                          fontSize="15px"
                          color="primary" //two types
                          name={val}
                          handleClick={() => {
                            handleBtnClickSecond(item);
                          }}
                        />
                      </TableCell>
                    ) : props.emailIndex == index ? (
                      <TableCell
                        component="th"
                        style={{ cursor: "pointer" }}
                        scope="row"
                        onClick={() => handleEmailClick(item)}
                      >
                        {val}
                      </TableCell>
                    ) : props.list && val.title ? (
                      <TableCell>
                        <li> {val.title}</li>
                      </TableCell>
                    ) : props.list && val.subTitle ? (
                      <TableCell>
                        <li className="ml-4" style={{ listStyle: "circle" }}>
                          {val.subTitle}
                        </li>
                      </TableCell>
                    ) : (
                      // (
                      //   <TableCell

                      //   >
                      //  <li className="ml-4" style={{listStyle:"circle"}}>  {val.subTitle}</li>
                      //   </TableCell>
                      // )
                      <TableCell
                        component="th"
                        scope="row"
                        // onClick={(e) => props.handleDropdownToggle(e, val, item)}
                      >
                        {typeof val === "object" ? (
                          val.toString()
                        ) : split === "yes" ? (
                          <div style={{ textAlign: "left" }}>
                            {" "}
                            {val.split(`${NEXT_LINE}`).map((data) => {
                              return (
                                <>
                                  {data} <br />
                                </>
                              );
                            })}
                          </div>
                        ) : (
                          val
                        )}
                      </TableCell>
                    )
                  )}

                  {/* new button added for view details */}

                  {props.button3 ? (
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          background={
                            item[1] == "Pending" ? "primary" : "secondary"
                          } // two types
                          fontSize="15px"
                          disabled={item[1] == "Pending" ? true : false}
                          color={item[1] == "Pending" ? "secondary" : "primary"} //two types
                          name={
                            props.buttonName3
                              ? props.buttonName3
                              : "View Details"
                          }
                        />
                      </div>
                    </TableCell>
                  ) : null}

                  {props.button ? (
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          background={
                            item[1] == "Pending" ? "primary" : "secondary"
                          } // two types
                          fontSize="15px"
                          disabled={item[1] == "Pending" ? true : false}
                          color={item[1] == "Pending" ? "secondary" : "primary"} //two types
                          name={
                            props.buttonName ? props.buttonName : "View Details"
                          }
                          handleClick={() => {
                            handleBtnClick(item);
                            // props.setDetailsModel(true);
                            // props.setEmail(item[0]);
                          }}
                        />
                        {props.button2 ? (
                          <Button
                            background={
                              item[1] == "Pending" ? "primary" : "secondary"
                            } // two types
                            fontSize="15px"
                            disabled={item[1] == "Pending" ? true : false}
                            color={
                              item[1] == "Pending" ? "secondary" : "primary"
                            } //two types
                            name={
                              props.buttonName2
                                ? props.buttonName2
                                : "View Details"
                            }
                            handleClick={() => {
                              handleBtnClickSecond(item);
                              // props.setDetailsModel(true);
                              // props.setEmail(item[0]);
                            }}
                          />
                        ) : null}
                      </div>
                    </TableCell>
                  ) : null}

                  {props.icon && props.ownerUuid != item[3] ? (
                    <TableCell className="del">
                      {/* <IconButton aria-label="delete"
                    // icon={<DeleteOutlined />}
                    onClick={() => handleDeleteUser(item)}
                    > */}
                      <div className="delIcon">
                        <DeleteOutlined
                          onClick={() => handleDeleteUser(item)}
                        />
                      </div>

                      {/* </IconButton> */}
                    </TableCell>
                  ) : null}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <div className="text-right">
        <ArrowLeftIcon
          className="pageIcon"
          onClick={(e) => getCurrentData(tableData, "less", e)}
          style={{ color: "black" }}
        />
        <ArrowRightIcon
          className="pageIcon"
          onClick={(e) => getCurrentData(tableData, "more", e)}
          style={{ color: "black" }}
        />
      </div> */}
    </div>
  );
};
export default Tabletab;
