import React from "react";
import CustomCard from "../Card/customCard";

import BarChart from "./BarChart";
import ScatterChart from "./ScatterChart";
import { useSelector } from "react-redux";
import { APPLICATION_CONSTANTS } from "../../Services/Constants";
import { getAvgScoreCB, getAvgScoreCB2 } from "../../Helper";
import { withRouter } from "react-router";
import Loader from "../Loader/loader";
import "./codeBlack.css";
import { CircularProgress } from "@material-ui/core";

const CodeBlackDash = React.memo((props) => {
  const { assessmentData, peopleCount } = props;
  const { loading } = useSelector((state) => state.CommonReducer);
  let currentOrg = useSelector((state) => state.OrgReducer.currentOrg);
  let user = useSelector((state) => state.AuthReducer.user);

  // console.log("props in cb dash :>> ", props, loading);
  if (loading)
    return (
      <div className="spinnerDiv" style={{ width: "100%" }}>
        {" "}
        <CircularProgress color="inherit"></CircularProgress>
      </div>
    );
  else
    return (
      <>
        <div className="d-flex cb">
          {currentOrg.length === 0 && user?.role != "superAdmin" ? (
            <div className="cusCard">
              <CustomCard
                handleClick={() =>
                  props.history.push(`/situation-assessment/${user.uuid}`)
                }
                fontSize="64px"
                title="View Assessments"
              />
            </div>
          ) : (
            <div className="cusCard">
              <CustomCard
                no={peopleCount}
                fontSize="64px"
                title="Number of People"
              ></CustomCard>
            </div>
          )}

          <div className="cusCard">
            <CustomCard
              no={assessmentData.length}
              fontSize="64px"
              title="Number of Assessments"
            ></CustomCard>
          </div>

          <div className="cusCard">
            <CustomCard
              no={`${
                assessmentData.length *
                APPLICATION_CONSTANTS.SINGLE_ASSESSMENT_TIME
              } mins`}
              fontSize="64px"
              title="Total Time in Sim"
            ></CustomCard>
          </div>

          <div className="cusCard">
            <CustomCard
              no={getAvgScoreCB2(assessmentData)}
              fontSize="64px"
              title="Average Score"
            ></CustomCard>
          </div>
        </div>

        <div className="flexGraph codeBlack">
          <div className="graphbgMainL fixgraphbgMainL">
            <div className="GraphBG">
              <ScatterChart assessmentData={assessmentData} />
            </div>
          </div>

          <div className="graphbgMainR barChartSpecial">
            <div className="GraphBG bar-chart">
              <h1>failToIdentify</h1>
              <BarChart
                assessmentData={assessmentData}
                type={"failToIdentify"}
              />
            </div>
            <div className="GraphBG bar-chart">
              <h1>misIdentify</h1>

              <BarChart assessmentData={assessmentData} type={"misIdentify"} />
            </div>
          </div>
        </div>
      </>
    );
});

export default withRouter(CodeBlackDash);
