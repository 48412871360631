import React from "react";
import { Bubble } from "react-chartjs-2";
import { useSelector } from "react-redux";
// import { assessments, FINAL_ASSESSMENTS } from "../../../FIXTURE";
import moment from "moment";
import { scaleScenarioDuration } from "../../Helper";

const BubbleGraph = ({ assessmentData }) => {
  // group users assessments according to the uuid

  //Grouping the userAssesment by uuid
  console.log("assssesssment data", assessmentData);
  const groupUserAssessments = () => {
    const _assessmentData = [...assessmentData];

    return _assessmentData.reduce((r, a) => {
      r[a.uuid] = [...(r[a.uuid] || []), a];
      return r;
    }, {});
  };

  // function to  return data for the bubble graph filtered according to orgId
  const filterGraphDataByOrg = () => {
    let _final = [];
    const groupedAssessments = { ...groupUserAssessments() };
    for (const key in groupedAssessments) {
      console.log("groupedAssessments[key] :>> ", groupedAssessments[key]);
      // scaleScenarioDuration(groupedAssessments[key]);
      console.log(
        "scaled groupedAssessments[key] :>> ",
        groupedAssessments[key]
      );
      const backgroundColor = `rgba(${genColor()},${genColor()},${genColor()}, 1)`;
      _final.push({
        ...options,
        backgroundColor,
        pointBackgroundColor: backgroundColor,
        uuid: key,
        // label: '',
        label:
          groupedAssessments[key][0] &&
          groupedAssessments[key][0]["user"] &&
          groupedAssessments[key][0]["user"][0] &&
          groupedAssessments[key][0]["user"][0]["userName"]
            ? groupedAssessments[key][0]["user"][0]["userName"]
            : key.slice(0, 5).toUpperCase(), // this displays the ids on top of the chart
        // label: "a", // this displays the ids on top of the chart
        data: groupedAssessments[key]
          // .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
          .sort((a, b) => moment(a.createdAt) - moment(b.createdAt))
          // .map(({ overallScore, createdAt, scenarioDuration }, index) => ({
          //   x: index,
          //   y: overallScore,
          //   r: parseInt(scenarioDuration),
          //   createdAt,
          // })),
          .map(({ results, createdAt }, indx) => {
            if (results.length > 0) {
              const scoreIndex = results.findIndex(
                (result) => result.name === "score percent"
              );
              const durationIndex = results.findIndex(
                (result) => result.name === "duration"
              );
              console.log("results**", results);
              return {
                x: indx,
                y: parseInt(
                  results[scoreIndex]["value"] &&
                    results[scoreIndex]["value"].length > 0 &&
                    results[scoreIndex]["value"][0]["x"]
                ),
                // r: parseInt(
                //   results[durationIndex]["value"] &&
                //     results[durationIndex]["value"].length > 0 &&
                //     results[durationIndex]["value"][0]["x"]
                // ),
                r: 10,
                createdAt,
                durationIndex: parseInt(
                  results[durationIndex]["value"] &&
                    results[durationIndex]["value"].length > 0 &&
                    results[durationIndex]["value"][0]["x"]
                ),
              };
            }
          }),
        //find index in results
        // .some((e) => {
        //   if (e.name === "score percent") {
        //   }
        // }),
      });
    }

    return _final;
  };

  // generate random color
  const genColor = () => Math.floor(Math.random() * 255);

  // Graph options.
  const options = {
    fill: true,
    pointBackgroundColor: "rgba(75,192,192,1)",
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointRadius: 4,
    pointHitRadius: 5,
  };

  const data = {
    datasets: filterGraphDataByOrg(),
  };

  // console.log("data in the bubble ", groupUserAssessments());

  return (
    <div
      id="line-graph-container"
      style={{ margin: "30px 0px", boxShadow: "none", padding: "10px" }}
    >
      <p style={{ fontWeight: "600", paddingTop: "10px", textAlign: "center" }}>
        Scores Ordered by Assessment
      </p>
      <Bubble
        data={data}
        label="First Dataset"
        options={{
          responsive: true,
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  console.log("context", context);
                  let { parsed: parsedContext, label, raw } = context;

                  let tempParsedContext = JSON.parse(
                    JSON.stringify(parsedContext)
                  );
                  let _custom = raw.durationIndex;
                  // tempParsedContext._custom *= 4;
                  tempParsedContext._custom = _custom;
                  let newValue = Object.values(tempParsedContext)
                    .map((item) => item)
                    .join(",");
                  console.log("ctxx", `${label}:${newValue}`);
                  return `${label}:${newValue}`;
                },
              },
            },
          },
          elements: {
            point: {
              radius: 1,
            },
          },
          legend: {
            display: true,
            labels: { fontColor: "black", boxWidth: 20, fontSize: 8 },
            align: "start",
          },
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        }}
      />
    </div>
  );
};

export default BubbleGraph;
