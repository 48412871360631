import { ACTION_TYPE } from "../../Services/Constants/index";
export const initialState = {
  product: "als",
};

export const ProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.SET_PRODUCT:
      return {
        ...state,
        product: action.payload,
      };

    default:
      return state;
  }
};
