import React, { useEffect } from "react";
import _Firebase from "../../Services/Utils/firebase";
import { useSelector, useDispatch } from "react-redux";
import { getUserDetailsJWT } from "../../Services/Utils/utils";

function RBAC({ userRole, children, renderNoAccess, initList }) {
  let currentOrg = useSelector((state) => state.OrgReducer.currentOrg);

  if (userRole?.includes("superAdmin")) {
    const superAdmin = initList.filter((list) => {
      if (list.role.some((role) => userRole.includes(role))) {
        console.log("initList", list);
        return list;
      }
    });

    const newchildren = React.cloneElement(children, [superAdmin], [children]);
    return newchildren;
  }

  if (localStorage.getItem("orgToken")) {
    // console.log("orgToken if", currentOrg.length);

    if (currentOrg.length > 0) {
      const checkOwnerOrAdmin = () => {
        // check user has rights to view::
        const owner =
          getUserDetailsJWT() &&
          currentOrg[0]?.ownerUuid === getUserDetailsJWT()?.uuid;
        if (owner) return { owner: true };
        else {
          const isAdmin =
            getUserDetailsJWT() &&
            currentOrg[0]?.users
              ?.find((usr) => usr.uuid === getUserDetailsJWT()?.uuid)
              ?.roles.includes("admin");
          const isSuperAdmin =
            getUserDetailsJWT() &&
            currentOrg[0]?.users
              ?.find((usr) => usr.uuid === getUserDetailsJWT()?.uuid)
              ?.roles.includes("superAdmin");
          const isTeacher =
            getUserDetailsJWT() &&
            currentOrg[0]?.users
              ?.find((usr) => usr.uuid === getUserDetailsJWT().uuid)
              ?.roles.includes("teacher");
          return { isAdmin, isTeacher, isSuperAdmin, owner: false };
        }
      };

      if (checkOwnerOrAdmin().owner || checkOwnerOrAdmin().isAdmin) {
        // console.log("orgToken if admin");

        const admin = initList.filter((list) => {
          if (list.role.some((role) => role === "admin")) {
            return list;
          }
        });

        const newchildren = React.cloneElement(children, [admin], [children]);
        return newchildren;
      } else if (checkOwnerOrAdmin().isTeacher) {
        // console.log("orgToken if teacher");

        const teacher = initList.filter((list) => {
          if (list.role.some((role) => role === "teacher")) {
            return list;
          }
        });

        const newchildren = React.cloneElement(children, [teacher], [children]);
        return newchildren;
      } else if (checkOwnerOrAdmin().isSuperAdmin) {
        const superAdmin = initList.filter((list) => {
          if (list.role.some((role) => role === "superadmin")) {
            return list;
          }
        });
        const newchildren = React.cloneElement(
          children,
          [superAdmin],
          [children]
        );
        return newchildren;
      } else {
        // console.log("orgToken if student");

        const student = initList.filter((list) => {
          if (list.role.some((role) => role === "student")) {
            return list;
          }
        });

        const newchildren = React.cloneElement(children, [student], [children]);
        return newchildren;
      }
    } else {
      return null;
    }
  } else {
    const student = initList.filter((list) => {
      if (list.role.some((role) => role === "student")) {
        return list;
      }
    });

    const newchildren = React.cloneElement(children, [student], [children]);
    return newchildren;
  }
}

export default RBAC;
