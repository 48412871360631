import React from "react";
import ChartCard from "./ChartCard";

const CPR = ({ assessment }) => {
  const setCpr = () => {
    const {
      cpr: {
        cprCycle,
        bpm110,
        bpm80,
        bpm50,
        cm5,
        cm10,
        cm2,
        ratio302,
        ratio152,
      },
    } = assessment;
    let labels = [];
    if (cprCycle < 2)
      labels.push({
        success: true,
        label: `CPR quality confirmed before second COACHED`,
      });
    else
      labels.push({
        success: false,
        label: `CPR quality not confirmed before second COACHED`,
      });

    if (bpm110) labels.push({ success: true, label: "110 bpm confirmed" });

    if (bpm80) labels.push({ success: false, label: "80 bpm confirmed" });

    if (bpm50) labels.push({ success: false, label: "50 bpm confirmed" });

    if (cm5)
      labels.push({ success: true, label: `5 cm compression depth confirmed` });

    if (cm10)
      labels.push({
        success: false,
        label: `10 cm compression depth confirmed`,
      });

    if (cm2)
      labels.push({
        success: false,
        label: `2 cm compression depth confirmed`,
      });

    if (ratio302) labels.push({ success: true, label: `30:2 ratio confirmed` });

    if (ratio152)
      labels.push({ success: false, label: `15:2 ratio confirmed` });

    return labels;
  };
  return <div>{assessment && <ChartCard labels={setCpr()} />}</div>;
};

export default CPR;
