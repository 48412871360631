import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import "./style.css";

import { CircularProgress } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import ChartCard from "./ChartCard";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    textTransform: "lowercase",
    fontSize: "14px",
    fontWeight: "bold",
    backgroundColor: "#116a9a",
    marginBottom: "15px",

    "& button": {
      backgroundColor: "transparent",
      color: "#ffffff",
      border: "1px solid #ffffff",
      borderRadius: "3px",
      textTransform: "capitalize",
      padding: "6px 20px",
      margin: "0px 10px",
      marginBottom: "10px",
      minWidth: "auto",
      minHeight: "auto",
      opacity: "1",
    },
    "& .Mui-selected": {
      backgroundColor: "#ffffff",
      color: "#000000",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "unset",
    },
  },
  "&:hover": {
    color: "#40a9ff",
    border: "none",
    backgroundColor: "rgba(0,0,0,.095)",
  },
});

const DetPatResultView = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.CommonReducer);
  let { assessmentData } = props;
  const [scenarioData, setScenarioData] = useState([]);
  useEffect(() => {
    if (assessmentData && Object.keys(assessmentData).length > 0) {
      console.log("yayayayayy");
      let tableData = [
        {
          title: "Airway",
          value: assessmentData?.airway.toString(),
          subTitle: [],
          subValue: [],
        },
        {
          title: "Breathing",
          value: assessmentData?.breathing.toString(),
          subTitle: ["Oxygen Saturation", "Respiratory Rate", "Oxygen"],
          subValue: [
            assessmentData?.oxygenSats.toString(),
            assessmentData?.respRate.toString(),
            assessmentData?.assistOxygen.toString(),
          ],
        },

        {
          title: "Circulation",
          value: assessmentData?.circulation.toString(),
          subTitle: ["Blood Pressure", "Cardiac Monitoring"],
          subValue: [
            assessmentData?.bloodPressure.toString(),
            assessmentData?.cardiacMonitoring.toString(),
          ],
        },

        {
          title: "Disability",
          value: assessmentData?.disability.toString(),
          subTitle: ["AVPU", "Glasgow Coma Scale"],
          subValue: [
            assessmentData?.avpu.toString(),
            assessmentData?.gcs.toString(),
          ],
        },

        {
          title: "Exposure",
          value: assessmentData?.exposure.toString(),
          subTitle: ["Temp"],
          subValue: [assessmentData?.temp.toString()],
        },
        {
          title: "Fluids",
          value: assessmentData?.fluids.toString(),
          subTitle: [],
          subValue: [],
        },
        {
          title: "Glucose",
          value: assessmentData?.glucose.toString(),
          subTitle: [],
          subValue: [],
        },
        {
          title: "Patient Interactions",
          value: assessmentData?.patientInteract.toString(),
          subTitle: [],
          subValue: [],
        },
      ];
      setScenarioData(tableData);
    }
  }, [assessmentData]);

  const [index, setIndex] = React.useState(0);
  // const loading = "";
  const handleChange = (_, newValue) => {
    setIndex(newValue);
  };

  // if (assessment === "") return null;

  // RecentAssessmentData
  const renderContent = () => {
    {
      console.log("scenarioData", scenarioData);
    }
    return <ChartCard data={scenarioData[index]} />;
  };

  return (
    <div className="assess-container Detpat-assess-container">
      {!(scenarioData && scenarioData.length > 0) && (
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: "0px",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={20} />
        </div>
      )}

      {/* <Branding /> */}

      {scenarioData && scenarioData.length > 0 && (
        <Paper className={classes.root}>
          <Tabs
            value={index}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            {scenarioData.map((item, index) => (
              <Tab className="detPat__tab" label={item.title} key={index} />
            ))}
          </Tabs>
        </Paper>
      )}

      {scenarioData && scenarioData.length > 0 && (
        <div
          style={{
            marginTop: "50px",
            backgroundColor: "#fff",
            borderRadius: "3px",
          }}
        >
          {renderContent()}
        </div>
      )}
    </div>
  );
};

export default DetPatResultView;

// import React, { useEffect, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import CustomCard from "../../../Component/Card/customCard";
// import "./style.css";

// import { withRouter } from "react-router";
// import { CircularProgress } from "@material-ui/core";
// import Table from "../../../Component/Table/Table";
// import { OrgActions } from "../../../ReduxStore/Actions";
// import Scenario from "../../../Component/Scenario/scenario";

// const DetPatResultView = React.memo((props) => {
//   const { loading } = useSelector((state) => state.CommonReducer);
//   let { assessmentData } = props;
//   const [scenarioData, setScenarioData] = useState([]);
//   useEffect(() => {
//     if (assessmentData && Object.keys(assessmentData).length > 0) {
//       console.log("yayayayayy");
//       let tableData = [
//         { title: "Airway", value: assessmentData?.airway.toString() },
//         {
//           title: "Breathing",
//           value: assessmentData?.breathing.toString(),
//           subTitle: ["Oxygen Saturation", "Respiratory Rate", "Oxygen"],
//           subValue: [
//             assessmentData?.oxygenSats.toString(),
//             assessmentData?.respRate.toString(),
//             assessmentData?.assistOxygen.toString(),
//           ],
//         },

//         {
//           title: "Circulation",
//           value: assessmentData?.circulation.toString(),
//           subTitle: ["Blood Pressure", "Cardiac Monitoring"],
//           subValue: [
//             assessmentData?.bloodPressure.toString(),
//             assessmentData?.cardiacMonitoring.toString(),
//           ],
//         },

//         {
//           title: "Disability",
//           value: assessmentData?.disability.toString(),
//           subTitle: ["AVPU", "Glasgow Coma Scale"],
//           subValue: [
//             assessmentData?.avpu.toString(),
//             assessmentData?.gcs.toString(),
//           ],
//         },

//         {
//           title: "Exposure",
//           value: assessmentData?.exposure.toString(),
//           subTitle: ["Temp"],
//           subValue: [assessmentData?.temp.toString()],
//         },
//         ,
//         { title: "Fluids", value: assessmentData?.fluids.toString() },
//         { title: "Glucose", value: assessmentData?.glucose.toString() },
//         {
//           title: "Patient Interactions",
//           value: assessmentData?.patientInteract.toString(),
//         },
//       ];
//       setScenarioData(tableData);
//     }
//   }, [assessmentData]);

//   console.log("TABLEEEEEEEEEEE", scenarioData);
//   if (loading)
//     return (
//       <div className="spinnerDiv" style={{ width: "100%" }}>
//         {" "}
//         <CircularProgress color="inherit"></CircularProgress>
//       </div>
//     );
//   else
//     return (
//       <>
//         {assessmentData && (
//           <>
//             <div className="d-flex justify-content-between flexsupad">
//               <CustomCard
//                 no={assessmentData?.scenarioTitle}
//                 // fontSize="64px"
//                 // title="Scenario Title"
//               ></CustomCard>

//               <CustomCard
//                 no={assessmentData?.overallScore}
//                 fontSize="64px"
//                 title="Overall Score"
//               ></CustomCard>

//               <CustomCard
//                 no={assessmentData?.scenarioDuration}
//                 fontSize="64px"
//                 title="Scenario Duration"
//               ></CustomCard>
//             </div>

//             <div className="pb-31 mt-4 mb-4">
//               {scenarioData &&
//                 scenarioData.map((data) => (
//                   <Scenario scenarioData={data}></Scenario>
//                 ))}
//             </div>
//           </>
//         )}
//       </>
//     );
// });
// export default withRouter(DetPatResultView);
