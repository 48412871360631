import ViewQuiltIcon from "@material-ui/icons/ViewQuilt";
import BusinessOutlinedIcon from "@material-ui/icons/BusinessOutlined";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import RadioButtonCheckedTwoToneIcon from "@material-ui/icons/RadioButtonCheckedTwoTone";
import {
  Assessment,
  CardMembership,
  FiberManualRecord,
  Category,
  Group,
  Person,
  ArrowRight,
} from "@material-ui/icons";
import { PRODUCTS, ROUTES } from "../../Services/Constants";
import ArrowDropDownTwoToneIcon from "@material-ui/icons/ArrowDropDownTwoTone";
import ArrowDropUpTwoToneIcon from "@material-ui/icons/ArrowDropUpTwoTone";
export const initList = [
  //superAdmin Routes
  // {
  //   icon: <ViewQuiltIcon />,
  //   title: "Super Admin Dashboard",
  //   route: ROUTES.SUPER_ADMIN_DASHBOARD,
  //   role: ["superAdmin"],
  // },
  // {
  //   icon: <Assessment />,
  //   title: "Product Analytics",
  //   route: ROUTES.PRODUCT_ANALYTICS_ADMIN,
  //   role: ["superAdmin"],
  // },

  // {
  //   icon: <CardMembership />,
  //   title: "Subscriptions",
  //   route: ROUTES.SUBSCRIPTION,
  //   role: ["superAdmin"],
  // },
  //Admin routes
  // {
  //   icon: <BusinessOutlinedIcon />,
  //   title: "Organization Dashboard",
  //   route: ROUTES.OGRANIZATIONDASHBOARD,
  //   role: ["admin", "teacher"],
  // },

  // {
  //   icon: <SettingsIcon />,
  //   title: "Organization Settings",
  //   route: ROUTES.ORGANISATION_SETTINGS,
  //   role: ["admin"],
  // },
  // {
  //   icon: <Person />,
  //   title: "User Management",
  //   route: ROUTES.USERMANAGEMENT,
  //   role: ["admin"],
  // },

  // {
  //   icon: <Group />,
  //   title: "Group Management",
  //   route: ROUTES.GROUPMANAGEMENT,
  //   role: ["admin", "teacher"],
  // },
  // {
  //   icon: <Assessment />,
  //   title: "Product Analytics",
  //   route: ROUTES.PRODUCT_ANALYTICS,
  //   role: ["admin"],
  // },
  // end SuperAdmin role

  // student routes

  // {
  //   icon: <BusinessOutlinedIcon />,
  //   title: "User Dashboard",
  //   route: ROUTES.USER_DASHBOARD,
  //   role: ["student"],
  // },
  // {
  //   icon: <Assessment />,
  //   title: "Product Analytics",
  //   route: ROUTES.USER_PRODUCT_ANALYTIC,
  //   role: ["student"],
  //   dropDown: "yes", // dispatch(
  //   //   OrgActions.getOrgResults(currentOrg[0]?.orgId, {
  //   //     callback: (response) => {},
  //   //   })
  //   // );
  //   iconDropDown: <ArrowDropDownTwoToneIcon />,
  //   iconDropUp: <ArrowDropUpTwoToneIcon />,
  //   list: [
  //     {
  //       //  icon: <FiberManualRecord fontSize="small" />,
  //       //  icon: <Category/>,
  //       title: "ALS",
  //       // route: ROUTES.PRODUCT_ANALYTICS,
  //       role: ["admin"],
  //       subRouteName: "/recent-assessment/",
  //     },
  //     {
  //       // icon: <Category />,
  //       className: "jjjjj",
  //       title: "CodeBlack",
  //       // route: ROUTES.PRODUCT_ANALYTICS,
  //       role: ["admin"],
  //       subRouteName: "/situation-assessment/",
  //     },
  //   ],
  // },

  // {
  //   icon: <Assessment />,
  //   title: "Account Settings",
  //   route: ROUTES.ACCOUNT_SETTINGS,
  //   role: ["student"],
  // },

  // jjjjjjjjjj
  {
    // icon: <p> A </p>,
    icon: <ArrowRight />,
    title: "Learn Suite",
    list: [
      {
        title: "Advanced Life Support",
        product: "als",
        assessmentPath: "/recent-assessment/",
        icon: <p className="imgOfOrg noImgOrg"> ALS </p>,
      },
      {
        title: "Codeblack",
        product: "codeBlack",
        assessmentPath: "/situation-assessment/",
        icon: <p className="imgOfOrg noImgOrg"> CB </p>,
      },
      {
        title: "De-Escalation",
        product: "deEscalation",
        assessmentPath: "/deescalation-assessment/",
        icon: <p className="imgOfOrg noImgOrg"> D </p>,
      },
      {
        title: "Rapid Response",
        product: "escalatingCareSim",
        assessmentPath: "/escalating-care-sim-assessment/",
        icon: <p className="imgOfOrg noImgOrg"> ECS </p>,
      },

      {
        title: "Handover Sim",
        product: PRODUCTS.HANDOVER,
        assessmentPath: "/handover-sim-assessment/",
        icon: <p className="imgOfOrg noImgOrg"> HS </p>,
      },
    ],
    role: ["admin", "teacher", "student", "superAdmin"],
  },
  {
    icon: <ArrowRight />,
    title: "Third Party Apps",
    list: [
      {
        title: "Deteriorating Patient",
        product: "deterioratingPatient",
        assessmentPath: "/dp-assessment/",
        icon: <p className="imgOfOrg noImgOrg"> DP </p>,
      },
    ],
    role: ["admin", "teacher", "student", "superAdmin"],
  },
];
