import { ACTION_TYPE } from "../../Services/Constants/index";
export const initialState = {
  collapsedClass: false,
};

export const CollapsedSidenavReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.SIDENAV_WIDTH:
      return {
        ...state,
        collapsedClass: action.payload,
      };

    default:
      return state;
  }
};
