import { useState } from "react";

import Cropper from "react-easy-crop";
import Button from "@material-ui/core/Button";
import './styles.css'

const CropModal = ({ image, aspect, handleCroppedImage }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const getCroppedImage = (croppedAreaPixels) => {
    handleCroppedImage(croppedAreaPixels);
  };

  return (
    <div className="crop-container">
      <div>
        <Cropper
          image={image}
          crop={crop}
          //   rotation={rotation}
          // zoom={zoom}
          aspect={aspect}
          onCropChange={setCrop}
          //   onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          // onZoomChange={setZoom}
          style={{ zIndex: 9999999999999999 }}
        />
      </div>
      <div>
        <Button
          onClick={() => getCroppedImage(croppedAreaPixels)}
          variant="contained"
          color="primary"
        >
          Crop
        </Button>
      </div>
    </div>
  );
};

export default CropModal;
