import React from "react";
import { useSelector } from "react-redux";
import HeaderBar from "../../../Component/HeaderBar/headerBar";
import RBAC from "../../../Component/RBAC/RBAC";
import { initList } from "../../../Component/SideNav/initList";
import _Firebase from "../../../Services/Utils/firebase";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SideNav from "../../../Component/SideNav/sideNav";
import Assessment from "./Assessments";
import { useHistory } from "react-router-dom";
const RecentAssessmentData = (props) => {
  const history = useHistory();
  let collapsedClass = useSelector(
    (state) => state.CollapsedSidenavReducer.collapsedClass
  );
  let user = useSelector((state) => state.AuthReducer.user);
  return (
    <div className="d-flex over">
      <div className={collapsedClass ? " sideNavExpand" : "sideNavContract"}>
        {user && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}{" "}
      </div>
      <div className={collapsedClass ? "screenContract bg" : "screenExpand bg"}>
        <HeaderBar
          width={collapsedClass ? "80%" : "95%"}
          title="Recent Assessments"
          searchbar="no"
          noProduct={true}
        />
        <div className="componentSec">
          <div className="d-flex justify-content-between flexsupad">
            <div className="fullDiv" style={{ padding: "0px 0px" }}>
              <div className="backIcon mr-2" onClick={() => history.goBack()}>
                <ArrowBackIcon className="icon" />
              </div>
              Recent Assessments
            </div>
          </div>
          <div className="mt-4">
            <Assessment aid={props?.match.params.id} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default RecentAssessmentData;
