import { combineReducers } from "redux";
import { CollapsedSidenavReducer } from "./CollapsedSidenavReducer";
import { SuperAdminReducer } from "./SuperAdminReducer";
import { AuthReducer } from "./AuthReducer";
import { OrgReducer } from "./OrgReducer";
import { ProductReducer } from "./ProductReducer";
import { CommonReducer } from "./CommonReducer";
import { LauncherReducer } from "./LauncherReducer";

const appReducer = combineReducers({
  CollapsedSidenavReducer,
  SuperAdminReducer,
  AuthReducer,
  OrgReducer,
  ProductReducer,
  CommonReducer,
  LauncherReducer,
});

const initialState = appReducer({}, {});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USER") {
    state = initialState;
  }

  return appReducer(state, action);
};
export default rootReducer;
