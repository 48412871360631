import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import _Firebase from "../../Services/Utils/firebase";
import ExitToAppSharpIcon from "@material-ui/icons/ExitToAppSharp";
import Button from "../Button/button";
import ViewQuiltIcon from "@material-ui/icons/ViewQuilt";
import BusinessOutlinedIcon from "@material-ui/icons/BusinessOutlined";
import { ROUTES } from "../../Services/Constants";
import { Assessment, CardMembership } from "@material-ui/icons";
import { initList } from "../SideNav/initList";
import { SuperAdminActions } from "../../ReduxStore/Actions";
import { useSelector, useDispatch } from "react-redux";
import { getUserDetailsJWT } from "../../Services/Utils/utils";
import { NavLink, withRouter, Redirect } from "react-router-dom";

function RouteRBAC({ userRole, children, renderNoAccess, location, ...props }) {
  let user = getUserDetailsJWT();
  if (user) {
    console.log("userRoles>1", user);
    // userRole = user.user.role;
    userRole = user.user.role;
  }
  let currentOrg = useSelector((state) => state.OrgReducer.currentOrg);

  // console.log("current org length", currentOrg.length);
  let orgToken = localStorage.getItem("orgToken");

  //method to get superadmin routes
  const superAdminRoute = () => {
    const superAdminRoutes = children.filter((child) => {
      if (child.props.authorisedUsers?.includes("superAdmin")) {
        return child;
      }
    });
    console.log("super admin routes", superAdminRoutes);

    return superAdminRoutes;
  };
  //method to get admin routes
  const AdminRoute = () => {
    const adminRoutes = children.filter((child) => {
      if (child.props.authorisedUsers?.includes("admin")) {
        return child;
      }
    });

    return adminRoutes;
  };
  //method to get teacher routes
  const teacherRoute = () => {
    const teacherRoutes = children.filter((child) => {
      if (child.props.authorisedUsers?.includes("teacher")) {
        return child;
      }
    });
    return teacherRoutes;
  };
  //method to get student routes
  const studentRoute = () => {
    const studentRoutes = children.filter((child) => {
      if (child.props.authorisedUsers?.includes("student")) {
        return child;
      }
    });

    return studentRoutes;
  };

  //method to get unauthorized routes
  const UnAuthorizedRoutes = () => {
    const unauthorizedRoutes = children.filter((child) => {
      if (child.props.authorisedUsers?.includes("guest")) {
        return child;
      }
    });

    return unauthorizedRoutes;
  };

  //Method to check the role of a user in org
  const checkOwnerOrAdmin = () => {
    // check user has rights to view::
    const owner =
      getUserDetailsJWT() &&
      currentOrg[0]?.ownerUuid === getUserDetailsJWT()?.uuid;
    if (owner) return { owner: true };
    else {
      const isAdmin =
        getUserDetailsJWT() &&
        currentOrg[0]?.users
          ?.find((usr) => usr.uuid === getUserDetailsJWT()?.uuid)
          ?.roles.includes("admin");
      const isSuperAdmin =
        getUserDetailsJWT() &&
        currentOrg[0]?.users
          ?.find((usr) => usr.uuid === getUserDetailsJWT()?.uuid)
          ?.roles.includes("superAdmin");

      const isTeacher =
        getUserDetailsJWT() &&
        currentOrg[0]?.users
          ?.find((usr) => usr.uuid === getUserDetailsJWT().uuid)
          ?.roles.includes("teacher");
      return { isAdmin, isSuperAdmin, isTeacher, owner: false };
    }
  };

  if (!user) {
    const unAuthorizedRoutes = UnAuthorizedRoutes();

    if (
      !unAuthorizedRoutes.some(
        (route) => route.props.path === location.pathname
      )
    ) {
      return renderNoAccess();
    }
    return unAuthorizedRoutes;
  } else {
    if (userRole === "superAdmin") {
      const superAdminRoutes = superAdminRoute();
      console.log("Super Admin23445", userRole, superAdminRoutes);

      if (location.pathname === "/") {
        console.log("ROUTES.SUPER_ADMIN_DASHBOARD");
        return (
          <Redirect
            to={{
              pathname: ROUTES.SUPER_ADMIN_DASHBOARD,
            }}
          />
        );
      }

      if (
        !superAdminRoutes.some(
          (route) =>
            route.props.path.split("/")[1] === location.pathname.split("/")[1]
        )
      ) {
        return renderNoAccess();
      }
      return superAdminRoutes;
    } else {
      // console.log("RBAC else user is present");
      if (!orgToken) {
        console.log("RBAC if org is empty");
        const studentRoutes = studentRoute();

        if (location.pathname === "/") {
          return (
            <Redirect
              to={{
                pathname: ROUTES.USER_DASHBOARD,
              }}
            />
          );
        }

        if (
          !studentRoutes.some(
            (route) =>
              route.props.path.split("/")[1] === location.pathname.split("/")[1]
          )
        ) {
          return (
            <Redirect
              to={{
                pathname: ROUTES.USER_DASHBOARD,
              }}
            />
          );
        }

        return studentRoutes;
      } else {
        // console.log("RBAC else org has data", currentOrg.length);

        if (currentOrg.length > 0) {
          // console.log("RBAC check role", checkOwnerOrAdmin());
          if (checkOwnerOrAdmin().owner || checkOwnerOrAdmin().isAdmin) {
            // console.log("RBAC if user is admin in org ");

            const adminRoutes = AdminRoute();

            if (
              !adminRoutes.some(
                (route) =>
                  route.props.path.split("/")[1] ===
                  location.pathname.split("/")[1]
              )
            ) {
              return (
                <Redirect
                  to={{
                    pathname: ROUTES.GROUPMANAGEMENT,
                    state: { from: location.pathname },
                  }}
                />
              );
            }

            return adminRoutes;
          } else if (checkOwnerOrAdmin().isTeacher) {
            console.log("RBAC else if user is teacher in org ");

            const teacherRoutes = teacherRoute();

            if (
              !teacherRoutes.some(
                (route) =>
                  route.props.path.split("/")[1] ===
                  location.pathname.split("/")[1]
              )
            ) {
              return <Redirect to={{ pathname: ROUTES.GROUPMANAGEMENT }} />;
            }

            return teacherRoutes;
          } else {
            console.log("RBAC else user is student  in org ");
            const studentRoutes = studentRoute();

            if (location.pathname === "/") {
              return (
                <Redirect
                  to={{
                    pathname: ROUTES.USER_DASHBOARD,
                  }}
                />
              );
            }

            if (
              !studentRoutes.some(
                (route) =>
                  route.props.path.split("/")[1] ===
                  location.pathname.split("/")[1]
              )
            ) {
              return (
                <Redirect
                  to={{
                    pathname: ROUTES.USER_DASHBOARD,
                  }}
                />
              );
            }

            return studentRoutes;
          }
        } else {
          // console.log("RBAC if current org length !> 0");
          // console.log("rbac outside");
          const adminRoutes = AdminRoute();

          if (
            !adminRoutes.some(
              (route) =>
                route.props.path.split("/")[1] ===
                location.pathname.split("/")[1]
            )
          ) {
            return (
              <Redirect
                to={{
                  pathname: ROUTES.GROUPMANAGEMENT,
                  state: { from: location.pathname },
                }}
              />
            );
          }

          return adminRoutes;
        }
      }
    }
  }

  return renderNoAccess();
}

export default withRouter(RouteRBAC);
