import React, { useState } from "react";

import Button from "../../Component/Button/button";
import { ValidateForm } from "./Validation";
// import "./Login.css";
import InputField from "../../Component/Input/input";

import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Header from "../Header/Header";
import { ACTION_TYPE, ROUTES } from "../../Services/Constants";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AuthActions, OrgActions } from "../../ReduxStore/Actions";
import Toast from "../../Component/Toast/toast";
import CryptoJS from "react-native-crypto-js";
import { APPLICATION_CONSTANTS } from "../../Services/index";
import jwt from "jsonwebtoken";
import { getUserDetailsFromAPI } from "../../Services/Utils/utils";
import _Firebase from "../../Services/Utils/firebase";

const ForgotPassword = (props) => {
  const dispatch = useDispatch();

  const [state, setState] = useState();
  const [toast, setToast] = useState();
  const [loading, setLoading] = useState(false);
  const [errorData, setError] = React.useState();

  const handleChange = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    // setLoading(true);
    const result = await ValidateForm(state);
    if (result?.isFormValid) {
      setLoading(true);
      setError({});
      console.log("state.email :>> ", state.email);

      _Firebase
        .auth()
        .sendPasswordResetEmail(state?.email)
        .then(() => {
          setToast({
            open: true,
            message: "A link to your email hs been sent",
            type: "success",
            duration: 5000,
          });
          setLoading(false);
          setTimeout(() => {
            props.history.push(ROUTES.LOGIN);
          }, 3000);
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          // ..

          setToast({
            open: true,
            message: errorMessage,
            type: "error",
            duration: 5000,
          });
          setLoading(false);
          setTimeout(() => {
            props.history.push(ROUTES.LOGIN);
          }, 3000);
        });
    } else {
      setError(result?.error);
    }
  };

  return (
    <div className="AuthBg">
      <Header />
      <div className="signIn">
        <div className="signInFormDiv signInmain">
          <div className="formDiv">
            <h1 className="signInHeading">Forgot Password</h1>
            <div className="horizontalLine"></div>
            <div className="randomName">
              <form className="signInForm" noValidate autoComplete="off">
                <div className="signInEmail">
                  <InputField
                    label="E-Mail"
                    type="text"
                    id="custom-css-outlined-input"
                    name="email"
                    value={state?.email}
                    handleChange={handleChange}
                    onSubmit={onSubmit}
                  />
                  <p className="errorMsg">
                    {" "}
                    {errorData?.email && errorData.email[0]}
                  </p>
                </div>

                {/* </Link> */}

                <div className="signInButton">
                  <Button
                    width="160px"
                    height="42px"
                    background="secondary"
                    borderRadius="13px"
                    fontSize="15px"
                    color="primary"
                    padding="10px"
                    transition="background 0.3s"
                    display="inline-flex"
                    alignItem="center"
                    name="Submit"
                    handleClick={onSubmit}
                    loading={loading}
                  />
                </div>
              </form>
            </div>
            {toast?.open ? (
              <Toast
                open={toast.open}
                message={toast.message}
                duration={toast.duration}
                type={toast.type}
                handleClose={() =>
                  setToast({
                    ...toast,
                    open: false,
                  })
                }
              ></Toast>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
