import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "./Tabs.css";
import LineGraph from "../../Component/Graph/LineGraph";
import { useSelector } from "react-redux";
import Tabletab from "../../Component/Table/Table";
import ALSGraphs from "../ALSGraphs";
import BubbleChart from "../../Component/Graph/bubble";
import CodeBlackDash from "../CodeBlackDash";
import { PRODUCTS } from "../../Services/Constants";
import DeEscalationDash from "../DeEscalationDash";
import DetPatDash from "../DetPat/index";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "../../Component/Button/button";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import Handover from "../../Component/Handover";
function TabPanel(props) {
  const { children, value, index, allTeamsAssessmentResults, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  siteTabs: {
    "& button": {
      backgroundColor: "transparent",
      color: "#ffffff",
      border: "1px solid #ffffff",
      borderRadius: "3px",
      textTransform: "capitalize",
      padding: "6px 20px",
      marginBottom: "10px",
      minWidth: "auto",
      minHeight: "auto",
      opacity: "1",
    },
    "& button:hover": {
      backgroundColor: "#ffffff",
      color: "#000000",
    },
    "& .Mui-selected": {
      backgroundColor: "#ffffff",
      color: "#000000",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "unset",
    },
  },
}));

export default function SimpleTabs({
  allTeamsAssessmentResults,
  handleDeleteUser,
  isProductAnalytics,
  handleCLickUser,
  tableData,
  handleEmailClick,
  product,
  setTabValue,
  tabValue,
  setAddUser,
  isDefaultGroupActive,
}) {
  console.log(
    "🚀 ~ file: Tabs.js ~ line 96 ~ allTeamsAssessmentResults",
    allTeamsAssessmentResults
  );
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const userFilters = [
    {
      label: "All Users",
      value: "0",
    },
    {
      label: "Student",
      value: "1",
    },
    {
      label: "Admins",
      value: "2",
    },
    {
      label: "Teachers",
      value: "3",
    },
  ];

  const handleChange = (event) => {
    // setValue(0);
    console.log("newValue", event.target.value);
    setValue(event.target.value);
    // setTabValue(event.target.value);
  };
  let adminData =
    tableData && tableData.filter((data) => data[2].includes("admin"));
  let teacherData =
    tableData && tableData.filter((data) => data[2].includes("teacher"));
  tableData && tableData.filter((data) => data[2].includes("teacher"));
  let studentData =
    tableData && tableData.filter((data) => data[2].includes("student"));

  return (
    <div className={classes.siteTabs}>
      {tabValue == 1 && (
        <AppBar position="static">
          <div style={{}} className="grpCardDiv">
            <div className="grpCardDivIn">
              <p> Role</p>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                label="Age"
                onChange={handleChange}
                className="uuuu"
              >
                {userFilters.map((userFilter) => {
                  return (
                    <MenuItem value={userFilter.value}>
                      {userFilter.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>

            {!isDefaultGroupActive && (
              <Button
                background="secondary"
                color="primary"
                name="Add User"
                className="personAddBtn"
                handleClick={() => setAddUser(true)}
                icon={<PersonAddOutlinedIcon />}
              />
            )}
          </div>

          {/* <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Overview" {...a11yProps(0)} />
          <Tab label="All Users" {...a11yProps(1)} />
          <Tab label="Admins" {...a11yProps(2)} />
          <Tab label="Teachers" {...a11yProps(3)} />
        </Tabs> */}
        </AppBar>
      )}
      {/* <TabPanel value={value} index={0} className="tabsContent "> */}
      {/* <div className="graphDiv">
          <LineGraph allTeamsAssessmentResults={allTeamsAssessmentResults} />
        </div> */}
      {tabValue == 0 && product === "als" ? (
        <div className="specialClass">
          <ALSGraphs
            allTeamsAssessmentResults={allTeamsAssessmentResults}
            peopleCount={tableData.length}
          ></ALSGraphs>
        </div>
      ) : tabValue == 0 && product === "codeBlack" ? (
        <div className="specialClass">
          <CodeBlackDash
            assessmentData={allTeamsAssessmentResults}
            peopleCount={tableData.length}
          />
        </div>
      ) : tabValue == 0 && product === PRODUCTS.DESCALATION ? (
        <div className="specialClass drift">
          <DeEscalationDash
            assessmentData={allTeamsAssessmentResults}
            peopleCount={tableData.length}
          />
        </div>
      ) : tabValue == 0 && product === PRODUCTS.DP ? (
        <div className="detpat">
          <DetPatDash
            assessmentData={allTeamsAssessmentResults}
            peopleCount={tableData.length}
          />
        </div>
      ) : tabValue == 0 && product === PRODUCTS.HANDOVER ? (
        <div className="detpat">
          <Handover
            assessmentData={allTeamsAssessmentResults}
            peopleCount={tableData.length}
          />
        </div>
      ) : null}

      {/* <div className="GraphBG">
          <BubbleChart allTeamsAssessmentResults={allTeamsAssessmentResults} />
        </div> */}
      {/* </TabPanel> */}
      {/* <TabPanel value={value} index={1}> */}
      {tabValue == 1 && value == 0 && (
        <div className="graphDiv">
          <Tabletab
            height="45vh"
            tableHead={["Email", "Status", "Role"]}
            tableData={tableData}
            button="yes"
            buttonName="Assessments"
            emailIndex="0"
            handleEmailClick={handleEmailClick}
            handleDeleteUser={handleDeleteUser}
            handleBtnClick={handleCLickUser}
            index="3"
            icon={!isDefaultGroupActive}
          ></Tabletab>
        </div>
      )}
      {/* </TabPanel> */}
      {/* <TabPanel value={value} index={2}> */}

      {tabValue == 1 && value == 1 && (
        <div className="graphDiv">
          <Tabletab
            height="45vh"
            tableHead={["Email", "Status", "Role"]}
            tableData={studentData}
            button="yes"
            buttonName="Assessments"
            emailIndex="0"
            handleEmailClick={handleEmailClick}
            handleDeleteUser={handleDeleteUser}
            handleBtnClick={handleCLickUser}
            index="3"
            icon={!isDefaultGroupActive}
          ></Tabletab>
        </div>
      )}
      {tabValue == 1 && value == 2 && (
        <div className="graphDiv">
          <Tabletab
            height="45vh"
            tableHead={["Email", "Status", "Role"]}
            tableData={adminData}
            button="yes"
            buttonName="Assessments"
            emailIndex="0"
            handleEmailClick={handleEmailClick}
            handleDeleteUser={handleDeleteUser}
            handleBtnClick={handleCLickUser}
            index="3"
            icon={!isDefaultGroupActive}
          ></Tabletab>
        </div>
      )}
      {/* </TabPanel> */}
      {/* <TabPanel value={value} index={3}> */}
      {tabValue == 1 && value == 3 && (
        <div className="graphDiv">
          <Tabletab
            height="45vh"
            tableHead={["Email", "Status", "Role"]}
            tableData={teacherData}
            button="yes"
            buttonName="Assessments"
            emailIndex="0"
            handleEmailClick={handleEmailClick}
            handleDeleteUser={handleDeleteUser}
            handleBtnClick={handleCLickUser}
            index="3"
            icon={!isDefaultGroupActive}
          ></Tabletab>
        </div>
      )}

      {/* </TabPanel> */}
    </div>
  );
}
