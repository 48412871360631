import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./subscription.css";
import { useSelector, useDispatch } from "react-redux";

import HeaderBar from "../../Component/HeaderBar/headerBar";
import SideNav from "../../Component/SideNav/sideNav";
import ProfileCard from "../../Component/Card/profileCard";
import Grid from "@material-ui/core/Grid";
import { OrgActions, SuperAdminActions } from "../../ReduxStore/Actions";
import { PersonAdd } from "@material-ui/icons";
import Button from "../../Component/Button/button";
import { v4 as uuidv4 } from "uuid";
import DetailsModel from "../../Component/Modal/inviteModal";
import AlertDialog from "../../Component/AlertDialog/alertDialog";
import {
  updateDate,
  deleteOrg,
} from "../../ReduxStore/Actions/SubscriptionAction";
import { initList } from "../../Component/SideNav/initList";

import RBAC from "../../Component/RBAC/RBAC";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  textBox: {
    textAlign: "center",
    position: "fixed",
    width: "100%",
    zIndex: 3,
    background: "#116a9a",
  },
  profileGrid: {
    flexGrow: 1,
    justifyContent: "center",
    display: "flex",
  },
  classGrid: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
  },
}));

const Subscription = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let collapsedClass = useSelector(
    (state) => state.CollapsedSidenavReducer.collapsedClass
  );
  let allOrgs = useSelector((state) => state.SuperAdminReducer.allOrgs);
  let user = useSelector((state) => state.AuthReducer.user);

  const [allOrgsList, setAllOrgList] = useState([]);
  const [calendar, setCalendar] = useState({});
  const [editList, setEditList] = useState({});
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [orgToDelete, setOrgToDelete] = useState();
  const [orgToEdit, setOrgToEdit] = useState();

  const [detailsModel, setDetailsModel] = useState(false);
  const [email, setEmail] = useState();
  const [inputOrgId, setInputOrgId] = useState();
  console.log(allOrgsList);
  useEffect(() => {
    if (allOrgs.length > 0) setInitialOrg();
  }, [allOrgs]);

  const setInitialOrg = () => {
    let orgState = allOrgs.filter((org) => org["orgId"]);

    setAllOrgList(orgState);
  };
  useEffect(() => {
    if (user.uuid)
      dispatch(
        SuperAdminActions.getAllOrgs({
          uuid: user.uuid,
          callback: (response) => {},
        })
      );
  }, [user]);

  const handleCalendar = async (subDate) => {
    dispatch(
      updateDate(subDate.id, subDate.value, {
        callback: (response) => {
          dispatch(
            SuperAdminActions.getAllOrgs({
              uuid: user.uuid,
              //to be changed
              callback: (response) => {},
            })
          );
          setCalendar((prevState) => ({
            ...prevState,
            [`${subDate.id}`]: false,
          }));
        },
      })
    );
  };

  const handleSave = (newState) => {
    dispatch(
      OrgActions.updateOrg({
        updateBody: { ...orgToEdit },
        ...orgToEdit.orgId,
        callback: () => {
          setOrgToEdit();
          setOpenEdit(false);
          setEditList({});
          setCalendar({});
        },
      })
    );
  };

  const handleDelete = async (orgId) => {
    dispatch(
      deleteOrg(orgToDelete, {
        callback: (response) => {
          setOrgToDelete();
          setOpen(false);
          dispatch(
            SuperAdminActions.getAllOrgs({
              uuid: user.uuid,
              callback: (response) => {
                setInitialOrg();
              },
            })
          );
        },
      })
    );
  };

  const filterFunction = (e) => {
    let searchKeyword = e.target.value;

    if (searchKeyword.trim().length > 0) {
      let list = [];
      allOrgsList &&
        allOrgsList.length > 0 &&
        allOrgsList.map((userr) => {
          var regex = new RegExp(`${searchKeyword}`, "i");
          let result = userr["orgName"]?.match(regex);

          if (result && result.length > 0) {
            list.push(userr);
          }
        });

      if (allOrgsList.length == 0 && allOrgs.length > 0) {
        allOrgs.map((userr) => {
          if (userr["orgName"]) {
            var regex = new RegExp(`${searchKeyword}`, "i");
            let result = userr["orgName"].match(regex);

            if (result && result.length > 0 && userr["orgName"]) {
              list.push(userr);
            }
          }
        });
      }

      setAllOrgList(list);
    } else {
      setAllOrgList(allOrgs);
    }
  };

  const handleCreateOrg = () => {
    let params = {
      orgId: uuidv4(),
      orgName: "Org Name",

      subscriptionEndDate: new Date().toISOString(),
      subscriptionStartDate: new Date().toISOString(),
      users: [],
      // status: "created",
      seats: 50,
    };

    dispatch(
      SuperAdminActions.createOrg({
        ...params,
        uuid: "tJbFA6yKZSQ2lq3aXb2tZkkxdja2",
        callback: () => {},
      })
    );

    setAllOrgList([{ ...params }, ...allOrgsList]);
  };

  const handleEditList = (e) => {
    const { name, value, id } = e.target;
    setAllOrgList(
      [...allOrgsList].map((object) => {
        if (object.orgId === id) {
          return {
            ...object,
            [name]: value,
          };
        } else return object;
      })
    );
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleInvite = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const { uuid } = user;
    const orgId = inputOrgId;
    dispatch(
      SuperAdminActions.sendInviteToOrg({
        uuid,
        email,
        orgId,

        callback: () => {
          setDetailsModel(false);
          setEmail();
          setInputOrgId();
        },
      })
    );
  };

  const handleInvitePress = (org) => {
    setInputOrgId(org.orgId);
    setEmail(org.ownerEmail);
    setDetailsModel(true);
  };

  return (
    <div className="subscDiv">
      <div className="d-flex over">
        <div className={collapsedClass ? " sideNavExpand" : "sideNavContract "}>
          {user && (
            <RBAC
              userRole={user?.role} // User roles that are given to user in the backend
              initList={initList}
              renderNoAccess={() => <SideNav />}
            >
              <SideNav />
            </RBAC>
          )}{" "}
        </div>
        <div
          className={collapsedClass ? "screenContract bg" : "screenExpand bg"}
        >
          <HeaderBar
            width={collapsedClass ? "80%" : "95%"}
            title="Subscriptions"
            searchbar="yes"
            filterFunction={filterFunction}
          />
          <div className="subStart">
            <div className="d-flex justify-content-end">
              <div className="inviteButton">
                <Button
                  background="secondary"
                  borderRadius="15px"
                  fontSize="15px"
                  color="tertiary"
                  padding="5px 40px"
                  transition="all 0.5s ease"
                  display="inline-flex"
                  alignItem="center"
                  icon={<PersonAdd />}
                  name="Create Org"
                  handleClick={handleCreateOrg}
                />
              </div>
            </div>

            <div className={classes.profileGrid}>
              <Grid
                // className={classes.classGrid}
                className="classGridSubs"
                container
                item
                xs={12}
                md={12}
                lg={12}
              >
                {allOrgsList.length > 0 &&
                  allOrgsList.map((item) => {
                    if (item["status"] || item["status"] !== "Pending")
                      return (
                        <ProfileCard
                          org={item}
                          handleCalendar={handleCalendar}
                          setCalendar={setCalendar}
                          calendar={calendar}
                          handleDelete={(org) => {
                            setOrgToDelete(org);
                            setOpen(true);
                          }}
                          editList={editList}
                          setEditList={setEditList}
                          handleEditList={handleEditList}
                          handleSave={(org) => {
                            setOrgToEdit(org);
                            setOpenEdit(true);
                          }}
                          setInitialOrg={setInitialOrg}
                          handleInvitePress={handleInvitePress}
                        />
                      );
                  })}
              </Grid>
              <AlertDialog
                open={open}
                handleClose={() => setOpen(false)}
                message="Are you sure you want to Delete?"
                button1="Yes"
                button2="No"
                handleDelete={handleDelete}
              />
              <AlertDialog
                open={openEdit}
                handleClose={() => setOpenEdit(false)}
                message="Are you sure you want to Edit?"
                button1="Yes"
                button2="No"
                handleDelete={handleSave}
              />
              <DetailsModel
                open={detailsModel}
                handleClose={() => setDetailsModel(false)}
                title="Invite Organisation"
                extraClasses={"super-admin-invite-org-container"}
                orgId={true}
                orgInput={
                  <input
                    placeholder="Enter Org Id"
                    style={{
                      maxWidth: "659px",
                      width: "100%",
                      padding: "4px 15px",
                      height: "47px",
                      marginBottom: "19px",
                      background: "#FFFFFF",
                      boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px",
                      border: "none",
                    }}
                    value={inputOrgId}
                    onChange={(e) => setInputOrgId(e.target.value)}
                  />
                }
                input={
                  <input
                    placeholder="Enter e-mail address"
                    style={{
                      maxWidth: "659px",
                      width: "100%",
                      padding: "4px 15px",
                      height: "47px",
                      marginBottom: "19px",
                      background: "#FFFFFF",
                      boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px",
                      border: "none",
                    }}
                    value={email}
                    onChange={handleEmailChange}
                  />
                }
                button={
                  <Button
                    background="secondary"
                    color="primary"
                    name="Invite"
                    handleClick={handleInvite}
                  />
                }
                cancelButton={
                  <Button
                    background="secondary"
                    color="primary"
                    name="Cancel"
                    handleClick={() => {
                      setDetailsModel(false);
                      setEmail();
                      setInputOrgId();
                    }}
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Subscription;
