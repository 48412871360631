import React from "react";
import Card from "./Card";
import "./Styles.css";

const Assessement = ({ guess, answer }) => {
  return (
    <div id="codeblack-assess-container">
      {[0, 1, 2, 3, 4, 5, 6, 7].map((_, index) => (
        <div className="cardAssmntInner">
          <Card index={index + 1} guess={guess[index]} answer={answer[index]} />
        </div>
      ))}
    </div>
  );
};

export default Assessement;
