import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../../Component/DropDown/DropDown";
import RBAC from "../../Component/RBAC/RBAC";
import { initList } from "../../Component/SideNav/initList";

import HeaderBar from "../../Component/HeaderBar/headerBar";
import InputField from "../../Component/Input/Input2";
import SideNav from "../../Component/SideNav/sideNav";
import SideUser from "../../Component/SideUser/sideUser";
import { SuperAdminActions } from "../../ReduxStore/Actions";

const fieldList = [
  {
    fieldType: "input",
    type: "text",
    label: "Username",
    id: "username",
    name: "username",
    value: "",
    id: "custom-css-outlined-input",

    value: "Test",
  },
  {
    fieldType: "input",
    type: "text",
    label: "Email",
    id: "email",
    name: "email",
    value: "",
    id: "custom-css-outlined-input",
    value: "Test",
  },
  {
    fieldType: "input",
    label: "Year Of Birth",
    id: "yob",
    name: "yob",
    value: "",
  },
  {
    fieldType: "dropdown",
    label: "Select Gender",
    name: "gender",
    value: "",
    menuItems: ["Male", "Female", "Other"],
  },
  {
    fieldType: "dropdown",
    label: "Select Occupation",
    name: "occupation",
    value: "",
    menuItems: ["Male", "Female", "Other"],
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const AccountSettings = () => {
  const classes = useStyles();
  let collapsedClass = useSelector(
    (state) => state.CollapsedSidenavReducer.collapsedClass
  );

  let user = useSelector((state) => state.AuthReducer.user);

  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      SuperAdminActions.fetchUserDetails(user?.uuid, {
        callback: (response) => {},
      })
    );
  }, []);

  const handleChangePassword = (password) => {
    dispatch(
      SuperAdminActions.updatePassword(password, {
        callback: (response) => {},
      })
    );
  };
  return (
    <div className="d-flex over">
      <div className={collapsedClass ? " sideNavExpand" : "sideNavContract "}>
        {user && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}{" "}
      </div>
      <div className={collapsedClass ? "screenContract bg" : "screenExpand bg"}>
        <HeaderBar
          width={collapsedClass ? "80%" : "95%"}
          title="Account Settings"
          searchbar="no"
        />
        <div className="componentSec">
          <Grid container className={classes.root} spacing={2}>
            <Grid item>
              <SideUser
                userName={user.userName}
                changePass={handleChangePassword}
              />
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item container direction="column" spacing={2}>
                <Grid item xs spacing={1}>
                  {fieldList.map((opt) =>
                    opt["fieldType"] === "input" ? (
                      <InputField {...opt} />
                    ) : (
                      <Dropdown {...opt} />
                    )
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default AccountSettings;
