import React from "react";
import ReactDOM from "react-dom";
import { Bar } from "react-chartjs-2";

// import "./styles.css";

const BarChart = ({ assessmentData, type }) => {
  console.log("bar assessment",assessmentData)
  const renderDataTrue = () => {
    let _data = [];

    let count = {
 
      "Intro" :0,
      "airway":0 ,
      "breathing":0 ,
      "circulation":0 ,
      "disability" :0,
      "exposure" :0,
      "fluids":0 ,
      "glucose":0 ,
      "handover" :0,
    };
   
          for (let i = 0; i < assessmentData.length; i++) {
            for (let key of Object.keys(count)) {
              if(assessmentData[i][key])
              count[key]++;
            }
          }
      

    _data = Object.values(count); // [""]
    return _data;
  };
  const renderDataFalse = () => {
    let _data = [];

    let count = {
  
      "Intro" :0,
      "airway":0 ,
      "breathing":0 ,
      "circulation":0 ,
      "disability" :0,
      "exposure" :0,
      "fluids":0 ,
      "glucose":0 ,
      "handover" :0,
    };
   
          for (let i = 0; i < assessmentData.length; i++) {
            for (let key of Object.keys(count)) {
              if(!assessmentData[i][key])
              count[key]++;
            }
          }
      

    _data = Object.values(count); // [""]
    return _data;
  };

      const data= {
        labels: [
        
          "Intro" ,
          "airway" ,
          "breathing" ,
          "circulation" ,
          "disability" ,
          "exposure" ,
          "fluids" ,
          "glucose" ,
          "handover" ,
        ],
        datasets: [
          {
            label: "True",
            backgroundColor: "rgba(155,231,91,0.2)",
            borderColor: "rgba(155,231,91,0.8)",
            borderWidth: 1,
            //stack: 1,
            hoverBackgroundColor: "rgba(155,231,91,0.8)",
            hoverBorderColor: "rgba(155,231,91,1)",
            data: renderDataTrue()
          },

          {
            label: "False",
            backgroundColor: "rgba(255, 0, 35,0.2)",
            borderColor: "rgba(255, 0, 35,0.8)",
            borderWidth: 1,
            //stack: 1,
            hoverBackgroundColor: "rgba(255, 0, 35,0.8)",
            hoverBorderColor: "rgba(255, 0, 35,1)",
            data: renderDataFalse()
          }
        ]
      }
   

    const options = {
      responsive: true,
      legend: {
        display: false
      },
      type: "bar"
   
    };
    return (
      <Bar
        data={data}
        width={null}
        height={null}
        options={options}
      />
    );
  
}
export default BarChart;

