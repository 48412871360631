import { ACTION_TYPE } from "../../Services/Constants/index";
export const initialState = {
  collapsedClass: true,
  token: "",
  user: {},
  isAuthenticated: false,
  allOrgs: [],
  orgResults: [],
  currentOrg: [],
  fieldList: [],
  dataOrgById: {},
  currentActiveOrg: {},
};

export const SuperAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_ALL_ORGS:
      return {
        ...state,
        allOrgs: action.payload,
      };

    case ACTION_TYPE.ORG_RESULTS:
      return {
        ...state,
        orgResults: action.payload.result,
        // currentActiveOrg:action.payload.
        // currentOrg: action.payload.org,
      };
    case "Clean":
      return {
        ...state,
        orgResults: [],
        currentOrg: [],
      };

    case ACTION_TYPE.FETCH_USER_DETAILS:
      return {
        ...state,
        user: action.payload,
      };

    case ACTION_TYPE.CLEAR_USER_DETAILS:
      return {
        ...state,
        user: {},
      };

    case ACTION_TYPE.GET_ORG_BY_ID:
      return {
        ...state,
        dataOrgById: action.payload,
      };

    default:
      return state;
  }
};
