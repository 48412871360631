import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import HeaderBar from "../../Component/HeaderBar/headerBar";
import SideNav from "../../Component/SideNav/sideNav";
import CustomCard from "../../Component/Card/customCard";
import { SuperAdminActions } from "../../ReduxStore/Actions";
import { OrgActions, AuthActions } from "../../ReduxStore/Actions";
import { ACTION_TYPE, PRODUCTS } from "../../Services/Constants/index";
import "./userDashBoard.css";
import RBAC from "../../Component/RBAC/RBAC";
import _Firebase from "../../Services/Utils/firebase";
import { initList } from "../../Component/SideNav/initList";
import LineGraph from "../../Component/Graph/LineGraph";
// import ScatterChart from "../../Component/Graph/scatter";
import BubbleChart from "../../Component/Graph/bubble";
import { getTotalSeconds } from "../../Helper/index";
import { checkJWTExpiry, getUserDetailsJWT } from "../../Services/Utils/utils";
import ALSGraphs from "../../Component/ALSGraphs";
import CodeBlackDash from "../../Component/CodeBlackDash";
import DeEscalationDash from "../../Component/DeEscalationDash";
import Handover from "../../Component/Handover";
import DetPat from "../../Component/DetPat";
import VrPasscode from "../../Component/VrPasscodeModel";

const UserDashboard = (props) => {
  const dispatch = useDispatch();
  const [modalState, setModal] = useState(
    localStorage.getItem("vrCodeState") === "false" ? false : true
  );
  let collapsedClass = useSelector(
    (state) => state.CollapsedSidenavReducer.collapsedClass
  );
  let assessmentData = useSelector(
    (state) => state.OrgReducer.singleUserAssessment
  );
  let situationAssessmentData = useSelector(
    (state) => state.OrgReducer.situationAssessment
  );

  let { deEscalationAssessment, handoverData } = useSelector(
    (state) => state.OrgReducer
  );
  let { singleDPResult } = useSelector((state) => state.OrgReducer);
  let user = useSelector((state) => state.AuthReducer.user);
  let userDetails = useSelector((state) => state.AuthReducer.userDetails);
  const { product } = useSelector((state) => state.ProductReducer);

  useEffect(() => {
    if (user?.uuid) {
      const userDetails = getUserDetailsJWT();
      const { user: userD } = userDetails;
      dispatch(
        AuthActions.getUserDetails({
          uuid: userD.uuid,
          email: userD.email,
          callback: (response) => {},
        })
      );

      dispatch(
        OrgActions.getSingleUserAssessmentResults({
          uuid: userD.uuid,
          callback: (response, result) => {},
        })
      );
      dispatch(
        OrgActions.getSingleUserSituationAssessment({
          uuid: user.uuid,
          callback: (response, result) => {},
        })
      );

      dispatch(
        OrgActions.getSingleUserDeEscalationResults({
          uuid: user.uuid,
          callback: (response, result) => {},
        })
      );
      dispatch(
        OrgActions.getSingleUserDPResults({
          uuid: user.uuid,
          callback: (response, result) => {},
        })
      );

      dispatch(
        OrgActions.getSingleUserDPResults({
          uuid: user.uuid,
          callback: (response, result) => {},
        })
      );
      dispatch(
        OrgActions.getHandoverDataByAppId({
          appid: "handover v1",
          uuid: userD.uuid,
          callback: (response, result) => {},
        })
      );
      setTimeout(() => {
        localStorage.setItem("vrCodeState", false);
      }, 4000);
    }
  }, [user]);
  const onChangeVrPassCodeModal = () => {
    localStorage.setItem("vrCodeState", false);
    setModal(false);
  };
  console.log("PROD+++++++++++++++++++++++++++++++++++", product);
  return (
    <div className="d-flex over">
      <div className={collapsedClass ? " sideNavExpand" : "sideNavContract "}>
        {user && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}{" "}
      </div>
      <div className={collapsedClass ? "screenContract bg" : "screenExpand bg"}>
        <HeaderBar
          width={collapsedClass ? "80%" : "95%"}
          title="User Dashboard"
          searchbar="no"
        />
        <div className="componentSec ">
          {product === PRODUCTS.ALS ? (
            <ALSGraphs allTeamsAssessmentResults={assessmentData}></ALSGraphs>
          ) : product === PRODUCTS.CB ? (
            <CodeBlackDash assessmentData={situationAssessmentData} />
          ) : product === PRODUCTS.DESCALATION ? (
            <DeEscalationDash assessmentData={deEscalationAssessment} />
          ) : product === PRODUCTS.DP ? (
            <DetPat assessmentData={singleDPResult} />
          ) : product === PRODUCTS.HANDOVER ? (
            <Handover assessmentData={handoverData} />
          ) : null}
        </div>
      </div>
      {userDetails && userDetails.vrCode && modalState && (
        <VrPasscode
          setModal={onChangeVrPassCodeModal}
          modalState={modalState}
          vrCode={userDetails.vrCode}
        />
      )}
    </div>
  );
};
export default UserDashboard;
