import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import "./styles.css";
import Defibulation from "./Defibulation";
import Ventilation from "./Ventilation";
import Fluids from "./Fluids";
import { CircularProgress } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import CPR from "./CPR";
import Other from "./Other";
import HandTs from "./HandTs";
import { OrgActions } from "../../../ReduxStore/Actions";

const useStyles = makeStyles({
	root: {
		flexGrow: 1,
		textTransform: "lowercase",
		fontSize: "14px",
		fontWeight: "bold",
		backgroundColor: "#116a9a",
		marginBottom: "15px",

		"& button": {
			backgroundColor: "transparent",
			color: "#ffffff",
			border: "1px solid #ffffff",
			borderRadius: "3px",
			textTransform: "capitalize",
			padding: "6px 20px",
			margin: "0px 10px",
			marginBottom: "10px",
			minWidth: "auto",
			minHeight: "auto",
			opacity: "1",
		},
		"& .Mui-selected": {
			backgroundColor: "#ffffff",
			color: "#000000",
		},
		"& .MuiTabs-indicator": {
			backgroundColor: "unset",
		},
	},
	"&:hover": {
		color: "#40a9ff",
		border: "none",
		backgroundColor: "rgba(0,0,0,.095)",
	},
});

const Assessments = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [index, setIndex] = React.useState(0);
	let assessment = useSelector((state) => state.OrgReducer.singleAssessment);
	const loading = "";
	const handleChange = (_, newValue) => {
		setIndex(newValue);
	};

	let user = useSelector((state) => state.AuthReducer.user);

	useEffect(() => {
		if (props?.aid) {
			dispatch(
				OrgActions.getSingleAssessmentResults({
					aid: props.aid,
					callback: () => {},
				})
			);
		}
	}, []);

	if (assessment === "") return null;

	// RecentAssessmentData
	const renderContent = () => {
		switch (index) {
			case 0:
				return <Other assessment={assessment} />;
			case 1:
				return <Defibulation assessment={assessment} />;
			case 2:
				return <Ventilation assessment={assessment} />;
			case 3:
				return <Fluids assessment={assessment} />;
			case 4:
				return <CPR assessment={assessment} />;
			case 5:
				return <HandTs assessment={assessment} />;

			default:
				return <Other assessment={assessment} />;
		}
	};

	return (
		<div className="assess-container">
			{loading.getSingleUserAssessmentResults && (
				<div
					style={{
						display: "flex",
						width: "100%",
						marginTop: "0px",
						justifyContent: "center",
					}}
				>
					<CircularProgress size={20} />
				</div>
			)}

			{/* <Branding /> */}

			{!loading.getSingleUserAssessmentResults && (
				<Paper className={classes.root}>
					<Tabs
						value={index}
						onChange={handleChange}
						indicatorColor="primary"
						textColor="primary"
						variant="fullWidth"
					>
						<Tab label="General" />
						<Tab label="Defibrillation" />
						<Tab label="Ventilation" />
						<Tab label="Fluids" />
						<Tab label="CPR" />
						<Tab label="H and Ts" />
					</Tabs>
				</Paper>
			)}

			{!loading.getSingleUserAssessmentResults && (
				<div
					style={{
						marginTop: "50px",
						backgroundColor: "#fff",
						borderRadius: "3px",
					}}
				>
					{renderContent()}
				</div>
			)}
		</div>
	);
};

export default Assessments;
