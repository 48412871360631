// import Logo from "../../Images/CommonImages/logo.png";
import "./Header.css";
import Button from "../../Component/Button/button";
import React from "react";
import { useHistory } from "react-router-dom";
import { AuthActions } from "../../ReduxStore/Actions";
import { useDispatch } from "react-redux";
import { APPLICATION_CONSTANTS, ROUTES } from "../../Services/Constants";

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <div>
      <div className="headerWrapper">
        <div className="BottomHeader">
          <img
            alt="logo"
            className="headerLogo"
            src={APPLICATION_CONSTANTS.SITE_LOGO}
          />
          {history.location.pathname === "/org-selection" ? (
            <div className="logoutbtn">
              <Button
                background="primary"
                color="#fff"
                name="Log Out"
                handleClick={() => {
                  dispatch(
                    AuthActions.signOut({
                      callback: () => {
                        history.push(ROUTES.LOGIN);
                      },
                    })
                  );
                }}
                className="personAddBtn"
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Header;
