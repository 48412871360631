import moment from "moment";
import { AuthActions, OrgActions } from "../../ReduxStore/Actions";
import { ACTION_TYPE, ROUTES } from "../Constants";

const jwt = require("jsonwebtoken");

const checkRole = (currentOrg) => {
  // check user has rights to view::
  const owner =
    getUserDetailsJWT() && currentOrg?.ownerUuid === getUserDetailsJWT()?.uuid;
  if (owner) return { owner: true };
  else {
    const isAdmin =
      getUserDetailsJWT() &&
      currentOrg?.users
        ?.find((usr) => usr.uuid === getUserDetailsJWT()?.uuid)
        ?.roles.includes("admin");
    const isTeacher =
      getUserDetailsJWT() &&
      currentOrg?.users
        ?.find((usr) => usr.uuid === getUserDetailsJWT().uuid)
        ?.roles.includes("teacher");
    return { isAdmin, isTeacher, owner: false };
  }
};

const getRole = (currentOrg) => {
  const roles = checkRole(currentOrg);
  let role = "Student";

  if (roles["isTeacher"] === true) role = "Teacher";
  if (roles["isAdmin"] === true) role = "Admin";
  if (roles["owner"] === true) role = "Owner";

  return role;
};

const checkJWTExpiry = (history, dispatch, returnObject) => {
  try {
    const user = jwt.verify(
      localStorage.getItem("token"),
      process.env.REACT_APP_PRIVATE_KEY
    );

    return returnObject ? returnObject(user) : user.uuid;
  } catch (error) {
    dispatch({
      type: ACTION_TYPE.LOGOUT,
    });
    return history.push(ROUTES.LOGIN);
  }
};

const getUserDetailsJWT = () => {
  try {
    return jwt.verify(
      localStorage.getItem("token"),
      process.env.REACT_APP_PRIVATE_KEY
    );
  } catch (error) {}
};

const getCustomJWT = (token) => {
  try {
    return jwt.verify(
      localStorage.getItem(token),
      process.env.REACT_APP_PRIVATE_KEY
    );
  } catch (error) {}
};

export const getUserDetailsFromStorage = (dispatch) => {
  const userDetails = jwt.verify(
    localStorage.getItem("token"),
    process.env.REACT_APP_PRIVATE_KEY
  );
  dispatch.User.setUserDetails(userDetails);
};

const getLocalUserDetails = () =>
  jwt.verify(localStorage.getItem("token"), process.env.REACT_APP_PRIVATEKEY);

const keyGenerator = () => Math.random().toString(36).slice(2);

const checkOrgIsActive = (org) =>
  org && moment(org.subscriptionEndDate).diff(new Date().toISOString(), "days");

const getUserDetailsFromAPI = ({ user, dispatch, callback }) => {
  dispatch(
    AuthActions.getUserDetails({
      uuid: user.uuid,
      email: user.email,
      callback: (response) => {
        const { user } = response.result.data;
        console.log("get user login page", user);

        if (user && user.filteredInvites.length === 0) {
          callback({ type: 0 });
        } else {
          dispatch(
            OrgActions.setActiveOrg({
              user,
              callback: () => {
                callback({ type: 1 });
              },
            })
          );
        }
      },
    })
  );
};

const getAvgTotalTime = (assessments) => {
  console.log("assessments in utils :>> ", assessments);
  if (assessments.length === 0) return 0;
  let totalTime = assessments.reduce((t, c) => {
    // console.log("moment(c.totalTime) :>> ", moment(c.totalTime));
    t = t + moment.duration(c.totalTime).as("milliseconds");
    return t;
  }, 0);

  console.log("totalTime :>> ", moment.duration(totalTime).asMinutes());
};

export {
  checkJWTExpiry,
  getLocalUserDetails,
  keyGenerator,
  getUserDetailsJWT,
  checkOrgIsActive,
  getCustomJWT,
  checkRole,
  getRole,
  getUserDetailsFromAPI,
  getAvgTotalTime,
};
