import React from "react";
import { withStyles, TextField } from "@material-ui/core";
const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#116a9a",
    },
    "& .MuiTextField-root": {
      // margin: theme.spacing(1),
      width: "25ch",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#116a9a",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#116a9a",
      },
      "&:hover fieldset": {
        borderColor: "#116a9a",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#116a9a",
      },
    },
    width: "100%",
  },
})(TextField);

const InputField = (props) => {
  return (
    <CssTextField
      className={props.className}
      label={props.label}
      variant="outlined"
      type={props.type}
      id={props.id}
      name={props.name}
      value={props.value}
      onChange={props.handleChange}
      disabled={props?.disabled}
      defaultValue={props?.defaultValue}
      onKeyPress={(e) => {
        return e.key === "Enter" && props.onSubmit ? props.onSubmit(e) : null;
      }}
    />
  );
};
export default InputField;
