import app from "firebase/app";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  // Prod
  apiKey: "AIzaSyBjuWy1RSiwdy0qDQBo0CwigZ2FmypNDM0",
  authDomain: "frle-282606.firebaseapp.com",
  projectId: "frle-282606",
  storageBucket: "frle-282606.appspot.com",
  messagingSenderId: "960155244509",
  appId: "1:960155244509:web:3792519cb8a3a4281bc834",

  // Dev
  // apiKey: "AIzaSyCSqXmgOoyjkeLXvGbuXhrYxBAurcAcJWo",
  // authDomain: "frle-24a5f.firebaseapp.com",
  // projectId: "frle-24a5f",
  // storageBucket: "frle-24a5f.appspot.com",
  // messagingSenderId: "411942034900",
  // appId: "1:411942034900:web:5e5ccae9a138d380114a41",
};

const FB = app.initializeApp(firebaseConfig);
const storage = app.storage();
export default FB;
export { storage };
