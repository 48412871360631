import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

import HeaderBar from "../../Component/HeaderBar/headerBar";

import SideNav from "../../Component/SideNav/sideNav";
import SideUser from "../../Component/SideUser/sideUser";
import RBAC from "../../Component/RBAC/RBAC";
import { initList } from "../../Component/SideNav/initList";
import "./organisationSetting.css";
import { OrgActions, AuthActions } from "../../ReduxStore/Actions";
import Tabletab from "../../Component/Table/Table";
import DetailsModel from "../../Component/Modal/inviteModal";
import Button from "../../Component/Button/button";
import { storage } from "../../Services/Utils/firebase";
import { ACTION_TYPE } from "../../Services/Constants";
import AlertDialog from "../../Component/AlertDialog/alertDialog";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const OrgSettings = () => {
  const classes = useStyles();
  let collapsedClass = useSelector(
    (state) => state.CollapsedSidenavReducer.collapsedClass
  );
  let user = useSelector((state) => state.AuthReducer.user);
  let fieldList = useSelector((state) => state.SuperAdminReducer.fieldList);
  let currentOrg = useSelector((state) => state.OrgReducer.currentOrg);
  const { product } = useSelector((state) => state.ProductReducer);

  //Modal
  const [detailsModel, setDetailsModel] = useState(false);
  const [passwordModel, setPasswordModel] = useState(false);
  const [state, setState] = useState({});
  const [orgName, setOrgName] = useState();
  const [newPassword, setNewPassword] = useState();
  const [currentPassword, setCurrentPassword] = useState();

  // const [tableData, setTableData] = useState([]);

  const allInputs = { imgUrl: "" };
  const [imageAsUrl, setImageAsUrl] = useState(allInputs);

  const [imageAsFile, setImageAsFile] = useState("");
  const [picEdit, setPicEdit] = useState(false);
  const [alertDetails, setAlertDetails] = useState({
    open: false,
    message: "",
    action: null,
  });

  let dispatch = useDispatch();

  const handleOrgNameChange = (e) => {
    const { value, name } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  useEffect(() => {
    if (currentOrg.length > 0) {
      // dispatch(
      //   OrgActions.getOrgResults(currentOrg[0]?.orgId, {
      //     callback: (response) => {},
      //   })
      // );
      // getTableData();
      setState({
        ...state,
        orgName: currentOrg[0].orgName,
      });

      setImageDetails();
    }
  }, [currentOrg]);

  const setImageDetails = () => {
    setPicEdit(false);
    if (currentOrg[0].orgImage == "" || currentOrg[0].orgImage == undefined) {
      setImageAsUrl({
        ...imageAsUrl,
        imgUrl: null,
      });
    } else {
      setImageAsUrl({
        ...imageAsUrl,
        imgUrl: currentOrg[0].orgImage,
      });
    }
  };

  const handleOrgNameUpdate = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const { orgId } = currentOrg[0];

    dispatch(
      OrgActions.updateOrg({
        updateBody: { ...state, orgId },
        orgId,
        callback: () => {
          setDetailsModel(false);
          dispatch(
            OrgActions.getOrgResults(currentOrg[0]?.orgId, product, {
              callback: (response) => {},
            })
          );
        },
      })
    );
  };

  // const getTableData = () => {
  //   let aggregatedUsers = [
  //     ...currentOrg[0].users,
  //     ...currentOrg[0].pendingInvitations,
  //   ];
  //   let finalData = aggregatedUsers.map((data) => {
  //     return [
  //       data["email"],
  //       data["uuid"] ? "Accepted" : "Pending",
  //       data["uuid"] ? data["roles"].toString() : "Student",
  //     ];
  //   });
  //   setTableData(finalData);
  // };

  const handlePicChange = (e) => {
    const image = e.target.files[0];
    console.log("image :>> ", image, image.size >= 5e6);
    if (image.size >= 5e6) {
      setAlertDetails({
        ...alertDetails,
        open: true,
        message: "Please upload an image less than 5MB!",
      });
      return;
    }
    setImageAsFile((imageAsFile) => image);
    setImageAsUrl({
      ...imageAsUrl,
      imgUrl: URL.createObjectURL(image),
    });
    setPicEdit(true);
  };

  const handleChangePassword = (e) => {
    e.preventDefault();

    dispatch(
      AuthActions.updatePassword({
        currentPassword,
        newPassword,
        user,
        callback: () => {
          setPasswordModel(false);
          setNewPassword("");
          setCurrentPassword("");
        },
      })
    );
  };

  const handleFireBaseUpload = (e) => {
    e.preventDefault();
    // async magic goes here...
    if (imageAsFile === "") {
    }
    const uploadTask = storage
      .ref(`/images/${imageAsFile.name}`)
      .put(imageAsFile);
    //initiates the firebase side uploading
    uploadTask.on(
      "state_changed",
      (snapShot) => {
        //takes a snap shot of the process as it is happening
      },
      (err) => {
        //catches the errors
      },
      () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        storage
          .ref("images")
          .child(imageAsFile.name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            const { orgId } = currentOrg[0];

            dispatch(
              OrgActions.updateOrg({
                updateBody: { orgImage: fireBaseUrl, orgId },
                orgId,
                // uuid: "Y4XDWlpHBdNdEuf34Aovekl8aXp2",
                callback: () => {
                  console.log("user", user);
                  dispatch(
                    // OrgActions.getOrgResults(user?.orgId, {
                    //   callback: (response) => {
                    //     setImageAsUrl((prevObject) => ({
                    //       ...prevObject,
                    //       imgUrl: fireBaseUrl,
                    //     }));

                    //     setPicEdit(false);
                    //   },
                    // })

                    OrgActions.getOrgById({
                      orgId: currentOrg[0]?.orgId,
                      callback: (response) => {
                        console.log("response", response.result.org[0]);
                        dispatch({
                          type: ACTION_TYPE.UPDATE_ORG,
                          payload: response.result.org[0],
                        });
                      },
                    })
                  );
                },
              })
            );
          });
      }
    );
  };

  return (
    <div className="d-flex over orgSetting">
      <div className={collapsedClass ? " sideNavExpand" : "sideNavContract "}>
        {user && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}{" "}
      </div>
      <div className={collapsedClass ? "screenContract bg" : "screenExpand bg"}>
        <HeaderBar
          width={collapsedClass ? "80%" : "95%"}
          title="Organisation Settings"
          searchbar="no"
        />
        <div className="componentSec orgSett ">
          <Grid container className="orgMainParent newSet sideUserOrg">
            <SideUser
              status="true"
              org={currentOrg}
              subEndDate={currentOrg[0]?.subscriptionEndDate}
              subStartDate={currentOrg[0]?.subscriptionStartDate}
              orgName={currentOrg[0]?.orgName}
              created={currentOrg[0]?.dateCreated}
              uploadImage="true"
              handleUpdateClick={() => setDetailsModel(true)}
              handlePicChange={handlePicChange}
              imageAsUrl={imageAsUrl}
              setImageAsFile={setImageAsFile}
              picEdit={picEdit}
              setPicEdit={setPicEdit}
              handleFireBaseUpload={handleFireBaseUpload}
              setImageDetails={setImageDetails}
              changePass="true"
              handleChangePasswordClick={() => setPasswordModel(true)}
            ></SideUser>
            {/* </Grid> */}
          </Grid>
        </div>
        {/* Multiple alert boxes will come here */}
        <AlertDialog
          open={alertDetails.open}
          handleClose={() =>
            setAlertDetails({
              ...alertDetails,
              open: false,
              message: "",
              action: null,
            })
          }
          message={alertDetails.message}
          button1={alertDetails.action == null ? null : "Yes"}
          button2={alertDetails.action == null ? "Ok" : "No"}
          handleDelete={alertDetails.action}
        />
        <DetailsModel
          open={detailsModel}
          handleClose={() => setDetailsModel(false)}
          title="Update Org Name"
          input={
            <input
              placeholder="Enter Org Name"
              style={{
                maxWidth: "659px",
                width: "100%",
                padding: "4px 15px",
                height: "47px",
                marginBottom: "19px",
                background: "#FFFFFF",
                boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.25)",
                borderRadius: "10px",
                border: "none",
              }}
              name="orgName"
              value={state.orgName}
              onChange={handleOrgNameChange}
            />
          }
          button={
            <Button
              background="primary"
              color="secondary"
              name="Update"
              handleClick={handleOrgNameUpdate}
            />
          }
          cancelButton={
            <Button
              background="primary"
              color="secondary"
              name="Cancel"
              handleClick={() => {
                setDetailsModel(false);
              }}
            />
          }
        />
        <DetailsModel
          open={passwordModel}
          handleClose={() => {
            setPasswordModel(false);
            setNewPassword("");
            setCurrentPassword("");
          }}
          title="Update Password"
          input={
            <>
              <input
                type="password"
                placeholder="Enter Current Password"
                style={{
                  maxWidth: "659px",
                  width: "100%",
                  padding: "4px 15px",
                  height: "47px",
                  marginBottom: "19px",
                  background: "#FFFFFF",
                  boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.25)",
                  borderRadius: "10px",
                  border: "none",
                }}
                name="currentPassword"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              <input
                type="password"
                placeholder="Enter New Password"
                style={{
                  maxWidth: "659px",
                  width: "100%",
                  padding: "4px 15px",
                  height: "47px",
                  marginBottom: "19px",
                  background: "#FFFFFF",
                  boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.25)",
                  borderRadius: "10px",
                  border: "none",
                }}
                name="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </>
          }
          button={
            <Button
              background="primary"
              color="secondary"
              name="Update"
              handleClick={handleChangePassword}
            />
          }
          cancelButton={
            <Button
              background="primary"
              color="secondary"
              name="Cancel"
              handleClick={() => {
                setPasswordModel(false);
                setNewPassword("");
                setCurrentPassword("");
              }}
            />
          }
        />
      </div>
    </div>
  );
};
export default OrgSettings;
