import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import "./ProgressBar.css";

const ProgressBarStep = (props) => {
  return (
    <ProgressBar
      className="bar"
      percent={props.value}
      filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
    >
      <Step transition="scale">
        {({ accomplished }) => (
          <div>
            1<span className="pbTitles">Register</span>
          </div>
        )}
      </Step>
      <Step transition="scale">
        {({ accomplished }) => (
          <div>
            2<span className="pbTitles pbTitles__center">Accept Invite</span>
          </div>
        )}
      </Step>
      <Step transition="scale">
        {({ accomplished }) => (
          <div>
            3<span className="pbTitles pbTitles__center">Start learning</span>
          </div>
        )}
      </Step>
    </ProgressBar>
  );
};

export default ProgressBarStep;
