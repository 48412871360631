import React, { useEffect, useRef, useState } from "react";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import Notification from "../Notification/Notification";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import "./headerBar.css";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SimpleBreadcrumbs from "../BreadCrumb/breadCrumb";
import { PRODUCTS, ROUTES } from "../../Services/Constants";
import { useDispatch, useSelector } from "react-redux";
import Account from "../Account/account";
import Button from "../Button/button";
import { ACTION_TYPE } from "../../Services/Constants";
import { useHistory, useLocation } from "react-router-dom";
import { checkRole, getRole } from "../../Services/Utils/utils";
import jwt from "jsonwebtoken";
import Learnsuite from "../../Images/CommonImages/Learnsuite.png";
import {
  AuthActions,
  OrgActions,
  ProductActions,
} from "../../ReduxStore/Actions";

const HearderBar = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, userDetails } = useSelector((state) => state.AuthReducer);
  let currentOrg = useSelector((state) => state.OrgReducer.currentOrg);
  const { loading } = useSelector((state) => state.CommonReducer);
  const ref = useRef();
  const { product } = useSelector((state) => state.ProductReducer);
  let allOrgs = [];
  const [role, setRole] = useState(null);
  const [roleType, setRoleType] = useState(null);
  const currURL = useLocation();

  useEffect(() => {
    if (user.uuid) {
      if (user?.role === "superAdmin") setRole("superAdmin");
      else if (currentOrg.length > 0) {
        // const roles = checkRole(currentOrg[0]);
        const role = getRole(currentOrg[0]);
        // console.log("role :>> ", role);
        setRole(role);
        setRoleType("org");
        // if (roles.isAdmin || roles.isTeacher || roles.owner) setRole("org");
      } else {
        setRole("user");
      }
      // console.log("currentrole", currentOrg[0]?.orgImage);
    }
  }, [user, currentOrg]);

  const handleOrgChange = (org) => {
    setActiveOrgToReducer(org);
  };

  const setActiveOrgToReducer = (org) => {
    const orgToken = jwt.sign(
      { orgId: org.orgId, userId: user.uuid },
      process.env.REACT_APP_PRIVATE_KEY
    );

    dispatch({
      type: ACTION_TYPE.CLEAR_CURRENT_ORG,
    });
    dispatch({
      type: ACTION_TYPE.SET_ACTIVE_ORG,
      payload: {
        activeOrg: org,
        token: orgToken,
      },
    });
  };
  const handleChangeProduct = (product) => {
    if (loading) return;
    if (currentOrg.length > 0)
      dispatch(
        OrgActions.getOrgResults(currentOrg[0]?.orgId, product, {
          callback: (response) => {},
        })
      );
    else {
      dispatch(ProductActions.setProduct({ product, callback: () => {} }));
    }
  };
  return (
    <div className="headerBar">
      <div
        className="topnavdivlist fixtopnavdivlist"
        style={{ width: props.width }}
      >
        <ul className="mb-0 headerUl">
          <li className="firtLitop" style={{ listStyle: "none" }}>
            <ul className="d-flex flex-row listHeaderBar pt-3">
              <li className="listHeaderBar1">
                <h3>{props.title}</h3>
                {/* {props.title !== "Super Admin Dashboard" ? (
                  <SimpleBreadcrumbs
                    Route={
                      user?.role == "superAdmin"
                        ? ROUTES.SUPER_ADMIN_DASHBOARD
                        : role == "org"
                        ? ROUTES.OGRANIZATIONDASHBOARD
                        : role == "user"
                        ? ROUTES.USER_DASHBOARD
                        : null
                    }
                    mainTitle={
                      user?.role == "superAdmin"
                        ? "Super Admin"
                        : role == "org"
                        ? "Org"
                        : role == "user"
                        ? "User"
                        : null
                    }
                    title={props.title}
                  />
                ) : null}{" "} */}
              </li>
              {props.searchbar !== "no" ? (
                <li className="listHeaderBar2 ">
                  <Paper component="form" className="inputHeaderBar">
                    <InputBase
                      placeholder="Search Org"
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (e.keyCode === 13 || e.keyCode === "Enter") {
                          return;
                        }
                      }}
                      onChange={(e) => {
                        props.filterFunction(e);
                        e.preventDefault();
                        if (e.keyCode === 13 || e.keyCode === "Enter") {
                          return;
                        }
                      }}
                      className="searchbox"
                    />
                  </Paper>
                </li>
              ) : product != PRODUCTS.DP ? (
                <li className="learnsuite">
                  <div className="learnsuite__logo">
                    <img src={Learnsuite}></img>
                  </div>
                </li>
              ) : null}

              <li className="listHeaderBar3">
                {/* <NotificationsNoneOutlinedIcon className=" ml-4 headerBarIcon" /> */}
                {/* <button onClick={props?.startTour}>
                  Start
                </button> */}
                {role == "user" ? (
                  <Notification
                    pendingInviteList={userDetails?.pendingInvitations}
                  />
                ) : null}
                <Account
                  className="headerBarIcon"
                  img={
                    roleType == "org"
                      ? currentOrg[0]?.orgImage
                      : role == "user"
                      ? user?.userImage
                      : null
                  }
                  name={
                    roleType == "org"
                      ? currentOrg[0]?.orgName
                      : role == "user"
                      ? user?.userName
                      : null
                  }
                  roleType={roleType == "org" ? "org" : role}
                />
                {/* {
                  user?.role == "superAdmin"
                    ? "Super Admin"
                    : ` ${user["userName"]}`
                  // : roleType == "org"
                  // ? `${role}`
                  // role == "user"
                  // ? ` ${user["userName"]}`
                  // : null
                } */}
              </li>
              {/* <h2>{currURL}</h2> */}
            </ul>
            {/* {currURL.pathname === "/organization-dashboard" || currURL.pathname === "/group-management" || currURL.pathname === "/user-dashboard" ? 
              <ul className="d-flex flex-row listHeaderBar pt-1">
                {role === "superAdmin" ? null : props.noProduct ? null : (
                  <li className="changeBtn">
                    <div className="dropdownMain">
                      <div className="personAddBtn">
                        {product === PRODUCTS.ALS
                          ? "ALS"
                          : product === PRODUCTS.CB
                            ? "Situational Awareness"
                            : product === PRODUCTS.DESCALATION
                              ? "De-Escalation"  : product === PRODUCTS.DP
                              ? "Deteriorating Patient"
                              : null}
                        <ArrowDropDownIcon />
                      </div>

                      <div className="dropdown">
                        <ul className="text-center">
                          <li
                            value="ALS"
                            onClick={(e) => {
                              handleChangeProduct("als");
                            }}
                          >
                            ALS
                          </li>
                          <li
                            value="Situational Awareness"
                            onClick={(e) => {
                              handleChangeProduct("codeBlack");
                            }}
                          >
                            Situational Awareness
                          </li>
                          <li
                            value="De-Escalation"
                            onClick={(e) => {
                              handleChangeProduct("deEscalation");
                            }}
                          >
                            De-Escalation
                          </li>
                          <li
                            value="De-Escalation"
                            onClick={(e) => {
                              handleChangeProduct("deterioratingPatient");
                            }}
                          >
                           Deteriorating Patient
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                )}

              </ul>
              : null
            } */}
          </li>
        </ul>
      </div>
    </div>
  );
};
export default HearderBar;
