import React from "react";
import ChartCard from "./ChartCard";

const Other = ({ assessment }) => {
  const setResults = () => {
    let labels = [];

    if (assessment.overallAccuracy > 80)
      labels.push({
        success: true,
        label: `Overall Accuracy ${assessment.overallAccuracy}%`,
      });
    else
      labels.push({
        success: false,
        label: `Overall Accuracy ${assessment.overallAccuracy}%`,
      });

    if (formatTime(assessment.totalTime) < 800)
      labels.push({
        success: true,
        label: `Total Time Taken: ${assessment.totalTime}`,
      });
    else
      labels.push({
        success: false,
        label: `Total Time Taken: ${assessment.totalTime}`,
      });

    if (assessment.stoppedCprCountOutCoached !== 0)
      labels.push({
        success: false,
        label: `Incorrectly stopped CPR out of COACHED: x ${assessment.stoppedCprCountOutCoached}`,
      });

    if (assessment.abcde)
      labels.push({
        success: true,
        label: `Completed A-G assessment`,
      });
    else
      labels.push({
        success: false,
        label: `Completed A-G assessment`,
      });

    if (formatTime(assessment.timeToFirstCoached) <= 100)
      labels.push({
        success: true,
        label: `Time to first COACHED: ${assessment.timeToFirstCoached}`,
      });
    else
      labels.push({
        success: false,
        label: `Time to first COACHED: ${assessment.timeToFirstCoached}`,
      });

    return labels;
  };

  const formatTime = (_time) => {
    return +_time?.replace(":", "");
  };

  return <div>{assessment && <ChartCard labels={setResults()} />}</div>;
};

export default Other;
