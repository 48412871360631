import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import HeaderBar from "../../Component/HeaderBar/headerBar";
import Table from "../../Component/Table2";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import { LauncherActions, OrgActions } from "../../ReduxStore/Actions";
import RBAC from "../../Component/RBAC/RBAC";
import SideNav from "../../Component/SideNav/sideNav";
import { initList } from "../../Component/SideNav/initList";
import DetailsModel from "../../Component/Modal/inviteModal";
import Button from "../../Component/Button/button";

import "./styles.css";
import AddEditAppDetailModal from "../../Component/Modal/AddEditAppDetailModal";

const PreviousVersions = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  console.log("props?.match?.params?.id", props?.match?.params?.id);

  const [modalDetails, setModalDetails] = useState({});
  const [data, setData] = useState([]);

  let storeApp = "ALS";

  let collapsedClass = useSelector(
    (state) => state.CollapsedSidenavReducer.collapsedClass
  );
  let user = useSelector((state) => state.AuthReducer.user);
  const { loading } = useSelector((state) => state.CommonReducer);
  useEffect(() => {
    getPrevVersions();
  }, []);

  const getPrevVersions = () => {
    dispatch(
      LauncherActions.getPrevVersions({
        appId: props?.match?.params?.id,
        callback: (res) => {
          console.log("getPrevVersions", res);
          if (res && res.result && res.result.length > 0) setData(res.result);
        },
      })
    );
  };

  const handleOptionClick = (item, option) => {
    console.log("handleOptionClick", item, option);
    if (option.value == "details")
      setModalDetails({
        ...modalDetails,
        type: "view",
        ...item,
      });
  };

  const resetModal = () => {
    setModalDetails({
      ...modalDetails,
      type: null,
      version: null,
      metadata: null,
      appId: null,
      open: false,
      description: "",
      whatsNew: "",
      imageArray: "",
      bannerImage: "",
    });
  };

  return (
    <div className="d-flex over">
      <div className={collapsedClass ? " sideNavExpand" : "sideNavContract"}>
        {user && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}{" "}
      </div>
      <div className={collapsedClass ? "screenContract bg" : "screenExpand bg"}>
        <HeaderBar
          width={collapsedClass ? "80%" : "95%"}
          title="Previous Versions"
          searchbar="no"
          noProduct={true}
        />
        <div className="componentSec">
          <div className="d-flex justify-content-between flexsupad">
            <div className="fullDiv">
              <div
                className="backIcon mr-2"
                onClick={() => {
                  history.goBack();
                }}
              >
                <ArrowBackIcon className="icon" />
              </div>
              Back
            </div>
          </div>
          {/* Render Previous Version List */}
          <div
            className="pb-31 mt-4 d-flex flex-column"
            style={{ gap: "10px", padding: "10px 20px", height: "350px" }}
          >
            <div className="tableHeading">Previous Versions</div>

            <Table
              tableHeaders={["Name", "Version", "Date Uploaded", "Action"]}
              tableData={data}
              hideColumns={[
                "id",
                "metadata",
                "downloadLink",
                "_id",
                "appId",
                "description",
                "whatsNew",
                "bannerImage",
                "imageArray",
              ]}
              action={{
                // name: "Action",
                buttonOne: (data) => {
                  return (
                    <Button
                      name="View Details"
                      id="updateBtn"
                      background="#fff"
                      color="#116a9a"
                      border="1px solid #116a9a"
                      maxWidth="180px"
                      padding="7px 15px"
                      handleClick={() => {
                        handleOptionClick(data, { value: "details" });
                      }}
                    />
                  );
                },
              }}
            />
          </div>
        </div>
      </div>

      <AddEditAppDetailModal
        open={modalDetails.type === "view"}
        type={modalDetails?.type?.toUpperCase()}
        handleClose={resetModal}
        appDetails={modalDetails || {}}
      />

      {/* <DetailsModel
        open={modalDetails.type == "details"}
        handleClose={resetModal}
        title={"Details"}
        detailContainer={
          <div className="simdashLauncherDetailModalContainer">
            <div className="detailModalContainerRow">
              <h6>Name</h6>

              <p>{modalDetails.appId}</p>
            </div>
            <div className="detailModalContainerRow">
              <h6>Version Number</h6>
              <p>{modalDetails.version}</p>
            </div>
            <div className="detailModalContainerRow">
              <h6>Date Uploaded</h6>
              <p>{modalDetails.createdAt}</p>
            </div>
            <div className="detailModalContainerRow">
              <h6>Additional Data</h6>
              <p>{modalDetails.metadata}</p>
            </div>
            <div className="detailModalContainerRow">
              <h6>Download</h6>
              <p>
                <a href={modalDetails.downloadLink}>
                  {modalDetails?.appId?.toUpperCase()}
                </a>
              </p>
            </div>
          </div>
        }
        cancelButton={
          <div className="cancelButton">
            <Button
              id="cancelBtn"
              color="primary"
              border="1px solid #116a9a"
              name="Close"
              maxWidth="103px"
              handleClick={resetModal}
            />
          </div>
        }
      /> */}
    </div>
  );
};

export default PreviousVersions;
