import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import GroupIcon from "@material-ui/icons/Group";
import "./orgdata.css";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  title: {
    marginLeft: "20px",
    fontSize: "25px",
    fontWeight: "900",
    marginBottom: "0px",
  },
  members: {
    padding: "8px 20px",
  },
  groups: {
    padding: "5px 20px",
    fontSize: "18px",
    fontWeight: "800",
  },
  listitem: {
    "& .MuiAvatar-colorDefault": {
      backgroundColor: "#116a9a",
      color: "white",
    },
  },
}));

const OrganizationMetaData = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Paper className="paperOrg">
        <div style={{ display: "flex" }}>
          <ListItem className={classes.listitem}>
            <ListItemAvatar>
              <Avatar>
                <GroupIcon className={classes.avatar} />
              </Avatar>
            </ListItemAvatar>
            <h2 className={classes.title}>{props.title}</h2>
          </ListItem>
        </div>
        <div style={{ display: "flex" }}>
          <div className={classes.groups}>{props.groups}</div>
          <div className={classes.members}>{props.members}</div>
        </div>
      </Paper>
    </div>
  );
};

export default OrganizationMetaData;
