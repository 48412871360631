import React from "react";
import "./styles.css";

const Labels = ({ labels }) => {
  return (
    <div className="card-info-container">
      {labels.map(({ label, success, warning = false, title }, index) => (
        <div className="card-info" key={Math.random() + Math.random() + index}>
          <div
            className="info-status-container"
            style={{
              backgroundColor: success
                ? warning
                  ? title
                    ? "white"
                    : "orange"
                  : "#5cb55b"
                : "#ff4f4f",
            }}
          >
            {success
              ? warning
                ? title
                  ? "Info"
                  : "warning"
                : "success"
              : "failed"}
          </div>
          <div
            className="info-container"
            style={{ fontWeight: title ? "bold" : null }}
          >
            {label}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Labels;
