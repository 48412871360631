import BubbleGraph from "../Graph/bubble";
import React from "react";
import HorizontalBarGraph from "../Graph/barGraph";
import CustomCard from "../Card/customCard";
import PieChart from "../Graph/multiSeriesPie";
import AsystolePie from "../Graph/asystolePie";
import AtrialFibrillationPie from "../Graph/atrialFibrillationPie";
import SinusRythmPie from "../Graph/sinusRythmPie";
import VentriCularFibrillationPie from "../Graph/ventriCularFibrillationPie";
import VentriCularTachycardiaPie from "../Graph/ventriCularTachycardiaPie";
import "./style.css";
import { useSelector } from "react-redux";
import { getAvgTotalTime, getNumOfPeople } from "../../Services/Utils/utils";
import { APPLICATION_CONSTANTS } from "../../Services/Constants";
import { getAETrueCount, getAvgScoreALS, getTotalSeconds } from "../../Helper";
import { withRouter } from "react-router";
import { CircularProgress } from "@material-ui/core";
import AssessmentIcon from "@material-ui/icons/Assessment";
import Legend from "../Legend";

const ALSGraphs = React.memo((props) => {
  const { allTeamsAssessmentResults, peopleCount } = props;

  const { loading } = useSelector((state) => state.CommonReducer);

  let user = useSelector((state) => state.AuthReducer.user);
  console.log("allTeamsAssessmentResults :>> ", allTeamsAssessmentResults);

  if (loading)
    return (
      <div className="spinnerDiv" style={{ width: "100%" }}>
        {" "}
        <CircularProgress color="inherit"></CircularProgress>
      </div>
    );
  else
    return (
      <>
        <div className="d-flex flexsupad">
          {user.role !== "superAdmin" && (
            <CustomCard
              //   no={
              //     <AssessmentIcon
              //       fontSize="large"
              //       style={{ fontSize: "2.1875rem !important" }}
              //     />
              //   }
              handleClick={() =>
                props.history.push(`/recent-assessment/${user.uuid}`)
              }
              // fontSize="64px"
              title="View Assessments"
            />
          )}
          {/* {currentOrg.length === 0 ? (
            <CustomCard
              //   no={
              //     <AssessmentIcon
              //       fontSize="large"
              //       style={{ fontSize: "2.1875rem !important" }}
              //     />
              //   }
              handleClick={() =>
                props.history.push(`/recent-assessment/${user.uuid}`)
              }
              // fontSize="64px"
              title="View Assessments"
            />
          ) : (
            <CustomCard
              no={peopleCount}
              // fontSize="64px"
              title="Number of People"
            ></CustomCard>
          )} */}

          <CustomCard
            no={allTeamsAssessmentResults.length}
            fontSize="64px"
            title="Number of Assessments"
          ></CustomCard>

          <CustomCard
            no={getTotalSeconds(allTeamsAssessmentResults)}
            fontSize="64px"
            title="Total Time in Sim"
          ></CustomCard>
          <CustomCard
            no={getTotalSeconds(allTeamsAssessmentResults, "avg")}
            fontSize="64px"
            title="Avg Total Time"
          ></CustomCard>
          <CustomCard
            no={getAvgScoreALS(allTeamsAssessmentResults)}
            fontSize="64px"
            title="Avg Score"
          ></CustomCard>
          <CustomCard
            no={getAETrueCount(allTeamsAssessmentResults)}
            fontSize="30px"
            className="A_G"
            title="A-G Completed"
          ></CustomCard>
        </div>
        {allTeamsAssessmentResults.length >= 0 ? (
          <>
            <div className="fixedPieCharts">
              <div className="gapPie">
                <AtrialFibrillationPie
                  allTeamsAssessmentResults={allTeamsAssessmentResults}
                ></AtrialFibrillationPie>
              </div>
              <div className=" gapPie">
                <PieChart
                  allTeamsAssessmentResults={allTeamsAssessmentResults}
                ></PieChart>
              </div>

              <div className="gapPie">
                <AsystolePie
                  allTeamsAssessmentResults={allTeamsAssessmentResults}
                ></AsystolePie>
              </div>

              <div className=" gapPie">
                <VentriCularFibrillationPie
                  allTeamsAssessmentResults={allTeamsAssessmentResults}
                ></VentriCularFibrillationPie>
              </div>

              <div className="gapPie">
                <VentriCularTachycardiaPie
                  allTeamsAssessmentResults={allTeamsAssessmentResults}
                ></VentriCularTachycardiaPie>
              </div>

              {/* <div className="col-lg-2 col-md-4 col-xs-6 gapPie">
                <SinusRythmPie
                  allTeamsAssessmentResults={allTeamsAssessmentResults}
                ></SinusRythmPie>
              </div> */}
            </div>
            <Legend />
          </>
        ) : (
          <div
            style={{
              color: "black",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "3px",
              display: "flex",
              justifyContent: "center",
              margin: "20px auto",
              fontWeight: "700",
              fontSize: "19px",
            }}
          >
            Data Not Yet Available
          </div>
        )}
        <div className="flexGraph als">
          <div className="graphbgMainL">
            <div className="GraphBG">
              <BubbleGraph
                allTeamsAssessmentResults={allTeamsAssessmentResults}
                // orgUsers={orgUsers}
              ></BubbleGraph>
            </div>
          </div>

          <div className="graphbgMainR barChartSpecial">
            <div className="GraphBG col-sm-6">
              <h1>ventilation</h1>

              <HorizontalBarGraph
                allTeamsAssessmentResults={allTeamsAssessmentResults}
                type="ventilation"
              ></HorizontalBarGraph>
            </div>
            <div className="GraphBG col-sm-6">
              <h1>fluids</h1>

              <HorizontalBarGraph
                allTeamsAssessmentResults={allTeamsAssessmentResults}
                type="fluids"
              ></HorizontalBarGraph>
            </div>
            <div className="GraphBG col-sm-6">
              <h1>cpr</h1>

              <HorizontalBarGraph
                allTeamsAssessmentResults={allTeamsAssessmentResults}
                type="cpr"
              ></HorizontalBarGraph>
            </div>
            <div className="GraphBG col-sm-6">
              <h1>HT</h1>

              <HorizontalBarGraph
                allTeamsAssessmentResults={allTeamsAssessmentResults}
                type="HT"
              ></HorizontalBarGraph>
            </div>
          </div>
        </div>
      </>
    );
});
export default withRouter(ALSGraphs);
