import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import "./customCard.css";
import CardContent from "@material-ui/core/CardContent";
import { Grid, Typography } from "@material-ui/core";
import Button from "../Button/button";

const useStyles = makeStyles({
  root: {
    borderRadius: "3px",
    backgroundColor: "#116a9a",
  },
  content: {
    width: "100%",
    "& p": {
      color: "#ffffff",
    },
  },
  name: {
    fontWeight: 600,
  },

  card: {
    borderRadius: "3px",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

function OrgSelectionCard({
  name,
  no,
  pendingInvites,
  created,
  setActiveOrg,
  org,
  handleAccept,
  buttonName,
  selectType,
  checkedState,
  ...props
}) {
  const classes = useStyles();

  return (
    <Card
      className={classes.root}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: props.width,
        height: props.height,
        fontSize: props.fontSize,
        margin: "10px 0",
        padding: "20px 20px",
        color: props.color,
        onClick: props?.handleClick,
        cursor: props?.handleClick ? "pointer" : "auto",
      }}
      onClick={props?.handleClick}
    >
      <CardContent className={classes.content}>
        <Grid
          container
          justifyContent="space-between"
          className="justify-content-space-between"
        >
          <Grid xs={12} sm={8} item>
            <Typography className="nameOrg text-left">{name}</Typography>
            <Grid container>
              {no && (
                <Grid item>
                  <Typography className="grayOrg pt-8">{no} Users</Typography>
                </Grid>
              )}
              {no && <span className="spacingDot">.</span>}
              {pendingInvites && (
                <Grid item>
                  <Typography className="grayOrg pt-8">
                    {pendingInvites} Pending Invitations
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid xs={12} sm={4} item>
            {created && (
              <Grid item>
                <Typography className="grayOrg pb-8">
                  Created {created}{" "}
                </Typography>
              </Grid>
            )}
            {buttonName && (
              <Grid item>
                <Button
                  background="primary"
                  name={buttonName}
                  handleClick={
                    handleAccept ? handleAccept : () => setActiveOrg(org)
                  }
                  color="secondary"
                  textAlign="center"
                ></Button>
              </Grid>
            )}
            {selectType && (
              <Grid item>
                {" "}
                <input
                  type="checkbox"
                  checked={checkedState}
                  className="tickBox"
                  onChange={handleAccept}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
export default OrgSelectionCard;
