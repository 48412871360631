import React, { useEffect, useState } from "react";
import "./productAnalyticsAdmin.css";
import Graph from "../../Component/Graph/graph";
import { useSelector, useDispatch } from "react-redux";
import HeaderBar from "../../Component/HeaderBar/headerBar";
import SideNav from "../../Component/SideNav/sideNav";
import CustomCard from "../../Component/Card/customCard";
import SideSearch from "../../Component/SideSearch/sideSearch";
import OrganizationMetaData from "../../Component/OrganizationMetaData/organizationMetaData";
import ScatterChart from "../../Component/Graph/scatter";
import { SuperAdminActions } from "../../ReduxStore/Actions";
import LineGraph from "../../Component/Graph/LineGraph";
import CircularProgress from "@material-ui/core/CircularProgress";
import RBAC from "../../Component/RBAC/RBAC";
import { initList } from "../../Component/SideNav/initList";
import { Typography } from "@material-ui/core";

import Tabs from "../../Component/Tabs/Tabs";
import BusinessIcon from "@material-ui/icons/Business";
import { YearSelection } from "@material-ui/pickers/views/Year/YearView";

const ProductAnalyticsAdmin = () => {
  const dispatch = useDispatch();
  const [activeOrg, setActiveOrg] = useState();

  const [allOrgsState, setAllOrgsState] = useState();
  const [resultState, setResultState] = useState([]);
  const [productDetails, setProductDetails] = useState("als");
  const [loading, setLoading] = useState(false);
  const [activeClass, setActiveClass] = useState();
  const [disable, setDisable] = useState(false);
  const [tableData, setTableData] = useState([]);
  let collapsedClass = useSelector(
    (state) => state.CollapsedSidenavReducer.collapsedClass
  );

  let result = useSelector((state) => state.SuperAdminReducer.orgResults);
  let allOrgs = useSelector((state) => state.SuperAdminReducer.allOrgs);
  let user = useSelector((state) => state.AuthReducer.user);
  const { product } = useSelector((state) => state.ProductReducer);
  // const { product } = useSelector((state) => state.ProductReducer);

  const { users: orgUsers } = useSelector(
    (state) => state.SuperAdminReducer.currentOrg
  );

  useEffect(() => {
    if (user.uuid)
      dispatch(
        SuperAdminActions.getAllOrgs({
          uuid: user.uuid,
          callback: (response) => {},
        })
      );
  }, [user]);

  useEffect(() => {
    if (allOrgs.length > 0 && product) {
      setProductDetails(product);

      changeOrgResults();
      let orgState = allOrgs.filter((org) => org["orgId"]);
      orgState.map((org) => {
        if (org.users && org.users.length > 0) {
          let filteredUsrs = org.users.filter(
            (usr) => usr.seatAlloted && usr.uuid
          );

          org.users = filteredUsrs;
        }
      });

      setAllOrgsState(orgState);
    }
  }, [allOrgs, product]);

  // useEffect(() => {
  //   if (product) {
  //     setProductDetails(product);
  //     changeOrgResults();
  //   }
  // }, [product]);

  const getTableData = (result) => {
    let finalData = result?.map((data) => {
      return [data, data, data];
    });
    setTableData(finalData);
  };

  const changeOrgResults = () => {
    if (allOrgs.length > 0) {
      handleOrgSelect(allOrgs[0]);
      setActiveClass(`active${allOrgs[0].orgId}`);
    }
  };

  const handleOrgSelect = async (org) => {
    setLoading(true);
    setDisable(true);
    dispatch({
      type: "Clean",
    });
    dispatch(
      SuperAdminActions.getOrgResults({
        orgId: org.orgId,
        product,

        callback: (response) => {
          if (response && Array.isArray(response.result)) {
            // Filter users who have uuid

            const validOrgUsers = org?.users?.map(
              (usr) => usr.seatAlloted && usr.uuid
            );

            const validResultData = response.result.filter(
              (usr) => usr && validOrgUsers?.includes(usr.uuid)
            );

            setResultState(validResultData);
            getTableData(validOrgUsers);

            setActiveOrg(org);
            setProductDetails(product);
            // setTimeout(() => {
            setLoading(false);
            // }, 5000);
            setDisable(false);
          } else {
            setLoading(false);
            // }, 5000);
            setDisable(false);
          }
        },
      })
    );
  };

  return (
    <div className="d-flex over">
      <div className={collapsedClass ? " sideNavExpand" : "sideNavContract "}>
        {user && (
          <RBAC
            userRole={user?.role}
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}
      </div>
      <div
        className={collapsedClass ? "screenContract bg" : "screenExpand bg "}
      >
        <HeaderBar
          width={collapsedClass ? "80%" : "95%"}
          title="Product Analytics"
          searchbar="no"
        />

        <div className="lifeSupport ">
          <div className="d-flex justify-content-between ">
            <SideSearch
              isProductAnalytics={true}
              allOrgs={allOrgsState}
              user={user}
              disable={disable}
              handleSelect={handleOrgSelect}
              activeClass={activeClass}
              setActiveClass={(cls) => setActiveClass(cls)}
            />
            {allOrgsState?.length === 0 ? (
              <div className="spinnerDiv">
                <Typography variant="h2" component="h2">
                  No Organisation
                </Typography>
              </div>
            ) : loading ? (
              <div className="spinnerDiv">
                {" "}
                <CircularProgress color="inherit"></CircularProgress>
              </div>
            ) : activeOrg ? (
              <div className="lifeSupportContent">
                <div className="grpCardDiv">
                  <div className="leftGrp">
                    <div>
                      <BusinessIcon />
                    </div>
                    <div>
                      <h2>{activeOrg?.orgName}</h2>
                      <div
                        className="btnDiff"
                        style={{
                          display: "flex",
                          gap: "10px",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="fixPadding grp-chart-parent">
                  {/* {!loading  ? ( */}
                  <Tabs
                    allTeamsAssessmentResults={resultState}
                    tableData={tableData}
                    product={productDetails}
                    tabValue={0}
                  />
                </div>
              </div>
            ) : (
              <div className="spinnerDiv">
                <Typography variant="h2" component="h2">
                  No Organisation
                </Typography>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductAnalyticsAdmin;
