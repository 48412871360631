import React from "react";
import { Bar } from "react-chartjs-2";
import moment from "moment";

const BarChart = ({ assessmentData, type }) => {
  const renderLabels = () => {
    let _labels = [
      "Confused",
      "Irratable",
      "Boisterous",
      "Pyshical Threats",
      "Verbal Threats",
      "Attacking",
    ];

    return _labels;
  };

  const generateColor = () => Math.floor(Math.random() * 255);

  const renderData = () => {
    let _data = [];

    let count = {
      confused: 0,
      irratable: 0,
      boisterous: 0,
      pyshicalThreats: 0,
      verbalThreats: 0,
      attacking: 0,
    };
    assessmentData &&
      assessmentData
        .sort((a, b) => a.dateCreated.localeCompare(b.dateCreated))
        .map(({ guesses, answers }, outerIndex) => {
          let answerLength =
            guesses.length < answers.length ? guesses.length : answers.length;

          for (let i = 0; i < answerLength; i++) {
            for (let key of Object.keys(count)) {
              if (type === "failToIdentify") {
                if (guesses[i][key] === false && answers[i][key] === true)
                  count[key]++;
              } else if (type === "misIdentify") {
                if (guesses[i][key] === true && answers[i][key] === false)
                  count[key]++;
              }
            }
          }
        });

    _data = Object.values(count); // [""]
    return _data;
  };

  const data = {
    labels: renderLabels(),
    datasets: [
      {
        label: `${type.toUpperCase()}`,
        fill: false,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.2)",
        ],
        fontSize: 8,
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
        ],
        borderWidth: 1,
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        // pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: `rgba(${generateColor()},${generateColor()},${generateColor()}, 1)`,
        pointBorderWidth: 1,
        pointHoverRadius: 4,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 5,
        data: renderData(),
      },
    ],
  };

  const options = {
    indexAxis: "y",
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    // elements: {
    //   bar: {
    //     borderWidth: 2,
    //   },
    // },
    plugins: {
      legend: {
        display: false,
      },
    },
    // legend: {
    //   display: false,
    // },
    responsive: true,
  };

  return (
    <div>
      {" "}
      <Bar data={data} options={options} />{" "}
    </div>
  );
};

export default BarChart;
