import React, { useState } from "react";

import Button from "../../Component/Button/button";
import { ValidateLoginForm } from "./Validation";
import "./Login.css";
import InputField from "../../Component/Input/input";

import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Header from "../Header/Header";
import { ACTION_TYPE, ROUTES } from "../../Services/Constants";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AuthActions, OrgActions } from "../../ReduxStore/Actions";
import Toast from "../../Component/Toast/toast";
import CryptoJS from "react-native-crypto-js";
import { APPLICATION_CONSTANTS } from "../../Services/index";
import jwt from "jsonwebtoken";
import { getUserDetailsFromAPI } from "../../Services/Utils/utils";
import _Firebase from "../../Services/Utils/firebase";

const Login = (props) => {
  const dispatch = useDispatch();

  const [loginAs, setLoginAs] = useState("user");
  const [checked, setChecked] = React.useState();
  const [state, setState] = useState();
  const [toast, setToast] = useState();
  const [loading, setLoading] = useState(false);
  const [errorData, setError] = React.useState();
  const [isShowPassword, setPasswordType] = React.useState(false);
  React.useEffect(() => {
    (async () => {
      let rememberme = await localStorage.getItem(
        APPLICATION_CONSTANTS.REMEMBER_ME
      );
      if (rememberme) {
        setChecked(true);
        rememberme = CryptoJS.AES.decrypt(
          rememberme,
          APPLICATION_CONSTANTS.REMEMBER_ME_SECRETE_KEY
        );
        rememberme = rememberme.toString(CryptoJS.enc.Utf8);
        rememberme = JSON.parse(rememberme);
      } else {
        setChecked(false);
      }
    })();
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const result = await ValidateLoginForm(state);
    if (result?.isFormValid) {
      setLoading(true);
      setError({});
      if (checked) {
        const rememberme = CryptoJS.AES.encrypt(
          JSON.stringify(state),
          APPLICATION_CONSTANTS.REMEMBER_ME_SECRETE_KEY
        ).toString();
        localStorage.setItem(APPLICATION_CONSTANTS.REMEMBER_ME, rememberme);
      } else {
        localStorage.removeItem(APPLICATION_CONSTANTS.REMEMBER_ME);
      }
      const { email, password } = state;
      dispatch(
        AuthActions.signIn({
          email: email.trim(),
          password: password.trim(),
          loginAs,
          callback: (response) => {
            const { user } = response;
            if ((!response.user && response.code) || !user) {
              setToast({
                open: true,
                message: response.code
                  ? response.message
                  : "Invalid Credentials",
                type: "error",
                duration: 5000,
              });
              setLoading(false);
              return;
            }

            getUserDetailsFromAPI({
              user,
              dispatch,
              callback: (response) => {
                const { type } = response;
                if (type === 0) {
                  return props.history.push(ROUTES.USER_DASHBOARD);
                } else if (type === 1)
                  return props.history.push(ROUTES.GROUPMANAGEMENT);
              },
            });
          },
        })
      );
    } else {
      setError(result?.error);
    }
  };

  const logout = () => {
    dispatch(
      AuthActions.signOut({
        callback: (response) => {},
      })
    );
  };

  if (props?.location?.state?.from === "signout") {
    logout();
  }
  // const handleUser = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   setLoginAs(e.target.name);
  //   setState({
  //     email: "",
  //     password: "",
  //   });
  // };

  return (
    <div className="AuthBg">
      <Header />
      <div className="signIn">
        {/* <div className="setUser d-flex mb-3">
          <button
            className={loginAs == "user" ? "button1 active" : "button1"}
            onClick={handleUser}
            name="user"
          >
            User
          </button>
          <button
            name="organization"
            className={
              loginAs == "organization" ? "button2 active" : "button2 "
            }
            onClick={handleUser}
          >
            Organization
          </button>
        </div> */}
        <div className="signInFormDiv signInmain">
          <div className="formDiv">
            <h1 className="signInHeading">Sign In </h1>
            <div className="horizontalLine"></div>
            <div className="randomName">
              <form className="signInForm" noValidate autoComplete="off">
                <div className="signInEmail">
                  <InputField
                    label="E-Mail"
                    type="text"
                    id="custom-css-outlined-input"
                    name="email"
                    value={state?.email}
                    handleChange={handleChange}
                  />
                  <p className="errorMsg">
                    {" "}
                    {errorData?.email && errorData.email[0]}
                  </p>
                </div>
                <div className="signInPassword">
                  <InputField
                    label="Password"
                    type={isShowPassword == true ? "text" : "password"}
                    variant="outlined"
                    id="custom-css-outlined-input"
                    name="password"
                    value={state?.password}
                    handleChange={handleChange}
                    onSubmit={onSubmit}
                  />
                  {isShowPassword === true ? (
                    <a
                      className="eyeicon"
                      onClick={() => setPasswordType(false)}
                    >
                      <VisibilityIcon />
                    </a>
                  ) : (
                    <a
                      className="eyeicon"
                      onClick={() => setPasswordType(true)}
                    >
                      <VisibilityOffIcon />
                    </a>
                  )}

                  <p className="errorMsg">
                    {" "}
                    {errorData?.password && errorData.password[0]}
                  </p>
                </div>
                <div className="signInFormOptions">
                  <div className="rememberMeOption">
                    <input
                      type="checkbox"
                      onChange={(e) => setChecked(!checked)}
                    />
                    <label className="pt-1 mt-1">Remember Me</label>
                  </div>
                  <div className="forgotPassword ">
                    <Link to={ROUTES.FORGOT_PASSWORD}>
                      <div className="pt-1 mt-1">Forgot Password?</div>
                    </Link>
                  </div>
                </div>
                <div className="signInButton">
                  <Button
                    width="160px"
                    height="42px"
                    background="secondary"
                    borderRadius="13px"
                    fontSize="15px"
                    color="primary"
                    padding="10px"
                    transition="background 0.3s"
                    display="inline-flex"
                    alignItem="center"
                    name="Sign In"
                    handleClick={onSubmit}
                    loading={loading}
                  />
                </div>
              </form>
              <div className="forgotPassword ">
                <a
                  rel="noreferrer"
                  href="https://learnsuite.kb.help/user-getting-started"
                  target="_blank"
                  style={{
                    color: "#0056b3",
                    textDecoration: "underline",
                  }}
                >
                  <div className="pt-1 mt-1">
                    Having trouble? Check our getting started guide
                  </div>
                </a>
              </div>
            </div>
            {toast?.open ? (
              <Toast
                open={toast.open}
                message={toast.message}
                duration={toast.duration}
                type={toast.type}
                handleClose={() =>
                  setToast({
                    ...toast,
                    open: false,
                  })
                }
              ></Toast>
            ) : null}
          </div>
          <div
            className="detailsDiv"
            style={loginAs == "organization" ? { display: "none" } : null}
          >
            <h2>Are you a new user? Register now.</h2>
            <div className="registerButton">
              <Link to={ROUTES.REGISTER}>
                <Button
                  width="160px"
                  height="42px"
                  background="secondary"
                  borderRadius="13px"
                  fontSize="19px"
                  color="primary"
                  padding="10px"
                  transition="background 0.3s"
                  display="inline-flex"
                  alignItem="center"
                  name="Register"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
