import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import HeaderBar from "../../Component/HeaderBar/headerBar";
import SideNav from "../../Component/SideNav/sideNav";
import { OrgActions } from "../../ReduxStore/Actions";
import RBAC from "../../Component/RBAC/RBAC";
import _Firebase from "../../Services/Utils/firebase";
import { initList } from "../../Component/SideNav/initList";
import LineGraph from "../../Component/Graph/LineGraph";
import { AccountCircleOutlined, ArrowBack } from "@material-ui/icons";
import Button from "../../Component/Button/button";
import { useHistory } from "react-router-dom";
import { getTotalSeconds } from "../../Helper/index";
import "./userData.css";

const UserData = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [id, setId] = useState();
  let collapsedClass = useSelector(
    (state) => state.CollapsedSidenavReducer.collapsedClass
  );
  let assessmentData = useSelector(
    (state) => state.OrgReducer.singleUserAssessment
  );
  let userName = useSelector((state) => state.OrgReducer.userDetailsUuid.user);
  let situationAssessmentData = useSelector(
    (state) => state.OrgReducer.situationAssessment
  );
  let user = useSelector((state) => state.AuthReducer.user);
  let currentOrg = useSelector((state) => state.OrgReducer.currentOrg);

  useEffect(() => {
    if (user) {
      if (props.location.pathname == "/user-product-analytics") {
        // If login as user
        setId(user.uuid);
      } else {
        setId(props.match.params.id); // If login as org
        // dispatch(
        //   OrgActions.getOrgResults(currentOrg[0]?.orgId, {
        //     callback: (response) => {},
        //   })
        // );
      }
    }
  }, [currentOrg, user]);
  useEffect(() => {
    if (id !== undefined) {
      dispatch(
        OrgActions.getSingleUserAssessmentResults({
          uuid: id,
          callback: (response, result) => {},
        })
      );
      dispatch(
        OrgActions.getSingleUserSituationAssessment({
          uuid: id,
          callback: (response, result) => {},
        })
      );

      dispatch(
        OrgActions.fetchUserDetailsUuid({
          uuid: id,

          callback: (response, result) => {},
        })
      );
    }
  }, [id]);

  return (
    <div className="d-flex over">
      <div className={collapsedClass ? " sideNavExpand" : "sideNavContract "}>
        {user && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}{" "}
      </div>
      <div className={collapsedClass ? "screenContract bg" : "screenExpand bg"}>
        <HeaderBar
          width={collapsedClass ? "80%" : "95%"}
          title={
            props.location.pathname == "/user-product-analytics"
              ? "Product Analytics"
              : "User Data"
          }
          searchbar="no"
          noProduct={true}
        />

        <div className="componentSec ">
          {props.location.pathname !== "/user-product-analytics" && (
            <div className="fullDiv">
              <div className="backIcon mr-2" onClick={() => history.goBack()}>
                <ArrowBack className="icon" />
              </div>
              {props?.location?.state?.address == "Product Analytics"
                ? "Product Analytics"
                : "   Group Management"}
            </div>
          )}
          <div className="d-flex justify-content-around detailsIconsFlex">
            <div className="d-flex detailsIcon">
              <AccountCircleOutlined />
              {userName && (
                <div className="details">
                  <h6>{`Email : ${userName.email}`}</h6>
                </div>
              )}
            </div>
            <div style={{ flex: "0 0 50%" }} className="detailsIconLower">
              <div className="d-flex justify-content-around banners">
                <h6>
                  Total Assessments :{" "}
                  <span>
                    {assessmentData.length + situationAssessmentData.length}
                  </span>{" "}
                </h6>
                {assessmentData.length > 0 && (
                  <h6 className="ml-2">
                    Total Time : <span>{getTotalSeconds(assessmentData)}</span>
                  </h6>
                )}
              </div>
              {props.location.pathname !== "/user-product-analytics" ? (
                <div className="d-flex justify-content-around mt-2 banner_2">
                  <Button
                    marginRight="10px"
                    handleClick={() => history.push(`/recent-assessment/${id}`)}
                    color="primary"
                    name="Recent Assessments"
                  ></Button>
                  <Button
                    className="ml-5"
                    handleClick={() =>
                      history.push(`/situation-assessment/${id}`)
                    }
                    color="primary"
                    name="Recent Assessments"
                  ></Button>
                </div>
              ) : null}
            </div>
          </div>
          <div className="GraphBG">
            {assessmentData.length > 0 && (
              <LineGraph allTeamsAssessmentResults={assessmentData} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserData;
