import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  breadCrumb: {
    color: "White",
    "& p": {
      marginTop: "5px",
    },
  },
}));

const SimpleBreadcrumbs = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const handleClick = () => {
    history.push(props.Route);
  };
  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.breadCrumb}>
      <Link to={props.Route} onClick={handleClick}>
        {props.mainTitle}
      </Link>
      <Link>{props.title}</Link>
    </Breadcrumbs>
  );
};
export default SimpleBreadcrumbs;
