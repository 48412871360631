import { ACTION_TYPE } from "../../Services/Constants/index";
export const initialState = {
  storeApps: [],
};

export const LauncherReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.SET_STORE_APPS:
      return {
        ...state,
        storeApps: action.payload,
      };

    default:
      return state;
  }
};
