import React from "react";
import ChartCard from "./ChartCard";

const Fluids = ({ assessment }) => {
  const setFluids = () => {
    const {
      fluids: {
        fluidCycle,
        viaCubitalFossa,
        viaCentralLine,
        onHandPump,
        onInfusionPump,
      },
    } = assessment;
    let labels = [];
    if (fluidCycle < 2)
      labels.push({
        success: true,
        label: `IV access established before second COACHED`,
      });
    else
      labels.push({
        success: false,
        label: `IV access established after second COACHED`,
      });

    if (viaCubitalFossa)
      labels.push({
        success: true,
        label: "IV access established via Cubital Fossa",
      });
    else
      labels.push({
        success: false,
        label: "IV access not established via Cubital Fossa",
      });

    if (viaCentralLine)
      labels.push({
        success: false,
        label: "IV access established via Central Line",
      });
    else
      labels.push({
        success: true,
        label: "IV access not established via Central Line",
      });

    if (onHandPump)
      labels.push({ success: true, label: "Fluids given via hand pump" });

    if (onInfusionPump)
      labels.push({ success: false, label: `Fluids given via infusion pump` });
    else
      labels.push({
        success: true,
        label: `Fluids not given via infusion pump`,
      });

    return labels;
  };
  return <div>{assessment && <ChartCard labels={setFluids()} />}</div>;
};

export default Fluids;
