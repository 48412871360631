import React from "react";
import CustomCard from "../../../Component/Card/customCard";
import Tabletab from "../../../Component/Table/Table";
import { formatSeconds, getMaxMin, getResponseTime } from "../../../Helper";
import { NEXT_LINE } from "../../../Services/Constants";
import BarChart from "./barChart";
import LineGraph from "./lineGraph";
import "./style.css";

// import Card from "./Card";
// import "./Styles.css";

const Assessement = ({ singleDeEscalationResult }) => {
  const {
    scenarioId,
    scenarioTitle,
    dialogue,
    responseToUserInputDelta,
    userInputDuration,
    userInputScoreDeltas,
    userInputScoresOverTime,
    overallScore,
    eyeContactUserInput,
    eyeContactResponse,
    distanceUserInput,
    distanceResponse,
    scenarioDuration,
    success,
  } = singleDeEscalationResult;
  console.log(
    "🚀 ~ file: assessment.js ~ line 28 ~ Assessement ~ responseToUserInputDelta",
    dialogue
  );

  const renderTableData = (index) => {
    let concatString = "";

    Object.keys(dialogue[index]).map((key, i) => {
      concatString += `
      ${key} : ${dialogue[index][key]},
      ${NEXT_LINE}`;
    });

    // console.log(
    //   "🚀 ~ file: assessment.js ~ line 37 ~ dialoguu ~ dialoguu",
    //   concatString
    //   // JSON.stringify(dialogue[index]).replace(/['"]+/g, "")
    // );
    return [
      (dialogue && dialogue[index] ? concatString : "") || "",
      JSON.stringify(responseToUserInputDelta[index]) || "",
      JSON.stringify(userInputScoreDeltas[index]) || "",
      JSON.stringify(userInputScoresOverTime[index]) || "",
    ];
  };
  console.log("DISSSSSSSSStance", distanceResponse, distanceUserInput);
  return (
    <div id="assesment">
      <div className="d-flex flex-wrap align-items-center justify-content-center deEscaCardDiv">
        <div className="col-lg-4 col-md-6 col-xs-12">
          <CustomCard
            no={scenarioTitle}
            fontSize="64px"
            title="Scenario Title"
          ></CustomCard>
        </div>
        <div className="col-lg-4 col-md-6 col-xs-12">
          <CustomCard
            no={success ? "true" : "false"}
            fontSize="64px"
            title="Success"
          ></CustomCard>
        </div>
        <div className="col-lg-4 col-md-6 col-xs-12">
          <CustomCard
            no={overallScore}
            fontSize="64px"
            title="Overall score"
          ></CustomCard>
        </div>
        <div className="col-lg-4 col-md-6 col-xs-12">
          <CustomCard
            no={formatSeconds(scenarioDuration)}
            fontSize="64px"
            title="Scenario Duration"
          ></CustomCard>
        </div>
        {responseToUserInputDelta && responseToUserInputDelta.length > 0 ? (
          <>
            <div className="col-lg-4 col-md-6 col-xs-12">
              <CustomCard
                no={getResponseTime(responseToUserInputDelta, "best")}
                fontSize="64px"
                title="Fastest Response Time"
              ></CustomCard>
            </div>
            <div className="col-lg-4 col-md-6 col-xs-12">
              <CustomCard
                no={getResponseTime(responseToUserInputDelta, "worst")}
                fontSize="64px"
                title="Slowest Response Time"
              ></CustomCard>
            </div>
          </>
        ) : null}
      </div>

      <div
        className="d-flex flex-wrap deEscaLineDiv"
        style={{ marginTop: "10px" }}
      >
        <div className="col-lg-4 col-md-6 col-xs-12">
          <LineGraph
            responseList={userInputScoreDeltas}
            label="Interaction Consistency (Score Delta)"
          />
        </div>

        <div className="col-lg-4 col-md-6 col-xs-12">
          <LineGraph
            responseList={eyeContactUserInput}
            label="Eye Contact While Talking"
          />
        </div>
        <div className="col-lg-4 col-md-6 col-xs-12">
          <LineGraph
            responseList={distanceUserInput}
            label="Distance While Talking"
            minMax={
              distanceResponse && distanceUserInput
                ? getMaxMin(distanceResponse, distanceUserInput)
                : null
            }
          />
        </div>

        <div className="col-lg-4 col-md-6 col-xs-12">
          <LineGraph
            responseList={responseToUserInputDelta}
            label="Response Time"
          />
        </div>
        <div className="col-lg-4 col-md-6 col-xs-12">
          <LineGraph
            responseList={eyeContactResponse}
            label="Eye Contact While Listening"
          />
        </div>

        <div className="col-lg-4 col-md-6 col-xs-12">
          <LineGraph
            responseList={distanceResponse}
            label="Distance While Listening"
            minMax={
              distanceResponse && distanceUserInput
                ? getMaxMin(distanceResponse, distanceUserInput)
                : null
            }
          />
        </div>
      </div>
      <div className="assmntTab">
      <Tabletab
        height="45vh"
        tableHead={[
          "Dialogue",
          "Response Time",
          "Score Delta",
          "Score Over Time",
        ]}
        tableData={
          dialogue &&
          dialogue.length > 0 &&
          dialogue.map((_, index) => {
            if (dialogue && dialogue.length > 0) return renderTableData(index);
            else return [];
          })
        }
        split="yes"
        //   button="yes"
        //   buttonName="Assessments"
        //   emailIndex="0"
        //   handleEmailClick={handleEmailClick}
        //   handleDeleteUser={handleDeleteUser}
        //   handleBtnClick={handleCLickUser}
        // index="3"
        // icon="yes"
      />
      </div>
    </div>
  );
};

export default Assessement;
