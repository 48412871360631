import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CancelIcon from "@material-ui/icons/Cancel";
import React from "react";
import "./vrPasscodeModel.css";

const VrPasscode = ({ setModal, modalState, vrCode }) => {
  return (
    <Dialog
      open={modalState}
      className="detailsVrCode"
      disableBackdropClick
      // onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title " className="text-center model-head">
        <div className="modelIcon2">
          <CancelIcon
            onClick={() => {
              setModal(false);
            }}
          />
        </div>
      </DialogTitle>
      <DialogContent className="privacyContent vrPassCodeParent">
        <h1>Here is your VR code:</h1>
        <p>
          <b>
            <span className="vrCodePasscode">{vrCode}</span>
          </b>
        </p>{" "}
        <br />
        <p>You can also find it from account settings page</p>
      </DialogContent>
    </Dialog>
  );
};
export default VrPasscode;
