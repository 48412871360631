// export const BASE_URL = "http://localhost:5000/api"; // local
//export const BASE_URL =
// "https://8a8d-2401-4900-1c2c-2576-25ba-cf1c-1493-9180.ngrok-free.app"; //ngrok
// console.log("================================ ")
// export const BASE_URL =
//   "https://frameless-backend-dot-frameless-interactive.uc.r.appspot.com/api"; //Staging
// export const BASE_URL =
//   "https://simdashdev-backend-dot-simdash.ts.r.appspot.com/api"; //Staging - Client
//export const BASE_URL =
//   "https://frameless-backend-dot-testappversion.df.r.appspot.com/api";

export const BASE_URL =
  "https://frameless-backend-dot-simdash.ts.r.appspot.com/api";
// Production
export const APPLICATION_CONSTANTS = {
  SINGLE_ASSESSMENT_TIME: 2,
  SITE_ICON:
    "https://firebasestorage.googleapis.com/v0/b/frle-282606.appspot.com/o/website%2Ficon.png?alt=media&token=c5a585d5-7f9d-4068-ba8a-08e13991ba2a",

  SITE_LOGO_MENU:
    "https://firebasestorage.googleapis.com/v0/b/frle-282606.appspot.com/o/website%2Flogo.png?alt=media&token=1fb999fa-3d4b-4a27-be15-8c32ca970f4f",
  SITE_LOGO:
    "https://firebasestorage.googleapis.com/v0/b/frle-282606.appspot.com/o/website%2FTransparent%20PNG%20File.png?alt=media&token=5e1e0e3a-554c-4ea3-9bd0-e88588cf5535",
};

export const PRODUCTS = {
  ALS: "als",
  CB: "codeBlack",
  DESCALATION: "deEscalation",
  DP: "deterioratingPatient",
  HANDOVER: "handoverSim",
};

export const ROUTES = {
  INVITE: "/invite",
  ORG_SELECTION: "/org-selection",
  RECENTASSESSMENT: "/recent-assessment/:id",
  USERMANAGEMENT: "/user-management",
  GROUPMANAGEMENT: "/group-management",
  OGRANIZATIONDASHBOARD: "/organization-dashboard",
  ORGANISATION_SETTINGS: "/org-settings",
  LOGIN: "/",
  FORGOT_PASSWORD: "/forgot-password",
  LOGIN_SUPERADMIN: "/login-super-admin",
  RECENTASSESSMENTDATA: "/recent-assessment-data/:id",
  SITUATIONASSESSMENT: "/situation-assessment/:id",
  DEESCALATIONASSESSMENT: "/deescalation-assessment/:id",
  DPASSESSMENT: "/dp-assessment/:id",
  DEESCALATIONASSESSMENTDATA: "/deescalation-assessment-data/:id",
  HANDOVER_ASSESSMENT_DATA: "/handover-assessment-data/:id",
  DPASSESSMENTDATA: "/dp-assessment-data/:id",
  HANDOVER_ASSESSMENT: "/handover-assessment/:id",
  ACCOUNT_SETTINGS: "/account-settings",
  REGISTER: "/register",
  SUPER_ADMIN_DASHBOARD: "/super-admin-dashboard",
  SIDEITEMS: "/sideitems",
  ADVANCED_LIFE_SUPPORT: "/advanced-life-support",
  PRODUCT_ANALYTICS_ADMIN: "/product-analytics-admin",
  SIMDASH_LAUNCHER: "/simdash-launcher",
  PREVIOUS_VERSIONS: "/previous-versions/:id",
  OGRANIZATION_MANAGEMENT: "/organisation-management",
  SUBSCRIPTION: "/subscription",
  USER_DATA: "/user-data/:id",
  USER_PRODUCT_ANALYTIC: "/user-product-analytics",
  SITUATIONASSESSMENTDATA: "/situation-assessment-data/:id",
  PRODUCT_ANALYTICS: "/product-analytics",
  USER_DASHBOARD: "/user-dashboard",
  USER_SETTING: "/user-setting",
  INVITE_ACCEPT: "/accept-invite",
  WELCOME_MESSAGE: "/welcome-msg",
};
export const ACTION_TYPE = {
  SET_LOGIN_AS: "SET_LOGIN_AS",
  SIDENAV_WIDTH: "SIDENAV_WIDTH",
  GET_ALL_GROUPS_IN_ORG: "GET_ALL_GROUPS_IN_ORG",
  LOGIN: "LOGIN",

  LOGOUT: "LOGOUT",
  SET_PRODUCT: "SET_PRODUCT",
  RESET_TOKEN: "RESET_TOKEN",
  SET_TOKEN: "SET_TOKEN",
  SET_SINGLE_ASSESSMNTS_RESULT: "SET_SINGLE_ASSESSMNTS_RESULT",
  SET_SINGLE_DP_RESULT: "SET_SINGLE_DP_RESULT",
  SET_USER: "SET_USER",
  SET_USER_DETAILS: "SET_USER_DETAILS",
  SET_SINGLE_SITUATION_RESULT: "SET_SINGLE_SITUATION_RESULT",
  SET_SINGLE_DEESCALATION_RESULT: "SET_SINGLE_DEESCALATION_RESULT",
  SET_SINGLE_USER_DP_ASSESSMNTS: "SET_SINGLE_USER_DP_ASSESSMNTS",
  GET_HANGOVER_DATA: "GET_HANGOVER_DATA",
  UPDATE_USERS: "UPDATE_USERS",
  GET_ALL_ORGS: "GET_ALL_ORGS",
  FETCH_USER_DETAILS_UUID: "FETCH_USER_DETAILS_UUID",
  ORG_RESULTS: "ORG_RESULTS",
  SET_SINGLE_USER_ASSESSMNTS: "SET_SINGLE_USER_ASSESSMNTS",
  SET_SINGLE_USER_DEESCALATION_ASSESSMNTS:
    "SET_SINGLE_USER_DEESCALATION_ASSESSMNTS",
  SET_SITUATION_ASSESSMENTS: "SET_SITUATION_ASSESSMENTS",
  UPDATE_DATE: "UPDATE_DATE",
  FETCH_USER_DETAILS: "FETCH_USER_DETAILS",
  CLEAR_USER_DETAILS: "CLEAR_USER_DETAILS",
  SET_ORGS: "SET_ORGS",
  SET_ORGS_AUTH: "SET_ORGS_AUTH",
  UPDATE_ORG: "UPDATE_ORG",
  SET_ALL_USERS: "SET_ALL_USERS",
  GET_ORG_BY_ID: "GET_ORG_BY_ID",
  CLEAR_SELECTED_USER: "CLEAR_SELECTED_USER",
  DEL_USER_FROM_ORG: "DEL_USER_FROM_ORG",
  SET_ACTIVE_ORG: "SET_ACTIVE_ORG",
  CLEAR_CURRENT_ORG: "CLEAR_CURRENT_ORG",
  SET_SINGLE_USER_HANDOVER_ASSESSMNTS: "SET_SINGLE_USER_HANDOVER_ASSESSMNTS",

  // Launcher
  SET_STORE_APPS: "SET_STORE_APPS",
  // SET_STORE_APPS:"SET_STORE_APPS"
};
export const NEXT_LINE = "\n\n";
