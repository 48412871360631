import React, { useState, useEffect } from "react";
import Table from "../../../Component/Table/Table";
import { useSelector, useDispatch } from "react-redux";
import HeaderBar from "../../../Component/HeaderBar/headerBar";
import RBAC from "../../../Component/RBAC/RBAC";
import { initList } from "../../../Component/SideNav/initList";
import { OrgActions } from "../../../ReduxStore/Actions";
import _Firebase from "../../../Services/Utils/firebase";

import SideNav from "../../../Component/SideNav/sideNav";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../Services/Constants";
import { changeDateFormatFromYYYY_MM_DDToDD_MM_YYYY } from "../../../Helper";

const SituationAssessment = (props) => {
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const dispatch = useDispatch();
  let collapsedClass = useSelector(
    (state) => state.CollapsedSidenavReducer.collapsedClass
  );
  let user = useSelector((state) => state.AuthReducer.user);
  let assessmentData = useSelector(
    (state) => state.OrgReducer.situationAssessment
  );
  let userName = useSelector((state) => state.OrgReducer.userDetailsUuid);

  useEffect(() => {
    getTableData(assessmentData);
  }, [assessmentData, userName]);
  const getTableData = (result) => {
    if (result.length > 0) {
      let finalData = result?.map((data) => {
        const date = changeDateFormatFromYYYY_MM_DDToDD_MM_YYYY(
          data["dateCreated"].split("T")[0]
        );
        return [
          "Code Black",
          // data.user[0]["userName"],
          date,
          data["dateCreated"].split("T")[1].split(".")[0],

          data["_id"],
        ];
      });

      setTableData(finalData);
    }
  };
  useEffect(() => {
    if (props?.match?.params?.id) {
      dispatch(
        OrgActions.getSingleUserSituationAssessment({
          uuid: props.match.params.id,
          callback: (response, result) => {},
        })
      );
    }
  }, []);
  const handleBtnClick = (item) => {
    history.push(
      `/situation-assessment-data/${item[3]}?id=${props.match.params.id}`
    );
  };

  return (
    <div className="d-flex over">
      <div className={collapsedClass ? " sideNavExpand" : "sideNavContract"}>
        {user && (
          <RBAC
            userRole={user?.role} // User roles that are given to user in the backend
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}{" "}
      </div>
      <div className={collapsedClass ? "screenContract bg" : "screenExpand bg"}>
        {/* <HeaderBar title="Seat Management" /> */}
        <HeaderBar
          width={collapsedClass ? "80%" : "95%"}
          title="Recent Assessments"
          searchbar="no"
          noProduct={true}
        />
        <div className="componentSec">
          <div className="d-flex justify-content-between flexsupad">
            <div className="fullDiv">
              <div
                className="backIcon mr-2"
                onClick={() =>
                  history.push(ROUTES.GROUPMANAGEMENT + "?tabValue=1")
                }
              >
                <ArrowBackIcon className="icon" />
              </div>
              User Data
            </div>
          </div>
          <div className="pb-31 mt-4">
            {
              <Table
                height="45vh"
                tableHead={["App", "Date", "Time"]}
                tableData={tableData}
                handleBtnClick={handleBtnClick}
                button="true"
                index="3"
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};
export default SituationAssessment;
