import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { PRODUCTS, ROUTES } from "../../Services/Constants";
import HeaderBar from "../../Component/HeaderBar/headerBar";
import SideNav from "../../Component/SideNav/sideNav";
import CustomCard from "../../Component/Card/customCard";
import { SuperAdminActions } from "../../ReduxStore/Actions";
import { OrgActions } from "../../ReduxStore/Actions";

import { ACTION_TYPE } from "../../Services/Constants/index";
import "./organizationDashboard.css";
import RBAC from "../../Component/RBAC/RBAC";
import { useHistory } from "react-router-dom";
import _Firebase from "../../Services/Utils/firebase";
import { initList } from "../../Component/SideNav/initList";
import LineGraph from "../../Component/Graph/LineGraph";
import ScatterChart from "../../Component/Graph/scatter";
import HorizontalBarGraph from "../../Component/Graph/barGraph";
import { Pie } from "react-chartjs-2";
import PieChart from "../../Component/Graph/multiSeriesPie";
import ALSGraphs from "../../Component/ALSGraphs";
// import ScatterChart from "../../Component/Graph/scatter";
import BubbleChart from "../../Component/Graph/bubble";

import CodeBlackDash from "../../Component/CodeBlackDash";
import DeEscalationDash from "../../Component/DeEscalationDash";
import DetPat from "../../Component/DetPat";
import Scenario from "../../Component/Scenario/scenario";
import Footer from "../../Component/Footer/Footer";
import Account from "../../Component/Account/account";

const OrganisationDashboard = () => {
  // const [auth, setAuth] = useState(false);
  // const history = useHistory();
  const dispatch = useDispatch();

  // const [tableData, setTableData] = useState([]);

  let collapsedClass = useSelector(
    (state) => state.CollapsedSidenavReducer.collapsedClass
  );

  // let allOrgs = useSelector((state) => state.SuperAdminReducer.allOrgs);
  let user = useSelector((state) => state.AuthReducer.user);
  let resultState = useSelector((state) => state.OrgReducer.orgResults);
  let currentOrg = useSelector((state) => state.OrgReducer.currentOrg);
  let situationAssessment = useSelector(
    (state) => state.OrgReducer.situationAssessment
  );
  const { product } = useSelector((state) => state.ProductReducer);
  // const [runTourTest, setRunTourTest] = useState(false);
  // const steps = [
  //   {
  //     target: ".tourTest1",
  //     content: "switch to the organization",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".tourTest2",
  //     content: "Display you role"
  //   }
  // ]

  useEffect(() => {
    console.log("org dash use effect called", currentOrg[0]);
    if (user?.uuid) {
      dispatch(
        OrgActions.getOrgResults(currentOrg[0]?.orgId, product, {
          callback: (response) => {},
        })
      );

      // dispatch(OrgActions.getCBResults({ callback: () => {} }));
    }
  }, [user, currentOrg]);

  useEffect(() => {
    console.log("situationAssessment :>> ", situationAssessment);
  }, [situationAssessment]);

  console.log("DETPAT", product);
  return (
    <>
      <div className="d-flex over">
        <div className={collapsedClass ? " sideNavExpand" : "sideNavContract "}>
          {user && (
            <RBAC
              userRole={user?.role} // User roles that are given to user in the backend
              initList={initList}
              renderNoAccess={() => <SideNav />}
            >
              <SideNav />
            </RBAC>
          )}{" "}
        </div>
        <div
          className={collapsedClass ? "screenContract bg" : "screenExpand bg"}
        >
          <HeaderBar
            width={collapsedClass ? "80%" : "95%"}
            title="Organisation Dashboard"
            searchbar="no"
            // startTour={() => {
            //   console.log("start tour");
            //   setRunTourTest(true);
            //   // setRunTourTest(true)
            // }
            // }
          />

          <div className="componentSec ">
            {product === PRODUCTS.ALS ? (
              <ALSGraphs
                allTeamsAssessmentResults={resultState}
                peopleCount={currentOrg[0]?.users?.length}
              ></ALSGraphs>
            ) : product === PRODUCTS.CB ? (
              <CodeBlackDash
                assessmentData={resultState}
                peopleCount={currentOrg[0]?.users?.length}
              />
            ) : product === PRODUCTS.DESCALATION ? (
              <DeEscalationDash
                assessmentData={resultState}
                peopleCount={currentOrg[0]?.users?.length}
              />
            ) : product === PRODUCTS.DP ? (
              <DetPat
                assessmentData={resultState}
                peopleCount={currentOrg[0]?.users?.length}
              ></DetPat>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
export default OrganisationDashboard;
