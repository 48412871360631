import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../PublicModule/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { Paper } from "@material-ui/core";
import OrgSelectionCard from "../../Component/Card/OrgSelectionCard";
import "./index.css";
import { AuthActions } from "../../ReduxStore/Actions";
import { ACTION_TYPE, ROUTES } from "../../Services/Constants";
import { checkJWTExpiry, getUserDetailsJWT } from "../../Services/Utils/utils";
import jwt from "jsonwebtoken";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));

const OrgSelection = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectOrg, setSelectOrg] = useState("You own");
  const [listOwn, setListOwn] = useState([]);
  const [listBelong, setListBelong] = useState([]);
  let { user, userDetails } = useSelector((state) => state.AuthReducer);
  const handleUser = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectOrg(e.target.name);
  };

  useEffect(() => {
    const { user: userD } = getUserDetailsJWT();
    dispatch(
      AuthActions.getUserDetails({
        uuid: userD.uuid,
        email: userD.email,
        callback: (response) => { },
      })
    );
  }, []);

  useEffect(() => { }, [userDetails]);

  const setActiveOrg = (org) => {
    const orgToken = jwt.sign(
      { orgId: org.orgId, userId: user.uuid },
      process.env.REACT_APP_PRIVATE_KEY
    );

    dispatch({
      type: ACTION_TYPE.SET_ACTIVE_ORG,
      payload: {
        activeOrg: org,
        token: orgToken,
      },
    });
    props.history.push(ROUTES.OGRANIZATIONDASHBOARD);
  };

  return (
    <div className="AuthBg orgSelectionDiv">
      <Header />
      <div className="signIn">
        <div className="setUser d-flex mb-3">
          <button
            className={selectOrg == "You own" ? "button1 active" : "button1"}
            onClick={handleUser}
            name="You own"
          >
            You Own
          </button>
          <button
            name="You belong to"
            className={selectOrg == "You own" ? "button2 " : "button2 active"}
            onClick={handleUser}
          >
            You belong to
          </button>
        </div>
        <Paper className="paperGrid">
          <div className="d-flex flex-column p-4">
            <h3>{"Organisations " + selectOrg} </h3>
            {userDetails && (
              <div className="parentOrgCrd">
                {selectOrg == "You own"
                  ? userDetails.orgs?.map((item) => {
                    return (
                      <OrgSelectionCard
                        name={item["orgName"]}
                        no={item["users"].length}
                        pendingInvites={item["pendingInvitations"].length}
                        created="4 months ago"
                        setActiveOrg={setActiveOrg}
                        org={item}
                        buttonName="Select"
                      />
                    );
                  })
                  : userDetails.filteredInvites?.map((item) => {
                    return (
                      <OrgSelectionCard
                        name={item["orgName"]}
                        no={item["users"].length}
                        pendingInvites={item["pendingInvitations"].length}
                        created="4 months ago"
                        setActiveOrg={setActiveOrg}
                        org={item}
                        buttonName="Select"

                      />
                    );
                  })}
              </div>
            )}
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default OrgSelection;
