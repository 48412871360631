import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "../../../Component/Button/button";
import { AccountCircleOutlined } from "@material-ui/icons";
import BackupIcon from "@material-ui/icons/Backup";
import moment from "moment";
import "./userInfo.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import InfoRounded from "@material-ui/icons/InfoRounded";
import { useDispatch } from "react-redux";
import { AuthActions } from "../../../ReduxStore/Actions";
import Toast from "../../../Component/Toast/toast";
const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",

    border: "2px solid white",
    backgroundColor: "#116a9a",
    // width: "20%",
    padding: "20px 0px",
    borderRadius: "3px",
    alignItems: "center",
    "& button": {
      margin: "0 5px",
    },
  },
  infoicon: {
    maxWidth: "20px !important",
    color: "#ffffff",
    marginBottom: "13px",
    position: "relative",
    // padding: "0px 0px 10px",
    // height: " 130px",
    // objectFit: "cover",
  },
  tooltip: {
    position: "absolute",
    top: "0px",
    left: "0px",
  },
  media: {
    maxWidth: "70% !important",
    color: "#ffffff",
    padding: "0px 0px 10px",
    height: " 130px",
    objectFit: "cover",
  },
  name: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#ffffff",
    fontSize: "30px",
    fontWeight: "700",
  },
  dates: {
    color: "#ffffff",
    textAlign: "center",
  },
  heading: {
    textAlign: "center",
    color: "white",
    fontWeight: "800",
  },
  button: {
    display: "flex",
    margin: "0 auto",
    justifyContent: "center",
  },
  OrgDP: {
    width: "50%",
    margin: "10px auto",
    "&  img": {
      width: "100%",
    },
  },
}));
const UserInfo = ({
  handleUpdateClick,
  handlePicChange,
  imageAsUrl,
  setImageAsFile,
  picEdit,
  setPicEdit,
  handleFireBaseUpload,
  setImageDetails,
  handleChangePasswordClick,
  updateOrg,
  vrCode,
  ...props
}) => {
  const picUpload = useRef();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [toast, setToast] = useState();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(
    "https://st4.depositphotos.com/14953852/22772/v/600/depositphotos_227725020-stock-illustration-image-available-icon-flat-vector.jpg"
  );
  // console.log("propsprops", props);
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} className={classes.tooltip}>
      The VR Code expires after every 30 Mintues. Click on Refresh button before
      continuing.
    </Tooltip>
  );
  const onRefreshVrCode = async() => {
    setLoading(true);
   await dispatch(
      AuthActions.matchToken({
        callback: (response) => {
          const { user } = response;
          
          dispatch(
            AuthActions.getUserDetails({
              uuid: user?.uuid,
              email: user?.email,
              callback: (response) => {
                setToast({
                  open: true,
                  message: response.success
                    ? "VR Code Refreshed"
                    : "Error while refreshing the VR Code!",
                  type: response.success
                  ? "success"
                  : "error",
                  duration: 4000,
                });
                setLoading(false);
              return;

              },
            })
          );
          
        },
      })
    );
    setLoading(false);
  };
  React.useEffect(() => {
    dispatch(
      AuthActions.matchToken({
        callback: (response) => {
          const { user } = response;
          dispatch(
            AuthActions.getUserDetails({
              uuid: user?.uuid,
              email: user?.email,
              callback: (response) => {},
            })
          );
        },
      })
    );
  }, []);
  return (
    <div>
      <Paper className={classes.paper}>
        {props.status ? (
          <div className={classes.name}>
            <p>
              <strong>Status :</strong>
            </p>{" "}
            &nbsp;&nbsp;{" "}
            <p>
              {moment(props.subEndDate).diff(new Date().toISOString(), "days") <
              0 ? (
                <span style={{ color: "red", fontWeight: 700 }}>Inactive</span>
              ) : (
                <span style={{ color: "#11ff02", fontWeight: 700 }}>
                  Active
                </span>
              )}
            </p>
          </div>
        ) : null}

        {props?.sideUserType ? (
          <AccountCircleOutlined className={classes.media} />
        ) : imageAsUrl?.imgUrl == null ? (
          <BackupIcon className={classes.media} />
        ) : (
          <div className="d-flex justify-content-center">
            <img className={classes.media} src={imageAsUrl.imgUrl} />
          </div>
        )}

        <input
          type="file"
          accept="image/*"
          ref={picUpload}
          onChange={handlePicChange}
          hidden
          onClick={(event) => {
            event.target.value = null;
          }}
        />
        {picEdit === false && (
          <Button
            color="primary"
            background="secondary"
            name="Change Photo"
            padding="10px 20px"
            height="30px"
            textAlign="center"
            handleClick={() => picUpload.current.click()}
          ></Button>
        )}

        {picEdit === true && (
          <div className=" optionButton">
            <div className="mr-2">
              <Button
                color="primary"
                background="secondary"
                name="Save"
                padding="10px 20px"
                height="30px"
                textAlign="center"
                handleClick={handleFireBaseUpload}
              ></Button>
            </div>
            <Button
              color="primary"
              background="secondary"
              name="Cancel"
              padding="10px 20px"
              height="30px"
              textAlign="center"
              handleClick={setImageDetails}
            ></Button>
          </div>
        )}
        <div>
          {props.SuperAdmin ? (
            <h6 className={classes.heading}>Super Admin</h6>
          ) : null}
          <div className="orgInfo">
            {props.name ? (
              <div className={classes.name}>
                <p>{props.userName}</p>
              </div>
            ) : null}
          </div>

          {props.orgName ? (
            <div className={classes.dates}>
              {/* <h6>Organisation Name</h6> */}
              <p>{props.orgName}</p>
            </div>
          ) : null}

          {props.subStartDate ? (
            <div className={classes.dates}>
              <h6>Subscription Start Date</h6>
              <p>{props.subStartDate}</p>
            </div>
          ) : null}
          {props.subEndDate ? (
            <div className={classes.dates}>
              <h6>Subscription End Date</h6>
              <p>{moment(props.subEndDate).format("YYYY-MM-DD")}</p>
            </div>
          ) : null}
          {updateOrg ? null : (
            <Button
              color="primary"
              background="secondary"
              name="Update Org Name"
              padding="10px 20px"
              height="30px"
              textAlign="center"
              handleClick={handleUpdateClick}
            ></Button>
          )}
        </div>
        {props.changePass ? (
          <div
            className="mt-2"
            style={{ padding: "0px 10px", lineHeight: "1.35" }}
          >
            <Button
              color="primary"
              background="secondary"
              name="Change password"
              padding="10px 20px"
              height="30px"
              textAlign="center"
              handleClick={handleChangePasswordClick}
            ></Button>
          </div>
        ) : null}
        <hr style={{ width: 80, height: 1, background: "white" }} />
        <div className="vrCode">
          {/* {props.name ? ( */}

          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <div className={classes.name}>
              <p className="Vr">
                <InfoRounded className={classes.infoicon} />
                VR Code: <p className="vrCodePasscode">{vrCode} </p>
              </p>{" "}
              &nbsp;
            </div>
          </OverlayTrigger>

          <div>
            <Button
              color="primary"
              background="secondary"
              name="Refresh"
              padding="0px 0px"
              height="30px"
              textAlign="center"
              handleClick={() => onRefreshVrCode()}
              loading={loading}
            ></Button>
          </div>
          {toast?.open ? (
              <Toast
                open={toast.open}
                message={toast.message}
                duration={toast.duration}
                type={toast.type}
                handleClose={() =>
                  setToast({
                    ...toast,
                    open: false,
                  })
                }
              ></Toast>
            ) : null}
          {/* ) : null} */}
        </div>
      </Paper>
    </div>
  );
};

export default UserInfo;
