import React, { useEffect } from "react";

import Button from "../../Component/Button/button";
import "../Register/register.css";

import Header from "../Header/Header";
import { Link } from "react-router-dom";
import { ROUTES } from "../../Services/Constants";
import { AuthActions, SuperAdminActions } from "../../ReduxStore/Actions";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";

const Invite = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  const handleAccept = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const query = new URLSearchParams(props.location.search);
    const token = query.get("token");

    dispatch(
      AuthActions.confirmEmail({
        token,
        callback: (response) => {
          if (response.data.success === true) {
            const { result } = response.data;
            const { token, email } = result;

            props.history.push({
              pathname: ROUTES.REGISTER,
              search: `?token=${token}`,
              state: {
                email,
              },
            });
          }
        },
      })
    );
  };

  return (
    <div className="AuthBg">
      <Header />
      <div className="signIn">
        <div className="signInFormDiv">
          {/* <div className="signInInnerWrapper"> */}

          {/* </div> */}
          <div className="detailsDiv">
            <h2 style={{ color: "white" }}>
              Learn how to integrate VR & Smart training into your training
              packages
            </h2>
            <div className="registerButton">
              <Link to={ROUTES.LOGIN}>
                <Button
                  width="160px"
                  height="42px"
                  background="secondary"
                  borderRadius="13px"
                  fontSize="19px"
                  color="primary"
                  padding="10px"
                  transition="background 0.3s"
                  display="inline-flex"
                  alignItem="center"
                  name="Sign In"
                />
              </Link>
            </div>
          </div>

          <div className="formDiv">
            <h1 className="signInHeading mb-0">Register</h1>
            <div className="horizontalLine"></div>
            <div className="randomName">
              <p className="randomNamep">
                You have an invitation to join <br />
                "Frameless"
              </p>
              <p className="randomNamep">
                By accepting you are agreeing to share your profile and results
                with this <br /> organisation
              </p>
            </div>
            <div className="signInButton">
              <Button
                width="160px"
                height="42px"
                background="secondary"
                borderRadius="15px"
                fontSize="15px"
                color="primary"
                padding="10px"
                transition="background 0.3s"
                display="inline-flex"
                alignItem="center"
                name="Accept Invite"
                handleClick={handleAccept}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invite;
