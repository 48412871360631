import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import Tabletab from "../../../Component/Table/Table";

function TabPanel(props) {
  const { children, value, index, allTeamsAssessmentResults, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  siteTabs: {
    "& button": {
      backgroundColor: "transparent",
      color: "#ffffff",
      border: "1px solid #ffffff",
      borderRadius: "3px",
      textTransform: "capitalize",
      padding: "6px 20px",
      marginBottom: "10px",
      minWidth: "auto",
      minHeight: "auto",
      opacity: "1",
    },
    tabContent: {
      paddingTop: "0px",
    },
    "& .MuiBox-root": {
      padding: "0px !important",
    },
    "& .Mui-selected": {
      backgroundColor: "#ffffff",
      color: "#000000",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "unset",
    },
  },
}));

export default function SimpleTabs({
  allTeamsAssessmentResults,
  handleDeleteUser,
  handleCLickUser,
  tableData,
  handleEmailClick,
  dropDownList,
  handleBtnClick,
  isDropdown,
  handleBtnClickSecond,
  handleChangeCheckBox,
  setIsDropdown,
  handleDropdownToggle,
  setDetailsModel,
  setEmail,
  CheckUUID,
  ownerUuid,
  tableTopText,
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let adminData =
    tableData && tableData.filter((data) => data[2].includes("admin"));
  let teacherData =
    tableData && tableData.filter((data) => data[2].includes("teacher"));
  let pendingData =
    tableData && tableData.filter((data) => data[1] === "Pending");

  return (
    <div className={classes.siteTabs}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="All Users" {...a11yProps(0)} />
          <Tab label="Admins" {...a11yProps(1)} />
          <Tab label="Teachers" {...a11yProps(2)} />
          <Tab label="Pending" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      {tableTopText ? (
        <h6 className="text-center" style={{ color: "#f0f8ff" }}>
          {tableTopText } 
        </h6>
      ) : null}

      <TabPanel value={value} index={3} className={classes.tabContent}>
        <Tabletab
          height="50vh"
          tableHead={["Email", "Status"]}
          tableData={pendingData}
          handleDeleteUser={handleDeleteUser}
          icon="true"
          index="3"
          CheckUUID={CheckUUID}
        ></Tabletab>
      </TabPanel>
      <TabPanel value={value} index={0} className={classes.tabContent}>
        <div className="graphDiv">
          {tableData?.length > 0 && (
            <Tabletab
              height="50vh"
              tableHead={["Email", "Status", "Role", "Seat"]}
              tableData={tableData}
              checkboxRole="checkboxRole"
              dropDownList={dropDownList}
              button="yes"
              emailIndex="0"
              handleEmailClick={handleBtnClick}
              handleBtnClick={handleBtnClick}
              handleDeleteUser={handleDeleteUser}
              isDropdown={isDropdown}
              button="true"
              handleBtnClickSecond={handleBtnClickSecond}
              handleChangeCheckBox={handleChangeCheckBox}
              icon="true"
              index="3"
              setIsDropdown={setIsDropdown}
              handleDropdownToggle={handleDropdownToggle}
              setDetailsModel={setDetailsModel}
              setEmail={setEmail}
              secondButtonId="4"
              secondButtonName="Seat"
              CheckUUID={CheckUUID}
              ownerUuid={ownerUuid}
            ></Tabletab>
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabContent}>
        <div className="graphDiv">
          <Tabletab
            height="50vh"
            tableHead={["Email", "Status", "Role", "Seat"]}
            tableData={adminData}
            button="yes"
            handleBtnClick={handleBtnClick}
            handleDeleteUser={handleDeleteUser}
            button="true"
            handleBtnClickSecond={handleBtnClickSecond}
            icon="true"
            index="3"
            emailIndex="0"
            handleEmailClick={handleBtnClick}
            setDetailsModel={setDetailsModel}
            setEmail={setEmail}
            secondButtonId="4"
            secondButtonName="Seat"
            CheckUUID={CheckUUID}
          ></Tabletab>
        </div>
      </TabPanel>
      <TabPanel value={value} index={2} className={classes.tabContent}>
        <div className="graphDiv">
          <Tabletab
            height="50vh"
            tableHead={["Email", "Status", "Role", "Seat"]}
            tableData={teacherData}
            button="yes"
            handleBtnClick={handleBtnClick}
            handleDeleteUser={handleDeleteUser}
            button="true"
            emailIndex="0"
            handleEmailClick={handleBtnClick}
            handleBtnClickSecond={handleBtnClickSecond}
            icon="true"
            index="3"
            setDetailsModel={setDetailsModel}
            setEmail={setEmail}
            secondButtonId="4"
            secondButtonName="Seat"
            CheckUUID={CheckUUID}
          ></Tabletab>
        </div>
      </TabPanel>
    </div>
  );
}
