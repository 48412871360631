import React, { useEffect, useState } from "react";
import { ValidateLoginForm } from "./Validation";

import Button from "../../Component/Button/button";
import "./register.css";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Header from "../Header/Header";
import { Link } from "react-router-dom";
import Toast from "../../Component/Toast/toast";

import { ROUTES } from "../../Services/Constants";
import InputField from "../../Component/Input/input";
import { AuthActions, SuperAdminActions } from "../../ReduxStore/Actions";
import { useDispatch } from "react-redux";
import ProgressBarStep from "../../Component/ProgressBar/ProgressBar";

const Register = (props) => {
  const dispatch = useDispatch();
  const [emailDisabled, setEmailDisabled] = useState(false);
  const [state, setState] = useState();
  const [toast, setToast] = useState();
  const [isShowPassword, setPasswordType] = React.useState(false);
  const [isShowConfirmPassword, setConfirmPasswordType] = React.useState(false);

  const [errorData, setError] = React.useState();

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const token = query.get("token");
    if (token) {
      setEmailDisabled(true);
      dispatch(
        AuthActions.getInviteInfoFromToken({
          token,
          callback: (response) => {
            const { result } = response.data;
            const { email, orgId, type } = result;

            setState({
              ...state,
              email,
              orgId,
              registerType: type,
            });
          },
        })
      );
    }
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setError();

    setState({ ...state });

    const result = await ValidateLoginForm(state);
    if (!result?.isFormValid) {
      setError(result?.error);

      return;
    } else if (result?.isFormValid) {
      dispatch(
        AuthActions.createUser({
          registerBody: state,
          callback: (response) => {
            if (response.status === null || response.status === undefined) {
              setToast({
                open: true,
                message: response.message,
                type: "error",
                duration: 5000,
              });
              return;
            } else if (response.status === 200) {
              dispatch(
                AuthActions.signIn({
                  email: state.email.trim(),
                  password: state.password.trim(),
                  loginAs: "user",
                  callback: (response) => {
                    const { user } = response;

                    if ((!response.user && response.code) || !user) {
                      setToast({
                        open: true,
                        message: response.message,
                        type: "error",
                        duration: 5000,
                      });

                      return;
                    }
                    props.history.push(ROUTES.INVITE_ACCEPT);
                  },
                })
              );
              // setTimeout(() => {
              //   return props.history.push(ROUTES.LOGIN);
              // }, 3000);
              // setToast({
              //   open: true,
              //   message: "User successful registration confirmation",
              //   type: "success",
              //   duration: 2000,
              // });
            }
          },
        })
      );
    }
  };
  console.log("state", props);
  return (
    <div className="AuthBg">
      <Header />
      <div className="signIn">
        <div className="signInFormDiv">
          <div className="detailsDiv">
            <h2 style={{ color: "white" }}>
              Already an existing user? Sign in here.
            </h2>
            <div className="registerButton">
              <Link to={ROUTES.LOGIN}>
                <Button
                  width="160px"
                  height="42px"
                  background="secondary"
                  borderRadius="13px"
                  fontSize="19px"
                  color="primary"
                  padding="10px"
                  transition="background 0.3s"
                  display="inline-flex"
                  alignItem="center"
                  name="Sign In"
                />
              </Link>
            </div>
          </div>

          <div className="formDiv">
            <h1 className="signInHeading mb-0">Register</h1>
            <div className="horizontalLine"></div>
            <div className="randomName">
              <form className="signInForm" noValidate autoComplete="off">
                <div className="RegisterInfo">
                  <div className="d-flex flex-row">
                    <div className="d-flex flex-column">
                      <InputField
                        label="First Name"
                        variant="outlined"
                        type="text"
                        id="custom-css-outlined-input"
                        name="userName"
                        value={state?.userName}
                        handleChange={handleChange}
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.email && errorData.userName[0]}
                      </p>
                    </div>
                    <div className="d-flex flex-column">
                      <InputField
                        className="ml-2"
                        label="Last Name"
                        variant="outlined"
                        type="text"
                        id="custom-css-outlined-input"
                        name="lastName"
                        value={state?.lastName}
                        handleChange={handleChange}
                      />
                      <p className="errorMsg">
                        {" "}
                        {errorData?.email && errorData.lastName[0]}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="signInEmail">
                  {(state?.email || props.location.search == "") && (
                    <InputField
                      label="E-Mail"
                      variant="outlined"
                      type="text"
                      id="custom-css-outlined-input"
                      disabled={emailDisabled}
                      name="email"
                      value={state?.email}
                      handleChange={handleChange}
                      InputProps={{
                        readOnly: emailDisabled ? true : false,
                      }}
                    />
                  )}
                  <p className="errorMsg">
                    {" "}
                    {errorData?.email && errorData.email[0]}
                  </p>
                </div>
                <div className="signInPassword">
                  <InputField
                    label="Password"
                    type={isShowPassword == true ? "text" : "password"}
                    variant="outlined"
                    id="custom-css-outlined-input"
                    name="password"
                    value={state?.password}
                    handleChange={handleChange}
                  />
                  <p className="errorMsg">
                    {" "}
                    {errorData?.email && errorData.password[0]}
                  </p>
                  {isShowPassword === true ? (
                    <a
                      className="eyeicon"
                      onClick={() => setPasswordType(false)}
                    >
                      <VisibilityIcon />
                    </a>
                  ) : (
                    <a
                      className="eyeicon"
                      onClick={() => setPasswordType(true)}
                    >
                      <VisibilityOffIcon />
                    </a>
                  )}
                </div>
                <div className="signInPassword">
                  <InputField
                    label=" Confirm Password"
                    type={isShowConfirmPassword == true ? "text" : "password"}
                    variant="outlined"
                    id="custom-css-outlined-input"
                    name="confirmPassword"
                    value={state?.confirmPassword}
                    handleChange={handleChange}
                  />
                  <p className="errorMsg">
                    {" "}
                    {errorData?.email && errorData.confirmPassword[0]}
                  </p>
                  {isShowConfirmPassword === true ? (
                    <a
                      className="eyeicon"
                      onClick={() => setConfirmPasswordType(false)}
                    >
                      <VisibilityIcon />
                    </a>
                  ) : (
                    <a
                      className="eyeicon"
                      onClick={() => setConfirmPasswordType(true)}
                    >
                      <VisibilityOffIcon />
                    </a>
                  )}
                </div>

                <div className="signUpButton">
                  <Button
                    width="160px"
                    height="42px"
                    background="secondary"
                    borderRadius="15px"
                    fontSize="15px"
                    color="primary"
                    padding="10px"
                    transition="background 0.3s"
                    display="inline-flex"
                    alignItem="center"
                    name="Next"
                    handleClick={onSubmit}
                  />
                </div>
              </form>
              <div className="forgotPassword ">
                <a
                  rel="noreferrer"
                  href="https://learnsuite.kb.help/user-getting-started"
                  target="_blank"
                  style={{
                    color: "#0056b3",
                    textDecoration: "underline",
                  }}
                >
                  <div className="pt-1 mt-1">
                    Having trouble? Check our getting started guide
                  </div>
                </a>
              </div>
              {toast?.open ? (
                <Toast
                  open={toast.open}
                  message={toast.message}
                  duration={toast.duration}
                  type={toast.type}
                  handleClose={() =>
                    setToast({
                      ...toast,
                      open: false,
                    })
                  }
                ></Toast>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <ProgressBarStep value={25} />
    </div>
  );
};

export default Register;
