import React from "react";
import { Doughnut } from "react-chartjs-2";
import "./Styles.css";

const Card = ({ guess, answer, index, id }) => {
  const is_scene = guess.scene === answer.scene;
  const is_slice = guess.slice === answer.slice;
  const is_slot = guess.slot === answer.slot;
  const is_confused = guess.confused === answer.confused;
  const is_irritable = guess.irritable === answer.irritable;
  const is_boisterous = guess.boisterous === answer.boisterous;
  const is_physicalThreats = guess.physicalThreats === answer.physicalThreats;
  const is_verbalThreats = guess.verbalThreats === answer.verbalThreats;
  const is_attacking = guess.attacking === answer.attacking;

  return (
    <div className="assess-card-container">
      <h4>
        SITUATION - {answer.scene}_{answer.slice}_
        {answer.slot === 0 ? "L" : "R"}
      </h4>

      <div className="card-item">
        <span style={{ backgroundColor: is_confused ? "#5cb55b" : "#ff4f4f" }}>
          {is_confused ? "success" : "failed"}
        </span>
        <span> - Confused</span>
      </div>
      <div className="card-item">
        <span style={{ backgroundColor: is_irritable ? "#5cb55b" : "#ff4f4f" }}>
          {is_irritable ? "success" : "failed"}
        </span>
        <span> - Irritable</span>
      </div>
      <div className="card-item">
        <span style={{ backgroundColor: is_boisterous ? "#5cb55b" : "#ff4f4f" }}>
          {is_boisterous ? "success" : "failed"}
        </span>
        <span> - Boisterous</span>
      </div>
      <div className="card-item">
        <span style={{ backgroundColor: is_physicalThreats ? "#5cb55b" : "#ff4f4f" }}>
          {is_physicalThreats ? "success" : "failed"}
        </span>
        <span> - Physical Threats</span>
      </div>
      <div className="card-item">
        <span style={{ backgroundColor: is_verbalThreats ? "#5cb55b" : "#ff4f4f" }}>
          {is_verbalThreats ? "success" : "failed"}
        </span>
        <span> - Verbal Threats</span>
      </div>
      <div className="card-item">
        <span style={{ backgroundColor: is_attacking ? "#5cb55b" : "#ff4f4f" }}>
          {is_attacking ? "success" : "failed"}
        </span>
        <span> - Attacking</span>
      </div>
    </div>
  );
};

export default Card;
