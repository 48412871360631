import { PersonAdd } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import Button from "../../Component/Button/button";
import Table from "../../Component/Table/Table";
import DetailsModel from "../../Component/Modal/inviteModal";
import { useSelector, useDispatch } from "react-redux";
import HeaderBar from "../../Component/HeaderBar/headerBar";
import SideNav from "../../Component/SideNav/sideNav";
import { SuperAdminActions } from "../../ReduxStore/Actions";
import RBAC from "../../Component/RBAC/RBAC";
import { initList } from "../../Component/SideNav/initList";

const OrganisationManagement = () => {
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState([]);

  const [detailsModel, setDetailsModel] = useState(false);
  const [email, setEmail] = useState();

  let collapsedClass = useSelector(
    (state) => state.CollapsedSidenavReducer.collapsedClass
  );
  let allOrgs = useSelector((state) => state.SuperAdminReducer.allOrgs);
  let { user } = useSelector((state) => state.AuthReducer);
  let userDetail = useSelector((state) => state.SuperAdminReducer.user);

  useEffect(() => {
    if (user.uuid)
      dispatch(
        SuperAdminActions.getAllOrgs({
          uuid: user.uuid,
          callback: (response) => {},
        })
      );
  }, [user]);

  useEffect(() => {
    getTableData(allOrgs);
  }, [allOrgs]);
  const filterFunction = (e) => {
    let searchKeyword = e.target.value;

    if (searchKeyword.trim().length > 0) {
      let list = [];
      tableData.map((userr) => {
        var regex = new RegExp(`${searchKeyword}`, "i");
        let result = userr[0].match(regex);

        if (result && result.length > 0) {
          list.push(userr);
        }
      });

      setTableData(list);
    } else {
      getTableData(allOrgs);
    }
  };

  const getTableData = (result) => {
    let finalData = result.map((data) => {
      return [
        data["orgName"] || "-",
        data["ownerEmail"],
        data["orgName"] ? "Accepted" : "Pending",
        data["users"]?.length,
        "50",
      ];
    });

    setTableData(finalData);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleInvite = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const { uuid } = user;
    dispatch(
      SuperAdminActions.sendInvite({
        uuid,
        email,
        callback: () => {
          setDetailsModel(false);
        },
      })
    );
  };

  return (
    <div className="d-flex over">
      <div className={collapsedClass ? " sideNavExpand" : "sideNavContract "}>
        {userDetail && (
          <RBAC
            userRole={user?.role}
            initList={initList}
            renderNoAccess={() => <SideNav />}
          >
            <SideNav />
          </RBAC>
        )}
      </div>
      <div className={collapsedClass ? "screenContract bg" : "screenExpand bg"}>
        <HeaderBar
          width={collapsedClass ? "80%" : "95%"}
          title="Organisation Management"
          searchbar="yes"
          filterFunction={filterFunction}
        />
        <div className="componentSec ">
          <div className="d-flex justify-content-end">
            <div className="inviteButton">
              <Button
                background="secondary"
                borderRadius="15px"
                fontSize="15px"
                color="tertiary"
                padding="5px 40px"
                transition="all 0.5s ease"
                display="inline-flex"
                alignItem="center"
                icon={<PersonAdd />}
                name="Invite"
                handleClick={() => setDetailsModel(true)}
              />
              <DetailsModel
                open={detailsModel}
                handleClose={() => setDetailsModel(false)}
                title="Invite Organisation"
                input={
                  <input
                    placeholder="Enter e-mail address"
                    style={{
                      maxWidth: "659px",
                      width: "100%",
                      padding: "4px 15px",
                      height: "47px",
                      marginBottom: "19px",
                      background: "#FFFFFF",
                      boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px",
                      border: "none",
                    }}
                    value={email}
                    onChange={handleEmailChange}
                  />
                }
                button={
                  <Button
                    background="secondary"
                    color="primary"
                    name="Invite Organisation"
                    handleClick={handleInvite}
                  />
                }
              />
            </div>
          </div>
          <div className="pb-3">
            <Table
              tableHead={["Name", "Email", "Status", "No. of Users", "Seats"]}
              tableData={tableData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrganisationManagement;
