import { Doughnut as Pie, Chart } from "react-chartjs-2";

import { useEffect, useState } from "react";
import { getMultiPieChart } from "../../Helper/index";
import React from "react";
const datasets = {
  labels: [],
  datasets: [
    // {
    //   backgroundColor: ["green"],
    //   data: [100],
    // },
  ],
};
const VentriCularFibrillationPie = React.memo(
  ({ allTeamsAssessmentResults, orgUsers, filters = [] }) => {
    const [ventriCularFibrillation, setVentriCularFibrillation] = useState();

    useEffect(() => {
      VentriCularFibrillationFunction();
    }, [allTeamsAssessmentResults]);

    const VentriCularFibrillationFunction = async () => {
      await setVentriCularFibrillation({});
      let rythmCount = 0;
      const allAtrialFibrillation = allTeamsAssessmentResults.map((assess) => {
        return assess.coached.filter((coach) => {
          if (coach.rhythm === "Ventricular Fibrillation") {
            rythmCount++;
            return coach;
          }
        });
      });
      getMultiPieChart(
        rythmCount,
        allAtrialFibrillation,
        setVentriCularFibrillation
      );
    };

    return (
      <div className="pieOuterWrapper">
        <div className="pieWrapper">
        <span>Ventricular Fibrillation</span>
          {ventriCularFibrillation?.labels && (
            <div className="piieee">
              <Pie
                type="pie"
                data={
                  allTeamsAssessmentResults.length === 0
                    ? datasets
                    : ventriCularFibrillation
                }
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  // plugins: {
                  //   legend: {
                  //     labels: {
                  //       generateLabels: function (chart) {
                  //         // Get the default label list
                  //         console.log("labelsOriginal", chart);

                  //         const original =
                  //           Chart.overrides.pie.plugins.legend.labels
                  //             .generateLabels;
                  //         const labelsOriginal = original.call(this, chart);
                  //         console.log("labelsOriginal", labelsOriginal);
                  //         // Build an array of colors used in the datasets of the chart
                  //         var datasetColors = chart.data.datasets.map(function (
                  //           e
                  //         ) {
                  //           return e.backgroundColor;
                  //         });
                  //         datasetColors = datasetColors.flat();

                  //         // Modify the color and hide state of each label
                  //         labelsOriginal.forEach((label) => {
                  //           // There are twice as many labels as there are datasets. This converts the label index into the corresponding dataset index
                  //           label.datasetIndex =
                  //             (label.index - (label.index % 2)) / 2;

                  //           // The hidden state must match the dataset's hidden state
                  //           label.hidden = !chart.isDatasetVisible(
                  //             label.datasetIndex
                  //           );

                  //           // Change the color to match the dataset
                  //           label.fillStyle = datasetColors[label.index];
                  //         });

                  //         return labelsOriginal;
                  //       },
                  //     },
                  //     onClick: function (mouseEvent, legendItem, legend) {
                  //       // toggle the visibility of the dataset from what it currently is
                  //       legend.chart.getDatasetMeta(
                  //         legendItem.datasetIndex
                  //       ).hidden = legend.chart.isDatasetVisible(
                  //         legendItem.datasetIndex
                  //       );
                  //       legend.chart.update();
                  //     },
                  //   },
                  //   tooltip: {
                  //     callbacks: {
                  //       label: function (context) {
                  //         console.log("context");
                  //         const labelIndex =
                  //           context.datasetIndex * 2 + context.dataIndex;
                  //         return (
                  //           context.chart.data.labels[labelIndex] +
                  //           ": " +
                  //           context.formattedValue
                  //         );
                  //       },
                  //     },
                  //   },
                  // },
                }}
              />
            </div>
          )}
        </div>
        {/* <div className=" pieName mt-2 p-1 text-center">
          Ventricular Fibrillation
        </div> */}
      </div>
    );
  }
);

export default VentriCularFibrillationPie;
