import React from "react";
import ChartCard from "./ChartCard";

const Ventilation = ({ assessment }) => {
  const setVentilation = () => {
    const {
      ventilation: {
        ventcycle,
        orop,
        airwayProtected,
        lma,
        twohanded,
        intubation,
        simple,
      },
    } = assessment;
    let labels = [];
    if (ventcycle < 3)
      labels.push({
        success: true,
        label: `Attempted to protect airway before third COACHED`,
      });
    else
      labels.push({
        success: false,
        label: `Failed to attempt to protect airway before third COACHED`,
      });

    if (airwayProtected)
      labels.push({ success: true, label: "Airway is protected" });

    if (intubation) labels.push({ success: false, label: "Patient Intubated" });

    if (lma) {
      labels.push({ success: true, label: "LMA inserted" });
    } else if (!lma && orop && twohanded) {
      labels.push({ success: false, label: "LMA not inserted" });
      labels.push({ success: true, label: `LMA inserted` });
      labels.push({
        success: true,
        label: `Two handed technique was implemented`,
      });
    }

    if (orop && lma && !twohanded) {
      labels.push({ success: false, label: `Oropharyngeal airway inserted` });
    }

    if (twohanded && lma && !orop)
      labels.push({
        success: false,
        label: `Two handed technique was implemented`,
      });

    // if (simple)
    //   labels.push({
    //     success: false,
    //     label: `Simple airway maneuvers were implemented`,
    //   });

    return labels;
  };

  return <div>{assessment && <ChartCard labels={setVentilation()} />}</div>;
};

export default Ventilation;
