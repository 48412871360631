import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./scenario.css";
import { Card } from "react-bootstrap";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles({
  card: {
    width: "100%",
    margin: "30px 0px 0px",
    padding: "20px",
  },
  titleHeading: {
    fontWeight: "bold",
    fontSize: "15px",
  },
  titleSubHeading: {
    fontWeight: "bold",
    fontSize: "13px",
  },
  titleStatus: {
    fontWeight: "500",
    fontSize: "11px",
  },
  marginTop: {
    marginTop: "30px",
  },
});

const Scenario = (props) => {
  const classes = useStyles();
  let { scenarioData } = props;
  console.log("propbbbbbbbbbbbbs", scenarioData);
  return (
    <Card className={classes.card}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        className="mobileFlex"
      >
        {scenarioData && scenarioData.title && (
          <Grid item md={3} xs={3} className="headsection">
            <h3 className={classes.titleHeading}>{scenarioData.title}</h3>
            <h5 className={classes.titleStatus}>
              {scenarioData.value === "true" ? "Completed" : "Skipped"}
            </h5>
          </Grid>
        )}

        <Grid
          style={{ display: "block" }}
          container
          item
          md={5}
          xs={5}
          className={`mobileView ${classes.marginTop}`}
        >
          {scenarioData &&
            scenarioData.subTitle &&
            scenarioData.subTitle.map((data) => (
              <Grid item xs={12}>
                <h4 className={classes.titleSubHeading}>{data}</h4>
              </Grid>
            ))}
        </Grid>
        <Grid
          style={{ display: "block" }}
          container
          item
          md={4}
          xs={4}
          className={classes.marginTop}
        >
          {scenarioData &&
            scenarioData.subValue &&
            scenarioData.subValue.map((data) => (
              <Grid item xs={12}>
                <h6 className={classes.titleStatus}>
                  {data === "true" ? "Completed" : "Skipped"}
                </h6>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Card>
  );
};
export default Scenario;
