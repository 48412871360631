import React, { useEffect, useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import Button from "../../Component/Button/button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import "./inviteModal.css";
import AppTour from "../AppTour/tour";
const FormDialog = (props) => {
  // useEffect(() => {
  //   if (props.open)
  //     props.startTour();
  // }, [props.open]);

  return (
    <div className="detailsModalContainer">
      <Dialog
        open={props.open}
        className="details"
        disableBackdropClick
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <DialogTitle
          id="form-dialog-title "
          className="text-center model-head inviteModalStart"
          style={{
            position: "static",
            fontSize: "25px",
            fontWeight: 600,
          }}
        >
          {props.title}
        </DialogTitle>
        <DialogContent className="mb-3" style={{ width: "100%" }}>
          <div
            className="d-flex flex-column text-center mt-2"
            style={{ alignItems: "center" }}
          >
            {/* <AppTour
              run={props.runTour}
              steps={props.tourSteps}
              handleClose={() => props.closeTour()}
            /> */}
            {/* <div style={{ marginBottom: "20px", fontSize: "20px" }}>
              Upload New Version
            </div> */}
            {props.input}
            {props.dropDown}
            {props.orgId && props.orgInput}
            {props.input2}
            {props.detailContainer}
          </div>
          <div
            className="d-flex flex-row justify-content-evenly flex-wrap text-center mt-2"
            style={{ alignItems: "center", justifyContent: "space-evenly" }}
          >
            {props.button}
            {props.cancelButton}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default FormDialog;
