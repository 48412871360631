import React from "react";
import "./index.css";
function Legend() {
  return (
    <div className="legends">
      <span className="first"></span>
      <span>Correct use of Defib</span>

      <span className="second"></span>
      <span>Incorrect use of Defib</span>
    </div>
  );
}

export default Legend;
