import React, { useEffect, useState } from "react";
import Header from "../../PublicModule/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { Paper } from "@material-ui/core";
import OrgSelectionCard from "../../Component/Card/OrgSelectionCard";
import { AuthActions } from "../../ReduxStore/Actions";
import { ROUTES } from "../../Services/Constants";
import { getUserDetailsJWT } from "../../Services/Utils/utils";
import Button from "../../Component/Button/button";
import moment from "moment";
import AlertDialog from "../../Component/AlertDialog/alertDialog";
import { useHistory } from "react-router-dom";
import ProgressBarStep from "../../Component/ProgressBar/ProgressBar";
import "./index.css";
const InviteAccept = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [orgIds, setOrgIds] = useState([]);
  const [open, setOpen] = React.useState({
    state: false,
    data: "none",
  });
  let { user, userDetails } = useSelector((state) => state.AuthReducer);

  console.log(
    "userDetails?.pendingInvitations",
    userDetails?.pendingInvitations
  );
  useEffect(() => {
    const { user: userD } = getUserDetailsJWT();
    dispatch(
      AuthActions.getUserDetails({
        uuid: userD.uuid,
        email: userD.email,
        callback: (response) => {},
      })
    );
  }, []);
  useEffect(() => {
    if (
      userDetails &&
      userDetails?.pendingInvitations &&
      userDetails?.pendingInvitations.length > 0
    ) {
      let orgIds = userDetails?.pendingInvitations.map((data) => data.orgId);

      setOrgIds(orgIds);
    }
  }, [userDetails]);
  const handleAccept = () => {
    dispatch(
      AuthActions.confirmEmail({
        uuid: user.uuid,
        email: user.email,
        orgId: open.data.orgId,
        from: open.data.pendingInvitations?.filter(
          (org) => org.email === user.email
        )[0].from,
        callback: (response) => {
          dispatch(
            AuthActions.getUserDetails({
              uuid: user.uuid,
              email: user.email,
              callback: (response) => {},
            })
          );
          setOpen({
            state: false,
            data: "none",
          });
        },
      })
    );
  };
  const handleAcceptMultiple = () => {
    let Orgs = userDetails?.pendingInvitations.map((data) => {
      if (orgIds.includes(data.orgId)) {
        return {
          orgId: data.orgId,
          from: data.pendingInvitations?.filter(
            (org) => org.email === user.email
          )[0].from,
        };
      }
    });
    Orgs=Orgs.filter((data)=> data!==undefined)
    console.log("ORGSSS", Orgs);
    dispatch(
      AuthActions.confirmOrgIdsEmails({
        uuid: user.uuid,
        email: user.email,
        Orgs: Orgs,
        callback: (response) => {
          dispatch(
            AuthActions.getUserDetails({
              uuid: user.uuid,
              email: user.email,
              callback: (response) => {},
            })
          );
          // setOpen({
          //   state: false,
          //   data: "none",
          // });
          history.push(ROUTES.WELCOME_MESSAGE);
        },
      })
    );
  };
  const handleOrgIds = (event, value) => {
    let { checked } = event.target;

    if (!checked) {
      let orgId = orgIds.filter((data) => data !== value);
      setOrgIds(orgId);
    } else {
      let orgId = [...orgIds];
      orgId.push(value);

      setOrgIds(orgId);
    }
  };
  return (
    <div className="AuthBg orgSelectionDiv">
      <Header />
      <div className="signIn">
        <Paper className="paperGrid">
          <div className="d-flex flex-column p-4">
            <h3>Accept Invitations</h3>
            {userDetails && userDetails?.pendingInvitations?.length > 0 ? (
              userDetails?.pendingInvitations?.map((item) => {
                return (
                  <OrgSelectionCard
                    name={item["orgName"]}
                    // no={item["users"].length}
                    handleAccept={(e) => {
                      handleOrgIds(e, item.orgId);
                    }}
                    selectType="checkBox"
                    checkedState={orgIds.includes(item.orgId)}
                    // buttonName="Accept"
                    // created={moment().diff(item.dateCreated.split("T")[0], 'month') + " months ago"}
                  />
                );
              })
            ) : (
              <div className="basicMsg">
                <span className="textSpan">
                  You don't have any pending invitation
                </span>
              </div>
            )}
            <Button
              background="secondary"
              name="Next"
              color="primary"
              textAlign="center"
              handleClick={() => {
                handleAcceptMultiple();
              }}
            ></Button>
          </div>
        </Paper>
        <AlertDialog
          open={open.state}
          handleClose={() =>
            setOpen({
              ...open,
              state: false,
            })
          }
          message="Are you sure you want accept"
          button1="Yes"
          button2="No"
          handleDelete={handleAccept}
        />
      </div>
      <ProgressBarStep value={60} />
    </div>
  );
};

export default InviteAccept;
