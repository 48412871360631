import React from "react";

import {
  BrowserRouter as Router,
  Redirect,
  useHistory,
} from "react-router-dom";
import WebsiteModule from "../PublicModule/WebsiteModules";

const PublicRoutes = () => {
  return (
    <Router>
      <WebsiteModule />
    </Router>
  );
};
export default PublicRoutes;
