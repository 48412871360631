import React from "react";
import { Bar } from "react-chartjs-2";
import moment from "moment";
const MAX_ALLOWED = 2;

const HorizontalBarGraph = ({ allTeamsAssessmentResults, type }) => {
  const renderLabels = () => {
    let _labels = [];
    if (type === "ventilation") {
      _labels = [
        // "lma",
        // "intubation",
        // "orop true && two handed",
        // "airway protected",
        // "vent cycle <= max true",
        // "vent cycle <= max false",
        "LMA",
        "Intubation",
        "OPA  & Two-Handed",
        "Airway Protected",
        "Protected Before 3rd",
        "Protected After 3rd",
      ];
      return _labels;
    } else if (type === "fluids") {
      _labels = [
        // "onHandPump",
        // "onInfusionPump",
        // "viaCentralLine",
        // "viaCubitalFossa",
        // "fluid cycle <= max true",
        // "fluid cycle <= max false",
        "  On Hand Pump",
        "On Infusion Pump",
        "IV Access Via Central Line",
        "IV Access Via Cubital Fossa",
        "Fluids Given Before 2nd",
        "Fluids Given After 2nd",
      ];
      return _labels;
    } else if (type === "HT") {
      // let obj =
      //   allTeamsAssessmentResults &&
      //   allTeamsAssessmentResults[0] &&
      //   allTeamsAssessmentResults.ht;
      // if (obj) _labels = Object.keys(obj);
      _labels =
        // allTeamsAssessmentResults.length > 0 && allTeamsAssessmentResults[0]
        // 	? [...Object.keys(allTeamsAssessmentResults[0]?.ht)]
        // 	: [];
        [
          // "hypovolemia",
          // "hypoxia",
          // "hyperHypokalemia",
          // "hypothermia",
          // "toxins",
          // "tamponade",
          // "tensionPneumothorax",
          // "thrombosis",
          "Hypovolaemia",

          "Hypoxia",
          "Hyper/Hypokalaemia",
          "Hyper/Hypothermia",
          "Toxins",
          "Tamponade",
          "Tension Pneumothorax",
          "Thrombosis",
        ];
      return _labels;
    } else if (type === "cpr") {
      _labels = [
        // "bpm 50",
        // "bpm 80",
        // "bpm 110",
        // "2 cm",
        // "5 cm",
        // "10 cm",
        // "cpr cycle <= max true",
        // "cpr cycle <= max false",
        "BPM 50",
        "BPM 80",
        "BPM 110",
        "2 cm Compression Depth",
        "3 cm Compression Depth",
        "10 cm Compression Depth",
        "CPR Confirmed Before 2nd",
        "CPR Confirmed After 2nd",
      ];
      return _labels;
    }
  };

  const renderData = () => {
    let _data = [0];

    // console.log("allTeamsAssessmentResults :>> ", allTeamsAssessmentResults);
    if (type === "ventilation") {
      let count = {
        lma: 0,
        intubation: 0,
        "orop true && two handed": 0,
        "airway protected": 0,
        "vent cycle <= max allowed true": 0,
        "vent cycle <= max allowed false": 0,
      };
      allTeamsAssessmentResults &&
        allTeamsAssessmentResults
          .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
          .map(({ ventilation }) => {
            if (ventilation.lma) count["lma"]++;
            if (ventilation.intubation) count.intubation++;
            if (ventilation.orop && ventilation.twohanded)
              count["orop true && two handed"]++;
            if (!ventilation.airwayProtected) count["airway protected"]++;
            if (ventilation.ventcycle <= MAX_ALLOWED)
              count["vent cycle <= max allowed true"]++;
            if (!(ventilation.ventcycle <= MAX_ALLOWED))
              count["vent cycle <= max allowed false"]++;
          });
      // console.log("OBJ", count);

      _data = [...Object.values(count)];
      // console.log("OBJ", _data);

      return _data;
    } else if (type === "fluids") {
      let count = {
        onHandPump: 0,
        onInfusionPump: 0,
        viaCentralLine: 0,
        viaCubitalFossa: 0,
        "fluid cycle <= max allowed true": 0,
        "fluid cycle <= max allowed false": 0,
      };
      allTeamsAssessmentResults &&
        allTeamsAssessmentResults
          .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
          .map(({ fluids }) => {
            if (fluids.onHandPump) count["onHandPump"]++;
            if (fluids.onInfusionPump) count["onInfusionPump"]++;
            if (fluids.viaCentralLine) count["viaCentralLine"]++;
            if (fluids.viaCubitalFossa) count["viaCubitalFossa"]++;
            if (fluids.fluidCycle <= MAX_ALLOWED)
              count["fluid cycle <= max allowed true"]++;
            if (!(fluids.fluidCycle <= MAX_ALLOWED))
              count["fluid cycle <= max allowed false"]++;
          });
      // console.log("OBJ", count);

      _data = [...Object.values(count)];
      // console.log("OBJ", _data);

      return _data;
    } else if (type === "cpr") {
      let count = {
        "bpm 50": 0,
        "bpm 80": 0,
        "bpm 110": 0,
        "2 cm": 0,
        "5 cm": 0,
        "10 cm": 0,
        "cpr cycle <= max allowed true": 0,
        "cpr cycle <= max allowed false": 0,
      };
      allTeamsAssessmentResults &&
        allTeamsAssessmentResults
          .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
          .map(({ cpr }) => {
            if (cpr.bpm110) count["bpm 110"]++;
            if (cpr.bpm80) count["bpm 80"]++;
            if (cpr.bpm50) count["bpm 50"]++;
            if (cpr.cm10) count["10 cm"]++;
            if (cpr.cm5) count["5 cm"]++;
            if (cpr.cm2) count["2 cm"]++;
            if (cpr.cprCycle <= MAX_ALLOWED)
              count["cpr cycle <= max allowed true"]++;
            if (!(cpr.cprCycle <= MAX_ALLOWED))
              count["cpr cycle <= max allowed false"]++;
          });
      // console.log("OBJ", count);

      _data = [...Object.values(count)];
      // console.log("OBJ", _data);
      return _data;
    } else if (type === "HT") {
      let count = {};
      allTeamsAssessmentResults &&
        allTeamsAssessmentResults[0] &&
        Object.keys(allTeamsAssessmentResults[0].ht).map(
          (key) => (count[`${key}`] = 0)
        );
      allTeamsAssessmentResults &&
        allTeamsAssessmentResults
          .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
          .map(({ correctHt, ht }) => {
            Object.keys(ht).map((key) => {
              if (ht[`${key}`] != correctHt[`${key}`]) count[`${key}`]++;
            });
          });
      // console.log("OBJ", count);

      _data = [...Object.values(count)];
      // console.log("OBJ", _data);
      return _data;
    }
  };

  // Generate random color
  const generateColor = () => Math.floor(Math.random() * 255);

  const datasets = [
    {
      fill: false,
      // lineTension: 0.1,
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(255, 205, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(201, 203, 207, 0.2)",
      ],
      fontSize: 8,
      borderColor: [
        "rgb(255, 99, 132)",
        "rgb(255, 159, 64)",
        "rgb(255, 205, 86)",
        "rgb(75, 192, 192)",
        "rgb(54, 162, 235)",
        "rgb(153, 102, 255)",
        "rgb(201, 203, 207)",
      ],
      borderWidth: 1,
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      // pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: `rgba(${generateColor()},${generateColor()},${generateColor()}, 1)`,
      pointBorderWidth: 1,
      pointHoverRadius: 4,
      pointHoverBackgroundColor: "rgba(75,192,192,1)",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 2,
      pointHitRadius: 5,
      data: renderData(),
    },
  ];
  // console.log("allTeamsAssessmentResults", type, datasets, renderLabels());
  // console.log(
  //   "findout",
  //   allTeamsAssessmentResults[0]?.ht,
  //   "allTeamsAssessmentResults",
  //   allTeamsAssessmentResults
  // );

  return (
    <div 
    style={{borderBottomLeftRadius: "3px",borderBottomRightRadius: "3px"}} className="graphParentMain_Div">
      <Bar
        data={{ datasets, labels: renderLabels() }}
        options={{
          maintainAspectRatio: false,
          indexAxis: "y",
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </div>
  );
};
export default HorizontalBarGraph;
