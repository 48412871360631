import React from "react";
import { Doughnut } from "react-chartjs-2";
import ChartLabels from "./ChartLabels";
import "./style.css";

export const Donut = (props) => {
    {console.log("data", props?.data)} 

    const {
        value, subTitle, subValue
    } = props?.data
    //   const calculatePercent = () => {
    //     let count = 0;
    //     let newLabels = [];
    //     let _labels = labels.map(({ success }) => {
    //       return success && (count = count + 1);
    //     });
    //     newLabels = _labels.filter((label) => !label.title);
    //     return parseInt((count / newLabels.length) * 100);
    //   };

    //   const data = {
    //     datasets: [
    //       {
    //         data: [calculatePercent(), 100 - calculatePercent()],
    //         backgroundColor: ["#5cb55b", "#ff4f4f"],
    //       },
    //     ],
    //   };

    return (
        <div className="card-container">
            <div className="card-donut-container">
                    <p style={{
                        backgroundColor: value == "true" ? "#5cb55b" : "#ff4f4f",
                    }}>{value == "true" ? "Completed" : "Skipped"}</p>
            </div>
            <ChartLabels
                subTitles={subTitle}
                subValues={subValue} />
        </div>
    );
};
export default Donut;
