import axios from "axios";
import { BASE_URL } from "../Constants";

const axiosInstance = axios.create({ baseURL: BASE_URL });

const errHandling = (error) => {
  console.log("error in axios", error);
  Promise.reject(error);
};

const reqHandling = (config) => {
  const obj = { ...config };
  const token = localStorage.getItem("token");

  if (token) obj.headers["Authorization"] = token;
  return obj;
};

const resHandling = (response) => response;
axiosInstance.interceptors.request.use(reqHandling, errHandling);
axiosInstance.interceptors.response.use(resHandling, errHandling);

export default axiosInstance;

// configuration.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem("token");
//     if (token) {
//       config.headers["Authorization"] = token;
//     }
// config.headers['Access-Control-Allow-Origin'] = 'http://localhost:3000';
// config.headers["Access-Control-Allow-Origin"] = "http://frle.app";
//     config.headers["Access-Control-Allow-Origin"] = "*";

//     return config;
//   },
//   (error) => Promise.reject(error)
// );

// // axios.defaults.headers['Access-Control-Allow-Origin'] = '*';
// // axios.defaults.headers['Authorization'] = localStorage.getItem('token');
// // axios.defaults.headers['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';
// // axios.defaults.headers['Content-Type'] = 'application/json';

// export default configuration;
