import axios from "axios";
import { ACTION_TYPE } from "../../Services/Constants/index";
import Axios from "../../Services/Utils/axios";
import { startLoading, stopLoading } from "./CommonActions";

export const getStoreApps =
  ({ callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.get(`/launcher/storeapps`);

      console.log("getStoreApps", response);
      //   dispatch({
      //     type: ACTION_TYPE.SET_SINGLE_ASSESSMNTS_RESULT,
      //     payload: response.data.result,
      //   });

      callback({ success: true, result: response.data.result });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

export const getLauncherApp =
  ({ callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.get(`/launcher/launcherapp`);

      console.log("getLauncherapp", response);
      //   dispatch({
      //     type: ACTION_TYPE.SET_SINGLE_ASSESSMNTS_RESULT,
      //     payload: response.data.result,
      //   });

      callback({ success: true, result: response.data.result });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

export const uploadFile =
  ({ file, appId, callback }) =>
  async (dispatch) => {
    try {
      Axios.post(`/launcher/upload`, {
        originalname: file.name,
        appId,
      }).then((resp) => {
        var options = {
          headers: {
            "Content-Type": file.type,
          },
        };

        Axios.put(resp.data.result.uri, file, options).then(
          async (resps, err) => {
            if (err) {
              console.log("err :>> ", err);
              callback({ success: false, result: err.message });
            }

            callback({ success: true, result: resp.data.result });
          }
        );
      });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };
export const saveDetailsToDB =
  ({ params, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post(`/launcher/save`, params);

      console.log("saveDetailsToDB", response);
      //   dispatch({
      //     type: ACTION_TYPE.SET_SINGLE_ASSESSMNTS_RESULT,
      //     payload: response.data.result,
      //   });

      callback({ success: true, result: response.data.result });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

export const updateLauncherAppDetails =
  ({ params, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post(`/launcher/update`, params);

      console.log("updateLauncherApp", response);

      callback({ success: true, result: response.data.result });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

export const getApp = async ({ appId, callback }) => {
  try {
    const {
      data: { data },
    } = await Axios.get(`/launcher/getApp/${appId}`);
    callback({ success: true, result: data });
  } catch (err) {
    console.log("err in fetching app details", err);
    callback({ success: false, result: err.message });
  }
};

export const getPrevVersions =
  ({ appId, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.get(`/launcher/prev/${appId}/1`);
      console.log(
        "🚀 ~ file: LauncherActions.js:105 ~ getPrevVersions ~ response:",
        response
      );
      callback({ success: true, result: response.data.result });
    } catch (err) {
      console.log("err in fetching app details", err);
      callback({ success: false, result: err.message });
    }
  };

export const updateLauncherAppTest =
  ({ params, callback }) =>
  async (dispatch) => {
    try {
      const formData = new FormData();
      for (let param in params) {
        if (param === "images") {
          for (let image of params[param]) {
            formData.append(param, image);
          }
        } else {
          formData.append(param, params[param]);
        }
      }
      const response = await Axios.post(`/launcher/update-test`, formData, {
        headers: { "Content-Type": "undefined" },
      });

      console.log("updateLauncherApp", response);

      callback({ success: true, result: response.data.result });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

export const updateStoreApp =
  ({ params, callback }) =>
  async (dispatch) => {
    try {
      const formData = new FormData();
      for (let param in params) {
        if (param === "images") {
          for (let image of params[param]) {
            formData.append(param, image);
          }
        } else {
          formData.append(param, params[param]);
        }
      }
      const response = await Axios.post(
        `/launcher/storeapps/update/${params.id}`,
        formData
        // {
        //   headers: { "Content-Type": "undefined" },
        // }
      );

      console.log("updateLauncherApp", response);

      callback({ success: true, result: response.data.result });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

export const upgradeStoreApp =
  ({ params, callback }) =>
  async (dispatch) => {
    const formData = new FormData();
    for (let param in params) {
      if (param === "images") {
        for (let image of params[param]) {
          formData.append(param, image);
        }
      } else {
        formData.append(param, params[param]);
      }
    }
    try {
      const response = await Axios.post(
        `/launcher/storeapps/upgrade/${params.appId}`,
        formData
      );

      console.log("upgradeStoreApp", response);
      callback({ success: true, result: response.data.result });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

export const addStoreApp =
  ({ params, callback }) =>
  async (dispatch) => {
    const formData = new FormData();
    for (let param in params) {
      if (param === "images") {
        for (let image of params[param]) {
          formData.append(param, image);
        }
      } else {
        formData.append(param, params[param]);
      }
    }
    try {
      const response = await Axios.post(`/launcher/storeapps/add`, formData);

      console.log("addStoreApp", response);
      callback({ success: true, result: response.data.result });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };

export const deleteStoreApp =
  ({ params, callback }) =>
  async (dispatch) => {
    try {
      const response = await Axios.delete(
        `/launcher/storeapps/${params.appId}`
      );

      console.log("deleteStoreApp", response);
      callback({ success: true, result: response.data.result });
    } catch (error) {
      callback({ success: false, result: error.message });
    }
  };
