import React from "react";
import "./table.css";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";

const Table = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuIndex, setMenuIndex] = React.useState(null);
  const handleMenuClick = (i, event) => {
    console.log("🚀  file: Table.js  line 86  handleMenuClick  i", i);

    setMenuIndex(i);
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  function capitalizeFirstLetter(str) {
    if (str) {
      return str
        .split(" ")
        .map(
          (string) => string && string.charAt(0).toUpperCase() + string.slice(1)
        )
        .join(" ");
    }

    return null;
  }

  return (
    <div className="tableContainer">
      <table>
        <thead className="tableHeading">
          <tr>
            {props.tableHeaders &&
              props.tableHeaders.length > 0 &&
              props.tableHeaders.map((header, index) => {
                return (
                  <th key={index}>
                    {capitalizeFirstLetter(header.replace(/_/g, " "))}
                  </th>
                );
              })}

            {props.action && props.action.name && <th>{props.action.name}</th>}
          </tr>
        </thead>
        <tbody className="tableBody">
          {props.tableData &&
            props.tableData.length > 0 &&
            props.tableData.map((item, index) => (
              <tr key={index}>
                {Object.keys(item)
                  .filter((key) => !props.hideColumns?.includes(key))
                  .map((result, key) => {
                    if (
                      props.showActionWithColumns &&
                      props.showActionWithColumns.length > 0 &&
                      props.showActionWithColumns[0]["key"] === result
                    ) {
                      return (
                        <td key={index}>
                          {props.showActionWithColumns[0].button(item)}
                        </td>
                      );
                    } else {
                      return (
                        <td key={index}>
                          {item[result] ? item[result] : "NA"}
                        </td>
                      );
                    }
                  }, {})}

                {props.action && (
                  <td>
                    <div className="actionBtn">
                      {props.action.buttonOne && props.action.buttonOne(item)}
                      {props.action.buttonTwo && props.action.buttonTwo(item)}
                      {props.action.buttonThree &&
                        props.action.buttonThree(item)}
                    </div>
                  </td>
                )}
                {props.optionList && props.optionList.length > 0 ? (
                  <td className="adminActionBtn">
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={(e) => handleMenuClick(item.id, e)}
                    >
                      <MoreVert />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={anchorEl}
                      open={open && menuIndex === item.id}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          maxHeight: "fit-content",
                          width: "fit-content",
                          background: "#fff",
                          padding: "0px",
                    
                        },
                      }}
                    >
                      {props.optionList &&
                        props.optionList.map((option) => (
                          <MenuItem
                          className="tableMenuItem"
                           
                            key={option.value}
                            // selected={option.value === "RTO"}
                            onClick={() => {
                              props.handleOptionClick(item, option);
                              handleClose();
                            }}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                    </Menu>
                  </td>
                ) : null}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
export default Table;
