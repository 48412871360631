import React, { useState, useEffect } from "react";
import "./Notification.css";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import Button from "../Button/button";
import { useDispatch, useSelector } from "react-redux";
import { AuthActions } from "../../ReduxStore/Actions";
import AlertDialog from "../AlertDialog/alertDialog";
import Badge from "@material-ui/core/Badge";

const Notification = (props) => {
  const dispatch = useDispatch();
  const [showing, setShowing] = useState(false);
  const [open, setOpen] = React.useState({
    state: false,
    data: "none",
  });

  let user = useSelector((state) => state.AuthReducer.user);

  const handleClick = () => {
    dispatch(
      AuthActions.confirmEmail({
        uuid: user.uuid,
        email: user.email,
        orgId: open.data.orgId,
        from: open.data.pendingInvitations?.filter(
          (org) => org.email === user.email
        )[0].from,
        callback: (response) => {
          dispatch(
            AuthActions.getUserDetails({
              uuid: user.uuid,
              email: user.email,
              callback: (response) => {},
            })
          );
          setOpen({
            state: false,
            data: "none",
          });
        },
      })
    );
  };
  /*
   add event listener to listen for the click event outside the notification section, so that notification section
     can be closed when click outside the notification section 
    
    event listener is only added when notification section is open
  */
  useEffect(() => {
    if (showing) {
      console.log(showing);
      document.addEventListener("click", (e) => {
        console.log("target", e.target?.parentNode?.parentNode, e.target?.id);
        if (e.target?.parentNode?.parentNode?.id != "notification") {
          setShowing(false);
        }
      });
    }
  }, [showing]);
  return (
    <div
      id="notification"
      className="dropdownMain"
      onClick={() => setShowing(!showing)}
    >
      <Badge
        color="secondary"
        className="badgeIcon"
        badgeContent={props.pendingInviteList?.length}
        showZero
      >
        <NotificationsNoneOutlinedIcon className=" ml-4 headerBarIcon" />
      </Badge>

      {showing ? (
        <div className="dropdown">
          <h3 className="notificationText">Notifications</h3>
          <ul className="notificationList">
            {props.pendingInviteList?.length > 0 &&
              props.pendingInviteList.map((invite) => {
                return (
                  <li className="withNotification">
                    {invite.orgName + " have invited you to join"}
                    <Button
                      background="primary"
                      color="#fff"
                      name="Accept"
                      handleClick={(e) => {
                        setOpen({
                          state: true,
                          data: invite,
                        });
                      }}
                    />
                  </li>
                );
              })}
            {props.pendingInviteList?.length == 0 && (
              <li classname="noNotification">No New Notification</li>
            )}
          </ul>
        </div>
      ) : null}
      <AlertDialog
        open={open.state}
        handleClose={() =>
          setOpen({
            ...open,
            state: false,
          })
        }
        message="Are you sure you want accept"
        button1="Yes"
        button2="No"
        handleDelete={handleClick}
      />
    </div>
  );
};
export default Notification;
