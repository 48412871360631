import React from "react";
import { Line } from "react-chartjs-2";

let booleanData = ["Eye Contact While Listening", "Eye Contact While Talking"];

const LineGraph = ({ responseList = [], label = "Score", minMax }) => {
  console.log(
    "🚀 ~ file: lineGraph.js ~ line 7 ~ LineGraph ~ responseList",
    responseList,
    minMax
  );
  if (booleanData.includes(label)) {
    console.log(
      "🚀 ~ file: lineGraph.js ~ line 7 ~ LineGraph ~ responseList",
      responseList,
      minMax
    );
  }
  const renderLabels = () => {
    let _labels = [];
    responseList &&
      responseList
        // .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
        .map((item, index) => {
          return _labels.push(index + 1);
        });
    return _labels;
  };

  const renderData = () => {
    let _data = [];

    responseList &&
      responseList
        // .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
        .map((item) => {
          console.log("item", item);
          _data.push(`${item}`);
        });
    console.log("item", _data);
    return _data;
  };

  // Generate random color
  const generateColor = () => Math.floor(Math.random() * 255);

  const datasets = [
    {
      // label,
      fill: false,
      lineTension: 0.1,
      backgroundColor: "rgba(75,192,192,0.4)",
      fontSize: 8,
      // borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      // pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: `rgba(${generateColor()},${generateColor()},${generateColor()}, 1)`,
      pointBorderWidth: 1,
      pointHoverRadius: 10,
      pointHoverBackgroundColor: "rgba(75,192,192,1)",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 10,
      pointHitRadius: 5,
      //   data: renderData(),
      data: booleanData.includes(label) ? renderData() : responseList,
    },
  ];

  return (
    <div
      //   id="line-graph-containe"
      className=""
      style={{
        padding: "20px",
        boxSizing: "border-box",
        backgroundColor: "white",
      }}
    >
      <p style={{ fontWeight: "600", paddingTop: "10px", textAlign: "center" }}>
        {label}
      </p>

      {responseList && (
        <Line
          data={{ datasets, labels: renderLabels() }}
          options={{
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    let rawInContext = context.raw.toString() || "";
                    console.log(
                      "🚀 ~ file: lineGraph.js ~ line 87 ~ LineGraph ~ context.dataset",
                      rawInContext
                    );

                    return rawInContext;
                  },
                },
              },
            },
            scales: {
              y: minMax
                ? {
                    min: minMax.min,
                    max: minMax.max,
                    type: booleanData.includes(label) ? "category" : "linear",
                    labels: ["true", "false"],

                    // position: "right", // `axis` is determined by the position as `'y'`
                    ticks: {},
                  }
                : {
                    type: booleanData.includes(label) ? "category" : "linear",
                    labels: ["true", "false"],

                    // position: "right", // `axis` is determined by the position as `'y'`
                    ticks: {},
                  },
            },
          }}
        />
      )}
    </div>
  );
};
export default LineGraph;
